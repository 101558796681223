import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["progressBar", "bar", "status", "button", "statusContainer", "profitMargin", "priceForm", "priceInput", "profitCell"]

  connect() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const statusDiv = mutation.addedNodes[0]
          if (statusDiv.dataset.progressBarStatus === 'success') {
            this.success()
          } else if (statusDiv.dataset.progressBarStatus === 'error') {
            this.error(statusDiv.dataset.errorMessage)
          }
        }
      })
    })

    this.observer.observe(this.statusContainerTarget, { childList: true })
  }

  disconnect() {
    this.observer?.disconnect()
  }

  startProgress(event) {
    event.preventDefault()
    
    // Reset progress bar state
    this.barTarget.classList.remove("bg-danger")
    this.progressBarTarget.classList.remove('d-none')
    this.buttonTarget.disabled = true
    this.updateProgress(0, "Starting price reset...")

    const margin = this.profitMarginTarget.value
    const productTypeId = this.buttonTarget.dataset.productTypeId
    const formData = new FormData()
    formData.append('profit_margin', margin)
    formData.append('product_type_id', productTypeId)

    // Clear any existing timeouts
    this.clearTimeouts()
    
    // Store timeouts for cleanup
    this.progressTimeouts = []
    
    this.progressTimeouts.push(
      setTimeout(() => this.updateProgress(30, "Resetting variant prices..."), 500),
      setTimeout(() => this.updateProgress(60, "Resetting placement prices..."), 1500),
      setTimeout(() => this.updateProgress(90, "Finalizing changes..."), 2500)
    )

    fetch('/dashboard/product_pricing/reset_product_type_prices', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'text/vnd.turbo-stream.html'
      },
      body: formData
    })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok')
      return response.text()
    })
    .then(text => {
      Turbo.renderStreamMessage(text)
    })
    .catch(error => {
      this.error("Network error occurred")
      console.error('Error:', error)
    })
  }

  success() {
    // Clear any existing timeouts immediately
    this.clearTimeouts()
    
    this.updateProgress(100, "Complete!")
    setTimeout(() => {
      this.progressBarTarget.classList.add("d-none")
      this.buttonTarget.disabled = false
    }, 1000)
  }

  error(message) {
    // Clear any existing timeouts immediately
    this.clearTimeouts()
    
    this.barTarget.classList.add("bg-danger")
    this.updateProgress(100, `Error: ${message}`)
    setTimeout(() => {
      this.progressBarTarget.classList.add("d-none")
      this.buttonTarget.disabled = false
      this.barTarget.classList.remove("bg-danger")
    }, 3000)
  }

  clearTimeouts() {
    if (this.progressTimeouts) {
      this.progressTimeouts.forEach(timeout => clearTimeout(timeout))
      this.progressTimeouts = null
    }
  }

  updateProgress(percentage, status) {
    this.barTarget.style.width = `${percentage}%`
    this.statusTarget.textContent = status
  }
}
