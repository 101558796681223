import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Path} from '../TextWarp/Path';
import {calculateViewportCenter} from "../lib/utils";
import Session from 'svg-text-to-path';  // For Text to SVG Path
import {ChromePicker} from 'react-color'; // Import the color picker component
import {Swatch} from '../SimpleElementOptions/SwatchControls'; // Import the Swatch component
//import { buildColorMap, updateSVGColors, extractColorsFromSVG } from "../SimpleElementOptions/propertiesUtils"; // Import color map functions
import CustomFontSelector from '../SimpleElementOptions/CustomFontSelector'; // Adjust the import path as needed
import {useSelectionContext} from "../../contexts/SelectionContext";
import { v4 as uuidv4 } from 'uuid'; // We'll use this for unique gradient stop IDs
import { DEFAULT_GRADIENT_STOPS, createGradientHandlers, getGradientProperties } from '../../utils/gradientUtils';
import { applyWarpToPath, measureWarpedPath } from '../../utils/warpUtils';
import { transform3DPath } from '../../utils/konva3DUtils';
import { debounce } from 'lodash';

const googleFonts = [
    'Roboto', 'Open Sans', 'Lato', 'Oswald', 'Montserrat', 'Raleway', 'PT Sans', 'Ubuntu', 'Rubik Burned',
    // Add more fonts as desired
];

const fontSizes = [
    '64', '72', '96', '128', '144', '192',
];

const TextAsset = ({
                       loadElement,
                       isListVisible,
                       isAuthenticated,
                       selectedButton,
                       setElems,
                       elems,
                       placementRef,
                       stageRef,
                       onClose = () => {
                       },
                       printAreaRef,
                       isMobileView,
                   }) => {


    const {
        activeNodesRef,
        selectedId,
        selectedElement,
        selectElement,
        deselectElement,
    } = useSelectionContext();


    const [distortionType, setDistortionType] = useState("");
    const [bendValue, setBendValue] = useState(30);
    const [horizontalDistort, setHorizontalDistort] = useState(0);
    const [verticalDistort, setVerticalDistort] = useState(0);
    const [selectedFont, setSelectedFont] = useState(googleFonts[0]);
    const [selectedSize, setSelectedSize] = useState(144);

    const [displayFillColorPicker, setDisplayFillColorPicker] = useState(false);
    const [displayStrokeColorPicker, setDisplayStrokeColorPicker] = useState(false);

    const [selectedCssClass, setSelectedCssClass] = useState(null);
    const [fillColor, setFillColor] = useState('#000000'); // Default color
    const [strokeColor, setStrokeColor] = useState('#000000'); // Default color
    const [strokeWidth, setStrokeWidth] = useState(0); // Default stroke width

    const [textValue, setTextValue] = useState('');
    const [svgDataUrl, setSvgDataUrl] = useState(""); // State to store the SVG data URL
    const svgContainerRef = useRef(null);

    //const isTextSelected = selectedElement && selectedElement.attrs.type === 'path';
    const [isTextSelected, setIsTextSelected] = useState(false);

    const [gradientType, setGradientType] = useState('none');
    const [gradientAngle, setGradientAngle] = useState(0);
    const [linearGradientX, setLinearGradientX] = useState(50);
    const [linearGradientY, setLinearGradientY] = useState(50);

    // Add this with other useState declarations at the top of TextAsset component
    const [radialGradientX, setRadialGradientX] = useState(50);
    const [radialGradientY, setRadialGradientY] = useState(50);
    const [radialGradientSize, setRadialGradientSize] = useState(50);
    const [radialGradientStartRadius, setRadialGradientStartRadius] = useState(0);
    const [radialGradientEndRadius, setRadialGradientEndRadius] = useState(100);
    const [radialGradientStartPoint, setRadialGradientStartPoint] = useState({ x: 0.5, y: 0.5 });
    const [radialGradientEndPoint, setRadialGradientEndPoint] = useState({ x: 0.5, y: 0.5 });
    const [fillRadialGradientColorStops, setFillRadialGradientColorStops] = useState([]);
    const [gradientStops, setGradientStops] = useState(DEFAULT_GRADIENT_STOPS);

    // Stroke Array
    const [strokes, setStrokes] = useState([
        {
            id: uuidv4(),
            color: '#000000',
            width: 0,
            offset: 0,
            dashArray: [],
            enabled: true,
        }
    ]);

    // 3D Effects
    const [threeDEffects, setThreeDEffects] = useState({
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        depth: 0,
        bevel: 0,
        sideColor: '#000000',
        layerOffset: { x: 0, y: 1 },
        layerDensity: 5,
        sideGradient: {
            enabled: false,
            startColor: '#000000',
            endColor: '#333333'
        }
    });

    const resetThreeDEffects = () => {
        setThreeDEffects({
            rotateX: 0,
            rotateY: 0,
            rotateZ: 0,
            depth: 0,
            bevel: 0,
            sideColor: '#000000',
            layerOffset: { x: 0, y: 1 },
            layerDensity: 5,
            sideGradient: {
                enabled: false,
                startColor: '#000000',
                endColor: '#333333'
            }
        });
    };

    // Reset 3D effects when the selected element changes
    useEffect(() => {
        if (!selectedElement) {
            resetThreeDEffects();
        }
    }, [selectedElement]);

    // Update text value when a text element is selected
    useEffect(() => {
        // Update component state based on selected element, if applicable
        if (selectedElement) {
            const attrs = selectedElement.attrs;
            if (attrs.type === 'path') {
                setIsTextSelected(true);
                setTextValue(attrs.textValue ?? '');
                setSelectedFont(attrs.fontFamily ?? googleFonts[0]);
                setSelectedSize(String(attrs.fontSize ?? 144));
                setDistortionType(attrs.distortionType ?? '');
                setBendValue(attrs.bendValue ?? 30);
                setHorizontalDistort(attrs.horizontalDistort ?? 0);
                setVerticalDistort(attrs.verticalDistort ?? 0);
                setFillColor(attrs.fill ?? '#000000');
                // Add gradient properties with fallbacks
                setGradientType(attrs.gradientType ?? 'none');
                setGradientStops(attrs.gradientStops ?? DEFAULT_GRADIENT_STOPS);
                setGradientAngle(attrs.gradientAngle ?? 0);
                setRadialGradientSize(attrs.radialGradientSize ?? 50);
                setRadialGradientX(attrs.radialGradientX ?? 50);
                setRadialGradientY(attrs.radialGradientY ?? 50);
                setRadialGradientStartRadius(attrs.fillRadialGradientStartRadius ?? 0);
                setRadialGradientEndRadius(attrs.fillRadialGradientEndRadius ?? Math.max(attrs.width ?? 100, attrs.height ?? 100));
                //setRadialGradientColorStops(attrs.fillRadialGradientColorStops ?? []);
                setRadialGradientStartPoint(attrs.fillRadialGradientStartPoint ?? { x: 0.5, y: 0.5 });
                setRadialGradientEndPoint(attrs.fillRadialGradientEndPoint ?? { x: 0.5, y: 0.5 });
                // Add 3D effects with fallbacks
                setThreeDEffects({
                    rotateX: attrs.threeDEffects?.rotateX || 0,
                    rotateY: attrs.threeDEffects?.rotateY || 0,
                    rotateZ: attrs.threeDEffects?.rotateZ || 0,
                    depth: attrs.threeDEffects?.depth || 0,
                    bevel: attrs.threeDEffects?.bevel || 0,
                    sideColor: attrs.threeDEffects?.sideColor || '#000000',
                    layerOffset: attrs.threeDEffects?.layerOffset || { x: 0, y: 1 },
                    layerDensity: attrs.threeDEffects?.layerDensity || 5,
                    sideGradient: attrs.threeDEffects?.sideGradient || { enabled: false, startColor: '#000000', endColor: '#333333' }
                });
            } else {
                // Reset everything to defaults
                setIsTextSelected(false);
                setTextValue('');
                setDistortionType("");
                setBendValue(30);
                setHorizontalDistort(0);
                setVerticalDistort(0);
                setFillColor(null);
                setGradientType('none');
                setGradientStops(DEFAULT_GRADIENT_STOPS);
                setGradientAngle(0);
                setRadialGradientSize(50);
                setRadialGradientX(50);
                setRadialGradientY(50);
                resetThreeDEffects();
            }
        } else {
            // Reset everything to defaults
            setIsTextSelected(false);
            setTextValue('');
            setDistortionType("");
            setBendValue(30);
            setHorizontalDistort(0);
            setVerticalDistort(0);
            setFillColor(null);
            setGradientType('none');
            setGradientStops(DEFAULT_GRADIENT_STOPS);
            setGradientAngle(0);
            setRadialGradientSize(50);
            setRadialGradientX(50);
            setRadialGradientY(50);
            resetThreeDEffects();
        }
    }, [selectedElement]);

    useEffect(() => {
        if (isTextSelected) {
            handleTextConversion(); // Call a function that reapplies distortion based on current settings
        }
    }, [distortionType, bendValue, horizontalDistort, verticalDistort]);

    useEffect(() => {
        if (isTextSelected) {
            updateOrCreatePath(textValue, selectedFont, selectedSize, strokeWidth, strokeColor);
        }
    }, [textValue, selectedFont, selectedSize]); // Removed: strokeWidth, strokeColor

    useEffect(() => {
        const selectedElem = elems.find(elem => elem.id === selectedId);
        if (selectedElem) {
            // Update all gradient properties at once
            setGradientType(selectedElem.gradientType || 'none');
            setGradientAngle(selectedElem.gradientAngle || 0);
            setGradientStops(selectedElem.gradientStops || DEFAULT_GRADIENT_STOPS);
            // Use the stored gradient points directly
            setLinearGradientX(selectedElem.linearGradientX || 50);
            setLinearGradientY(selectedElem.linearGradientY || 50);
            setRadialGradientX(selectedElem.radialGradientX || 50);
            setRadialGradientY(selectedElem.radialGradientY || 50);
            setRadialGradientSize(selectedElem.radialGradientSize || 50);
        }
    }, [selectedId]); // Only run when selection changes

    // Add this after the other useEffect hooks (around line 181)
    useEffect(() => {
        if (isTextSelected && selectedElement) {
            handleTextConversion();
        }
    }, [threeDEffects]); // This will trigger when any 3D effect value changes

    const updateOrCreatePath = async (textValue, selectedFont, selectedSize, strokeWidth, strokeColor) => {
        if (!svgContainerRef.current) {
            console.error('SVG container ref not found');
            return;
        }

        const {width, height} = measureSvgText(textValue, selectedFont, selectedSize);
        const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgElement.setAttribute("width", `${width}px`);
        svgElement.setAttribute("height", `${height}px`);

        const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
        textElement.setAttribute("x", "0");
        textElement.setAttribute("y", selectedSize); // Set y to fontSize for proper baseline
        textElement.setAttribute("font-family", selectedFont);
        textElement.setAttribute("font-size", selectedSize);
        textElement.setAttribute("fill", "#000000");
        textElement.textContent = textValue;

        // Append text to SVG and SVG to container
        svgElement.appendChild(textElement);
        svgContainerRef.current.innerHTML = ''; // Clear previous content
        svgContainerRef.current.appendChild(svgElement);
        
        try {
            // Replace text with path using svg-text-to-path
            const session = new Session(svgElement, {
                googleApiKey: 'AIzaSyCOE5K7L-6bbNK6aY3SBU-ZT8kLVw_D2XU',
            });
        
            await session.replaceAll();
            // Add debug logging after conversion
            console.log('SVG text-to-path conversion');
            const paths = svgElement.querySelectorAll('path');

            if (paths.length > 0) {
                const pathData = paths[0].getAttribute('d');
                
                // Apply warping using utility
                const warpedPath = applyWarpToPath(pathData, {
                    type: distortionType,
                    bend: bendValue,
                    distortV: horizontalDistort,
                    distortH: verticalDistort
                });


                // Now apply 3D transformation to the warped path
                const transformedLayers = transform3DPath(warpedPath, {
                    rotateX: threeDEffects.rotateX || 0,
                    rotateY: threeDEffects.rotateY || 0,
                    rotateZ: threeDEffects.rotateZ || 0,
                    depth: threeDEffects.depth || 0,
                    bevel: threeDEffects.bevel || 0,
                    layerDensity: threeDEffects.layerDensity || 5,
                    sideGradient: threeDEffects.sideGradient || { enabled: false, startColor: '#000000', endColor: '#333333' }
                });
                
                const combinedPaths = transformedLayers.map(layer => layer.pathData);

                // Get the stage dimensions
                const stage = stageRef.current;
                const stageWidth = stage.width();
                const stageHeight = stage.height();

                // Calculate center position
                const centerX = stageWidth / 2 - width / 2;
                const centerY = stageHeight / 2 - height / 2;

                const elementData = {
                    data: combinedPaths[0] || warpedPath,
                    originalData: pathData,
                    fontFamily: selectedFont,
                    fontSize: selectedSize,
                    textValue: textValue,
                    fill: gradientType === 'none' ? fillColor : undefined,  // Remove the url reference
                    stroke: strokeColor,
                    strokeWidth: strokeWidth,
                    distortionType: distortionType,
                    bendValue: bendValue,
                    horizontalDistort: horizontalDistort,
                    verticalDistort: verticalDistort,
                    type: 'path',
                    threeDPaths: combinedPaths,
                    threeDEffects: {
                        rotateX: threeDEffects.rotateX || 0,
                        rotateY: threeDEffects.rotateY || 0,
                        rotateZ: threeDEffects.rotateZ || 0,
                        depth: threeDEffects.depth || 0,
                        bevel: threeDEffects.bevel || 0,
                        sideColor: threeDEffects.sideColor || '#000000',
                        layerOffset: threeDEffects.layerOffset || { x: 0, y: 1 },
                        layerDensity: threeDEffects.layerDensity || 5,
                        sideGradient: threeDEffects.sideGradient || { enabled: false, startColor: '#000000', endColor: '#333333' }
                    }
                };

                if (isTextSelected && selectedId) {
                    const updatedElems = elems.map(elem => {
                        if (elem.id === selectedId) {
                            // First apply transformations
                            const transformed = applyTransformations(elementData.originalData, {
                                ...elem,
                                ...elementData,
                                // Preserve the original data
                                originalData: elem.originalData
                            });
                            
                            return {
                                ...elem,
                                ...elementData,
                                // Use transformed data and paths
                                data: transformed.data,
                                threeDPaths: transformed.threeDPaths,
                                // Keep original data unchanged
                                originalData: elem.originalData
                            };
                        }
                        return elem;
                    });
                    setElems(updatedElems);
                } else {
                    const newPathElement = {
                        id: `path_${Date.now()}`,
                        type: 'path',
                        x: centerX,
                        y: centerY,
                        width: width,
                        height: height,
                        placement: placementRef.current,
                        ...elementData
                    };

                    setElems([...elems, newPathElement]);
                    selectElement(newPathElement.id);
                }
            }

        } catch (error) {
            console.error('Error converting text to path:', error);
        } finally {
            // Clean up
            if (svgContainerRef.current.contains(svgElement)) {
                svgContainerRef.current.removeChild(svgElement);
            }
        }
    }

    const applyTransformations = (pathData, element) => {
        // Apply warping first
        const warpedPath = applyWarpToPath(pathData, {
            type: element.distortionType,
            bend: element.bendValue,
            distortV: element.horizontalDistort,
            distortH: element.verticalDistort
        });

        // Apply 3D transformation directly
        const transformedLayers = transform3DPath(warpedPath, {
            rotateX: element.threeDEffects?.rotateX || 0,
            rotateY: element.threeDEffects?.rotateY || 0,
            rotateZ: element.threeDEffects?.rotateZ || 0,
            depth: element.threeDEffects?.depth || 0,
            bevel: element.threeDEffects?.bevel || 0,
            layerDensity: element.threeDEffects?.layerDensity || 5,
            sideGradient: element.threeDEffects?.sideGradient || { enabled: false, startColor: '#000000', endColor: '#333333' }
        });

        return {
            data: transformedLayers[0].pathData,
            threeDPaths: transformedLayers.map(layer => layer.pathData),
            pathAttributes: transformedLayers.map(layer => ({
                offsetX: 0,
                offsetY: 0,
                fill: element.fill,
                opacity: layer.shadowOpacity,
                zIndex: layer.zIndex
            }))
        };
    };

    const handleTextConversion = async () => {
        if (!isTextSelected || !selectedElement) return;
        
        // console.log("3D Effects being applied:", threeDEffects);

        const originalPathData = selectedElement.attrs.originalData;
        
        // Apply warping using utility
        const warpedPath = applyWarpToPath(originalPathData, {
            type: distortionType,
            bend: bendValue,
            distortV: horizontalDistort,
            distortH: verticalDistort
        });

        // Update state for warping controls
        setHorizontalDistort(horizontalDistort);
        setVerticalDistort(verticalDistort);
        setBendValue(bendValue);
        setDistortionType(distortionType);

        // Apply 3D effects and get all layers
        const transformedLayers = transform3DPath(warpedPath, {
            rotateX: threeDEffects.rotateX || 0,
            rotateY: threeDEffects.rotateY || 0,
            rotateZ: threeDEffects.rotateZ || 0,
            depth: threeDEffects.depth || 0,
            bevel: threeDEffects.bevel || 0,
            layerDensity: threeDEffects.layerDensity || 5,
            sideGradient: threeDEffects.sideGradient || { enabled: false, startColor: '#000000', endColor: '#333333' }
        });

        const combinedPaths = transformedLayers.map(layer => layer.pathData);

        // Preserve all existing properties while adding 3D effects
        const updatedElems = elems.map(elem => {
            if (elem.id === selectedId) {
                // Preserve all gradient-related properties
                const {
                    fillPriority,
                    fillRadialGradientStartPoint,
                    fillRadialGradientEndPoint,
                    fillRadialGradientStartRadius,
                    fillRadialGradientEndRadius,
                    fillRadialGradientColorStops,
                    fillLinearGradientStartPoint,
                    fillLinearGradientEndPoint,
                    fillLinearGradientColorStops,
                    gradientType,
                    gradientStops,
                    gradientAngle,
                    radialGradientX,
                    radialGradientY,
                    radialGradientSize,
                    radialGradientShape,
                } = elem;

                return {
                    ...elem,
                    data: combinedPaths[0] || warpedPath,
                    threeDPaths: combinedPaths,
                    threeDEffects: { ...threeDEffects },
                    distortionType,
                    bendValue,
                    horizontalDistort,
                    verticalDistort,
                    // Preserve gradient properties
                    fillPriority,
                    fillRadialGradientStartPoint,
                    fillRadialGradientEndPoint,
                    fillRadialGradientStartRadius,
                    fillRadialGradientEndRadius,
                    fillRadialGradientColorStops,
                    fillLinearGradientStartPoint,
                    fillLinearGradientEndPoint,
                    fillLinearGradientColorStops,
                    gradientType,
                    gradientStops,
                    gradientAngle,
                    radialGradientX,
                    radialGradientY,
                    radialGradientSize,
                    radialGradientShape,
                };
            }
            return elem;
        });

        setElems(updatedElems);
    };

    // Handle clicking on a color swatch
    function handleFillColorClick(cssClass) {
        setSelectedCssClass(cssClass);
        setDisplayFillColorPicker(true); // Show the ChromePicker
        setFillColor(cssClass); // Set the initial color of the ChromePicker to the swatch color
    };

    // Function to handle fill color change
    const handleFillColorChange = (color) => {
        // Update the fill color of the selected path
        const updatedElems = elems.map((elem) => {
            if (elem.id === selectedId) {
                return {...elem, fill: color.hex};
            }
            return elem;
        });
        setElems(updatedElems);
        setFillColor(color.hex);
    };

    // Add handler for stroke updates
    const handleStrokeUpdate = (strokeId, updates) => {
        const updatedStrokes = strokes.map(stroke => 
            stroke.id === strokeId ? { ...stroke, ...updates } : stroke
        );
        setStrokes(updatedStrokes);
        
        const updatedElems = elems.map(elem => {
            if (elem.id === selectedId) {
                return {
                    ...elem,
                    strokes: updatedStrokes
                };
            }
            return elem;
        });
        setElems(updatedElems);
    };

    // Add handler for adding new strokes
    const handleAddStroke = () => {
        const newStroke = {
            id: uuidv4(),
            color: '#000000',
            width: 0,
            offset: 0,
            dashArray: [],
            enabled: true
        };
        setStrokes([...strokes, newStroke]);
    };

    const handleStrokeColorChange = (color) => {
        // Update the stroke color of the selected path
        const updatedElems = elems.map((elem) => {
            if (elem.id === selectedId) {
                return {...elem, stroke: color.hex};
            }
            return elem;
        });
        setElems(updatedElems);
        setStrokeColor(color.hex);
    }

    const handleAddText = () => {
        updateOrCreatePath(textValue, selectedFont, selectedSize);

        if (isTextSelected.current) {
            // Update existing text element
        }
    }

    const measureSvgText = (text, font, fontSize) => {
        // Create a temporary SVG element
        const svgNS = "http://www.w3.org/2000/svg";
        const tempSvg = document.createElementNS(svgNS, "svg");
        const textElement = document.createElementNS(svgNS, "text");

        // Apply text, font, and size
        textElement.setAttribute("fill", "black");
        textElement.setAttribute("font-family", font);
        textElement.setAttribute("font-size", fontSize);
        textElement.textContent = text;

        // Append text element to SVG
        tempSvg.appendChild(textElement);
        document.body.appendChild(tempSvg); // Temporarily add to body to measure

        // Measure text
        const bbox = textElement.getBBox();
        const width = bbox.width;
        const height = bbox.height;

        // Clean up by removing the temporary SVG
        document.body.removeChild(tempSvg);

        return {width, height};
    };

    const handleFontChange = (fontName) => {
        setSelectedFont(fontName);
        // Additional logic for when a font is selected
    };

    const gradientHandlers = createGradientHandlers(setElems, elems, selectedId, {
        setGradientType,
        setGradientAngle,
        setLinearGradientX,
        setLinearGradientY,
        setRadialGradientX,
        setRadialGradientY,
        setRadialGradientSize,
        setGradientStops,
    });

    const {
        handleGradientTypeChange,
        handleGradientStopsChange,
        handleGradientAngleChange,
        handleRadialGradientSizeChange,
        handleRadialGradientPositionChange,
        handleLinearGradientPositionChange,
    } = gradientHandlers;

    const debouncedSetThreeDEffects = useCallback(
        debounce((newEffects) => {
            setThreeDEffects(newEffects);
        }, 150),
        [] // Empty dependency array since we don't want to recreate the debounced function
    );

    return (
        <div className="p-3 bg-light rounded" style={{
            maxHeight: 'calc(100vh - 100px)', // Adjust based on your header/footer
            overflowY: 'auto',
            position: 'relative'
        }}>
            <div ref={svgContainerRef} style={{display: 'none'}}></div>
            {isTextSelected ? (
                // Form for when a text element is selected
                <>
                    <div className="mb-3">
                        <label htmlFor="textValue" className="form-label">Text:</label>
                        <input
                            id={"textValue"}
                            type="text"
                            className="form-control"
                            placeholder="Enter text..."
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Fill Type:</label>
                        <select 
                            className="form-select"
                            value={gradientType}
                            onChange={(e) => handleGradientTypeChange(e.target.value)}
                        >
                            <option value="none">Solid Color</option>
                            <option value="linear">Linear Gradient</option>
                            <option value="radial">Radial Gradient</option>
                        </select>
                    </div>

                    {gradientType !== 'none' && (
                        <>
                            {gradientType === 'linear' && (
                                <>
                                    <div className="mb-3">
                                        <label className="form-label">Gradient Angle: {gradientAngle}°</label>
                                        <input
                                            type="range"
                                            className="form-range"
                                            min="0"
                                            max="360"
                                            value={gradientAngle}
                                            onChange={(e) => handleGradientAngleChange(Number(e.target.value))}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Position X: {linearGradientX}%</label>
                                        <input
                                            type="range"
                                            className="form-range"
                                            min="0"
                                            max="100"
                                            value={linearGradientX}
                                            onChange={(e) => handleLinearGradientPositionChange(Number(e.target.value), linearGradientY)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Position Y: {linearGradientY}%</label>
                                        <input
                                            type="range"
                                            className="form-range"
                                            min="0"
                                            max="100"
                                            value={linearGradientY}
                                            onChange={(e) => handleLinearGradientPositionChange(linearGradientX, Number(e.target.value))}
                                        />
                                    </div>
                                </>
                            )}

                            {gradientType === 'radial' && (
                                <>
                                    <div className="mb-3">
                                        <label className="form-label">Gradient Size: {radialGradientSize}%</label>
                                        <input
                                            type="range"
                                            className="form-range"
                                            min="0"
                                            max="300"  // Increased max size
                                            value={radialGradientSize}
                                            onChange={(e) => handleRadialGradientSizeChange(Number(e.target.value))}
                                        />
                                    </div>
                                    
                                    <div className="mb-3">
                                        <label className="form-label">Center Position</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="form-label small">X: {radialGradientX}%</label>
                                                <input
                                                    type="range"
                                                    className="form-range"
                                                    min="0"
                                                    max="100"
                                                    value={radialGradientX}
                                                    onChange={(e) => handleRadialGradientPositionChange(Number(e.target.value), radialGradientY)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label small">Y: {radialGradientY}%</label>
                                                <input
                                                    type="range"
                                                    className="form-range"
                                                    min="0"
                                                    max="100"
                                                    value={radialGradientY}
                                                    onChange={(e) => handleRadialGradientPositionChange(radialGradientX, Number(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            
                            <div className="mb-3">
                                <label className="form-label">Gradient Stops:</label>
                                {gradientStops.map((stop, index) => (
                                    <div key={stop.id} className="d-flex align-items-center gap-2 mb-2">
                                        <input
                                            type="color"
                                            className="form-control form-control-color"
                                            value={stop.color}
                                            onChange={(e) => {
                                                const newStops = [...gradientStops];
                                                newStops[index] = { ...stop, color: e.target.value };
                                                handleGradientStopsChange(newStops);
                                            }}
                                        />
                                        <input
                                            type="range"
                                            className="form-range flex-grow-1"
                                            min="0"
                                            max="100"
                                            value={stop.offset * 100}
                                            onChange={(e) => {
                                                const newStops = [...gradientStops];
                                                newStops[index] = { 
                                                    ...stop, 
                                                    offset: Number(e.target.value) / 100 
                                                };
                                                handleGradientStopsChange(newStops);
                                            }}
                                        />
                                        <button
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={() => {
                                                if (gradientStops.length > 2) {
                                                    handleGradientStopsChange(
                                                        gradientStops.filter(s => s.id !== stop.id)
                                                    );
                                                }
                                            }}
                                            disabled={gradientStops.length <= 2}
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                                
                                <button
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() => {
                                        const lastStop = gradientStops[gradientStops.length - 1];
                                        handleGradientStopsChange([...gradientStops, {
                                            id: uuidv4(),
                                            offset: Math.min(lastStop.offset + 0.1, 1),
                                            color: lastStop.color
                                        }]);
                                    }}
                                >
                                    Add Color Stop
                                </button>
                            </div>
                        </>

                    )}

                    <div className="row mb-3">
                        {/* Font selection */}
                        <div className="col-8">
                            <label htmlFor="fontSelection" className="form-label">Font:</label>
                            <CustomFontSelector
                                selectedFont={selectedFont}
                                onFontChange={handleFontChange}
                            />
                        </div>

                        {/* Font size selection */}
                        <div className="col-4">
                            <label htmlFor="fontSizeSelection" className="form-label">Font Size:</label>
                            <select id="fontSizeSelection" className="form-select" value={selectedSize}
                                    onChange={(e) => setSelectedSize(e.target.value)}>
                                {fontSizes.map(size => (
                                    <option key={size} value={size}>{size}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="arc_type" className="form-label">Text Effect Type:</label>
                        <select id="arc_type" className="form-select" value={distortionType}
                                onChange={(e) => setDistortionType(e.target.value)}>
                            <option value="">Select</option>
                            <option value="WARP_ARC">Arc</option>
                            <option value="WARP_ARC_LOWER">Arc Lower</option>
                            <option value="WARP_ARC_UPPER">Arc Upper</option>
                            <option value="WARP_ARCH">Arch</option>
                            <option value="WARP_BULGE">Bulge</option>
                            <option value="WARP_FLAG">Flag</option>
                            <option value="WARP_FISH">Fish</option>
                            <option value="WARP_RISE">Rise</option>
                            <option value="WARP_INFLATE">Inflate</option>
                            <option value="WARP_SQUEEZE">Squeeze</option>
                            <option value="WARP_WAVE_LOWER">Wave Lower</option>
                            <option value="WARP_WAVE_UPPER">Wave Upper</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="bendValue">Effect Strength:</label>
                        <input id="bendValue" className="form-range" type="range" min="-100" max="100" value={bendValue}
                               onChange={(e) => setBendValue(e.target.value)}
                               disabled={!distortionType}
                        />

                        <label className="form-label" htmlFor="horizontalDistort">Horizontal Distortion:</label>
                        <input id="horizontalDistort" className="form-range" type="range" min="-100" max="100"
                               value={horizontalDistort}
                               onChange={(e) => setHorizontalDistort(e.target.value)}/>

                        <label className="form-label" htmlFor="verticalDistort">Vertical Distortion:</label>
                        <input id="verticalDistort" className="form-range" type="range" min="-100" max="100"
                               value={verticalDistort}
                               onChange={(e) => setVerticalDistort(e.target.value)}/>


                        <label className="form-label">Border Width:</label>
                        <input
                            className="form-range"
                            type="range"
                            min="0" max="20"
                            value={strokeWidth}
                            onChange={(e) => setStrokeWidth(Number(e.target.value))}
                        />

                        {/* Strokes Section */}
                        <div className="mb-4">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <label className="form-label">Strokes</label>
                                <button 
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={handleAddStroke}
                                >
                                    Add Stroke
                                </button>
                            </div>
                            
                            {strokes.map((stroke, index) => (
                                <div key={stroke.id} className="stroke-control border rounded p-3 mb-2">
                                    <div className="d-flex justify-content-between mb-2">
                                        <h6>Stroke {index + 1}</h6>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={stroke.enabled}
                                                onChange={(e) => handleStrokeUpdate(stroke.id, { enabled: e.target.checked })}
                                            />
                                        </div>
                                    </div>

                                    {/* Color Picker */}
                                    <div className="mb-3">
                                        <label className="form-label">Color</label>
                                        <div 
                                            className="color-preview"
                                            style={{ 
                                                backgroundColor: stroke.color,
                                                width: '30px',
                                                height: '30px',
                                                border: '1px solid #ccc',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handleStrokeUpdate(stroke.id, { showColorPicker: !stroke.showColorPicker })}
                                        />
                                        {stroke.showColorPicker && (
                                            <ChromePicker
                                                color={stroke.color}
                                                onChange={(color) => handleStrokeUpdate(stroke.id, { color: color.hex })}
                                            />
                                        )}
                                    </div>

                                    {/* Width Control */}
                                    <div className="mb-3">
                                        <label className="form-label">Width: {stroke.width}px</label>
                                        <input
                                            type="range"
                                            className="form-range"
                                            min="0"
                                            max="50"
                                            value={stroke.width}
                                            onChange={(e) => handleStrokeUpdate(stroke.id, { width: Number(e.target.value) })}
                                        />
                                    </div>

                                    {/* Offset Control */}
                                    <div className="mb-3">
                                        <label className="form-label">Offset: {stroke.offset}px</label>
                                        <input
                                            type="range"
                                            className="form-range"
                                            min="-25"
                                            max="25"
                                            value={stroke.offset}
                                            onChange={(e) => handleStrokeUpdate(stroke.id, { offset: Number(e.target.value) })}
                                        />
                                    </div>

                                    {/* Dash Array Control */}
                                    <div className="mb-3">
                                        <label className="form-label">Dash Pattern</label>
                                        <select
                                            className="form-select"
                                            value={stroke.dashArray.join(',')}
                                            onChange={(e) => handleStrokeUpdate(stroke.id, { 
                                                dashArray: e.target.value ? e.target.value.split(',').map(Number) : [] 
                                            })}
                                        >
                                            <option value="">Solid</option>
                                            <option value="5,5">Dashed</option>
                                            <option value="2,2">Dotted</option>
                                            <option value="10,5,2,5">Dash-Dot</option>
                                        </select>
                                    </div>

                                    {/* Delete Button */}
                                    {strokes.length > 1 && (
                                        <button
                                            className="btn btn-sm btn-outline-danger"
                                            onClick={() => {
                                                const updatedStrokes = strokes.filter(s => s.id !== stroke.id);
                                                setStrokes(updatedStrokes);
                                            }}
                                        >
                                            Remove Stroke
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>

                    </div>

                    {/* 3D Effects */}
                    <div className="mb-4">
                        <label className="form-label">3D Effects</label>
                        <div className="mb-3">
                            <label className="form-label">Rotate X: {threeDEffects.rotateX}°</label>
                            <input
                                type="range"
                                className="form-range"
                                min="-180"
                                max="180"
                                value={threeDEffects.rotateX}
                                onChange={(e) => {
                                    const newValue = Number(e.target.value);
                                    // Update the UI immediately
                                    setThreeDEffects(prev => ({
                                        ...prev,
                                        rotateX: newValue
                                    }));
                                    // Debounce the actual transformation
                                    debouncedSetThreeDEffects({
                                        ...threeDEffects,
                                        rotateX: newValue
                                    });
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Rotate Y: {threeDEffects.rotateY}°</label>
                            <input
                                type="range"
                                className="form-range"
                                min="-180"
                                max="180"
                                value={threeDEffects.rotateY}
                                onChange={(e) => {
                                    const newValue = Number(e.target.value);
                                    setThreeDEffects(prev => ({
                                        ...prev,
                                        rotateY: newValue
                                    }));
                                    debouncedSetThreeDEffects({
                                        ...threeDEffects,
                                        rotateY: newValue
                                    });
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Depth: {threeDEffects.depth}px</label>
                            <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="50"
                                value={threeDEffects.depth}
                                onChange={(e) => {
                                    const newValue = Number(e.target.value);
                                    // Update UI immediately
                                    setThreeDEffects(prev => ({
                                        ...prev,
                                        depth: newValue
                                    }));
                                    // Debounce the actual transformation
                                    debouncedSetThreeDEffects({
                                        ...threeDEffects,
                                        depth: newValue
                                    });
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Bevel: {threeDEffects.bevel}px</label>
                            <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="20"
                                value={threeDEffects.bevel}
                                onChange={(e) => setThreeDEffects({
                                    ...threeDEffects,
                                    bevel: Number(e.target.value)
                                })}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Side Color:</label>
                            <ChromePicker
                                color={threeDEffects.sideColor}
                                onChange={(color) => {
                                    const newColor = color.hex;
                                    setThreeDEffects(prev => ({
                                        ...prev,
                                        sideColor: newColor
                                    }));
                                    debouncedSetThreeDEffects({
                                        ...threeDEffects,
                                        sideColor: newColor
                                    });
                                }}
                            />
                        </div>

                        <div className="mb-3">
                            <div className="form-check mb-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={threeDEffects.sideGradient.enabled}
                                    onChange={(e) => {
                                        setThreeDEffects(prev => ({
                                            ...prev,
                                            sideGradient: {
                                                ...prev.sideGradient,
                                                enabled: e.target.checked
                                            }
                                        }));
                                    }}
                                />
                                <label className="form-check-label">
                                    Enable Side Gradient
                                </label>
                            </div>
                            
                            {threeDEffects.sideGradient.enabled && (
                                <>
                                    <div className="mb-2">
                                        <label className="form-label">Start Color:</label>
                                        <input
                                            type="color"
                                            className="form-control form-control-color"
                                            value={threeDEffects.sideGradient.startColor}
                                            onChange={(e) => {
                                                setThreeDEffects(prev => ({
                                                    ...prev,
                                                    sideGradient: {
                                                        ...prev.sideGradient,
                                                        startColor: e.target.value
                                                    }
                                                }));
                                            }}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">End Color:</label>
                                        <input
                                            type="color"
                                            className="form-control form-control-color"
                                            value={threeDEffects.sideGradient.endColor}
                                            onChange={(e) => {
                                                setThreeDEffects(prev => ({
                                                    ...prev,
                                                    sideGradient: {
                                                        ...prev.sideGradient,
                                                        endColor: e.target.value
                                                    }
                                                }));
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Layer Offset X: {threeDEffects.layerOffset.x}</label>
                            <input
                                type="range"
                                className="form-range"
                                min="-2"
                                max="2"
                                step="0.1"
                                value={threeDEffects.layerOffset.x}
                                onChange={(e) => {
                                    const newValue = Number(e.target.value);
                                    setThreeDEffects(prev => ({
                                        ...prev,
                                        layerOffset: { ...prev.layerOffset, x: newValue }
                                    }));
                                    debouncedSetThreeDEffects({
                                        ...threeDEffects,
                                        layerOffset: { ...threeDEffects.layerOffset, x: newValue }
                                    });
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Layer Offset Y: {threeDEffects.layerOffset.y}</label>
                            <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="2"
                                step="0.1"
                                value={threeDEffects.layerOffset.y}
                                onChange={(e) => {
                                    const newValue = Number(e.target.value);
                                    setThreeDEffects(prev => ({
                                        ...prev,
                                        layerOffset: { ...prev.layerOffset, y: newValue }
                                    }));
                                    debouncedSetThreeDEffects({
                                        ...threeDEffects,
                                        layerOffset: { ...threeDEffects.layerOffset, y: newValue }
                                    });
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Layer Density: {threeDEffects.layerDensity}</label>
                            <input
                                type="range"
                                className="form-range"
                                min="2"
                                max="15"
                                step="1"
                                value={threeDEffects.layerDensity}
                                onChange={(e) => {
                                    const newValue = Number(e.target.value);
                                    setThreeDEffects(prev => ({
                                        ...prev,
                                        layerDensity: newValue
                                    }));
                                    debouncedSetThreeDEffects({
                                        ...threeDEffects,
                                        layerDensity: newValue
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">Fill Color:</label>
                            <div style={{position: 'relative'}}>
                                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    <Swatch
                                        cssClass={fillColor} // Adjust based on Swatch component expectations
                                        isSelected={selectedCssClass === fillColor}
                                        fillValue={fillColor}
                                        elems={elems}
                                        selectedId={selectedId}
                                        selectedElement={selectedElement}
                                        isMobileView={isMobileView}
                                        displayColorPicker={displayFillColorPicker}
                                        setDisplayColorPicker={setDisplayFillColorPicker}
                                        fillColor={fillColor}
                                        setFillColor={setFillColor}
                                        handleFillColorClick={() => handleFillColorClick(fillColor)}
                                    />
                                </div>
                                {/* Color picker that shows up when the swatch is clicked */}
                                {displayFillColorPicker && (
                                    <div>
                                        <div style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px'
                                        }} onClick={() => setDisplayFillColorPicker(false)}/>
                                        <ChromePicker
                                            color={fillColor}
                                            onChangeComplete={handleFillColorChange}
                                            disableAlpha={true}
                                            style={{position: 'absolute', zIndex: '2'}}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className={"col-6"}>
                            <div className="mb-3 position-relative">
                                <label className="form-label">Border Color:</label>
                                <Swatch
                                    cssClass={strokeColor}
                                    isSelected={true}
                                    fillValue={strokeColor}
                                    handleFillColorClick={() => setDisplayStrokeColorPicker(true)} // Assuming this toggles the color picker for stroke color
                                />

                                {displayStrokeColorPicker && (
                                    <div className="position-absolute" style={{top: '100%', zIndex: 2, left: '-120px'}}>
                                        <div style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px'
                                        }} onClick={() => setDisplayStrokeColorPicker(false)}/>
                                        <ChromePicker
                                            color={strokeColor}
                                            onChangeComplete={handleStrokeColorChange}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Color Picker */}

                </>
            ) : (
                // Form for when no text element is selected
                <>

                    <div className="mb-3">
                        <label htmlFor="textValue" className="form-label">Text:</label>
                        <input
                            id={"textValue"}
                            type="text"
                            className="form-control"
                            placeholder="Enter text..."
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                        />
                    </div>

                    <div className="row mb-3">
                        {/* Font selection */}
                        <div className="col-8">
                            <label htmlFor="fontSelection" className="form-label">Font:</label>
                            <CustomFontSelector
                                selectedFont={selectedFont}
                                onFontChange={handleFontChange}
                            />
                        </div>

                        {/* Font size selection */}
                        <div className="col-4">
                            <label htmlFor="fontSizeSelection" className="form-label">Font Size:</label>
                            <select id="fontSizeSelection" className="form-select" value={selectedSize}
                                    onChange={(e) => setSelectedSize(e.target.value)}>
                                {fontSizes.map(size => (
                                    <option key={size} value={size}>{size}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <button onClick={handleAddText} className="btn btn-primary">Add</button>
                </>
            )}
        </div>
    );
}

export default TextAsset;
