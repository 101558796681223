import React from "react";

const AssetItem = ({ asset, onSelect, type }) => {
    const handleClick = (event) => {
        event.preventDefault();
        onSelect(event, asset, type);
    };

    return (
        <a className='sidebar-link sidebar-asset-link text-muted' href="#" onClick={(handleClick)}>
            <img
                src={asset.image_address}
                data-itemtype={type}
                data-itemdata={asset.image_address}
                data-itemdatas3url={asset.s3_full_image_address}
                data-itemformat={asset.element_type === 'svg' ? 'svg' : 'png'}
                data-itemid={asset.id}
                width="50"
                height="50"
            />
        </a>
    );
};

export default AssetItem;
