import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const googleFonts = [
    'Roboto', 'Open Sans', 'Lato', 'Oswald', 'Montserrat', 'Raleway', 'PT Sans', 'Ubuntu', 'Rubik Burned',
    // Add more fonts as desired
];

const CustomFontSelector = ({ selectedFont, onFontChange }) => {

    // Handle font change
    const handleFontChange = (fontName) => {
        onFontChange(fontName); // Call the passed in onFontChange handler
    };

    return (
        <Dropdown>
            <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                style={{ fontFamily: selectedFont, fontSize: '16px' }}
            >
                {selectedFont || "Select a font"}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {googleFonts.map((fontName) => (
                    <Dropdown.Item
                        key={fontName}
                        onClick={() => handleFontChange(fontName)}
                        active={selectedFont === fontName}
                        style={{ fontFamily: fontName, fontSize: '18px' }}
                    >
                        {fontName}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CustomFontSelector;
