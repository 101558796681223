import React from "react";

export const Swatch = (props) => {
    const swatchSize = props.isMobileView ? '50px' : '40px';
    
    const swatchStyle = {
        boxShadow: props.isSelected ? '0 0 0 3px rgba(1,1,1,1)' : '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        margin: props.isMobileView ? '8px' : '5px',  // More spacing for mobile
        borderRadius: '8px',  // Rounded corners
    };

    return(
        <div>
            <div style={swatchStyle} onClick={props.handleFillColorClick}>
                <div 
                    data-colorkey={props.cssClass} 
                    style={{
                        background: props.fillValue, 
                        width: swatchSize, 
                        height: swatchSize,
                        borderRadius: '6px'
                    }} 
                />
            </div>
        </div>
    )
}