import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainImage", "thumbnail"]

  selectMockup(event) {
    const clickedThumb = event.currentTarget
    const newUrl = clickedThumb.dataset.url

    // Update main image
    this.mainImageTarget.src = newUrl

    // Update thumbnail states
    this.thumbnailTargets.forEach(thumb => {
      thumb.classList.toggle('opacity-50', thumb !== clickedThumb)
    })
  }
}
