import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message", "input"]
  static values = {
    isValid: { type: Boolean, default: false }
  }

  connect() {
    if (!this.hasMessageTarget) {
      const messageDiv = document.createElement('div')
      messageDiv.setAttribute('data-subdomain-validator-target', 'message')
      messageDiv.classList.add('mt-2')
      this.element.insertAdjacentElement('afterend', messageDiv)
    }
  }

  validate() {
    if (!this.hasInputTarget) return
    
    const subdomain = this.inputTarget.value.toLowerCase()
    this.isValidValue = false // Reset validation status
    
    if (!subdomain) {
      this.messageTarget.innerHTML = ''
      return
    }

    // First validate format
    const formatRegex = /^[a-z0-9][a-z0-9-]*[a-z0-9]$/
    if (!formatRegex.test(subdomain)) {
      this.showMessage("Subdomain must start and end with a letter or number, and can only contain lowercase letters, numbers, and hyphens", "danger")
      return
    }

    // Check availability with server
    fetch(`/shops/setup/validate_subdomain?subdomain=${subdomain}`)
      .then(response => response.json())
      .then(data => {
        this.isValidValue = data.valid && data.available
        if (this.isValidValue) {
          this.showMessage(data.message, "success")
        } else {
          this.showMessage(data.message, "danger")
        }
      })
      .catch(() => {
        this.isValidValue = false
        this.showMessage("Error checking subdomain availability", "danger")
      })
  }

  showMessage(message, type) {
    if (!this.hasMessageTarget) {
      this.connect()
    }
    this.messageTarget.innerHTML = `<span class="text-${type}">${message}</span>`
  }
}

