// For Shop Dashboard Product Pricing
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "submit"]

  connect() {
    this.validatePrice()
  }

  formatPrice(event) {
    const input = this.inputTarget
    const value = parseFloat(input.value)
    if (!isNaN(value)) {
      // Format to 2 decimal places
      input.value = value.toFixed(2)
    }
  }

  validatePrice() {
    const input = this.inputTarget
    const basePrice = parseFloat(this.element.dataset.basePrice)
    const currentPrice = parseFloat(input.value)
    const submitButton = this.submitTarget

    if (isNaN(currentPrice) || currentPrice < basePrice) {
      input.classList.add('is-invalid')
      submitButton.disabled = true
      input.title = `Price must be at least $${basePrice.toFixed(2)}`
    } else {
      input.classList.remove('is-invalid')
      submitButton.disabled = false
      input.title = ''
    }
  }

  handleSuccess(event) {
    const response = event.detail.fetchResponse
    if (response.ok) {
      const form = this.element
      form.classList.add('bg-success-subtle')
      setTimeout(() => {
        form.classList.remove('bg-success-subtle')
      }, 1000)
    }
  }
}
