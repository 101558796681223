/* eslint-disable react/prop-types */
import setAxiosHeaders from '../AxiosHeaders';
import axios from 'axios';
import { useMutation } from 'react-query';
import React, { forwardRef, useImperativeHandle } from 'react';

const axiosUpdateProduct = (productId, formData) => {
    console.log('Request URL:', `/products/${productId}/design_customizations/update_design`);
    
    return axios({
        method: 'patch',
        url: `/products/${productId}/design_customizations/update_design`,
        data: {
            state: JSON.parse(formData.get('product[state]'))
        },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    });
};

const axiosAddProduct = (formData) => {
    console.log('Form data for add product:', formData);
    setAxiosHeaders();
    return axios({
        method: 'post',
        url: '/api/v1/products',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

const useSaveProductData = (productId) => {
    return useMutation(
        (formData) => {
            return productId ? axiosUpdateProduct(productId, formData) : axiosAddProduct(formData);
        },
        {
            onSuccess: (response) => {
                console.log('Success response:', response);
                
                // For update_design endpoint
                if (response?.data?.product_path) {
                    window.location.href = response.data.product_path;
                    return;
                }
                
                // For create product endpoint
                if (response?.data?.data?.redirect_url) {
                    window.location.href = response.data.data.redirect_url;
                    return;
                }

                // If we get here, something went wrong with the response format
                console.error('Unexpected response format:', response);
                alert('Error: Unexpected response format. Please try again.');
            },
            onError: (error) => {
                console.error('Error saving product:', error);
                alert('Error saving product. Please try again.');
            }
        }
    );
};

export const CreateNewProduct = forwardRef((props, ref) => {
    const { mutate: saveProduct } = useSaveProductData(props.productId);

    const handleSave = (e, productStatus) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('product[title]', props.title);
        formData.append('product[product_type_id]', props.productTypeId);
        formData.append('product[product_variant_id]', props.selectedProductVariantId);
        formData.append('product[external_variant_id]', props.externalVariantId);
        formData.append('product[external_product_id]', props.externalProductId);
        formData.append('product[state]', JSON.stringify(props.elems));
        formData.append('product[status]', productStatus);

        saveProduct(formData);
    };

    useImperativeHandle(ref, () => ({
        saveProduct: handleSave
    }));

    const showAuthModal = () => {
        $('#loginModal').modal('show');
    }

    // Check if user is product owner
    const isProductOwner = props.currentUser?.id === props.currentUser?.product_user_id;
    
    // Check if user is admin or shop owner
    const isAdminOrShopOwner = props.currentUser?.is_admin === true || 
                              (props.currentUser?.roles || []).includes('shop_admin');

    // Determine button text based on context
    const getButtonText = () => {
        if (isProductOwner) return "Save Changes";
        if (props.productId) return "Save as Copy";
        return "Create Product";
    };

    return (
        <div className="d-flex gap-2 justify-content-center">
            <button 
                className="btn btn-primary" 
                onClick={(e) => handleSave(e, 'published')}
            >
                {getButtonText()}
            </button>
        </div>
    );
});

