import {Layer} from "react-konva";
import {ProductTemplate} from "./producttemplate/ProductTemplate";
import React from "react";

const TemplateLayer = (props) => {
    return (
        <Layer
            name={'templateLayer'}
            ref={props.templateLayerRef}
            opacity={0.9}
        >
            <ProductTemplate
                template={props.template}
                stageRect={props.stageRect}
                scale={props.scale}
            />
        </Layer>
    )
}

export default TemplateLayer;