export const transform3DPath = (pathData, options) => {
    // console.log("transform3DPath called with options:", options);
    
    const { 
        rotateX = 0, 
        rotateY = 0, 
        rotateZ = 0, 
        depth = 0,
        bevel = 0,
        layerDensity = 5
    } = options;

    // Initialize layers array at the top
    const layers = [];

    // Use layerDensity to create more intermediate steps
    const depthSteps = Math.max(1, Math.min(50, depth * layerDensity)); // 50 is the max number of layers
    // const depthSteps = Math.max(1, depth * layerDensity);
    // console.log("Creating layers:", depthSteps);

    // Parse and cache initial points
    const commands = pathData.match(/([A-Za-z])([^A-Za-z]*)/g) || [];
    const cachedPoints = new Map();

    // Pre-calculate trig values
    const radX = (rotateX * Math.PI) / 180;
    const radY = (rotateY * Math.PI) / 180;
    const radZ = (rotateZ * Math.PI) / 180;
    const cosX = Math.cos(radX);
    const sinX = Math.sin(radX);
    const cosY = Math.cos(radY);
    const sinY = Math.sin(radY);
    const cosZ = Math.cos(radZ);
    const sinZ = Math.sin(radZ);

    for (let i = 0; i < depthSteps; i++) {
        const zOffset = (i / depthSteps) * (depth * 10);
        const cacheKey = `${zOffset}`;

        const transformedCommands = commands.map(cmd => {
            const type = cmd[0];
            const coords = cmd.slice(1).trim().split(/[\s,]+/).map(Number);
            
            if (coords.length === 0 || coords.some(isNaN)) {
                return type === 'Z' ? 'Z' : cmd;
            }

            const transformedCoords = [];
            for (let j = 0; j < coords.length; j += 2) {
                const pointKey = `${coords[j]},${coords[j + 1]},${cacheKey}`;
                
                if (cachedPoints.has(pointKey)) {
                    const cached = cachedPoints.get(pointKey);
                    transformedCoords.push(cached.x, cached.y);
                    continue;
                }

                const x = coords[j];
                const y = coords[j + 1];
                
                // Optimized transformation using pre-calculated trig values
                const x1 = x * cosY + zOffset * sinY;
                const z1 = -x * sinY + zOffset * cosY;
                
                const y2 = y * cosX - z1 * sinX;
                const z2 = y * sinX + z1 * cosX;
                
                const x3 = x1 * cosZ - y2 * sinZ;
                const y3 = x1 * sinZ + y2 * cosZ;
                
                const scale = 1000 / (1000 + z2);
                const transformedX = x3 * scale;
                const transformedY = y3 * scale;

                cachedPoints.set(pointKey, { x: transformedX, y: transformedY });
                transformedCoords.push(transformedX, transformedY);
            }
            
            return `${type}${transformedCoords.join(',')}`;
        });

        const transformedPath = transformedCommands.join('');
        const shadowOpacity = bevel > 0 ? 0.2 * (1 - i/depthSteps) : 0;
        
        layers.push({
            pathData: transformedPath,
            zIndex: depthSteps - i,
            shadowOpacity,
            isFrontFace: i === 0
        });
    }
    
    // Clear cache
    cachedPoints.clear();
    
    return layers;
}; 