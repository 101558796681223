import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

export const ProductTemplate = (props) => {
  const [image] = useImage(props.template.image_url);
  const [background, status] = useImage(props.template.background_url);
  //console.log(props.template.background_url);
  //console.log(background);
  let designView = document.querySelector("#DesignView");

  React.useEffect(() => {
    if (status === 'loaded'){
      //console.log("Background Loaded");
      return
    } else if (status === 'loading'){
      //console.log("Background Loading");
    } else if (status === 'error'){
      //console.log("Background Error");
    }
  }, [status]);

  return (<>
    <Image
      width={props.template.template_width}
      height={props.template.template_height}
      image={image}
      listening={false}
      border={'#000000'}
      borderWidth={10}
      fill={props.template.background_color}
      fillPatternImage={background}
    /></>
  )
}

