import React from "react";
import {Button} from "react-bootstrap";
import resizeIcon from '../../../../assets/images/icon/scale.svg';
import scaleUpIcon from '../../../../assets/images/icon/scale-up.svg';
import scaleDownIcon from '../../../../assets/images/icon/scale-down.svg';

export const ScaleControls = ({
                                  isMobileView,
                                  onButtonClick,
                                  mobileIconStyles,
    elems,
    setElems,
    selectedId,
    selectedItem,
    onElementsChange,
    selectedElement,
                              }) => {

    if (isMobileView) {
        return (
            <button
                style={mobileIconStyles.mobile}
                className="mobile_context_button"
                onClick={onButtonClick}
            >
                <div
                    className="mobile_context_button_icon"
                    data-icon="Scale22"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${resizeIcon})`
                    }}
                />
                <div>Scale</div>
            </button>
        );
    } else {
        return (
            <button
                className="properties-panel__button desktop"
                onClick={onButtonClick}
            >
                <div
                    className="properties-panel__button-icon"
                    style={{
                        backgroundImage: `url(${resizeIcon})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <span className="properties-panel__button-label">Scale</span>
            </button>
        );
    }
}

export const ScaleControlsContent = ({
    elems,
    setElems,
    selectedId,
    selectedItem,
    onElementsChange,
    selectedElement,
    mobileIconStyles,
    isMobileView
}) => {
    const handleScaleUp = () => {
        const currentAttributes = selectedElement.getAttrs();
        const scaleFactor = 1.1;
        let width = currentAttributes.width * scaleFactor;
        let height = currentAttributes.height * scaleFactor;

        const element = {
            ...selectedItem,
            width: width,
            height: height
        };

        selectedElement.width(element.width);
        selectedElement.height(element.height);

        const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
        let elems_copy = elems.slice();
        elems_copy.splice(index, 1, element);

        setElems(elems_copy.slice());
        selectedElement.getLayer().batchDraw();
    };

    const handleScaleDown = () => {
        const currentAttributes = selectedElement.getAttrs();
        const scaleFactor = 0.9;
        let width = currentAttributes.width * scaleFactor;
        let height = currentAttributes.height * scaleFactor;

        const element = {
            ...selectedItem,
            width: width,
            height: height
        };

        selectedElement.width(element.width);
        selectedElement.height(element.height);

        const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
        let elems_copy = elems.slice();
        elems_copy.splice(index, 1, element);

        onElementsChange(elems_copy.slice());
    };

    return (
        <div className={isMobileView ? "slide-panel__grid" : "properties-panel__control-group"}>
            <button 
                className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
                onClick={handleScaleUp}
            >
                <div 
                    className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                    style={{
                        backgroundImage: `url(${scaleUpIcon})`
                    }}
                />
                <span className="slide-panel__button-label">
                    {isMobileView ? "Scale Up" : "Up"}
                </span>
            </button>

            <button 
                className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
                onClick={handleScaleDown}
            >
                <div 
                    className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                    style={{
                        backgroundImage: `url(${scaleDownIcon})`
                    }}
                />
                <span className="slide-panel__button-label">
                    {isMobileView ? "Scale Down" : "Down"}
                </span>
            </button>
        </div>
    );
}




