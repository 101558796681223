import React from "react";
import axios from "axios";

const PhotosAsset = ({ isMobileView, mobileIconStyles, loadElement, isListVisible }) => {
    const [selectedCategory, setSelectedCategory] = React.useState('Flags'); // default category
    const [photos, setPhotos] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [loadingPhotoId, setLoadingPhotoId] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [pageLoading, setPageLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const lastPhotoRef = React.useRef(null);

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setPage(prevPage => prevPage + 1);
        }
    }

    const goToPreviousPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    goToNextPage();
                }
            },
            { threshold: 1 }
        );

        if (lastPhotoRef.current) {
            observer.observe(lastPhotoRef.current);
        }

        return () => observer.disconnect();
    }, [lastPhotoRef]);

    React.useEffect(() => {
        const fetchCategories = async () => {
            const result = await axios('/api/v1/element_categories/by_element_type/raster/');
            if (result.data.length > 0) {
                setCategories(result.data);
            }
        };
        fetchCategories();
    }, []);

    React.useEffect(() => {
        if (selectedCategory) {
            const fetchData = async () => {
                const result = await axios(`/api/v1/element_categories/by_element_type/raster/elements/${selectedCategory}?page=${page}`);
                const totalPagesFromApi = parseInt(result.headers['pagy_pages']);
                const currentPageFromApi = parseInt(result.headers['pagy_page']);

                setTotalPages(totalPagesFromApi);
                setCurrentPage(currentPageFromApi);

                if (result.data.length > 0) {
                    setPhotos(prevPhotos => [...prevPhotos, ...result.data]);
                } else {
                    setPhotos([]);
                }
            };
            fetchData();
        }
    }, [selectedCategory, page]);

    const setSelectedCategoryWithReset = (category) => {
        setSelectedCategory(category);
        setPage(1);
        setPhotos([]);
    };

    const handlePhotoClick = (event) => {
        const s3Url = event.target.attributes.getNamedItem('data-itemdatas3url').value;
        const photoId = event.target.attributes.getNamedItem('data-itemid').value;

        setLoadingPhotoId(photoId);

        loadElement(event).then(() => {
            setLoadingPhotoId(null);
        }).catch(error => {
            console.error("Error in loadElement:", error);
        });
    };

    return (
        <div>
            <div className="photo-categories">
                {categories.map(categoryObj => (
                    <button
                        key={categoryObj.name}
                        onClick={() => setSelectedCategoryWithReset(categoryObj.name)}
                        className={selectedCategory === categoryObj.name ? 'active' : ''}
                    >
                        <img
                            src={categoryObj.element_category_icon}
                            alt={categoryObj.name}
                            title={categoryObj.name}
                            width="20"
                        />
                    </button>
                ))}
            </div>

            <div className="photos-grid">
                {page > 1 && <button onClick={goToPreviousPage} className="load-prev-btn">Load Previous</button>}

                {photos.map((photo, index) => {
                    const isLoading = String(loadingPhotoId) === String(photo.id);
                    const isLastPhoto = index === photos.length - 1;

                    return (
                        <div key={photo.id} className="photo-wrapper" ref={isLastPhoto ? lastPhotoRef : null}>
                            <img
                                src={photo.image_address}
                                alt={photo.name}
                                onClick={handlePhotoClick}
                                data-itemtype='Photos'
                                data-itemdata={photo.image_address}
                                data-itemdatas3url={photo.s3_full_image_address}
                                data-itemformat={photo.element_type}
                                data-itemid={photo.id}
                            />
                            {isLoading && <div className="asset-loading-spinner"></div>}
                        </div>
                    );
                })}
                {pageLoading && <div className="asset-loading-spinner"></div>}
                {currentPage < totalPages && <button onClick={goToNextPage} className="load-next-btn">Load More</button>}
            </div>
        </div>
    );
};

export default PhotosAsset;