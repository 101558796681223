// app/javascript/controllers/address_controller.js
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static targets = ["shipping", "tax", "total"];
    saveAddress(event) {
        console.log("Save address button clicked");

        event.preventDefault();

        // Fetch the form data
        // Find the form containing the clicked button
        const form = event.target.form;

        // Fetch the form data
        const formData = new FormData(form);

        // Send the data to the server
        fetch("/checkout/save_address", {
            method: "POST",
            body: formData,
            headers: {
                "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // Update the UI with the received data
                // select the targets and update the text content
                // Select the right-column
                const rightColumn = document.querySelector(".right-column");
                // change the contents of       <div data-address-target="shipping"></div> which is inside the right-column
                rightColumn.querySelector("[data-address-target='shipping']").textContent = data.shipping;
                rightColumn.querySelector("[data-address-target='tax']").textContent = data.tax;
                rightColumn.querySelector("[data-address-target='total']").textContent = data.total;

                // hide all the form fields and replace them with the data
                const addressFormFields = document.querySelector("#address-form-fields");
                addressFormFields.querySelectorAll('div').forEach((input) => {
                    input.style.display = "none";
                });
                // hide the save address button
                const saveAddressButton = document.querySelector("#save-address-button");
                saveAddressButton.style.display = "none";
                // show the edit address button
                //const editAddressButton = document.querySelector("#edit-address-button");
                //editAddressButton.classList.remove("hidden");
                // Create a new div element
                const addressData = document.createElement("div");
                // add class name to the div
                addressData.classList.add("address_data");
                // Add the data to the div
                // shipping_address: shipping_address, address2: address2, city: city, zip: zip, state: state, country: country_name }
                addressData.innerHTML = `
                    <div class="mt-1 text-sm text-gray-600">
                        ${data.shipping_address}
                    </div>
                    <div class="mt-1 text-sm text-gray-600">
                        ${data.address2}
                    </div>
                    <div class="mt-1 text-sm text-gray-600">
                        ${data.city}, ${data.state} ${data.zip}
                    </div>
                    <div class="mt-1 text-sm text-gray-600">
                        ${data.country}
                    </div>
                `;
                // Add the div to the address-form-fields
                addressFormFields.appendChild(addressData);


            })
            .catch((error) => {
                // Handle the error in JSON
                JSON.stringify(error);
            });
    }

    editAddress(event) {
        console.log("Edit address button clicked");
        event.preventDefault();

        // Hide the filled address
        document.querySelector('.displayAddress').style.display = 'none';

        // Show the address form fields
        document.querySelector('#address-form-fields').style.display = 'block';
    }
}
