import {Rect} from "react-konva";
import React from "react";

export const HorizontalScrollbar = (props) => {
    const horzRef = props.scrollerRef;
    let stage = props.stageRef.current;
    let printArea = props.printAreaRef.current;
    const PADDING = 0;
    const [scrollbarWidth, setScrollbarWidth] = React.useState(100);  // default value
    const scrollbarHeight = 10;
    let designView = document.querySelector("#DesignView");
    let containerWidth = designView.offsetWidth;
    let containerHeight = designView.offsetHeight;

    const positionHorizontalBar = (event) => {
        let designView = document.querySelector("#DesignView");
        let containerWidth = designView.offsetWidth;
        let containerHeight = designView.offsetHeight;
        horzRef.current.x(containerWidth / 2 - scrollbarWidth);
        horzRef.current.y(containerHeight - scrollbarHeight);
    }

    React.useEffect(() => {
        // on resize and load, move to correct location
        window.addEventListener('handleZoomInClick', positionHorizontalBar);
        window.addEventListener('handleZoomOutClick', positionHorizontalBar);
        window.addEventListener('fitScreen', positionHorizontalBar);
        window.addEventListener('resize', positionHorizontalBar);
        return function cleanup() {
            window.removeEventListener('handleZoomInClick', positionHorizontalBar);
            window.removeEventListener('handleZoomOutClick', positionHorizontalBar);
            window.removeEventListener('fitScreen', positionHorizontalBar);
            window.removeEventListener('resize', positionHorizontalBar);
        }
    }, []);

    return (
        <Rect
            ref={horzRef}
            width={scrollbarWidth}
            height={scrollbarHeight}
            fill={'grey'}
            opacity={0.99}
            cornerRadius={5}   // Rounded corners
            shadowBlur={5}     // Shadow for depth
            shadowOffsetX={2}  // Shadow offset
            shadowOffsetY={2}  // Shadow offset
            shadowColor={'black'}
            x={containerWidth / 2 - scrollbarWidth} //calculate start position
            y={containerHeight - scrollbarHeight}
            draggable={true}
            onMouseEnter={() => {   // Highlight on hover
                horzRef.current.fill('darkgrey');
                horzRef.current.draw();
            }}
            onMouseLeave={() => {
                horzRef.current.fill('grey');
                horzRef.current.draw();
            }}
            onDragMove={() => {
                // delta in %
                let drawLayer = props.drawingLayerRef.current;
                let printArea = props.printAreaRef.current;
                let template = props.templateLayerRef.current;

                const availableWidth = containerWidth - PADDING * 2 - scrollbarWidth;
                let absTemplateWidth = template.getClientRect({relativeTo: stage}).width;

                let delta = (horzRef.current.x() - PADDING) / availableWidth;

                const maxXShift = absTemplateWidth - containerWidth;

                const imageXShift = -maxXShift * delta;

                [printArea, drawLayer, template].forEach(layer => {
                    layer.x(imageXShift);
                });
            }}

            dragBoundFunc={(position) => {
                position.x = Math.max(
                    Math.min(position.x, containerWidth - scrollbarWidth - PADDING),
                    PADDING
                );
                position.y = containerHeight - PADDING - scrollbarHeight;
                return position;
            }}

        />
    );
};