import {EditableArea} from "./producttemplate/EditableArea";
import {PrintedArea} from "./producttemplate/PrintedArea";
import {Layer, Text} from "react-konva";
import React from "react";

const PrintArea = (props) => {
    return (
        <Layer
            ref={props.printAreaRef}
            name={'printArea'}
        >
            <EditableArea
                template={props.template}
                stageRef={props.stageRef}
                stageRect={props.stageRect}
                scale={props.scale}
            />
            <PrintedArea
                printedAreaRef={props.printedAreaRef}
                name={'printedArea'}
                template={props.template}
                stageRef={props.stageRef}
                stageRect={props.stageRect}
                scale={props.scale}
            />

            <Text
                x={0}
                y={0}
                text={'PRINT AREA'}
                fontSize={10}
                fontFamily={'Calibri'}
                fill={'black'}
                name={'printArea'}
            />
        </Layer>
    )
}

export default PrintArea;