import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["structureSelect", "feeInput"]

  connect() {
    // Initialize the fee input state based on current structure
    this.toggleFeeInput({ target: this.structureSelectTarget })
  }

  toggleFeeInput(event) {
    const isFixed = event.target.value === "true"
    const row = event.target.closest("tr")
    const feeInput = row.querySelector("input[type='number']")
    
    if (isFixed) {
      feeInput.disabled = true
      feeInput.value = ""
    } else {
      feeInput.disabled = false
      // Set default value if empty
      if (!feeInput.value) {
        feeInput.value = "10.00"
      }
    }
  }
}
