import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["panel", "tab", "tabContent"]
  static values = {
    activeTab: String
  }

  connect() {
    console.log('QuickEditPanelController connected')
    window.addEventListener('popstate', this.close.bind(this))
    // Listen for quick edit open events
    document.addEventListener('quick-edit:open', this.open.bind(this))
    
    // Show first available tab by default on mobile
    if (window.innerWidth <= 768 && this.tabTargets.length > 0) {
      this.showTab(this.tabTargets[0].dataset.tab)
    }
  }

  disconnect() {
    window.removeEventListener('popstate', this.close.bind(this))
    document.removeEventListener('quick-edit:open', this.open.bind(this))
  }

  // Update the open method to be callable from event
  open(event) {
    console.log('Opening panel')
    
    // Find and scroll to konva container
    const konvaContainer = document.querySelector('.konva-container')
    if (konvaContainer) {
      const headerOffset = 0
      const elementPosition = konvaContainer.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }

    this.panelTarget.classList.add('is-open')
    this.panelTarget.style.overflow = 'auto'
    history.pushState({ quickEdit: true }, '')
    
    // Show first available tab when opening on mobile
    if (window.innerWidth <= 768 && this.tabTargets.length > 0) {
      const firstTab = this.tabTargets[0]
      this.showTab(firstTab.dataset.tab)
    }

    document.body.classList.add('quick-edit-open');
  }

  close() {
    this.panelTarget.classList.remove('is-open')
    this.panelTarget.style.overflow = ''
    if (window.history.state?.quickEdit) {
      history.back()
    }

    document.body.classList.remove('quick-edit-open');
  }

  showTab(event) {
    const tabId = event.currentTarget?.dataset?.tab || event
    console.log('Showing tab:', tabId)

    // Update active tab
    this.tabTargets.forEach(tab => {
      tab.classList.toggle('active', tab.dataset.tab === tabId)
    })

    // Only hide/show tab content on mobile devices
    if (window.innerWidth <= 768) {
      // Show active content
      this.tabContentTargets.forEach(content => {
        content.classList.toggle('d-none', content.dataset.tab !== tabId)
      })
    } else {
      // On desktop, ensure all contents are visible
      this.tabContentTargets.forEach(content => {
        content.classList.remove('d-none')
      })
    }
  }
} 