import React from "react";
import flipIcon from '../../../../assets/images/icon/flip.svg';
import flipHorizontalIcon from "../../../../assets/images/icon/flip-horizontal.svg";
import flipVerticalIcon from "../../../../assets/images/icon/flip-vertical.svg";



export const FlipControls = ({
                                 isMobileView,
                                 onButtonClick,
                                 mobileIconStyles,
                                 props
                             }) => {


    if (isMobileView) {
        return (
            <button
                style={mobileIconStyles.mobile}
                className="mobile_context_button"
                onClick={onButtonClick}
            >
                <div
                    className="mobile_context_button_icon"
                    data-icon="Flip22"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${flipIcon})`
                    }}
                />
                <div>Flip</div>
            </button>
        )
    } else {
        return (
            <button
                className="properties-panel__button desktop"
                onClick={onButtonClick}
            >
                <div
                    className="properties-panel__button-icon"
                    style={{
                        backgroundImage: `url(${flipIcon})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <span className="properties-panel__button-label">Flip</span>
            </button>
        )
    }
}


export const FlipControlsContent = ({
    elems,
    setElems,
    selectedId,
    selectedItem,
    onElementsChange,
    selectedElement,
    onFlipVertical,
    onFlipHorizontal,
    mobileIconStyles,
    isMobileView
}) => {
    return (
        <div className={isMobileView ? "slide-panel__grid" : "properties-panel__control-group"}>
            <button 
                className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
                onClick={() => onFlipVertical(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange
                )}
            >
                <div 
                    className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                    style={{
                        backgroundImage: `url(${flipVerticalIcon})`
                    }}
                />
                <span className="slide-panel__button-label">
                    {isMobileView ? "Flip Vertical" : "Vertical"}
                </span>
            </button>

            <button 
                className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
                onClick={() => onFlipHorizontal(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange
                )}
            >
                <div 
                    className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                    style={{
                        backgroundImage: `url(${flipHorizontalIcon})`
                    }}
                />
                <span className="slide-panel__button-label">
                    {isMobileView ? "Flip Horizontal" : "Horizontal"}
                </span>
            </button>
        </div>
    );
};


export const handleHorizontalFlip = (
    elems,
    setElems,
    selectedId,
    selectedItem,
    selectedElement,
    onElementsChange
) => {
    // Flip the horizontal scale
    let newScaleX = -selectedElement.scaleX();
    let newScaleY = selectedElement.scaleY();

    const element = {
        ...selectedItem,
        scaleX: newScaleX,
        scaleY: newScaleY,
    };

    selectedElement.scaleX(newScaleX);
    selectedElement.scaleY(newScaleY);

    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    onElementsChange(elems_copy.slice());
}

export const handleVerticalFlip = (
    elems,
    setElems,
    selectedId,
    selectedItem,
    selectedElement,
    onElementsChange
) => {
    // Flip the vertical scale
    let newScaleX = selectedElement.scaleX();
    let newScaleY = -selectedElement.scaleY();

    const element = {
        ...selectedItem,
        scaleX: newScaleX,
        scaleY: newScaleY
    };

    selectedElement.scaleX(newScaleX);
    selectedElement.scaleY(newScaleY);

    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    onElementsChange(elems_copy.slice());
}
