import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element)
    this.modal.show()
    
    // Auto hide after 2 seconds
    setTimeout(() => {
      this.modal.hide()
    }, 2000)
  }

  disconnect() {
    this.modal.hide()
  }
} 