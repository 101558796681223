import React, { useRef, useEffect } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import PropTypes from "prop-types";
import { handleZoomInClick, handleZoomOutClick, handleZoomFitClick, handleZoomEditableClick } from "../SimpleDesigner/utils";

export const Toolbar = (props) => {
    const stage = props.stageRef.current;
    const printArea = props.printAreaRef.current;
    const drawingArea = props.drawingLayerRef.current;
    const templateArea = props.templateLayerRef.current;

    return (
        <div className="Toolbar">
            <ButtonGroup className='worktable__menu'>
                <Button
                    title="undo"
                    onClick={e => {
                        e.preventDefault();
                        props.drawingHistory.undo();
                    }}
                    disabled={!props.drawingHistory.hasUndo}
                >
                    <i className="fas fa-undo"></i>
                </Button>
                <Button
                    title="redo"
                    onClick={e => {
                        e.preventDefault();
                        props.drawingHistory.redo();
                    }}
                    disabled={!props.drawingHistory.hasRedo}
                >
                    <i className="fas fa-redo"></i>
                </Button>
                <Button onClick={(e) => handleZoomInClick(e, stage, printArea, drawingArea, templateArea, printArea.scaleX(), props.setScale, null, null, props.horizontalScrollerRef, props.verticalScrollerRef)}>+</Button>
                <Button onClick={(e) => handleZoomOutClick(e, stage, printArea, drawingArea, templateArea, printArea.scaleX(), props.setScale, null, null, props.horizontalScrollerRef, props.verticalScrollerRef)}>-</Button>
                <Button onClick={() => handleZoomFitClick(props.fitToScreen)}>Fit</Button>
                <Button onClick={() => handleZoomEditableClick(props.fitToScreen, props.fitEditableAreaToScreen)}>Fit 2</Button>
                <DropdownButton
                    id="dropdown-settings"
                    title="..."
                    className="worktable__settings-dropdown"
                >
                    <Dropdown.Item
                        onClick={() => props.setSnappingEnabled(!props.snappingEnabled)}
                    >
                        {props.snappingEnabled ? "Disable Snapping" : "Enable Snapping"}
                    </Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>
        </div>
    );
}

Toolbar.propTypes = {
    drawingHistory: PropTypes.object,
    stageRef: PropTypes.object,
    printAreaRef: PropTypes.object,
    drawingLayerRef: PropTypes.object,
    templateLayerRef: PropTypes.object,
    fitToScreen: PropTypes.func,
    fitEditableAreaToScreen: PropTypes.func,
    setScale: PropTypes.func,
    horizontalScrollerRef: PropTypes.object,
    verticalScrollerRef: PropTypes.object
};
