import React from "react";
import {Button} from "react-bootstrap";
import lockIcon from '../../../../assets/images/icon/padlock-lock.svg';
import unlockIcon from '../../../../assets/images/icon/padlock-unlock.svg';

export const LockControls = ({
                                 isMobileView,
                                 elems,
                                 selectedId,
                                 selectedItem,
                                 onElementsChange,
                                 onButtonClick,
                                 mobileIconStyles
                             }) => {
    if (isMobileView) {
        return (
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={onButtonClick}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Lock22"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${unlockIcon})`
                    }}
                />
                <div>Lock</div>
            </button>
        );
    }

    else {
        return (
            <Button disabled title="Lock/Unlock"><i className="fas fa-lock-open"></i></Button>
        )
    }

}