import React, { useState } from 'react';
import { SimplePreviewer } from "../SimplePreviewer/SimplePreviewer";
import { useDelayedUnmount } from '../../hooks/useDelayedUnmount';

export const PreviewerSliderPanel = ({ isMobileView, isOpen, onClose, ...props }) => {
    const [isDragging, setIsDragging] = useState(false);
    const shouldRenderContent = useDelayedUnmount(isOpen, 800);

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleClose = () => {
        if (!isDragging) {
            onClose();
        }
    };

    return (
        <div className={`slide-panel ${isOpen ? 'open' : 'closed'}`}>
            <div className="slide-panel__close-area" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onClick={handleClose}>
                <div className="slide-panel__close-text">Tap here to close</div>
            </div>
            {shouldRenderContent && (
                <SimplePreviewer {...props} isMobileView={isMobileView} onClose={onClose} />
            )}
        </div>
    );
}
