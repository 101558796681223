import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "suggestions", "filters"]

  connect() {
    this.debounceTimer = null
  }

  suggest() {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      const query = this.inputTarget.value.trim()
      if (query.length >= 2) {
        this.performLiveSearch(query)
      } else {
        this.clearSuggestions()
      }
    }, 300)
  }

  async performLiveSearch(query) {
    try {
      const response = await fetch(`/search/suggestions?q=${encodeURIComponent(query)}`)
      if (!response.ok) throw new Error('Search request failed')
      const data = await response.json()
      this.updateSuggestions(data)
      this.showSuggestions()
    } catch (error) {
      console.error('Search error:', error)
      this.handleSearchError(error)
    }
  }

  updateSuggestions(data) {
    if (!data.products?.length) {
      this.suggestionsTarget.innerHTML = '<div class="p-3 text-muted">No results found</div>'
      return
    }

    this.suggestionsTarget.innerHTML = data.products.map(product => `
      <a href="/products/${product.id}" class="suggestion-item d-flex align-items-center p-2">
        <div class="suggestion-image me-3">
          <img src="${product.thumbnail_url}" alt="${product.title}" class="rounded" width="40" height="40">
        </div>
        <div class="suggestion-details flex-grow-1">
          <div class="suggestion-title">${product.title}</div>
          <div class="suggestion-price">${product.price}</div>
        </div>
      </a>
    `).join('')
  }

  showSuggestions() {
    this.suggestionsTarget.classList.add('active')
  }

  hideSuggestions() {
    setTimeout(() => {
      this.suggestionsTarget.classList.remove('active')
    }, 200)
  }

  clearSuggestions() {
    this.suggestionsTarget.innerHTML = ''
  }

  handleSearchError(error) {
    let errorMessage = 'Sorry, something went wrong. Please try again.'
    if (error.message.includes('Failed to fetch') || error.message.includes('NetworkError')) {
      errorMessage = 'Search is temporarily unavailable. Please try again later.'
    }
    
    this.suggestionsTarget.innerHTML = `
      <div class="p-3 text-danger">
        <i class="fas fa-exclamation-circle me-2"></i>
        ${errorMessage}
      </div>
    `
    
    setTimeout(() => {
      this.clearSuggestions()
    }, 3000)
  }
}