import {updateSelectionRect} from "./utils";
import Konva from "konva";
import {handleZoomInClick, handleZoomOutClick} from './utils';


let initialScale = 0.5;
let lastDist = 0;
let isPanning = false;
let lastX = 0;
let lastY = 0;

export const handleCanvasTouchStart = (e, scaleRef, setScale, stage, printArea, drawingArea, templateArea, isElementTouched, isDrawingMode) => {
    if (isDrawingMode) {
        return;
    }
    if (isElementTouched === true) {
        // if an element is touched, do not handle touch start for the canvas
        return;
    }
    if (e.evt.touches && e.evt.touches.length === 2) {
        if (e.target == stage) {
            isPanning = false;
            let p1 = e.evt.touches[0];
            let p2 = e.evt.touches[1];
            lastDist = Math.sqrt(Math.pow(p2.clientX - p1.clientX, 2) + Math.pow(p2.clientY - p1.clientY, 2));
            initialScale = scaleRef.current.x  // Assuming scale.x and scale.y are always the same
        }
    } else if (e.evt.touches && e.evt.touches.length === 1) {
        isPanning = true;
        lastX = e.evt.touches[0].clientX;
        lastY = e.evt.touches[0].clientY;
    }
};

const ZOOM_THRESHOLD = 5;  // Adjust this value for your requirements

export const handleCanvasTouchMove = (e,
                                      scaleRef,
                                      setScale,
                                      stage,
                                      printArea,
                                      drawingArea,
                                      templateArea,
                                      isElementTouched,
                                      isElementTransformingRef,
                                      isDrawingMode
) => {
    if (isDrawingMode) {
        return;
    }
    if (isElementTouched === true) {
        // if an element is touched, do not handle touch move for the canvas
        return;
    }
    if (isElementTransformingRef.current === true) {
        // if an element is being transformed, do not handle touch move for the canvas
        return;
    }
    e.evt.preventDefault();
    if (e.evt.touches && e.evt.touches.length === 2) {
        if (e.target == stage) {
            let p1 = e.evt.touches[0];
            let p2 = e.evt.touches[1];
            let dist = Math.sqrt(Math.pow(p2.clientX - p1.clientX, 2) + Math.pow(p2.clientY - p1.clientY, 2));

            if (Math.abs(dist - lastDist) > ZOOM_THRESHOLD) {
                if (dist > lastDist) {
                    handleZoomInClick(e, stage, printArea, drawingArea, templateArea, scaleRef.current.x, setScale, dist, lastDist);
                } else {
                    handleZoomOutClick(e, stage, printArea, drawingArea, templateArea, scaleRef.current.x, setScale, dist, lastDist);
                }
                lastDist = dist;
            }
        }
    } else if (isPanning && e.evt.touches && e.evt.touches.length === 1) {
        let stage = e.target.getStage();
        let x = e.evt.touches[0].clientX;
        let y = e.evt.touches[0].clientY;
        stage.x(stage.x() + (x - lastX));
        stage.y(stage.y() + (y - lastY));

        lastX = x;
        lastY = y;
    }
};


export const handleCanvasTouchEnd = (e, scale, setScale) => {
    if (isPanning) {
        isPanning = false;
    } else {
        let newScale = Math.max(scale.x, 0.15);
        setScale({x: newScale, y: newScale});
    }
};

export const onMouseDown = (e, handleSelectionStart, props, isDrawingMode,
                            setCurrentLine,
                            brushSize, brushColor, isEraserActive,
                            setIsEraserActive, setBrushColor, setBrushSize,
                            brushType) => {

    if (isDrawingMode) {
        e.evt.preventDefault();
        let dashPattern = [];
        const pos = e.target.getStage().getPointerPosition();

        if (brushType === 'dotted') {
            dashPattern = [brushSize, brushSize * 2]; // Example dash pattern for dotted brush
        }

        const newLine = {
            type: 'freehand',
            points: [pos.x - 15, pos.y - 15],
            stroke: brushColor,
            strokeWidth: brushSize,
            placement: props.placementRef.current,
            scaleX: 1 / props.drawingLayerRef.current.scaleX(),
            scaleY: 1 / props.drawingLayerRef.current.scaleY(),
            dash: dashPattern,
            // Add any other properties needed for your line
        };
        setCurrentLine(newLine); // Start a new line
        return;
    }

    // Check if the click is not on an element or transformer
    if (e.target === e.target.getStage()) {
        e.evt.preventDefault();
        handleSelectionStart(e);
    }
};

export const onMouseMove = (e, handleSelectionMouseMove, selectionIsVisible, isDrawingMode,
                            currentLine, setCurrentLine,
                            brushSize, brushColor, isEraserActive, setIsEraserActive,
                            brushType
) => {
    if (isDrawingMode && currentLine) {
        e.evt.preventDefault();
        let dashPattern = [];
        let additionalProps = {}; // Object to hold additional properties based on brushType
        let amplitude = 0; // Used for wavy and zigzag brush types
        let frequency = 0; // Used for zigzag brush type
        let wavelength = 0; // Used for wavy brush type
        let newX = 0;
        let newY = 0;
        const pos = e.target.getStage().getPointerPosition();
        // Update currentLine's points
        const updatedPoints = currentLine.points.concat([pos.x - 15, pos.y - 15]);


        // Handle special brush types
        switch (brushType) {
            case 'dotted':
                dashPattern = [brushSize, brushSize * 2];
                break;
            case 'zigzag':
                amplitude = 10; // Height of the zigzags
                frequency = 20; // How often the zigzag pattern repeats
                newX = pos.x;
                newY = pos.y + (Math.round(newX / frequency) % 2 === 0 ? amplitude : -amplitude);
                updatedPoints.push(newX, newY);
                break;
            case 'wavy':
                amplitude = 10; // Height of the waves
                wavelength = 50; // Width of the waves
                newX = pos.x;
                newY = pos.y + amplitude * Math.sin(newX / wavelength);
                updatedPoints.push(newX, newY);
                break;
            case 'tapered':
                // Not implemented
                const maxStrokeWidth = 20;
                const minStrokeWidth = 2;
                const lineLength = Math.min(updatedPoints.length / 2, 100); // Assuming 2 points per line segment
                additionalProps.strokeWidth = maxStrokeWidth - ((maxStrokeWidth - minStrokeWidth) * (lineLength / 100));
                break;
            case 'glowing':
                // Not implemented
                additionalProps.shadowColor = 'yellow';
                additionalProps.shadowBlur = 10;
                break;
            case 'rainbow':
                // Not implemented
                const colors = ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet'];
                const colorIndex = Math.floor(updatedPoints.length / 20) % colors.length;
                additionalProps.stroke = colors[colorIndex];
                break;
        }

        setCurrentLine({
            ...currentLine,
            points: updatedPoints,
            stroke: brushColor,
            strokeWidth: brushSize,
            dash: dashPattern,
            ...additionalProps
        });
        return;
    }


    if (selectionIsVisible()) {
        e.evt.preventDefault();
        handleSelectionMouseMove(e);
    }

};

/*export const onMouseUp = (e, handleSelectionMouseUp, selectionIsVisible, selection, props, trRef, selectionRectRef,
                          isDrawingMode, currentLine, setCurrentLine,
                          brushSize, brushColor, isEraserActive,
                          setIsEraserActive, setBrushColor, setBrushSize,
                          elems, setElems, brushType, setBrushType,
                          setSelectedId, setSelectedElement, setNodes,
                          selectedGroupRef, selectedElement) => {

    if (isDrawingMode && currentLine) {
        e.evt.preventDefault();

        let dashPattern = [];
        if (brushType === 'dotted') {
            dashPattern = [brushSize, brushSize * 2]; // Example dash pattern for dotted brush
        }

        // For other brush types, create a new line element
        const newLineElement = {
            id: `line_${Date.now()}`,
            type: 'freehand',
            points: currentLine.points,
            stroke: brushColor,
            strokeWidth: brushSize,
            dash: dashPattern,
            placement: props.placementRef.current,
            scaleX: 1 / props.drawingLayerRef.current.scaleX(),
            scaleY: 1 / props.drawingLayerRef.current.scaleY(),
        };

        // Add the new line to the canvas
        setElems([...elems, newLineElement]);
        setCurrentLine(null); // Reset currentLine to indicate drawing is complete
        return;
    }

    if (!selection.current.visible) {
        return;
    }

    if (selectionIsVisible()){
        e.evt.preventDefault();
        handleSelectionMouseUp(e, trRef);
    }
};*/

export const onMouseUp = (e, handleSelectionMouseUp, selectionIsVisible, selection, props, trRef, selectionRectRef,
                          isDrawingMode, currentLine, setCurrentLine,
                          brushSize, brushColor, isEraserActive,
                          setIsEraserActive, setBrushColor, setBrushSize,
                          elems, setElems, brushType, setBrushType,
                          setSelectedId, setSelectedElement, setNodes,
                          selectedGroupRef, selectedElement) => {
    if (isDrawingMode && currentLine) {
        e.evt.preventDefault();

        let dashPattern = [];
        if (brushType === 'dotted') {
            dashPattern = [brushSize, brushSize * 2]; // Example dash pattern for dotted brush
        }

        // For other brush types, create a new line element
        const newLineElement = {
            id: `line_${Date.now()}`,
            type: 'freehand',
            points: currentLine.points,
            stroke: brushColor,
            strokeWidth: brushSize,
            dash: dashPattern,
            placement: props.placementRef.current,
            scaleX: 1 / props.drawingLayerRef.current.scaleX(),
            scaleY: 1 / props.drawingLayerRef.current.scaleY(),
        };

        // Add the new line to the canvas
        setElems([...elems, newLineElement]);
        setCurrentLine(null); // Reset currentLine to indicate drawing is complete
        return;
    }

    if (!selection.current.visible) {
        return;
    }

    if (selectionIsVisible()) {
        e.evt.preventDefault();
        handleSelectionMouseUp(e, trRef);

        /*// Check if a Pattern Element is selected
        const selectedNodes = trRef.current.nodes();
        const selectedPatternElement = elems.find((elem) => elem.type === 'pattern' && selectedNodes.some((node) => node.attrs.id === elem.id));

        if (selectedPatternElement) {
            // If a Pattern Element is selected, update the selection state
            console.log("selectedPatternElement", selectedPatternElement);
            console.log("selectedPatternElement.id", selectedPatternElement.id);
            console.log("selectedPatternElement.elems", selectedPatternElement.elems);
            console.log("selectedPatternElement.elems[0]", selectedPatternElement.elems[0]);
            setSelectedId(selectedPatternElement.id);
            setSelectedElement(selectedPatternElement);
            selectedGroupRef.current = selectedPatternElement;
        } else {
            // If no Pattern Element is selected, clear the selection state
            setSelectedId(null);
            setSelectedElement(null);
            selectedGroupRef.current = null;
        }*/
    }
};

export const onClickTap = (e, handleSelectionMouseUp, selection, setSelectedId, setSelectedElement, trRef, setNodes, deselectElement, selectElement, elems, setSelectedPatternDesignElementId) => {
    console.log("onClickTap", e.target);



    // Check if the clicked element belongs to the SymmetryShapeEditor layer
    if (e.target.getLayer() && e.target.getLayer().attrs.id === 'symmetryShapeEditorLayer') {
        //console.log("SymmetryShapeEditor layer clicked");
        //setSelectedPatternDesignElementId(null);
        // Handle the click event within the SymmetryShapeEditor component
        // You can dispatch a custom event or call a specific function to handle the selection logic
        // For example:
        // Check if the clicked element is the background rectangle in the SymmetryShapeEditor layer
        const clickEvent = new CustomEvent('symmetryShapeClick', { detail: e.target });
        e.target.getStage().container().dispatchEvent(clickEvent);

        if (e.target.attrs.id === 'symmetrySquareDrawingPad') {
            console.log("Clicked on symmetry drawing pad area");
            setSelectedPatternDesignElementId(null); // Deselect the element in the pattern design
            return; // Early return to prevent further execution
        }
        return;
    }

    // if click on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
        deselectElement();
        setNodes([]);
        setSelectedPatternDesignElementId(null);
        return;
    }

    // if we are selecting with rect, do nothing
    if (selection.current.visible) {
        return;
    }


    // Check if the clicked element is part of a 'pattern' group
    const patternElement = elems.find((elem) => elem.type === 'pattern' && elem.elems.some((child) => child.id === e.target.id()));
    console.log("patternElement", patternElement);




    if (patternElement) {
        // If the clicked element is part of a 'pattern' group, select the group
        setSelectedId(patternElement.id);
        setSelectedElement(patternElement);

        // Find the Konva group node for the pattern element
        const patternGroup = e.target.getStage().findOne(`#${patternElement.id}`);
        trRef.current.nodes([patternGroup]);
    } else if (e.target.hasName("element") && !patternElement) {
        // If the clicked element is not part of a 'pattern' group, handle the selection as before
        const tr = trRef.current;
        const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
        const isSelected = tr.nodes().indexOf(e.target) >= 0;

        if (!metaPressed && !isSelected) {
            handleSelectionMouseUp(e, trRef);
        } else if (metaPressed && isSelected) {
            const nodes = tr.nodes().slice();
            nodes.splice(nodes.indexOf(e.target), 1);
            tr.nodes(nodes);
        } else if (metaPressed && !isSelected) {
            const nodes = tr.nodes().concat([e.target]);
            tr.nodes(nodes);
        }
    } else {
        return;
    }
};

/*export const onClickTap = (e, handleSelectionMouseUp, selection, setSelectedId, setSelectedElement, trRef, setNodes, deselectElement, selectElement) => {

    console.log("onClickTap", e.target);
    //if click on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
        //console.log("clicked on empty");

        deselectElement();
        setNodes([]);
    }
    // if we are selecting with rect, do nothing
    if (selection.current.visible) {
        return;
    }

    // do nothing if clicked NOT on our elements
    if (!e.target.hasName("element")) {
        return;
    }

    // do we pressed shift or ctrl?
    const tr = trRef.current;
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
    const isSelected = tr.nodes().indexOf(e.target) >= 0;

    if (!metaPressed && !isSelected) {
        // if no key pressed and the node is not selected
        // select just one
        //tr.nodes([e.target]);
        //selectElement(e.target.id());
        handleSelectionMouseUp(e, trRef);


    } else if (metaPressed && isSelected) {
        // if we pressed keys and node was selected
        // we need to remove it from selection:
        const nodes = tr.nodes().slice(); // use slice to have new copy of array
        // remove node from array
        nodes.splice(nodes.indexOf(e.target), 1);
        tr.nodes(nodes);
    } else if (metaPressed && !isSelected) {
        // add the node into selection
        const nodes = tr.nodes().concat([e.target]);
        tr.nodes(nodes);
    }
    //props.drawingLayerRef.current.draw();
};*/
