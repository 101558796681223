import React from 'react';
import {Group, Shape, Text} from 'react-konva';
import {Rect} from 'react-konva';

export const PrintedArea = (props) => {

  return (<Group
      ref={props.printedAreaRef}
      x={props.template.print_area_left}
      y={props.template.print_area_top}
      width={props.template.print_area_width}
      height={props.template.print_area_height}
  >
    <Rect
        width={props.template.print_area_width}
        height={props.template.print_area_height}
        fill='rgba(255,255,255,0)'
        listening={false}
        borderRadius={10}
        borderStrokeWidth={40}
        borderStroke={'black'}
      />
    <Text
        x={0}
        y={0}
        text={'PRINTED AREA'}
        fontSize={12}
        fontFamily={'Calibri'}
        fill={'black'}
        name={'printArea'}
    />
    </Group>)
}