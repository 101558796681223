import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "saveStatus", "price", "profitAmount", "profitMargin"]
  
  connect() {
    this.debouncedSave = this.debounce(this.autoSave.bind(this), 1000)
    if (this.hasPriceTarget) {
      this.calculateProfit()
    }
  }
  
  debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }
  
  async autoSave() {
    const form = this.formTarget
    const formData = new FormData(form)
    
    try {
      const response = await fetch(form.action + '/autosave', {
        method: 'PATCH',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      })
      
      const data = await response.json()
      
      if (response.ok) {
        this.saveStatusTarget.textContent = `Last saved at ${data.timestamp}`
        this.saveStatusTarget.classList.remove('text-danger')
        this.saveStatusTarget.classList.add('text-success')
      } else {
        this.saveStatusTarget.textContent = 'Error saving changes'
        this.saveStatusTarget.classList.remove('text-success')
        this.saveStatusTarget.classList.add('text-danger')
      }
    } catch (error) {
      console.error('Autosave failed:', error)
    }
  }
  
  calculateProfit() {
    const price = parseFloat(this.priceTarget.value) || 0
    const baseCost = parseFloat(this.priceTarget.dataset.baseCost) || 0
    
    if (price > 0) {
      const profit = price - baseCost
      const margin = (profit / price) * 100
      
      this.profitAmountTarget.textContent = `$${profit.toFixed(2)}`
      this.profitMarginTarget.textContent = `${margin.toFixed(1)}%`
      
      // Update styling based on profit
      const profitClass = profit > 0 ? 'text-success' : 'text-danger'
      this.profitAmountTarget.className = profitClass
      this.profitMarginTarget.className = profitClass
    } else {
      this.profitAmountTarget.textContent = '$0.00'
      this.profitMarginTarget.textContent = '0%'
    }
  }
  
  handlePriceChange() {
    this.calculateProfit()
    this.debouncedSave()
  }
  
  formatPrice(event) {
    const input = this.priceTarget
    const value = parseFloat(input.value)
    if (!isNaN(value)) {
      input.value = value.toFixed(2)
    }
  }
}
