import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["searchBar", "cartButton"]

  connect() {
    document.addEventListener('click', this.handleOutsideClick.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick.bind(this))
  }

  toggleSearch(event) {
    event.preventDefault()
    event.stopPropagation()
    
    this.searchBarTarget.classList.toggle("slide-up-active")
    document.body.classList.toggle("search-active")
    
    if (this.searchBarTarget.classList.contains("slide-up-active")) {
      this.addOverlay()
      // Focus the search input when opened
      setTimeout(() => {
        const searchInput = this.searchBarTarget.querySelector('.search-input')
        if (searchInput) searchInput.focus()
      }, 300)
    } else {
      this.removeOverlay()
    }
  }

  handleOutsideClick(event) {
    if (this.searchBarTarget.classList.contains("slide-up-active") && 
        !this.searchBarTarget.contains(event.target) && 
        !event.target.closest('[data-action="click->mobile-menu#toggleSearch"]')) {
      this.searchBarTarget.classList.remove("slide-up-active")
      document.body.classList.remove("search-active")
      this.removeOverlay()
    }
  }

  addOverlay() {
    if (!document.querySelector(".mobile-menu-overlay")) {
      const overlay = document.createElement("div")
      overlay.classList.add("mobile-menu-overlay")
      document.body.appendChild(overlay)
      
      overlay.addEventListener("click", () => {
        this.searchBarTarget.classList.remove("slide-up-active")
        document.body.classList.remove("search-active")
        this.removeOverlay()
      })
    }
  }

  removeOverlay() {
    const overlay = document.querySelector(".mobile-menu-overlay")
    if (overlay) {
      overlay.remove()
    }
  }
}

