import React, { useState } from 'react';
import { loadElement } from "../lib/assetUtils";
import PhotosAsset from "../SimpleAssets/PhotosAsset";
import IconsAsset from "../SimpleAssets/IconsAsset";
import TextAsset from "../SimpleAssets/TextAsset";
import MyFilesAsset from "../SimpleAssets/MyFilesAsset";
import photosIcon from '../../../../assets/images/icon/photos.svg';
import vectorIcon from '../../../../assets/images/icon/vector.svg';
import textIcon from '../../../../assets/images/icon/text.svg';
import myFilesIcon from '../../../../assets/images/icon/myfiles.svg';
import { useDelayedUnmount } from '../../hooks/useDelayedUnmount';
import { useSelectionContext } from '../../contexts/SelectionContext';

export const SliderPanel = ({
    isMobileView,
    isOpen,
    onClose,
    isAuthenticated,
    mobileIconStyles,
    printAreaRef,
    stageRef,
    placementRef,
    elems,
    setElems,
    ...props
}) => {
    const { 
        selectElement,
        selectedElement,
        setSelectedElement,
        setSelectedId 
    } = useSelectionContext();

    const [isDragging, setIsDragging] = useState(false);
    const [activeView, setActiveView] = useState('initial');
    const [isLoading, setIsLoading] = useState(false);
    const shouldRenderContent = useDelayedUnmount(isOpen, 800);

    const handleMouseDown = () => setIsDragging(true);
    const handleMouseUp = () => setIsDragging(false);
    const handleClose = () => !isDragging && onClose();

    const handleViewChange = (view) => {
        setIsLoading(true);
        setActiveView(view);
        setTimeout(() => setIsLoading(false), 300);
    };

    return (
        <div className={`slide-panel ${isOpen ? 'open' : 'closed'}`}>
            {shouldRenderContent && (
                <>
                    <div className="slide-panel__header">
                        <div className="slide-panel__header-pill" />
                        {activeView !== 'initial' && (
                            <>
                                <button className="slide-panel__header-back" onClick={() => setActiveView('initial')}>
                                    <i className="fas fa-chevron-left" />
                                </button>
                                <h2 className="slide-panel__header-title">
                                    {activeView.charAt(0).toUpperCase() + activeView.slice(1)}
                                </h2>
                            </>
                        )}
                    </div>

                    <div className="slide-panel__content">
                        {activeView === 'initial' ? (
                            <div className="slide-panel__grid">
                                <button
                                    className="slide-panel__button"
                                    onClick={() => setActiveView('photos')}
                                >
                                    <div 
                                        className="slide-panel__button-icon"
                                        style={{ backgroundImage: `url(${photosIcon})` }}
                                    />
                                    <span className="slide-panel__button-label">Photos</span>
                                </button>

                                <button
                                    className="slide-panel__button"
                                    onClick={() => setActiveView('icons')}
                                >
                                    <div 
                                        className="slide-panel__button-icon"
                                        style={{ backgroundImage: `url(${vectorIcon})` }}
                                    />
                                    <span className="slide-panel__button-label">Icons</span>
                                </button>

                                <button
                                    className="slide-panel__button"
                                    onClick={() => setActiveView('text')}
                                >
                                    <div 
                                        className="slide-panel__button-icon"
                                        style={{ backgroundImage: `url(${textIcon})` }}
                                    />
                                    <span className="slide-panel__button-label">Text</span>
                                </button>

                                <button
                                    className="slide-panel__button"
                                    onClick={() => setActiveView('myFiles')}
                                >
                                    <div 
                                        className="slide-panel__button-icon"
                                        style={{ backgroundImage: `url(${myFilesIcon})` }}
                                    />
                                    <span className="slide-panel__button-label">My Files</span>
                                </button>
                            </div>
                        ) : activeView === 'photos' ? (
                            <PhotosAsset
                                isMobileView={isMobileView}
                                loadElement={(e) => loadElement(e, { 
                                    ...props, 
                                    printAreaRef, 
                                    stageRef, 
                                    placementRef, 
                                    elems, 
                                    setElems 
                                }, activeView, selectElement, onClose)}
                                printAreaRef={printAreaRef}
                                stageRef={stageRef}
                                placementRef={placementRef}
                                elems={elems}
                                setElems={setElems}
                                selectedElement={selectedElement}
                                setSelectedElement={setSelectedElement}
                                setSelectedId={setSelectedId}
                                onClose={onClose}
                            />
                        ) : activeView === 'icons' ? (
                            <IconsAsset
                                isMobileView={isMobileView}
                                loadElement={(e) => loadElement(e, { 
                                    ...props, 
                                    printAreaRef, 
                                    stageRef, 
                                    placementRef, 
                                    elems, 
                                    setElems 
                                }, activeView, selectElement, onClose)}
                                printAreaRef={printAreaRef}
                                stageRef={stageRef}
                                placementRef={placementRef}
                                elems={elems}
                                setElems={setElems}
                                selectedElement={selectedElement}
                                setSelectedElement={setSelectedElement}
                                setSelectedId={setSelectedId}
                                onClose={onClose}
                            />
                        ) : activeView === 'text' ? (
                            <TextAsset
                                isMobileView={isMobileView}
                                loadElement={(e) => loadElement(e, { ...props, printAreaRef, stageRef, placementRef, elems, setElems }, activeView, selectElement, onClose)}
                                printAreaRef={printAreaRef}
                                stageRef={stageRef}
                                placementRef={placementRef}
                                elems={elems}
                                setElems={setElems}
                                selectedElement={selectedElement}
                                setSelectedElement={setSelectedElement}
                                setSelectedId={setSelectedId}
                                onClose={onClose}
                            />
                        ) : activeView === 'myFiles' ? (
                            <MyFilesAsset
                                isMobileView={isMobileView}
                                loadElement={(e) => loadElement(e, { ...props, printAreaRef, stageRef, placementRef, elems, setElems }, activeView, selectElement, onClose)}
                                printAreaRef={printAreaRef}
                                stageRef={stageRef}
                                placementRef={placementRef}
                                elems={elems}
                                setElems={setElems}
                                selectedElement={selectedElement}
                                setSelectedElement={setSelectedElement}
                                setSelectedId={setSelectedId}
                                onClose={onClose}
                                isAuthenticated={isAuthenticated}
                            />
                        ) : null}
                    </div>
                </>
            )}
             <div 
                className="slide-panel__close-area" 
                onMouseDown={handleMouseDown} 
                onMouseUp={handleMouseUp} 
                onClick={handleClose}
            >
                <div className="slide-panel__close-text">Tap here to close</div>
            </div>
        </div>
    );
};

