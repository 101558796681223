import React, {useRef, useMemo} from 'react';
import {Line} from 'react-konva';

export const FreehandDrawingShape = ({
                                         onSelect,
                                         isSelected,
                                         trRef,
                                         addToActiveNodes,
                                         removeFromActiveNodes,
                                         element,
                                         onChange,
                                         draggable = true,
                                         onMouseDown,
                                         onMouseMove,
                                         onMouseOver,
                                         onMouseOut,
                                     }) => {

    const shapeRef = useRef(null);

    // Optionally, keep the onDragEnd and onTransformEnd handlers to manage post-drawing transformations
    const handleDragEnd = (e) => {
        const node = shapeRef.current;
        onChange({
            ...element,
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),
        });
    };

    const handleTransformEnd = (e) => {
        const node = shapeRef.current;
        onChange({
            ...element,
            x: node.x(),
            y: node.y(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
            rotation: node.rotation(),
        });
    };

    return (
        <Line
            id={element.id.toString()}
            name={element.name || 'element'}
            points={element.points}
            stroke={element.stroke || '#000000'}
            strokeWidth={element.strokeWidth || 2}
            lineCap="round"
            lineJoin="round"
            dash={element.dash || []}
            draggable={draggable}
            onClick={onSelect}
            onTap={onSelect}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onDragEnd={handleDragEnd}
            onTransformEnd={handleTransformEnd}
            ref={shapeRef}
            placement={element.placement || 'front'}
            scaleX={element.scaleX}
            scaleY={element.scaleY}
        />
    );
};


export default FreehandDrawingShape;
