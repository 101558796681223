import { Controller } from "@hotwired/stimulus"
import KonvaStageService from "../services/konva_stage_service"

export default class extends Controller {
  static targets = ["canvas"]
  static values = {
    productId: String,
    design: Object,
    templates: Array,
    colorCode: String
  }

  async connect() {
    if (!this.hasCanvasTarget) return
    
    const container = this.canvasTarget
    const options = {
      templates: this.templatesValue,
      designData: this.designValue,
      variants: [{ 
        color: this.colorCodeValue,
        color_code: this.colorCodeValue 
      }],
      width: 100,
      height: 100,
      backgroundColor: this.colorCodeValue,
      defaultColor: this.colorCodeValue,
      scale: 0.25
    }

    this.stageService = await KonvaStageService.createProductPreview(container, options)
    
    if (this.stageService?.stage) {
      this.stageService.stage.container().style.backgroundColor = this.colorCodeValue
      this.stageService.stage.width(100)
      this.stageService.stage.height(100)
      this.stageService.stage.draw()
    }
  }

  disconnect() {
    if (this.stageService) {
      this.stageService.destroy()
    }
  }
}
