import {buildColorMap, updateSVGColors, extractColorsFromSVG} from "./propertiesUtils";
import React from "react";
import {Swatch} from "./SwatchControls";
import colorsIcon from "../../../../assets/images/icon/color.svg";
import {Dropdown} from "react-bootstrap";
import {ChromePicker, SketchPicker} from "react-color";
import {base64encode} from "../lib/utils";
import {useSelectionContext} from "../../contexts/SelectionContext";


export const ColorControls = ({
                                  isMobileView,
                                  onButtonClick,
                                  mobileIconStyles,
                                  props
                              }) => {
    return (
        <button
            style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
            className={isMobileView ? "mobile_context_button" : "properties-panel__button"}
            onClick={onButtonClick}
        >
            <div
                className={isMobileView ? "mobile_context_button_icon" : "properties-panel__button-icon"}
                data-icon="Colors"
                style={{
                    ...(isMobileView ? mobileIconStyles.icon : mobileIconStyles.desktopIcon),
                    backgroundImage: `url(${colorsIcon})`
                }}
            />
            <div>Colors</div>
        </button>
    );
};

const ShapeColorMagic = (props) => {
    const [shapeColors, setShapeColors] = React.useState([]);
    const {
        selectedId,
        selectedItem,
        selectedElement
    } = useSelectionContext();

    return (
        <Swatch
            fillValue={selectedItem.fill}
            onElementsChange={props.onElementsChange}
            elems={props.elems}
            setShapeColors={setShapeColors}
            //buildColorMap={props.buildColorMap}
            selectedId={selectedId}
            selectedElement={selectedElement}
            selectedItem={selectedItem}
        />
    )
}

const FillColorMagic = (props) => {
    const {
        selectedId,
        selectedItem,
        selectedElement
    } = useSelectionContext();

    const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
    const [fillColor, setFillColor] = React.useState();
    const [selectedCssClass, setSelectedCssClass] = React.useState(null);
    const [svgColors, setSvgColors] = React.useState([]);
    const [extractedColors, setExtractedColors] = React.useState([]);
    const [currentColors, setCurrentColors] = React.useState({});

    React.useEffect(() => {
        if (selectedItem && selectedItem.format === 'svg') {
            const uniqueColors = extractColorsFromSVG(selectedItem?.svgXML);
            setExtractedColors(uniqueColors);

            // Initialize currentColors
            let initialColorMapping = {};
            uniqueColors.forEach(color => {
                initialColorMapping[color] = color;
            });
            setCurrentColors(initialColorMapping);
        }
    }, [props.selectedElement]);

    React.useEffect(() => {
        // If the selected item is a path, extract the fill color
        if (selectedItem && selectedItem.type === 'path') {
            // Extract the fill color from the selected item
            setExtractedColors([selectedItem?.fill]);
        }
    }, [selectedElement]);


    const extractUniqueColorsFromSVG = (svgXml) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(svgXml, "text/xml");

        const elementsWithFill = xmlDoc.querySelectorAll("[fill]");
        const uniqueColorsSet = new Set();

        elementsWithFill.forEach(element => {
            const color = element.getAttribute("fill");
            if (color && color !== "none") {
                uniqueColorsSet.add(color);
            }
        });

        const styleTags = xmlDoc.getElementsByTagName("style");
        let allCssRules = "";

        for (let i = 0; i < styleTags.length; i++) {
            allCssRules += styleTags[i].textContent;
        }

        const colorRegex = /fill:\s*(#[0-9a-fA-F]{3,6}|rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)|rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*[0-1]?\.?\d*\s*\))/g;
        const matchedColors = allCssRules.match(colorRegex) || [];

        matchedColors.forEach(match => {
            const color = match.replace("fill:", "").trim();
            uniqueColorsSet.add(color);
        });

        return [...uniqueColorsSet];
    };



    function handleChangeFillColor(color) {

        const index = props.elems.findIndex((arrItem) => arrItem.id === selectedId);
        let elems_copy = [...props.elems]; // Use the spread operator for copying arrays

        if (selectedItem.format === 'svg') {
            let newColor = color.hex.toLowerCase(); // Normalize the new color to lowercase
            let colorMap = {};

            // Use the current color as the key in colorMap
            const currentColor = (currentColors[selectedCssClass] || selectedCssClass).toLowerCase(); // Normalize current color to lowercase
            colorMap[currentColor] = newColor;

            // Re-parse the SVG and apply color changes
            let updatedSVG = updateSVGColors(colorMap, selectedItem.svgXML);

            // Ensure you are using a proper base64 encoding function
            const newData = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(updatedSVG)));
            const updatedItem = {...selectedItem, svgXML: updatedSVG, data: newData};
            elems_copy[index] = updatedItem; // Update the specific item

            props.onElementsChange(elems_copy);
            setExtractedColors(extractColorsFromSVG(updatedSVG));

            // Update the current color mapping
            let updatedCurrentColors = {...currentColors, [selectedCssClass]: newColor};
            setCurrentColors(updatedCurrentColors);
            setSelectedCssClass(newColor); // Update selectedCssClass to the new color
        }
        else if (selectedItem.type === 'path') {
            // Update the fill color of the selected item
            elems_copy[index].fill = color.hex;
            props.onElementsChange(elems_copy);
            setFillColor(color.hex); // You might want to normalize this as well
        }



        setFillColor(color.hex); // You might want to normalize this as well
    }







    function handleFillColorClick(cssClass) {
        setSelectedCssClass(cssClass); // which swatch was clicked.
        //setDisplayColorPicker(!displayColorPicker);
        setDisplayColorPicker(true);
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {extractedColors.map((color, index) => {
                    return (
                        <Swatch key={index}
                                cssClass={color} // Adjust based on Swatch component expectations
                                isSelected={selectedCssClass === color}
                                fillValue={color}
                                onElementsChange={props.onElementsChange}
                                elems={props.elems}
                                setSvgColors={setSvgColors}
                                selectedId={selectedId}
                                selectedElement={selectedElement}
                                selectedItem={selectedItem}
                                isMobileView={props.isMobileView}
                                displayColorPicker={displayColorPicker}
                                setDisplayColorPicker={setDisplayColorPicker}
                                fillColor={fillColor}
                                setFillColor={setFillColor}
                                handleFillColorClick={() => handleFillColorClick(color)}
                        />
                    );
                })}
            </div>
            {displayColorPicker && (
                <div>
                    <div 
                        style={{
                            position: 'fixed', 
                            top: '0px', 
                            right: '0px', 
                            bottom: '0px', 
                            left: '0px'
                        }} 
                        onClick={() => setDisplayColorPicker(false)}
                    />
                    {props.isMobileView ? (
                        <div style={{
                            position: 'fixed', 
                            bottom: '0',
                            left: '0',
                            width: '100%',
                            backgroundColor: '#fff',
                            borderTopLeftRadius: '16px',
                            borderTopRightRadius: '16px',
                            boxShadow: '0 -2px 10px rgba(0,0,0,0.12)',
                            padding: '16px',
                            zIndex: 1000
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '16px'
                            }}>
                                <span style={{fontSize: '18px', fontWeight: 500}}>Choose Color</span>
                                <button 
                                    onClick={() => setDisplayColorPicker(false)}
                                    style={{
                                        border: 'none',
                                        background: 'none',
                                        fontSize: '24px',
                                        padding: '8px'
                                    }}
                                >
                                    ×
                                </button>
                            </div>
                            <SketchPicker
                                color={fillColor}
                                onChange={handleChangeFillColor}
                                disableAlpha={true}
                                width="100%"
                                styles={{
                                    default: {
                                        picker: {
                                            width: '100%',
                                            padding: '8px',
                                            boxShadow: 'none',
                                        },
                                        hue: {
                                            height: '30px',  // Increased from default ~10px
                                            position: 'relative',
                                            marginBottom: '8px',
                                            borderRadius: '8px'
                                        },
                                        controls: {
                                            paddingTop: '12px'  // Add some spacing above controls
                                        },
                                        swatch: {
                                            width: '30px',  // Make swatches bigger
                                            height: '30px',  // Make swatches bigger
                                            borderRadius: '6px'
                                        }
                                    }
                                }}
                                presetColors={[
                                    '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321',
                                    '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2',
                                    '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'
                                ]}
                            />
                        </div>
                    ) : (
                        <div style={{
                            position: 'absolute',
                            zIndex: '2',
                            top: '100%',
                            left: '0',
                            marginTop: '8px',
                            boxShadow: '0 2px 10px rgba(0,0,0,0.12)'
                        }}>
                            <ChromePicker
                                color={fillColor}
                                onChange={handleChangeFillColor}
                                disableAlpha={true}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )



}


export const ColorControlsContent = ({
                                         buildColorMap,
                                         updateSVGColors,
                                         elems,
                                         setElems,
                                         selectedId,
                                         selectedItem,
                                         onElementsChange,
                                         selectedElement,
                                         mobileIconStyles,
                                         isMobileView,
                                         placementRef,
                                     }) => (
    <div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>

            {/* Render SVG Colors */}
            {selectedItem?.format === 'svg' &&
                <FillColorMagic
                    buildColorMap={buildColorMap}
                    updateSVGColors={updateSVGColors}
                    elems={elems}
                    onElementsChange={onElementsChange}
                    selectedElement={selectedElement}
                    selectedId={selectedId}
                    selectedItem={selectedItem}
                    isMobileView={isMobileView}
                />
            }

            {/* Render Shape Colors */}
            {selectedItem?.type !== 'image' && selectedItem?.type !== 'text' && typeof selectedItem?.svgFills !== 'object' &&
                <ShapeColorMagic
                    elems={elems}
                    onElementsChange={onElementsChange}
                    selectedElement={selectedElement}
                    selectedId={selectedId}
                    selectedItem={selectedItem}
                />
            }

            {/* Render Text Colors */}
            {selectedItem?.type === 'text' &&
                <FillColorMagic
                    elems={elems}
                    onElementsChange={onElementsChange}
                    selectedElement={selectedElement}
                    selectedId={selectedId}
                    selectedItem={selectedItem}
                />
            }

        </div>
    </div>
);


