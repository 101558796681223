// app/javascript/controllers/authentication_controller.js
// This controller handles the authentication process, including login, registration, and error handling.
import { Controller } from "@hotwired/stimulus"
import { handleLoginSuccess, updateUserMenu, dispatchAuthEvent } from '../services/auth'

export default class extends Controller {
  static targets = ["loginModal"]

  connect() {
    this.handleSignInSuccess = this.handleSignInSuccess.bind(this)
    this.handleRegistrationSuccess = this.handleRegistrationSuccess.bind(this)
    this.handleError = this.handleError.bind(this)

    $(document).on('ajax:success', '#new_user_sign_in', this.handleSignInSuccess)
    $(document).on('ajax:success', '#registration-form', this.handleRegistrationSuccess)
    $(document).on('ajax:error', '#registration-form', this.handleError)
  }

  disconnect() {
    $(document).off('ajax:success', '#new_user_sign_in')
    $(document).off('ajax:success', '#registration-form')
    $(document).off('ajax:error', '#registration-form')
  }

  handleSignInSuccess(event) {
    const [data] = event.detail
    const responseData = this.parseResponse(data)
    if (!responseData) return

    if (responseData.success) {
      this.handleSuccessfulAuth(responseData)
    } else {
      alert(responseData.errors)
    }
  }

  handleRegistrationSuccess(event) {
    const [data] = event.detail
    if (data.success) {
      this.handleSuccessfulAuth(data)
    } else {
      alert(data.errors.join("\n"))
    }
  }

  handleError(event) {
    const [xhr, status, error] = event.detail
    try {
      const responseData = JSON.parse(error.responseText)
      alert(responseData.errors?.[0] || "An error occurred. Please try again.")
    } catch (e) {
      console.error("Failed to parse error response:", e)
      alert("An error occurred. Please try again.")
    }
  }

  parseResponse(data) {
    if (typeof data === "string") {
      try {
        return JSON.parse(data)
      } catch (e) {
        console.error("Failed to parse JSON:", e)
        return null
      }
    }
    return data
  }

  handleSuccessfulAuth(data) {
    handleLoginSuccess(data)
    this.hideLoginModal()
    updateUserMenu()
    dispatchAuthEvent()
  }

  hideLoginModal() {
    setTimeout(() => {
      $(this.loginModalTarget).modal('hide')
    }, 100)
  }
}
