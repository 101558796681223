import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "button", "count"]

  updateCount() {
    const fileCount = this.inputTarget.files.length
    if (fileCount > 0) {
      this.countTarget.textContent = `Upload ${fileCount} Files`
      this.buttonTarget.classList.remove('btn-secondary')
      this.buttonTarget.classList.add('btn-success')
    } else {
      this.countTarget.textContent = 'Upload Files'
      this.buttonTarget.classList.remove('btn-success')
      this.buttonTarget.classList.add('btn-secondary')
    }
  }
}