import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static values = {
    autoHide: Boolean
  }

  connect() {
    this.modal = new Modal(this.element)
    
    // Show the modal
    requestAnimationFrame(() => {
      this.show()
      
      // Auto-hide if enabled
      if (this.autoHideValue) {
        setTimeout(() => {
          this.hide()
        }, 5000)
      }
    })
    
    // Listen for the modal shown event
    this.element.addEventListener('shown.bs.modal', () => {
      // Initialize the product-thumbnail controllers inside the modal
      this.initializeThumbnails()
    })
  }

  show() {
    this.modal.show()
  }

  hide() {
    this.modal.hide()
  }

  disconnect() {
    if (this.modal) {
      this.modal.dispose()
    }
  }

  initializeThumbnails() {
    // Find all elements with data-controller="product-thumbnail" inside the modal
    const thumbnails = this.element.querySelectorAll('[data-controller="product-thumbnail"]')
    
    thumbnails.forEach(thumbnailElement => {
      // Manually initialize the controller
      const controller = this.application.getControllerForElementAndIdentifier(thumbnailElement, 'product-thumbnail')
      if (controller) {
        controller.connect()
      }
    })
  }
}