import Konva from 'konva'
import { KonvaRenderer } from '../../../lib/asset-generator/src/shared/konva/renderer';

const interpolateColor = (startColor, endColor, progress) => {
    const start = {
        r: parseInt(startColor.slice(1,3), 16),
        g: parseInt(startColor.slice(3,5), 16),
        b: parseInt(startColor.slice(5,7), 16)
    };
    
    const end = {
        r: parseInt(endColor.slice(1,3), 16),
        g: parseInt(endColor.slice(3,5), 16),
        b: parseInt(endColor.slice(5,7), 16)
    };
    
    const r = Math.round(start.r + (end.r - start.r) * progress);
    const g = Math.round(start.g + (end.g - start.g) * progress);
    const b = Math.round(start.b + (end.b - start.b) * progress);
    
    return `#${r.toString(16).padStart(2,'0')}${g.toString(16).padStart(2,'0')}${b.toString(16).padStart(2,'0')}`;
};

export default class KonvaStageService {
  constructor(container, options = {}) {
    this.renderer = new KonvaRenderer(container, options);
    this.container = this._initializeContainer(container)
    this.width = this.container.offsetWidth
    this.height = options.height || this.width
    this.sizeReferenceSelector = options.sizeReferenceSelector
    this.stage = this._createStage()
    this.layer = this._createLayer()
    this.stage.add(this.layer)
    
    // Optional UI-specific properties
    this.currentPlacement = options.defaultPlacement || 'front'
    this.currentColor = null
    this.variants = options.variants || []
    this.templates = options.templates || []
    this.designData = options.designData || { state: [] }
    this.uiElements = {
      swatches: options.swatchesContainer || null,
      placements: options.placementsContainer || null
    }
    this.productId = options.productId
    this.mockupGallerySelector = options.mockupGallerySelector
  }

  // New static methods for different use cases
  static async createThumbnail(container, templateData, designData) {
    const service = new KonvaStageService(container, {
      width: 80,  // Default thumbnail size
      height: 80
    });
    
    // Use renderer to create thumbnail
    const stage = await service.renderer.renderDesign({
      elements: designData?.state || [],
      template: templateData,
      isPrintable: false,
      scale: service._calculateScale(templateData)
    });
    
    service.stage = stage;
    service.layer = stage.getLayers()[0];
    
    return service;
  }

  static async createProductGallery(container, templates, designData) {
    const service = new KonvaStageService(container, {
      templates,
      designData,
      height: container.offsetWidth * 2 // Match product gallery dimensions
    })
    await service.initializeGallery()
    return service
  }

  static async createProductPreview(container, options) {
    const enabledTemplates = options.templates.filter(t => t.enabled) // Filter enabled templates
    const service = new KonvaStageService(container, {
      templates: enabledTemplates,
      designData: options.designData,
      variants: options.variants,
      swatchesContainer: options.swatchesContainer,
      placementsContainer: options.placementsContainer,
      productId: options.productId,
      mockupGallerySelector: options.mockupGallerySelector
    })
    
    await service.initializePlacements(enabledTemplates, options.designData)
    return service
  }

  // New method for gallery initialization
  async initializeGallery() {
    if (!this.templates?.length) return false
    
    const currentTemplate = this.templates.find(t => t.placement === this.currentPlacement)
    if (!currentTemplate) return false

    // Get reference element dimensions
    const referenceElement = this.sizeReferenceSelector ? 
      document.querySelector(this.sizeReferenceSelector) : 
      this.container.parentElement
      
    const containerWidth = referenceElement ? referenceElement.clientWidth : this.width
    const containerHeight = referenceElement ? referenceElement.clientHeight : this.height

    const responsiveScale = Math.min(
      containerWidth / currentTemplate.template_width,
      containerHeight / currentTemplate.template_height
    )

    const scaledWidth = currentTemplate.template_width * responsiveScale
    const scaledHeight = currentTemplate.template_height * responsiveScale

    this.stage = new Konva.Stage({
      container: this.container,
      width: scaledWidth,
      height: scaledHeight,
      listening: false
    })
    
    this.layer = new Konva.Layer()
    this.layer.listening(false)
    this.stage.add(this.layer)
    
    // Center stage in container
    const xOffset = (containerWidth - scaledWidth) / 2
    this.stage.absolutePosition({
      x: xOffset,
      y: 0
    })
    
    // Add window resize handler
    /*
    window.addEventListener('resize', () => {
      const newContainerWidth = this.container.parentElement.clientWidth
      const newMaxWidth = newContainerWidth * 0.8
      const newScale = Math.min(
        newMaxWidth / currentTemplate.template_width,
        maxHeight / currentTemplate.template_height
      )
      
      this.stage.width(currentTemplate.template_width * newScale)
      this.stage.x((newContainerWidth - this.stage.width()) / 2)
      this.stage.batchDraw()
    })*/

    return this.initializePreview(currentTemplate, this.designData)
  }

  async initializePreview(templateData, designData) {
    try {
        const scale = this._calculateScale(templateData)
        
        // First add the template/product image
        await this._addTemplateImage(templateData, scale)
        
        // Create and add the composite design group
        if (designData?.state) {
            const compositeGroup = await this._createCompositeDesign(templateData, designData, scale)
            if (compositeGroup) {
                this.layer.add(compositeGroup)
            }
        }
        
        this.stage.draw()
        return true
    } catch (error) {
        console.error('Error initializing preview:', error)
        return false
    }
  }

  async _createCompositeDesign(template, designData, scale) {
    // Create an offscreen canvas for compositing
    const offscreenStage = new Konva.Stage({
      container: document.createElement('div'),
      width: template.print_area_width,
      height: template.print_area_height
    });
    
    const offscreenLayer = new Konva.Layer();
    offscreenStage.add(offscreenLayer);
    
    // Filter and load all designs for current placement
    const placementDesigns = designData.state.filter(
      design => design.placement === this.currentPlacement
    );
    
    // Create a group for clipping
    const group = new Konva.Group({
      width: template.print_area_width,
      height: template.print_area_height,
      clipFunc: (context) => {
        context.rect(
          0,
          0,
          template.print_area_width,
          template.print_area_height
        );
      },
    });
    offscreenLayer.add(group);
    
    // Load all elements using renderer
    const elements = await Promise.all(
      placementDesigns.map(async (design) => {
        const adjustedDesign = {
          ...design,
          x: design.x - template.print_area_left,
          y: design.y - template.print_area_top
        };
        return this.renderer.createElementNode(adjustedDesign, {
          scale: 1,
          template,
          isPrintable: false
        });
      })
    );
    
    // Add all successfully loaded elements to group
    elements.filter(Boolean).forEach(element => {
      group.add(element);
    });
    
    offscreenLayer.draw();
    
    // Create composite image
    const compositeDataUrl = offscreenStage.toDataURL({ pixelRatio: 1 });
    const compositeImage = await this.loadImage(compositeDataUrl);
    
    if (!compositeImage) {
      console.error('Failed to create composite image');
      return null;
    }
    
    // Create final group with proper positioning and compositing
    const finalGroup = new Konva.Group({
      x: template.print_area_left * scale,
      y: template.print_area_top * scale,
      opacity: 0.7,
      clipFunc: (context) => {
        context.rect(
          0,
          0,
          template.print_area_width * scale,
          template.print_area_height * scale
        );
      }
    });
    
    const compositeNode = new Konva.Image({
      image: compositeImage,
      width: template.print_area_width * scale,
      height: template.print_area_height * scale,
      globalCompositeOperation: 'multiply'
    });
    
    finalGroup.add(compositeNode);
    offscreenStage.destroy();
    
    return finalGroup;
  }

  async changePlacement(placementType) {
    const template = this.templates.find(t => t.placement === placementType)
    
    if (template) {
      this.currentPlacement = placementType
      
      // Clear existing stage content
      this.layer.destroyChildren()
      
      // Get current design state, filtered for this placement
      let currentDesignData = {
        ...this.designData,
        state: this.designData.state.filter(d => d.placement === placementType)
      }
      
      // Clear and reinitialize the preview with new placement
      await this.initializePreview(template, currentDesignData)
      
      // Update thumbnails to reflect new active state
      const thumbnails = document.querySelectorAll('.product-gallery-thumblist-item')
      thumbnails.forEach(thumb => {
        thumb.classList.toggle('active', thumb.dataset.placement === placementType)
      })
      
      // Update placement UI if needed
      this.updateActivePlacement(placementType)
      
      // Ensure stage is redrawn
      if (this.stage) {
        this.stage.draw()
      }
    }
  }

  getAvailablePlacements(templateData) {
    // Extract unique placements from template maps
    return [...new Set(templateData.template_maps.map(map => map.placement))]
  }

  getCurrentPlacement() {
    return this.currentPlacement
  }

  async initializeThumbnail(templateData, designData) {
    try {
      const scale = this._calculateScale(templateData);
      
      // Use renderer to create complete thumbnail
      const stage = await this.renderer.renderDesign({
        elements: [
          // Add template image as first element
          {
            type: 'image',
            id: 'template-image',
            image_url: templateData.image_url || templateData.background_url,
            x: 0,
            y: 0,
            width: templateData.template_width,
            height: templateData.template_height
          },
          // Add all design elements
          ...(designData?.state || [])
        ],
        template: templateData,
        isPrintable: false,
        scale
      });
      
      // Replace existing stage and layer
      if (this.stage) this.stage.destroy();
      this.stage = stage;
      this.layer = stage.getLayers()[0];
      
      return true;
    } catch (error) {
      console.error('Error initializing thumbnail:', error);
      return false;
    }
  }

  // Public methods
  getStage() {
    return this.stage
  }

  destroy() {
    this.stage.destroy()
  }

  async loadImage(url) {
    return new Promise((resolve) => {
        if (!url) {
            console.warn('No URL provided for image loading')
            return resolve(null)
        }

        const image = new Image()
        image.crossOrigin = 'anonymous'
        
        image.onload = () => {
            resolve(image)
        }

        image.onerror = (error) => {
            console.error(`Failed to load image: ${url}`, error)
            const proxyUrl = `/api/v1/proxy_image?url=${encodeURIComponent(url)}`
            const proxyImage = new Image()
            proxyImage.crossOrigin = 'anonymous'
            
            proxyImage.onload = () => {
                resolve(proxyImage)
            }
            
            proxyImage.onerror = () => {
                console.error(`Failed to load image through proxy: ${url}`)
                resolve(null)
            }
            
            proxyImage.src = proxyUrl
        }

        image.src = url
    })
  }

  async addDesignElement(design, scale = 1) {
    let element = null;
    
    // Calculate padded dimensions for gradient (matching element-path.jsx)
    const PADDING_FACTOR = 2.5;
    const box = {
      width: Math.max(design.width || 1, 1),
      height: Math.max(design.height || 1, 1)
    };
    const paddedWidth = box.width * PADDING_FACTOR;
    const paddedHeight = box.height * PADDING_FACTOR;
    const offsetX = (paddedWidth - box.width) / 2;
    const offsetY = (paddedHeight - box.height) / 2;

    switch (design.type) {
      case 'freehand':
        element = new Konva.Line({
          id: design.id,
          points: design.points.map(p => p * scale),
          stroke: design.stroke || '#000000',
          strokeWidth: (design.strokeWidth || 1) * scale,
          lineCap: 'round',
          lineJoin: 'round',
          tension: 0.5,
          scaleX: (design.scaleX || 1),
          scaleY: (design.scaleY || 1),
          dash: design.dash || [],
          x: design.x * scale || 0,
          y: design.y * scale || 0
        })
        break

      case 'path':
        return this.renderer.createElementNode(design, {
          scale,
          template: {
            print_area_left: 0,  // We're already adjusting for print area in _createCompositeDesign
            print_area_top: 0,   // lines 183-187
            width: design.width,
            height: design.height
          },
          isPrintable: false
        });
        break

      case 'text':
        const textScale = scale * (design.scaleX || 1)
        element = new Konva.Text({
          id: design.id,
          x: design.x,
          y: design.y,
          text: design.text,
          fontSize: design.fontSize * textScale,
          fontFamily: design.fontFamily,
          fill: design.fill,
          width: design.width,
          height: design.height,
          scaleX: design.scaleX || 1,
          scaleY: design.scaleY || 1,
          rotation: design.rotation || 0
        })
        break

      case 'rect':
        element = new Konva.Rect({
          id: design.id,
          x: design.x * scale,
          y: design.y * scale,
          width: design.width * scale,
          height: design.height * scale,
          fill: design.fill || 'transparent',
          stroke: design.stroke || 'black',
          strokeWidth: (design.strokeWidth || 1) * scale
        })
        break

      case 'pattern':
        element = new Konva.Group({
          id: design.id,
          x: design.x * scale,
          y: design.y * scale,
          width: design.width * scale,
          height: design.height * scale,
          scaleX: (design.scaleX || 1),
          scaleY: (design.scaleY || 1),
          rotation: design.rotation || 0
        })

        // Add each image element in the pattern
        if (design.elems && Array.isArray(design.elems)) {
          for (const elem of design.elems) {
            const image = await this.loadImage(elem.image)
            const shapeElement = new Konva.Image({
              id: elem.id,
              image: image,
              x: elem.x * scale,
              y: elem.y * scale,
              width: elem.width * scale,
              height: elem.height * scale
            })
            element.add(shapeElement)
          }
        }
        break

      default:
        try {
          let src = null;
          
          if (design.data?.startsWith('data:image/svg+xml;base64')) {
            src = design.data;
          } 
          else if (design.s3Url) {
            src = design.s3Url;
          }

          if (src) {
            const image = await this.loadImage(src);
            
            if (!image) {
                console.warn(`Failed to load image for element ${design.id}`);
                return null;
            }

            // Calculate dimensions while preserving aspect ratio
            const originalWidth = design.originalWidth || design.width || image.naturalWidth;
            const originalHeight = design.originalHeight || design.height || image.naturalHeight;
            
            // Apply scaling while maintaining aspect ratio
            const aspectRatio = originalWidth / originalHeight;
            let finalWidth = originalWidth * scale;
            let finalHeight = originalHeight * scale;

            if (design.width) {
                finalWidth = design.width * scale;
                finalHeight = finalWidth / aspectRatio;
            } else if (design.height) {
                finalHeight = design.height * scale;
                finalWidth = finalHeight * aspectRatio;
            }

            element = new Konva.Image({
                image: image,
                x: design.x * scale,
                y: design.y * scale,
                id: design.id,
                width: finalWidth,
                height: finalHeight,
                rotation: design.rotation || 0,
                scaleX: design.scaleX || 1,
                scaleY: design.scaleY || 1,
                opacity: design.opacity || 1,
                draggable: false,
                listening: false
            });
            
            return element;
          }
          return null;
        } catch (err) {
          console.error(`Error creating image element ${design.id}:`, err);
          return null;
        }
    }

    return element
  }

  // Internal methods (prefixed with underscore)
  _initializeContainer(container) {
    if (container.tagName.toLowerCase() === 'canvas') {
      const div = document.createElement('div')
      div.style.width = '100%'
      div.style.height = '100%'
      container.parentNode.insertBefore(div, container)
      container.remove()
      return div
    }
    return container
  }

  _createStage() {
    return new Konva.Stage({
      container: this.container,
      width: this.width,
      height: this.height,
      background: '#f8f9fa',
      listening: false
    })
  }

  _createLayer() {
    return new Konva.Layer({
      listening: false
    })
  }

  _calculateScale(templateData) {
    const stageWidth = this.stage.width()
    const stageHeight = this.stage.height()
    
    // Calculate scale while maintaining aspect ratio
    return Math.min(
      stageWidth / templateData.template_width,
      stageHeight / templateData.template_height
    ) 
  }

  async _addTemplateImage(templateData, scale) {
    try {
      const image = await new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => resolve(img);
        img.onerror = (error) => {
          console.warn('Failed to load template image:', error);
          resolve(null); // Resolve with null instead of rejecting
        };
        img.src = templateData.image_url || templateData.background_url;
      });

      if (!image) return null;

      const imageNode = new Konva.Image({
        x: 0,
        y: 0,
        image: image,
        width: templateData.template_width * scale,
        height: templateData.template_height * scale
      });

      this.layer.add(imageNode);
      return imageNode;
    } catch (error) {
      console.error('Error adding template image:', error);
      return null;
    }
  }

  _createDesignGroup(template, scale) {
    return new Konva.Group({
      x: 0,
      y: 0,
      opacity: 0.8,
      clipX: template.print_area_left * scale,
      clipY: template.print_area_top * scale,
      clipWidth: template.print_area_width * scale,
      clipHeight: template.print_area_height * scale
    })
  }

  async _addDesignElements(designs, group, scale) {
    // Only add elements that match the current placement
    const placementDesigns = designs.filter(design => design.placement === this.currentPlacement);
    
    for (const design of placementDesigns) {
      const element = await this.renderer.createElementNode(design, {
        scale,
        template: {
          print_area_left: 0,
          print_area_top: 0,
          width: design.width,
          height: design.height
        },
        isPrintable: false
      });
      
      if (element) {
        group.add(element);
      }
    }
  }

  setBackgroundColor(color) {
    if (this.stage) {
      this.stage.container().style.backgroundColor = color
      this.stage.draw()
    }
  }

  clear() {
    if (this.layer) {
      this.layer.destroyChildren()
      this.layer.draw()
    }
  }

  setVariants(variants) {
    this.variants = variants
  }

  getColorCode(colorName) {
    const variant = this.variants.find(v => v.color === colorName)
    return variant?.color_code || '#FFFFFF'
  }

  changeColor(color) {
    this.currentColor = color
    this.setBackgroundColor(this.getColorCode(color))
    this.updateActiveColorSwatch(color)
    this.updateMockups(color)
  }

  async initializePlacements(templates, designData) {
    this.templates = templates
    this.designData = designData
    
    // Initialize UI components
    this.initializeColorSwatches()
    this.initializePlacementSelector()
    
    // Then initialize preview with first template
    return this.initializePreview(templates[0], designData)
  }

  getCurrentTemplate() {
    return this.templates.find(t => t.placement === this.currentPlacement)
  }

  // Move UI initialization methods
  initializeColorSwatches() {
    if (this.uiElements.swatches && this.variants.length) {
      const uniqueColors = [...new Set(this.variants.map(variant => variant.color))]
      const defaultColor = uniqueColors[0]
      
      const swatchesHtml = uniqueColors.map(color => `
        <button type="button"
                class="btn btn-outline-secondary p-2 ${color === defaultColor ? 'active' : ''}"
                style="width: 40px; height: 40px; background-color: ${this.getColorCode(color)};"
                data-action="click->product-preview#changeColor"
                data-color="${color}">
        </button>
      `).join('')
      
      this.uiElements.swatches.innerHTML = swatchesHtml
      this.changeColor(defaultColor)
    }
  }

  initializePlacementSelector() {
    if (this.uiElements.placements && this.templates?.length) {
      // Get unique placements from enabled templates only
      const uniquePlacements = [...new Set(
        this.templates
          .filter(t => t.enabled)
          .map(t => t.placement)
      )]
      
      if (uniquePlacements.length === 0) return
      
      const defaultPlacement = uniquePlacements[0]
      
      const placementsHtml = uniquePlacements.map(placement => `
        <button type="button"
                class="btn btn-outline-secondary flex-grow-1 ${placement === defaultPlacement ? 'active' : ''}"
                style="min-width: 80px; max-width: 120px;"
                data-action="click->product-preview#changePlacement"
                data-placement-type="${placement}">
          ${placement.charAt(0).toUpperCase() + placement.slice(1)}
        </button>
      `).join('')
      
      this.uiElements.placements.innerHTML = placementsHtml
      
      // Set initial placement
      this.currentPlacement = defaultPlacement
    }
  }

  updateActiveColorSwatch(color) {
    if (this.uiElements.swatches) {
      this.uiElements.swatches.querySelectorAll('button').forEach(btn => {
        btn.classList.toggle('active', btn.dataset.color === color)
      })
    }
  }

  updateActivePlacement(placementType) {
    if (this.uiElements.placements) {
      this.uiElements.placements.querySelectorAll('button').forEach(btn => {
        btn.classList.toggle('active', btn.dataset.placementType === placementType)
      })
    }
  }

  async updateMockups(color) {
    if (!this.productId) return

    try {
      const response = await fetch(`/api/v1/products/${this.productId}/mockups?color=${color}`)
      const data = await response.json()
      
      if (data.mockups) {
        const gallery = document.querySelector(this.mockupGallerySelector)
        if (gallery) {
          // Update mockup gallery implementation
          // This keeps all product preview related logic in one place
          gallery.dispatchEvent(new CustomEvent('mockups:update', { 
            detail: { mockups: data.mockups } 
          }))
        }
      }
    } catch (error) {
      console.error('Error fetching mockups:', error)
    }
  }

  // Add new static factory method for product gallery
  static async createProductGalleryWithPlacements(container, options) {
    const service = new KonvaStageService(container, {
      templates: options.templates,
      designData: options.designData,
      defaultPlacement: options.defaultPlacement || 'front',
      sizeReferenceSelector: options.sizeReferenceSelector
    })
    
    await service.initializeGallery()
    service._createPlacementThumbnails(options.templates, options.designData)
    
    return service
  }

  // Add new method for product gallery initialization
  async initializeProductGallery(templates, designData) {
    if (!templates?.length) return false
    
    // Create thumbnail list
    this._createPlacementThumbnails(templates, designData)
    
    // Initialize first template
    return this.initializePreview(templates[0], designData)
  }

  // Add new private method for thumbnail creation
  async _createPlacementThumbnails(templates, designData) {
    const thumbnailContainer = document.getElementById('product-gallery-thumbnail-list')
    if (!thumbnailContainer) return
    
    thumbnailContainer.innerHTML = ''
    
    const enabledTemplates = templates.filter(t => t.enabled)
    const thumbnailSize = window.innerWidth <= 768 ? 120 : 80
    
    for (const template of enabledTemplates) {
      const thumbnail = document.createElement('div')
      thumbnail.className = 'product-gallery-thumblist-item'
      thumbnail.dataset.placement = template.placement
      if (template.placement === this.currentPlacement) {
        thumbnail.classList.add('active')
      }
      
      const thumbStage = new Konva.Stage({
        container: thumbnail,
        width: thumbnailSize,
        height: thumbnailSize,
        listening: true
      })
      
      const thumbLayer = new Konva.Layer()
      thumbStage.add(thumbLayer)
      
      thumbnail.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.changePlacement(template.placement)
      })
      
      // Calculate scale and offsets
      const scale = Math.min(
        thumbnailSize / template.template_width,
        thumbnailSize / template.template_height
      )
      
      const centerX = (thumbnailSize - template.template_width * scale) / 2
      const centerY = (thumbnailSize - template.template_height * scale) / 2
      
      // Add template image
      const templateImage = await this._addTemplateImage(template, scale)
      if (templateImage) {
        templateImage.position({
          x: centerX,
          y: centerY
        })
        thumbLayer.add(templateImage)
      }
      
      // Create composite design with proper print area positioning
      const compositeGroup = await this._createCompositeDesign(template, {
        state: designData?.state?.filter(d => d.placement === template.placement) || []
      }, scale)
      
      if (compositeGroup) {
        // Position composite group relative to print area
        compositeGroup.position({
          x: centerX + (template.print_area_left * scale),
          y: centerY + (template.print_area_top * scale)
        })
        thumbLayer.add(compositeGroup)
      }
      
      thumbLayer.draw()
      thumbnailContainer.appendChild(thumbnail)
    }
  }

  _calculateThumbnailScale(template) {
    return Math.min(
      80 / template.template_width,
      80 / template.template_height
    )
  }

  // Add new method for photo gallery initialization
  async loadProductPhotos(photos) {
    if (!photos?.length) return false
    
    const galleryContainer = document.getElementById('product-gallery-large-photo')
    if (!galleryContainer) return false
    
    // Clear any existing content
    galleryContainer.innerHTML = ''
    
    // Create main photo display
    const mainPhoto = document.createElement('img')
    mainPhoto.src = photos[0].url
    mainPhoto.className = 'img-fluid'
    galleryContainer.appendChild(mainPhoto)
    
    // Create thumbnails
    const thumbnailContainer = document.getElementById('product-gallery-thumbnail-list')
    if (thumbnailContainer) {
      thumbnailContainer.innerHTML = photos.map((photo, index) => `
        <div class="product-gallery-thumblist-item">
          <img src="${photo.url}" 
               class="img-fluid" 
               alt="Product photo ${index + 1}"
               data-action="click->product#changePhoto"
               data-photo-url="${photo.url}">
        </div>
      `).join('')
    }
    
    return true
  }

  // Add method to change displayed photo
  changePhoto(url) {
    const mainPhoto = document.querySelector('#product-gallery-large-photo img')
    if (mainPhoto) {
      mainPhoto.src = url
    }
  }

  async updateDesign(designData) {
    this.designData = designData
    
    // Get current template and placement
    const template = this.getCurrentTemplate()
    if (!template) {
      console.error('No template found for current placement:', this.currentPlacement)
      return false
    }

    const scale = this._calculateScale(template)

    // Clear existing design elements
    this.clear()

    // Re-add template image first
    await this._addTemplateImage(template, scale)

    // Create and add new composite design
    const compositeGroup = await this._createCompositeDesign(template, designData, scale)
    if (compositeGroup) {
      this.layer.add(compositeGroup)
    }
        
    // Redraw the stage
    this.stage.draw()
    return true
  }

  // Add new method to find text customization controller
  findTextCustomizationController() {
    const element = document.querySelector('[data-controller="text-customization"]')
    if (!element) return null
    
    // Get the Stimulus application instance from the window
    const application = window.Stimulus
    if (!application) return null
    
    return application.getControllerForElementAndIdentifier(
      element,
      'text-customization'
    )
  }
}
