// app/javascript/controllers/quantity_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["value"]

    connect() {
        this.valueTarget.value = this.valueTarget.value || 1
    }

    increase() {
        const currentValue = parseInt(this.valueTarget.value)
        if (currentValue < parseInt(this.valueTarget.max)) {
            this.valueTarget.value = currentValue + 1
            this.submitForm()
        }
    }

    decrease() {
        const currentValue = parseInt(this.valueTarget.value)
        if (currentValue > parseInt(this.valueTarget.min)) {
            this.valueTarget.value = currentValue - 1
            this.submitForm()
        }
    }

    updateQuantity() {
        this.submitForm()
    }

    submitForm() {
        const form = this.element.querySelector('form')
        if (form) {
            // Submit form using Turbo
            form.requestSubmit()
        }
    }
}
