import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '../../IconButton/IconButton.jsx'

const ArrangeTools = ({ canvas }) => {
    const handleMove = (direction) => {
        switch (direction) {
            case 'up':
                canvas.moveUpDownSelected('Up');
                break;
            case 'down':
                canvas.moveUpDownSelected('Down');
                break;
            case 'top':
                canvas.moveToTopSelectedElement();
                break;
            case 'bottom':
                canvas.moveToBottomSelectedElement();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <IconButton icon="MoveUp" onClick={() => handleMove('up')} />
            <IconButton icon="MoveDown" onClick={() => handleMove('down')} />
            <IconButton icon="MoveTop" onClick={() => handleMove('top')} />
            <IconButton icon="MoveBottom" onClick={() => handleMove('bottom')} />
        </>
    );
};

ArrangeTools.propTypes = { canvas: PropTypes.object };
ArrangeTools.defaultProps = { canvas: null };

export default ArrangeTools;
