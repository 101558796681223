import React, {useLayoutEffect, useState} from "react";

export const SimplePlacementPicker = (
    {
        template,
        templates,
        setTemplate,
        setTemplates,
        placementRef,
        fitToScreen
    }) => {

    if (!Array.isArray(templates)) {
        console.error("templates is not an array:", templates);
        return null; // or some default JSX
    }

    return (
        <>
            {templates.map((t, index) =>
                (<a
                    key={`slide_${index}`}
                    data-placement={t.placement}
                    className={`side sides__item ${index === 0 ? 'side_active' : ''}`}
                    onClick={e => {
                        let result = templates.find(t => t.placement === e.target.dataset.placement);
                        placementRef.current = result.placement;
                        // change side_active class to clicked element

                        let sides = document.querySelectorAll('.side');
                        sides.forEach(s => s.classList.remove('side_active'));
                        //find the parent of the clicked element with class sides__item and add side_active class
                        e.target.closest('.sides__item').classList.add('side_active');

                        setTemplate(result['template_data']);
                        setTimeout(() => {
                            fitToScreen();
                        }, 250);
                        setTimeout(() => {
                            fitToScreen();
                        }, 500);
                    }}
                >
                    <div>
                        <img
                            data-placement={t.placement}
                            loading="lazy"
                            src={t.template_data['image_url']}
                            alt={t.placement}
                            className="side__img"
                            //value={t.placement}
                        />
                        { t.additional_price !== "$0.00" && <span
                            className="side__price-label"
                            data-placement={t.placement}
                        >
                            <font
                                data-placement={t.placement}
                                style={{ verticalAlign: "inherit" }}
                            >
                                <font
                                    data-placement={t.placement}
                                    style={{ verticalAlign: "inherit" }}>+ ${t.additional_price/100}
                                </font>
                            </font>
                        </span>}
                    </div>
                </a>
            ))}
        </>
    )
}
