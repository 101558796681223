import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "button"]

  togglePlacement(event) {
    const button = event.currentTarget
    const placement = button.dataset.placement
    const checkboxes = this.checkboxTargets.filter(checkbox => 
      checkbox.dataset.placement === placement
    )
    
    // Toggle all checkboxes for this placement
    const newState = !checkboxes[0]?.checked
    checkboxes.forEach(checkbox => checkbox.checked = newState)
    
    // Toggle button active state
    button.classList.toggle('active', newState)
  }

  connect() {
    // Set initial button states
    this.buttonTargets.forEach(button => {
      const placement = button.dataset.placement
      const checkboxes = this.checkboxTargets.filter(checkbox => 
        checkbox.dataset.placement === placement
      )
      const isActive = checkboxes.some(checkbox => checkbox.checked)
      button.classList.toggle('active', isActive)
    })
  }
}