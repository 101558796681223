import React from 'react';

import { ArrangeControlsContent, ArrangeControls, handleMoveToBottom, handleMoveToTop, handleMoveDown, handleMoveUp} from "./ArrangeControls";
import { CloneControls } from "./CloneControls";
import { ColorControls, ColorControlsContent } from "./ColorControls";
import { FontControls, FontControlsContent } from "./FontControls";
import { FontFormattingControls, FontFormattingControlsContent } from "./FontFormattingControls";
import { TextTransformsControlsContent } from "./TextTransformsControls";

import {buildColorMap, updateSVGColors} from "./propertiesUtils";
import { EffectControls, EffectsControlsContent } from "./EffectControls";
import { FlipControls, FlipControlsContent, handleVerticalFlip, handleHorizontalFlip } from "./FlipControls";
import { LockControls } from "./LockControls";
import { RemoveControls } from "./RemoveControls";
import { RotateControls, RotateControlsContent, handleRotateRight, handleRotateLeft } from "./RotateControls";
import { ScaleControls, ScaleControlsContent, handleScaleUp, handleScaleDown } from "./ScaleControls";
import { Modal, Button } from 'react-bootstrap';
import SVGEditorWrapper from "../SVGEdit/SVGEditorWrapper";
import { OptionSlider } from "../mobile/OptionSlider";
import svgEditorIcon from '../../../../assets/images/icon/photo-editing.svg';
import arrangeIcon from "../../../../assets/images/icon/arrange.svg";
import {useSelectionContext} from "../../contexts/SelectionContext";
import fontIcon from '../../../../assets/images/icon/font.svg';
import formatIcon from '../../../../assets/images/icon/format.svg';
import scaleIcon from '../../../../assets/images/icon/scale.svg';
import flipIcon from '../../../../assets/images/icon/flip.svg';
import rotateIcon from '../../../../assets/images/icon/rotate.svg';
import cloneIcon from '../../../../assets/images/icon/clone.svg';
import removeIcon from '../../../../assets/images/icon/remove.svg';
import rotateLeftIcon from "../../../../assets/images/icon/rotate-left.svg";
import rotateRightIcon from "../../../../assets/images/icon/rotate-right.svg";


export const PropertiesPanel = (props) =>
{
    const {
        selectedElement,
        selectedId,
        selectedItem,
        setSelectedElement,
        setSelectedId
    } = useSelectionContext();

    const [isModalOpen, setModalOpen] = React.useState(false);

    function removeForeignObjectAndSwitch(svgString) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(svgString, "text/xml");

        // Remove the foreignObject element
        const foreignObject = xmlDoc.querySelector('foreignObject');
        if (foreignObject) {
            foreignObject.parentNode.removeChild(foreignObject);
        }

        // Remove the switch elements
        const switchElements = xmlDoc.querySelectorAll('switch');
        switchElements.forEach(switchElement => {
            // Move all children of the switch element up one level in the DOM tree
            while (switchElement.firstChild) {
                switchElement.parentNode.insertBefore(switchElement.firstChild, switchElement);
            }
            // Remove the now empty switch element
            switchElement.parentNode.removeChild(switchElement);
        });

        // Serialize back to string
        const serializer = new XMLSerializer();
        const updatedSvgString = serializer.serializeToString(xmlDoc);

        return updatedSvgString;
    }


    // Retrieve SVG content from the selected SVG element
    const getSelectedSVGContent = () => {
        //console.log("Selected Element Ref:", props.selectedElement);
        //console.log("Selected Item:", props.selectedItem);

        if (selectedElement) {
            return selectedItem;
        }
        return '';
    };


    // SVG content to be edited
    const [svgToEdit, setSvgToEdit] = React.useState('');
    const [elemToEdit, setElemToEdit] = React.useState('');

    const handleOpenEditor = () => {
        let elem = getSelectedSVGContent();

        // Log the received element and its SVG content
        //console.log("Element to Edit:", elem);
        //console.log("SVG Content:", elem.svgXML);

        // Remove foreignObject from the SVG content
        const cleanedSvgContent = removeForeignObjectAndSwitch(elem.svgXML);

        //console.log("Cleaned SVG Content:", cleanedSvgContent);

        // Set states for modal and editor content with the cleaned SVG
        setElemToEdit(elem);
        setSvgToEdit(cleanedSvgContent);

        setModalOpen(true);
    };

    const [isOptionsSliderOpen, setIsOptionsSliderOpen] = React.useState(false);
    const [activeControl, setActiveControl] = React.useState(null);


    const handleButtonClick = (controlType) => {
        setIsOptionsSliderOpen(true);
        setActiveControl(controlType);
    }


    const handleOptionsSliderClose = () => {
        setIsOptionsSliderOpen(false);
        setActiveControl(null);
    }

    const mobileIconStyles = {
        mobile: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80px',
            backgroundColor: 'none',
            color: '#000',
            fontSize: '14px',
            cursor: 'pointer',
        },
        desktop: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            height: '50px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            fontSize: '15px',
            cursor: 'pointer',
            padding: '5px'
        },
        icon: {
            width: '40px',
            height: '40px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        },
        desktopIcon: {
            width: '15px',
            height: '15px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            marginBottom: '5px'  // Adds a little space between the icon and text
        }
    };

    const renderControlButton = (icon, label, onClick, isVisible = true) => {
        if (!isVisible) return null;
        
        if (props.isMobileView) {
            return (
                <button
                    className="slide-panel__button"
                    onClick={onClick}
                >
                    <div 
                        className="slide-panel__button-icon"
                        style={{ 
                            backgroundImage: `url(${icon})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    />
                    <span className="slide-panel__button-label">{label}</span>
                </button>
            );
        } else {
            return (
                <button
                    className="properties-panel__button desktop"
                    onClick={onClick}
                >
                    <div 
                        className="properties-panel__button-icon"
                        style={{ 
                            width: '20px',
                            height: '20px',
                            backgroundImage: `url(${icon})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            marginBottom: '5px'
                        }}
                    />
                    <span className="properties-panel__button-label">{label}</span>
                </button>
            );
        }
    };

    const renderBackButton = () => {
        if (!props.isMobileView) {
            return (
                <button 
                    className="properties-panel__back-button"
                    onClick={handleOptionsSliderClose}
                >
                    ← Back
                </button>
            );
        }
        return null;
    };

    const handleCloneElement = () => {
        if (!selectedId || !selectedItem) return;

        // Create a copy of the selected element with a new ID
        const clonedElement = {
            ...selectedItem,
            id: `${selectedItem.type}_${Date.now()}`, // Generate a unique ID
            x: selectedItem.x + 20, // Offset the clone slightly
            y: selectedItem.y + 20
        };

        // Add the cloned element to the elements array
        const newElems = [...props.elems, clonedElement];
        props.onElementsChange(newElems);

        // Select the newly created clone
        setSelectedId(clonedElement.id);
    };

    const handleRemoveElement = () => {
        if (!selectedId) return;

        // Filter out the selected element
        const newElems = props.elems.filter(elem => elem.id !== selectedId);
        props.onElementsChange(newElems);

        // Clear the selection
        setSelectedId(null);
    };

    const renderControlContent = (controlType) => {
        const commonProps = {
            elems: props.elems,
            setElems: props.setElems,
            selectedId: selectedId,
            selectedItem: selectedItem,
            onElementsChange: props.onElementsChange,
            selectedElement: selectedElement,
            mobileIconStyles: mobileIconStyles,
            isMobileView: props.isMobileView,
            placementRef: props.placementRef
        };

        switch (controlType) {
            case 'rotate':
                return <RotateControlsContent {...commonProps} />;
            case 'scale':
                return <ScaleControlsContent {...commonProps} />;
            case 'flip':
                return (
                    <FlipControlsContent 
                        {...commonProps}
                        onFlipVertical={handleVerticalFlip}
                        onFlipHorizontal={handleHorizontalFlip}
                    />
                );
            case 'arrange':
                return (
                    <ArrangeControlsContent 
                        {...commonProps}
                        onMoveUp={handleMoveUp}
                        onMoveDown={handleMoveDown}
                        onMoveToTop={handleMoveToTop}
                        onMoveToBottom={handleMoveToBottom}
                    />
                );
            case 'svgColors':
                return (
                    <ColorControlsContent 
                        {...commonProps}
                        buildColorMap={buildColorMap}
                        updateSVGColors={updateSVGColors}
                    />
                );
            case 'fonts':
                return <FontControlsContent {...commonProps} />;
            case 'fontFormatting':
                return <FontFormattingControlsContent {...commonProps} />;
            default:
                return null;
        }
    };

    if (!selectedId){
        if (props.isMobileView === true) {
            return null;
        } else {
            return (
                <div className={'row'}>
                    <p>Select an element in the design to change its properties</p>
                </div>
            )
        }
    } else {
        return(
            <>
                { isOptionsSliderOpen ? (
                    <div className={props.isMobileView ? "option-slider" : "properties-panel desktop"}>
                        {props.isMobileView ? (
                            <OptionSlider isVisible={isOptionsSliderOpen} onClose={handleOptionsSliderClose}>
                                {activeControl === 'fonts' && (
                                    <FontControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        onElementsChange={props.onElementsChange}
                                        selectedElement={selectedElement}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />
                                )}
                                {activeControl === 'fontFormatting' && (
                                    <FontFormattingControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        onElementsChange={props.onElementsChange}
                                        selectedElement={selectedElement}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />
                                )}
                                {activeControl === 'scale' && (
                                    <ScaleControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        onElementsChange={props.onElementsChange}
                                        selectedElement={selectedElement}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />
                                )}
                                {activeControl === 'flip' && (
                                    <FlipControlsContent
                                        elems={props.elems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        selectedElement={selectedElement}
                                        onFlipVertical={handleVerticalFlip}
                                        onFlipHorizontal={handleHorizontalFlip}
                                        onElementsChange={props.onElementsChange}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />)}
                                {activeControl === 'arrange' && (
                                    <ArrangeControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        selectedElement={selectedElement}
                                        onMoveUp={handleMoveUp}
                                        onMoveDown={handleMoveDown}
                                        onMoveToTop={handleMoveToTop}
                                        onMoveToBottom={handleMoveToBottom}
                                        onElementsChange={props.onElementsChange}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                        placementRef={props.placementRef}
                                        //trRef={props.trRef}
                                    />)}
                                {activeControl === 'svgColors' && (
                                    <ColorControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        selectedElement={selectedElement}
                                        onElementsChange={props.onElementsChange}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                        placementRef={props.placementRef}
                                        buildColorMap={buildColorMap}
                                        updateSVGColors={updateSVGColors}
                                    />)}
                                {activeControl === 'rotate' && (
                                    <RotateControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        onElementsChange={props.onElementsChange}
                                        selectedElement={selectedElement}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />
                                )}
                            </OptionSlider>
                        ) : (
                            <div className="properties-panel__controls">
                                {renderBackButton()}
                                {activeControl === 'fonts' && (
                                    <FontControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        onElementsChange={props.onElementsChange}
                                        selectedElement={selectedElement}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />
                                )}
                                {activeControl === 'fontFormatting' && (
                                    <FontFormattingControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        onElementsChange={props.onElementsChange}
                                        selectedElement={selectedElement}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />
                                )}
                                {activeControl === 'scale' && (
                                    <ScaleControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        onElementsChange={props.onElementsChange}
                                        selectedElement={selectedElement}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />
                                )}
                                {activeControl === 'flip' && (
                                    <FlipControlsContent
                                        elems={props.elems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        selectedElement={selectedElement}
                                        onFlipVertical={handleVerticalFlip}
                                        onFlipHorizontal={handleHorizontalFlip}
                                        onElementsChange={props.onElementsChange}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />)}
                                {activeControl === 'arrange' && (
                                    <ArrangeControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        selectedElement={selectedElement}
                                        onMoveUp={handleMoveUp}
                                        onMoveDown={handleMoveDown}
                                        onMoveToTop={handleMoveToTop}
                                        onMoveToBottom={handleMoveToBottom}
                                        onElementsChange={props.onElementsChange}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                        placementRef={props.placementRef}
                                        //trRef={props.trRef}
                                    />)}
                                {activeControl === 'svgColors' && (
                                    <ColorControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        selectedElement={selectedElement}
                                        onElementsChange={props.onElementsChange}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                        placementRef={props.placementRef}
                                        buildColorMap={buildColorMap}
                                        updateSVGColors={updateSVGColors}
                                    />)}
                                {activeControl === 'rotate' && (
                                    <RotateControlsContent
                                        elems={props.elems}
                                        setElems={props.setElems}
                                        selectedId={selectedId}
                                        selectedItem={selectedItem}
                                        onElementsChange={props.onElementsChange}
                                        selectedElement={selectedElement}
                                        mobileIconStyles={mobileIconStyles}
                                        isMobileView={props.isMobileView}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={props.isMobileView ? "properties-panel mobile" : "properties-panel desktop"}>
                        <div className={props.isMobileView ? "slide-panel__grid" : "properties-panel__controls"}>
                            {selectedItem?.format === 'svg' && renderControlButton(
                                svgEditorIcon,
                                'Colors',
                                () => handleButtonClick('svgColors')
                            )}
                            
                            {(selectedItem?.type === 'text' || (selectedItem?.type === 'path' && selectedItem?.textValue)) && props.isMobileView ? (
                                <>
                                    {renderControlButton(
                                        fontIcon,
                                        'Font',
                                        () => handleButtonClick('fonts')
                                    )}
                                    {renderControlButton(
                                        formatIcon,
                                        'Format',
                                        () => handleButtonClick('fontFormatting')
                                    )}
                                    {renderControlButton(
                                        rotateIcon,
                                        'Rotate',
                                        () => handleButtonClick('rotate')
                                    )}
                                    {renderControlButton(
                                        cloneIcon,
                                        'Clone',
                                        () => handleCloneElement()
                                    )}
                                    {renderControlButton(
                                        removeIcon,
                                        'Remove',
                                        () => handleRemoveElement()
                                    )}
                                </>
                            ) : (
                                <>
                                    {renderControlButton(
                                        scaleIcon,
                                        'Scale',
                                        () => handleButtonClick('scale')
                                    )}
                                    {renderControlButton(
                                        flipIcon,
                                        'Flip',
                                        () => handleButtonClick('flip')
                                    )}
                                    {renderControlButton(
                                        arrangeIcon,
                                        'Arrange',
                                        () => handleButtonClick('arrange')
                                    )}
                                    {renderControlButton(
                                        rotateIcon,
                                        'Rotate',
                                        () => handleButtonClick('rotate')
                                    )}
                                    {renderControlButton(
                                        cloneIcon,
                                        'Clone',
                                        () => handleCloneElement()
                                    )}
                                    {renderControlButton(
                                        removeIcon,
                                        'Remove',
                                        () => handleRemoveElement()
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }
}