// Authentication related functionality
// app/javascript/services/auth.js
// included by app/javascript/controllers/authentication_controller.js
export const handleLoginSuccess = (data) => {
  const csrf_token = data.csrf_token;
  document.querySelector('meta[name="csrf-token"]').setAttribute('content', csrf_token);
  axios.defaults.headers.common['X-CSRF-Token'] = csrf_token;
};

export const updateUserMenu = () => {
  return $.get("/users/user_menu");
};

export const dispatchAuthEvent = () => {
  const event = new Event('userAuthenticated');
  window.dispatchEvent(event);
};

