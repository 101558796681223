import { v4 as uuidv4 } from 'uuid';

export const DEFAULT_GRADIENT_STOPS = [
    { id: uuidv4(), offset: 0, color: '#000000' },
    { id: uuidv4(), offset: 1, color: '#FF0000' }
];

export const calculateBaseDimensions = (element) => {
    const PADDING_FACTOR = 2.5;
    const baseWidth = Math.max(element.width || 1, 1);
    const baseHeight = Math.max(element.height || 1, 1);
    
    return {
        baseWidth,
        baseHeight,
        paddedWidth: baseWidth * PADDING_FACTOR,
        paddedHeight: baseHeight * PADDING_FACTOR
    };
};

export const createGradientHandlers = (setElems, elems, selectedId, stateSetters) => {
    const {
        setGradientType,
        setGradientAngle,
        setGradientStops,
        setRadialGradientX,
        setRadialGradientY,
        setRadialGradientSize,
        setLinearGradientX,
        setLinearGradientY
    } = stateSetters;

    return {
        handleGradientTypeChange: (type) => {
    setGradientType(type);

    const updatedElems = elems.map((elem) => {
        if (elem.id === selectedId) {
            const updatedElem = { 
                ...elem,
                gradientType: type,
                fillPriority: type === 'linear' ? 'linear-gradient' : 
                             type === 'radial' ? 'radial-gradient' : 'color',
            };

            if (type === 'linear') {
                // Set default values if not present
                updatedElem.linearGradientX = elem.linearGradientX ?? 50;
                updatedElem.linearGradientY = elem.linearGradientY ?? 50;
                updatedElem.fillLinearGradientStartPoint = elem.fillLinearGradientStartPoint ?? { x: 0, y: 0 };
                updatedElem.fillLinearGradientEndPoint = elem.fillLinearGradientEndPoint ?? { x: 100, y: 100 };
                updatedElem.fillLinearGradientColorStops = elem.fillLinearGradientColorStops ?? DEFAULT_GRADIENT_STOPS.reduce(
                    (arr, stop) => [...arr, stop.offset, stop.color],
                    []
                );

                // Update state
                setLinearGradientX(updatedElem.linearGradientX ?? 50);
                setLinearGradientY(updatedElem.linearGradientY ?? 50);
            } else if (type === 'radial') {
                // Set default values if not present
                updatedElem.radialGradientX = elem.radialGradientX ?? 50;
                updatedElem.radialGradientY = elem.radialGradientY ?? 50;
                updatedElem.radialGradientSize = elem.radialGradientSize ?? 50;
                updatedElem.fillRadialGradientStartPoint = elem.fillRadialGradientStartPoint ?? { x: 0, y: 0 };
                updatedElem.fillRadialGradientEndPoint = elem.fillRadialGradientEndPoint ?? { x: 0, y: 0 };
                updatedElem.fillRadialGradientColorStops = elem.fillRadialGradientColorStops ?? DEFAULT_GRADIENT_STOPS.reduce(
                    (arr, stop) => [...arr, stop.offset, stop.color],
                    []
                );
                updatedElem.fillRadialGradientStartRadius = elem.fillRadialGradientStartRadius ?? 0;
                updatedElem.fillRadialGradientEndRadius = elem.fillRadialGradientEndRadius ?? 100;

                // Update state
                setRadialGradientX(updatedElem.radialGradientX ?? 50);
                setRadialGradientY(updatedElem.radialGradientY ?? 50);
                setRadialGradientSize(updatedElem.radialGradientSize ?? 50);
            }

            return updatedElem;
        }
        return elem;
    });
    setElems(updatedElems);
},

        handleGradientAngleChange: (angle) => {
            const angleRad = angle * Math.PI / 180;
            const length = 100;
            
            const updatedElems = elems.map((elem) => {
                if (elem.id === selectedId) {
                    return {
                        ...elem,
                        gradientAngle: angle,
                        fillLinearGradientStartPoint: { x: 0, y: 0 },
                        fillLinearGradientEndPoint: {
                            x: Math.cos(angleRad) * length,
                            y: Math.sin(angleRad) * length
                        },
                        fillLinearGradientColorStops: elem.fillLinearGradientColorStops ?? DEFAULT_GRADIENT_STOPS.reduce(
                            (arr, stop) => [...arr, stop.offset, stop.color],
                            []
                        )
                    };
                }
                return elem;
            });
            setElems(updatedElems);
            setGradientAngle(angle);
        },

        handleGradientStopsChange: (stops) => {
            const updatedElems = elems.map((elem) => {
                if (elem.id === selectedId) {
                    return {
                        ...elem,
                        gradientStops: stops,
                        fillLinearGradientColorStops: stops.reduce(
                            (arr, stop) => [...arr, stop.offset, stop.color],
                            []
                        ),
                        fillRadialGradientColorStops: stops.reduce(
                            (arr, stop) => [...arr, stop.offset, stop.color],
                            []
                        )
                    };
                }
                return elem;
            });
            setElems(updatedElems);
            setGradientStops(stops);
        },

        handleRadialGradientPositionChange: (x, y) => {
            setRadialGradientX(x);
            setRadialGradientY(y);
            const updatedElems = elems.map((elem) => {
                if (elem.id === selectedId) {
                    // Use base dimensions if they exist, otherwise calculate them
                    const dimensions = elem.baseDimensions || calculateBaseDimensions(elem);
                    const { paddedWidth, paddedHeight } = dimensions;
                    
                    // Calculate local coordinates based on padded dimensions
                    const xDecimal = x / 100;
                    const yDecimal = y / 100;
                    const localX = (paddedWidth * xDecimal);
                    const localY = (paddedHeight * yDecimal);
                    
                    return {
                        ...elem,
                        baseDimensions: dimensions, // Store the base dimensions
                        radialGradientX: x,
                        radialGradientY: y,
                        fillPriority: 'radial-gradient',
                        fillRadialGradientStartPoint: { x: localX, y: localY },
                        fillRadialGradientEndPoint: { x: localX, y: localY }
                    };
                }
                return elem;
            });
            setElems(updatedElems);
        },
        
        handleRadialGradientSizeChange: (size) => {
            const safeSize = Math.max(1, size);
            setRadialGradientSize(safeSize);
            
            const updatedElems = elems.map((elem) => {
                if (elem.id === selectedId) {
                    const xDecimal = (elem.radialGradientX || 50) / 100;
                    const yDecimal = (elem.radialGradientY || 50) / 100;
        
                    // Use base dimensions if they exist, otherwise calculate them
                    const dimensions = elem.baseDimensions || calculateBaseDimensions(elem);
                    const endRadius = Math.max(dimensions.paddedWidth, dimensions.paddedHeight) * (safeSize / 100);
                    
                    return {
                        ...elem,
                        baseDimensions: dimensions, // Store the base dimensions
                        radialGradientSize: safeSize,
                        fillPriority: 'radial-gradient',
                        fillRadialGradientStartPoint: elem.fillRadialGradientStartPoint ?? { x: xDecimal, y: yDecimal },
                        fillRadialGradientEndPoint: elem.fillRadialGradientEndPoint ?? { x: xDecimal, y: yDecimal },
                        fillRadialGradientStartRadius: 0,
                        fillRadialGradientEndRadius: endRadius
                    };
                }
                return elem;
            });
            setElems(updatedElems);
        },

        handleLinearGradientPositionChange: (x, y) => {
            setLinearGradientX(x);
            setLinearGradientY(y);
            const updatedElems = elems.map((elem) => {
                if (elem.id === selectedId) {
                    // Use base dimensions if they exist, otherwise calculate them
                    const dimensions = elem.baseDimensions || calculateBaseDimensions(elem);
                    const { paddedWidth, paddedHeight } = dimensions;
                    
                    // Calculate local coordinates based on padded dimensions
                    const xDecimal = x / 100;
                    const yDecimal = y / 100;
                    const localX = (paddedWidth * xDecimal);
                    const localY = (paddedHeight * yDecimal);
                    
                    return {
                        ...elem,
                        baseDimensions: dimensions, // Store the base dimensions
                        linearGradientX: x,
                        linearGradientY: y,
                        fillPriority: 'linear-gradient',
                        fillLinearGradientStartPoint: { x: localX, y: localY },
                    };
                }
                return elem;
            });
            setElems(updatedElems);
        }
    };
};

export const getGradientProperties = (type, options) => {
    const { 
        gradientAngle,
        gradientStops,
        linearGradientX,
        linearGradientY,
        radialGradientX,
        radialGradientY,
        radialGradientSize,
        element
    } = options;

    if (type === 'radial') {
        return {
            fillPriority: 'radial-gradient',
            radialGradientX,
            radialGradientY,
            radialGradientSize,
            fillRadialGradientStartPoint: element?.fillRadialGradientStartPoint,
            fillRadialGradientEndPoint: element?.fillRadialGradientEndPoint,
            fillRadialGradientStartRadius: element?.fillRadialGradientStartRadius,
            fillRadialGradientEndRadius: element?.fillRadialGradientEndRadius,
            fillRadialGradientColorStops: element?.fillRadialGradientColorStops
        };
    } else if (type === 'linear') {
        return {
            fillPriority: 'linear-gradient',
            gradientAngle,
            linearGradientX,
            linearGradientY,
            fillLinearGradientStartPoint: element?.fillLinearGradientStartPoint,
            fillLinearGradientEndPoint: element?.fillLinearGradientEndPoint,
            fillLinearGradientColorStops: element?.fillLinearGradientColorStops
        };
    }
    
    return {
        fillPriority: 'color'
    };
};