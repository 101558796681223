import React, { useState, useEffect } from 'react';
import fontsIcon from '../../../../assets/images/icon/font.svg';
import Session from 'svg-text-to-path';
import { Path } from '../TextWarp/Path';

const FONT_CATEGORIES = {
    recommended: {
        label: 'Recommended',
        fonts: ['Roboto', 'Montserrat', 'Open Sans']
    },
    headings: {
        label: 'Headings',
        fonts: ['Playfair Display', 'Oswald', 'Abril Fatface']
    },
    decorative: {
        label: 'Decorative',
        fonts: ['Pacifico', 'Rubik Burned', 'Great Vibes']
    }
};

const loadGoogleFont = (fontFamily) => {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(' ', '+')}:wght@400&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
};

export const FontControls = ({
    isMobileView,
    onButtonClick,
    mobileIconStyles
}) => {
    if (isMobileView) {
        return (
            <button
                style={mobileIconStyles.mobile}
                className="mobile_context_button"
                onClick={onButtonClick}
            >
                <div
                    className="mobile_context_button_icon"
                    data-icon="Fonts"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${fontsIcon})`
                    }}
                />
                <div>Fonts</div>
            </button>
        );
    }
    
    return (
        <button
            className="properties-panel__button desktop"
            onClick={onButtonClick}
        >
            <div
                className="properties-panel__button-icon"
                style={{
                    backgroundImage: `url(${fontsIcon})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            />
            <span className="properties-panel__button-label">Fonts</span>
        </button>
    );
};

export const FontControlsContent = ({
    selectedItem,
    onElementsChange,
    elems,
    selectedId,
    isMobileView
}) => {
    const [selectedCategory, setSelectedCategory] = useState('recommended');
    const [loadedFonts, setLoadedFonts] = useState(new Set(FONT_CATEGORIES.recommended.fonts));
    const fontSize = selectedItem?.fontSize || 144;

    // Load fonts for initial category
    useEffect(() => {
        FONT_CATEGORIES.recommended.fonts.forEach(loadGoogleFont);
    }, []);

    // Handle category change and font loading
    const handleCategoryChange = async (category) => {
        setSelectedCategory(category);
        
        const fonts = FONT_CATEGORIES[category].fonts;
        const newFonts = fonts.filter(font => !loadedFonts.has(font));
        
        if (newFonts.length > 0) {
            await Promise.all(newFonts.map(async (font) => {
                await loadGoogleFont(font);
                return font;
            }));
            
            setLoadedFonts(prev => {
                const updated = new Set(prev);
                newFonts.forEach(font => updated.add(font));
                return updated;
            });
        }
    };

    const handleFontSelect = async (fontFamily) => {
        if (!selectedId || !selectedItem?.textValue) return;
        
        let svgElement = null;
        let tempContainer = document.createElement('div');
        tempContainer.style.display = 'none';
        document.body.appendChild(tempContainer);
        
        try {
            // Create SVG element
            svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            const {width, height} = measureSvgText(selectedItem.textValue, fontFamily, fontSize);
            svgElement.setAttribute("width", `${width}px`);
            svgElement.setAttribute("height", `${height}px`);
            
            // Create text element with attributes
            const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
            Object.entries({
                fill: selectedItem.fill || "black",
                x: "0",
                y: fontSize.toString(),
                "font-family": fontFamily,
                "font-size": fontSize.toString(),
            }).forEach(([attr, value]) => textElement.setAttribute(attr, value));
            
            textElement.textContent = selectedItem.textValue;
            svgElement.appendChild(textElement);
            tempContainer.appendChild(svgElement);

            // Convert text to path
            const session = new Session(svgElement, {
                googleApiKey: 'AIzaSyCOE5K7L-6bbNK6aY3SBU-ZT8kLVw_D2XU',
            });
            
            await session.replaceAll();
            const path = svgElement.querySelector('path');
            const pathData = path?.getAttribute('d');

            if (pathData) {
                let p = new Path(pathData);
                
                // Only apply warp if there's an existing warp effect
                if (selectedItem.distortionType) {
                    p.warp({
                        type: selectedItem.distortionType,
                        bend: (selectedItem.bendValue / 100) || 0,
                        distortV: (selectedItem.horizontalDistort / 100) || 0,
                        distortH: (selectedItem.verticalDistort / 100) || 0
                    });
                }

                // Create updated element with all necessary properties
                const updatedElems = elems.map(elem => 
                    elem.id === selectedId 
                        ? {
                            ...elem,
                            fontFamily,
                            fontSize,
                            data: p.output(),
                            originalData: pathData,
                            width,
                            height,
                            textValue: selectedItem.textValue,
                            fill: selectedItem.fill || "black",
                            type: 'path'
                        }
                        : elem
                );

                onElementsChange(updatedElems);
            }
        } catch (error) {
            console.error('Font conversion failed:', error);
        } finally {
            if (tempContainer && document.body.contains(tempContainer)) {
                document.body.removeChild(tempContainer);
            }
        }
    };

    // Helper function to measure text dimensions
    const measureSvgText = (text, font, fontSize) => {
        const svgNS = "http://www.w3.org/2000/svg";
        const tempSvg = document.createElementNS(svgNS, "svg");
        const textElement = document.createElementNS(svgNS, "text");

        textElement.setAttribute("fill", "black");
        textElement.setAttribute("font-family", font);
        textElement.setAttribute("font-size", fontSize);
        textElement.textContent = text;

        tempSvg.appendChild(textElement);
        document.body.appendChild(tempSvg);

        const bbox = textElement.getBBox();
        const width = bbox.width;
        const height = bbox.height;

        document.body.removeChild(tempSvg);
        return {width, height};
    };

    const filteredFonts = FONT_CATEGORIES[selectedCategory].fonts;

    return (
        <div className={`font-controls ${isMobileView ? 'mobile' : ''}`}>
            <div className="font-controls__categories">
                {Object.entries(FONT_CATEGORIES).map(([key, category]) => (
                    <button
                        key={key}
                        className={`category-button ${selectedCategory === key ? 'active' : ''}`}
                        onClick={() => handleCategoryChange(key)}
                    >
                        {category.label}
                    </button>
                ))}
            </div>

            <div className="font-controls__list">
                {filteredFonts.map(font => (
                    <button
                        key={font}
                        className={`font-button ${selectedItem?.fontFamily === font ? 'active' : ''}`}
                        onClick={() => handleFontSelect(font)}
                    >
                        <div className="font-button__content">
                            <span className="font-button__name">{font}</span>
                            <span 
                                className="font-button__preview"
                                style={{ 
                                    fontFamily: `'${font}', sans-serif`,
                                    visibility: loadedFonts.has(font) ? 'visible' : 'hidden'
                                }}
                            >
                                {selectedItem?.textValue?.slice(0, 10) || 'Aa'}
                            </span>
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
};
