import React, { useState, useEffect } from 'react';
import { generateThumbnail, updateElementThumbnail } from "../elements/element-utils";

// Assuming `generateThumbnail` is already imported and correctly implemented

const LayersAssetItem = ({ elem, selectElement, isSelected }) => {
    // Initialize the thumbnail state as empty initially
    const [thumbnail, setThumbnail] = useState('');

    // Update the thumbnail using the `generateThumbnail` function
    useEffect(() => {
        const fetchThumbnail = async () => {
            try {
                const dataURL = await generateThumbnail(elem);
                setThumbnail(dataURL); // Update the local state
            } catch (error) {
                console.error("Error generating thumbnail:", error);
            }
        };

        fetchThumbnail(); // Invoke the async function
    }, [elem]); // Re-run the effect whenever the element changes

    const layerName = elem.type === 'path' ? elem.textValue || `Layer ${elem.id}` : `Layer ${elem.id}`;

    // Render the layer item with the resolved thumbnail
    return (
        <li
            className={`layer-item ${isSelected ? 'selected' : ''}`}
            onClick={() => selectElement(elem.id)}
        >
            {thumbnail ? (
                <img
                    src={thumbnail}
                    alt={`Layer ${elem.id}`}
                    className='layer-thumbnail'
                />
            ) : (
                <div className='layer-thumbnail-placeholder'></div>
            )}
            <span className='layer-name'>{layerName}</span>
        </li>
    );
};

const LayersAsset = ({ elems, selectElement, selectedId }) => {
    return (
        <div className='layers-list'>
            {elems.length === 0 ? (
                <p>No layers available</p>
            ) : (
                <ul>
                    {elems.map((elem, index) => (
                        <LayersAssetItem
                            key={index}
                            elem={elem}
                            selectElement={selectElement}
                            isSelected={elem.id === selectedId}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default LayersAsset;
