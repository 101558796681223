// app/javascript/packs/components/DashboardCreateNewProduct/DashboardCreateNewProduct.jsx

/* eslint-disable react/prop-types */
import { useMutation } from 'react-query';
import React, { forwardRef, useImperativeHandle } from 'react';

const useSaveShopProduct = () => {
  return useMutation((formData) => {
    return fetch('/dashboard/make/create_design', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
      body: formData,
    }).then(response => response.json());
  }, {
    onSuccess: (data) => {
      if (data.status === 'ok') {
        window.location.href = data.redirect_url;
      } else {
        alert('Error saving product: ' + data.error);
      }
    },
  });
};

export const DashboardCreateNewProduct = forwardRef((props, ref) => {
  const { mutate: addProduct } = useSaveShopProduct();

  // Check if user is product owner
  const isProductOwner = props.currentUser?.id === props.currentUser?.product_user_id;
  
  // Check if user has permission to use dashboard features
  const hasDashboardAccess = props.currentUser?.is_admin === true || 
                            (props.currentUser?.roles || []).includes('shop_admin');

  const saveProduct = (e, productStatus) => {
    e.preventDefault();

    if (!hasDashboardAccess) {
      alert('You do not have permission to perform this action');
      return;
    }

    let formData = new FormData();
    formData.append('product_id', props.productId);
    formData.append('product[title]', props.title);
    formData.append('product[product_type_id]', props.productTypeId);
    formData.append('product[product_variant_id]', props.selectedProductVariantId);
    formData.append('product[state]', JSON.stringify(props.elems));
    formData.append('product[status]', productStatus);
    if (props.designId) {
      formData.append('product[design_id]', props.designId);
    }

    addProduct(formData);
  };

  useImperativeHandle(ref, () => ({
    saveProduct
  }));

  if (!hasDashboardAccess) return null;

  return (
    <div className="d-flex gap-2 justify-content-center">
      <button 
        className="btn btn-outline-primary" 
        onClick={(e) => saveProduct(e, 'draft')}
      >
        Save to Draft
      </button>
      <button 
        className="btn btn-primary" 
        onClick={(e) => saveProduct(e, 'published')}
      >
        Save & Publish
      </button>
    </div>
  );
});

