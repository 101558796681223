import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Notification controller connected")
    console.log("Initial children length:", this.element.children.length)
    
    // Listen for Turbo Stream updates
    document.addEventListener("turbo:before-stream-render", this.beforeStreamRender.bind(this))
  }

  disconnect() {
    document.removeEventListener("turbo:before-stream-render", this.beforeStreamRender.bind(this))
  }

  beforeStreamRender(event) {
    console.log("Before stream render triggered")
    // Check if this stream update is targeting our element
    if (event.target.target === "settings-notification") {
      console.log("Stream update for settings-notification")
      // Wait for the content to be updated
      requestAnimationFrame(() => {
        this.handleContentChange()
      })
    }
  }

  handleContentChange() {
    console.log("Handle content change triggered")
    // Show the notification regardless of current content
    this.element.style.display = "block"
    console.log("Showing notification")
    
    // Set a timeout to hide it
    setTimeout(() => {
      console.log("Hiding notification")
      this.element.style.display = "none"
      this.element.innerHTML = ''
    }, 3000)
  }
}
