import {Layer, Rect} from "react-konva";
import {HorizontalScrollbar} from "./scrollers/HorizontalScrollbar";
import {VertScrollbar} from "./scrollers/verticalScrollbar";
import React from "react";

const ScrollbarLayer = (props) => {
    const horizontalScrollerRef = React.useRef();
    const verticalScrollerRef = React.useRef();
    const scrollLayerRef = React.useRef();
    let selectionRectRef = props.selectionRectRef;
    return (
        <Layer ref={scrollLayerRef}>
            <Rect
                fill="rgba(0,0,255,0.5)"
                ref={selectionRectRef}
            />
            <HorizontalScrollbar
                scrollerRef={horizontalScrollerRef}
                drawingLayerRef={props.drawingLayerRef}
                template={props.template}
                printAreaRef={props.printAreaRef}
                templateLayerRef={props.templateLayerRef}
                stageRef={props.stageRef}
            />
            <VertScrollbar
                scrollerRef={verticalScrollerRef}
                drawingLayerRef={props.drawingLayerRef}
                template={props.template}
                printAreaRef={props.printAreaRef}
                templateLayerRef={props.templateLayerRef}
                stageRef={props.stageRef}
            />

        </Layer>
    )
}

export default ScrollbarLayer;