import axios from 'axios';

const setAxiosHeaders = () => {
    const token = document.querySelector('meta[name="csrf-token"]')?.content;
    if (token) {
        axios.defaults.headers.common['X-CSRF-Token'] = token;
    } else {
        console.error('CSRF token not found in meta tags');
    }
}

export default setAxiosHeaders;