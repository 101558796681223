import {Rect} from "react-konva";
import React from "react";

export const VertScrollbar = (props) => {
    const vertRef = props.scrollerRef;
    const stage = props.stageRef.current;
    const PADDING = 0;
    const scrollbarWidth = 10;
    const scrollbarHeight = 100;
    let designView = document.querySelector("#DesignView");
    let containerHeight = designView.offsetHeight;
    let containerWidth = designView.offsetWidth;

    const positionVerticalBar = (event) => {
        let designView = document.querySelector("#DesignView");
        let containerWidth = designView.offsetWidth;
        let containerHeight = designView.offsetHeight;
        vertRef.current.y(containerHeight / 2 - scrollbarHeight);
        vertRef.current.x(containerWidth - 10);
    }

    React.useLayoutEffect(() => {
        // on resize and load, move to correct location
        window.addEventListener('handleZoomInClick', positionVerticalBar);
        window.addEventListener('handleZoomOutClick', positionVerticalBar);
        window.addEventListener('fitScreen', positionVerticalBar);
        window.addEventListener('resize', positionVerticalBar);
        return function cleanup() {
            window.removeEventListener('handleZoomInClick', positionVerticalBar);
            window.removeEventListener('handleZoomOutClick', positionVerticalBar);
            window.removeEventListener('fitScreen', positionVerticalBar);
            window.removeEventListener('resize', positionVerticalBar);
        }
    }, []);

    return (
        <Rect
            ref={vertRef}
            width={scrollbarWidth}
            height={scrollbarHeight}
            fill={'grey'}
            opacity={0.99}
            cornerRadius={5}   // Rounded corners
            shadowBlur={5}     // Shadow for depth
            shadowOffsetX={2}  // Shadow offset
            shadowOffsetY={2}  // Shadow offset
            shadowColor={'black'}
            x={containerWidth - 10}
            y={containerHeight / 2 - scrollbarHeight}
            draggable={true}
            onMouseEnter={() => {   // Highlight on hover
                vertRef.current.fill('darkgrey');
                vertRef.current.draw();
            }}
            onMouseLeave={() => {
                vertRef.current.fill('grey');
                vertRef.current.draw();
            }}
            onDragMove={() => {
                let designView = document.querySelector("#DesignView");
                let containerHeight = designView.offsetHeight;
                let drawLayer = props.drawingLayerRef.current;
                let printArea = props.printAreaRef.current;
                let template = props.templateLayerRef.current;

                let absTemplateHeight = template.getClientRect({relativeTo: stage}).height;

                const availableHeight = containerHeight - PADDING * 2 - scrollbarHeight;
                let delta = (vertRef.current.y() - PADDING) / availableHeight;

                const maxYShift = absTemplateHeight - containerHeight;

                const imageYShift = -maxYShift * delta;

                [printArea, drawLayer, template].forEach(layer => {
                    layer.y(imageYShift);
                });
            }}

            dragBoundFunc={(position) => {
                let designView = document.querySelector("#DesignView");
                let containerWidth = designView.offsetWidth;
                let containerHeight = designView.offsetHeight;

                position.x = containerWidth - 10;

                position.y =
                    Math.max(
                        Math.min(
                            position.y,
                            containerHeight - vertRef.current.height() - PADDING),
                        0);

                return position;
            }}
        />
    );
};