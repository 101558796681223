import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["result"]

    check(event) {
        const subdomain = event.target.value

        // Validate subdomain
        if (!this.isValidSubdomain(subdomain)) {
            this.resultTarget.innerHTML = "<span class='text-danger'>Invalid sub-domain. Only letters, numbers, and hyphens are allowed.</span>"
            return
        }

        const url = `/shops/onboarding/check_subdomain?subdomain=${subdomain}`

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.available) {
                    this.resultTarget.innerHTML = "<span class='text-success'>Sub-domain is available!</span>"
                } else {
                    this.resultTarget.innerHTML = "<span class='text-danger'>Sub-domain is already taken.</span>"
                }
            })
            .catch(() => {
                this.resultTarget.innerHTML = "<span class='text-danger'>Error checking sub-domain availability.</span>"
            })
    }

    isValidSubdomain(subdomain) {
        const maxLength = 63
        const pattern = /^[a-zA-Z0-9-]+$/
        return subdomain.length <= maxLength && pattern.test(subdomain)
    }
}
