import { Point } from './Point';
import { ParameterClass } from './ParameterClass';

export class Warp {
    constructor(settings, source) {
        this.source = source;
        this.initializePoints(source);
        this.initializeDefaultValues();
        this.applyParameters(settings);
    }

    initializePoints(source) {
        const {left, top, right, bottom, width, height} = source;
        this.A = new Point(left, top);
        this.B = new Point(right, top);
        this.C = new Point(right, bottom);
        this.D = new Point(left, bottom);
        this.Aleft = new Point(left, top + height / 3);
        this.Atop = new Point(left + width / 3, top);
        this.Btop = new Point(right - width / 3, top);
        this.Bright = new Point(right, top + height / 3);
        this.Cright = new Point(right, bottom - height / 3);
        this.Cbottom = new Point(right - width / 3, bottom);
        this.Dbottom = new Point(left + width / 3, bottom);
        this.Dleft = new Point(left, bottom - height / 3);
    }

    initializeDefaultValues() {
        this.ABx = this.ABy = this.CDx = this.CDy = this.BCx = this.BCy = this.ADx = this.ADy = 0;
        this.STEPS = 10;
        this.distortX = this.distortY1 = this.distortY2 = 0;
        this.T1 = 1 / 3;
        this.T2 = 2 / 3;
        this.kAB = this.kBC = this.kCD = this.kAD = 0;
        this.angle = this.sin = this.cos = this.kt = undefined;
    }

    applyParameters(settings) {
        const parameters = this._parameters = new ParameterClass(settings);
        this.calculateAngle(parameters.bend);
        this.calculateDistortions(parameters);
        this.setAnchors();
        this.applyWarpType(parameters.type);
    }

    calculateAngle(bend) {
        this.angle = bend * Math.PI / 2;
        this.sin = Math.sin(this.angle);
        this.cos = Math.cos(this.angle);
        this.kt = 1 + Math.pow(bend, 4);
    }

    calculateDistortions(parameters) {
        const halfWidthDistortion = 0.5 * parameters.distortV * this.source.width;
        const halfHeightDistortion = 0.5 * parameters.distortH * this.source.height;

        this.distortX = halfWidthDistortion;
        this.distortY1 = halfHeightDistortion * (1 - parameters.distortV);
        this.distortY2 = halfHeightDistortion * (1 + parameters.distortV);
        this.kAB = 1 - parameters.distortV;
        this.kBC = 1 + parameters.distortH;
        this.kCD = 1 + parameters.distortV;
        this.kAD = 1 - parameters.distortH;
    }

    applyWarpType(warpType) {
        switch (warpType) {
            case "WARP_ARC":
                this.setArc();
                break;
            case "WARP_ARC_LOWER":
                this.setArcLower();
                break;
            case "WARP_ARC_UPPER":
                this.setArcUpper();
                break;
            case "WARP_ARCH":
                this.setArch();
                break;
            case "WARP_BULGE":
                this.setBulge();
                break;
            case "WARP_FLAG":
                this.setFlag();
                break;
            case "WARP_FISH":
                this.setFish();
                break;
            case "WARP_RISE":
                this.setRise();
                break;
            case "WARP_INFLATE":
                this.setInflate();
                break;
            case "WARP_SQUEEZE":
                this.setSqueeze();
                break;
            case "WARP_WAVE_LOWER":
                this.setWaveLower();
                break;
            case "WARP_WAVE_UPPER":
                this.setWaveUpper()
                break;
            default:
                break;
        }
    }

    setAnchors() {
        const { left, top, right, bottom } = this.source;
        const { distortX, distortY1, distortY2 } = this;

        this.A.x = left + distortX;
        this.B.x = right - distortX;
        this.C.x = right + distortX;
        this.D.x = left - distortX;

        this.A.y = top + distortY1;
        this.B.y = top - distortY1;
        this.C.y = bottom + distortY2;
        this.D.y = bottom - distortY2;
    }

    setControls() {
        this.Atop.x = this.A.x + this.ABx;
        this.Atop.y = this.A.y + this.ABy;
        this.Btop.x = this.B.x - this.ABx;
        this.Btop.y = this.B.y - this.ABy;

        this.Dbottom.x = this.D.x + this.CDx;
        this.Dbottom.y = this.D.y + this.CDy;
        this.Cbottom.x = this.C.x - this.CDx;
        this.Cbottom.y = this.C.y - this.CDy;

        this.Bright.x = this.B.x + this.BCx;
        this.Bright.y = this.B.y + this.BCy;
        this.Cright.x = this.C.x - this.BCx;
        this.Cright.y = this.C.y - this.BCy;

        this.Aleft.x = this.A.x + this.ADx;
        this.Aleft.y = this.A.y + this.ADy;
        this.Dleft.x = this.D.x - this.ADx;
        this.Dleft.y = this.D.y - this.ADy;
    }

    changeCubic(startPoint, controlPoint1, controlPoint2, endPoint) {
        const changedStartPoint = this._changePoint(startPoint);
        const changedEndPoint = this._changePoint(endPoint);

        const oneThirdPoint = this.getPointOn(1 / 3, startPoint, endPoint, controlPoint1, controlPoint2);
        const twoThirdsPoint = this.getPointOn(2 / 3, startPoint, endPoint, controlPoint1, controlPoint2);

        const changedOneThirdPoint = this._changePoint(oneThirdPoint);
        const changedTwoThirdsPoint = this._changePoint(twoThirdsPoint);

        return this.getNewSegment(changedStartPoint, changedEndPoint, changedOneThirdPoint, changedTwoThirdsPoint);
    }

    changePoint(point) {
        const changedPoint = this._changePoint(point);
        return [changedPoint.x, changedPoint.y];
    }

    _changePoint(point) {
        const { A, Atop, Aleft, B, Btop, Bright, C, Cright, Cbottom, D, Dbottom, Dleft } = this;
        const normalizedX = (point.x - this.source.x) / this.source.width;
        const normalizedY = (point.y - this.source.y) / this.source.height;
        const complementY = 1 - normalizedY;

        const bezierStart = this.getPointOn(normalizedY, A, D, Aleft, Dleft);
        const bezierEnd = this.getPointOn(normalizedY, B, C, Bright, Cright);
        const intermediatePoint1 = new Point(Atop.x * complementY + Dbottom.x * normalizedY, Atop.y * complementY + Dbottom.y * normalizedY);
        const intermediatePoint2 = new Point(Btop.x * complementY + Cbottom.x * normalizedY, Btop.y * complementY + Cbottom.y * normalizedY);

        const bezierControl = this.getPointOn(normalizedX, bezierStart, bezierEnd, intermediatePoint1, intermediatePoint2);
        return new Point(bezierControl.x, bezierControl.y);
    }

    getPointOn(fraction, start, end, control1, control2) {
        const cubicFraction = fraction * fraction * fraction;
        const squareFraction = fraction * fraction;
        const oneMinusFraction = 1 - fraction;
        const cubicOneMinusFraction = oneMinusFraction * oneMinusFraction * oneMinusFraction;

        const x = cubicFraction * (end.x + 3 * (control1.x - control2.x) - start.x) + 3 * squareFraction * (start.x - 2 * control1.x + control2.x) + 3 * fraction * (control1.x - start.x) + start.x;
        const y = cubicFraction * (end.y + 3 * (control1.y - control2.y) - start.y) + 3 * squareFraction * (start.y - 2 * control1.y + control2.y) + 3 * fraction * (control1.y - start.y) + start.y;

        return new Point(x, y);
    }

    setArc() {
        const parameters = this._parameters;
        const { height } = this.source;
        const invertedDistortV = 1 - parameters.distortV;

        if (parameters.bend > 0) {
            this.adjustArcForPositiveBend(invertedDistortV, height);
        } else if (parameters.bend < 0) {
            this.adjustArcForNegativeBend(invertedDistortV, height);
        }

        this.calculateArms();
        this.setControls();
        this.adjustControlPoints();
    }

    adjustArcForPositiveBend(invertedDistortV, height) {
        this.A.x -= invertedDistortV * (height - this.distortY1) * this.sin;
        this.B.x += invertedDistortV * (height + this.distortY1) * this.sin;
        this.A.y += (height - this.distortY1) * (1 - this.cos);
        this.B.y += (height + this.distortY1) * (1 - this.cos);
        this.C.x -= this.distortY2 * this.sin;
        this.D.x -= this.distortY2 * this.sin;
        this.C.y += this.distortY2 * (this.cos - 1);
        this.D.y -= this.distortY2 * (this.cos - 1);
    }

    adjustArcForNegativeBend(invertedDistortV, height) {
        this.D.x += invertedDistortV * (height - this.distortY2) * this.sin;
        this.C.x -= invertedDistortV * (height + this.distortY2) * this.sin;
        this.D.y += (height - this.distortY2) * (this.cos - 1);
        this.C.y += (height + this.distortY2) * (this.cos - 1);
        this.A.x += this.distortY1 * this.sin;
        this.B.x += this.distortY1 * this.sin;
        this.A.y -= this.distortY1 * (1 - this.cos);
        this.B.y += this.distortY1 * (1 - this.cos);
    }

    adjustControlPoints() {
        this.Atop.x += this.ABx * (this.cos * this.kt - 1);
        this.Atop.y -= this.ABx * this.sin * this.kt;
        this.Btop.x += this.ABx * (1 - this.cos * this.kt);
        this.Btop.y -= this.ABx * this.sin * this.kt;
        this.Dbottom.x += this.CDx * (this.cos * this.kt - 1);
        this.Dbottom.y -= this.CDx * this.sin * this.kt;
        this.Cbottom.x += this.CDx * (1 - this.cos * this.kt);
        this.Cbottom.y -= this.CDx * this.sin * this.kt;
    }

    setFish() {
        const parameters = this._parameters;
        this.calculateArms();
        this.setControls();
        this.adjustFishControlPoints(parameters.bend);
    }

    adjustFishControlPoints(bend) {
        this.Atop.y -= 2 * bend * this.kAB * (2 * this.ADy + this.BCy);
        this.Btop.y += 2 * bend * this.kAB * (this.ADy + 2 * this.BCy);
        this.Dbottom.y += 2 * bend * this.kCD * (2 * this.ADy + this.BCy);
        this.Cbottom.y -= 2 * bend * this.kCD * (this.ADy + 2 * this.BCy);
    }

    setFlag() {
        const parameters = this._parameters;
        this.calculateArms();
        this.setControls();
        this.adjustFlagControlPoints(parameters.bend);
    }

    setBulge() {
        this.calculateArms();
        this.setControls();

        this.adjustBulgeControlPoints();
    }

    adjustBulgeControlPoints() {
        this.Atop.x += this.ABx * (this.cos * this.kt - 1);
        this.Atop.y -= this.ABx * this.sin * this.kt;
        this.Btop.x += this.ABx * (1 - this.cos * this.kt);
        this.Btop.y -= this.ABx * this.sin * this.kt;
        this.Dbottom.x += this.CDx * (this.cos * this.kt - 1);
        this.Dbottom.y += this.CDx * this.sin * this.kt;
        this.Cbottom.x += this.CDx * (1 - this.cos * this.kt);
        this.Cbottom.y += this.CDx * this.sin * this.kt;
    }

    setArch() {
        this.calculateArms();
        this.setControls();

        this.adjustArchControlPoints();
    }

    adjustArchControlPoints() {
        this.Atop.x += this.ABx * (this.cos * this.kt - 1);
        this.Atop.y -= this.ABx * this.sin * this.kt;
        this.Btop.x += this.ABx * (1 - this.cos * this.kt);
        this.Btop.y -= this.ABx * this.sin * this.kt;
        this.Dbottom.x += this.CDx * (this.cos * this.kt - 1);
        this.Dbottom.y -= this.CDx * this.sin * this.kt;
        this.Cbottom.x += this.CDx * (1 - this.cos * this.kt);
        this.Cbottom.y -= this.CDx * this.sin * this.kt;
    }

    setArcLower() {
        this.calculateArms();
        this.setControls();

        this.adjustArcLowerControlPoints();
    }

    adjustArcLowerControlPoints() {
        this.Dbottom.x += this.CDx * (this.cos * this.kt - 1);
        this.Dbottom.y += this.CDx * this.sin * this.kt;
        this.Cbottom.x += this.CDx * (1 - this.cos * this.kt);
        this.Cbottom.y += this.CDx * this.sin * this.kt;
    }

    setArcUpper() {
        this.calculateArms();
        this.setControls();

        this.adjustArcUpperControlPoints();
    }

    adjustArcUpperControlPoints() {
        this.Atop.x += this.ABx * (this.cos * this.kt - 1);
        this.Atop.y -= this.ABx * this.sin * this.kt;
        this.Btop.x += this.ABx * (1 - this.cos * this.kt);
        this.Btop.y -= this.ABx * this.sin * this.kt;
    }

    adjustFlagControlPoints(bend) {
        this.Atop.y += 3 * bend * this.kAB * (this.ADy + this.BCy);
        this.Btop.y -= 3 * bend * this.kAB * (this.ADy + this.BCy);
        this.Dbottom.y += 3 * bend * this.kCD * (this.ADy + this.BCy);
        this.Cbottom.y -= 3 * bend * this.kCD * (this.ADy + this.BCy);
    }

    setSqueeze() {
        this.calculateArms();
        this.setControls();

        const { bend } = this._parameters;
        this.adjustSqueezeControlPoints(bend);
    }

    adjustSqueezeControlPoints(bend) {
        this.Atop.y -= bend * this.kAB * this.ADy;
        this.Btop.y -= bend * this.kAB * this.BCy;
        this.Dbottom.y += bend * this.kCD * this.ADy;
        this.Cbottom.y += bend * this.kCD * this.BCy;
        this.Bright.x -= bend * this.kBC * this.ABx;
        this.Cright.x -= bend * this.kBC * this.CDx;
        this.Aleft.x += bend * this.kAD * this.ABx;
        this.Dleft.x += bend * this.kAD * this.CDx;
    }

    setInflate() {
        this.calculateArms();
        this.setControls();

        const { bend } = this._parameters;
        const averageVerticalDistortion = (this.ADy + this.BCy) / 2;
        const averageHorizontalDistortion = (this.ABx + this.CDx) / 2;
        this.adjustInflateControlPoints(bend, averageVerticalDistortion, averageHorizontalDistortion);
    }

    adjustInflateControlPoints(bend, avgVertDistortion, avgHorizDistortion) {
        this.Atop.y -= bend * this.kAB * avgVertDistortion;
        this.Btop.y -= bend * this.kAB * avgVertDistortion;
        this.Dbottom.y += bend * this.kCD * avgVertDistortion;
        this.Cbottom.y += bend * this.kCD * avgVertDistortion;
        this.Bright.x += bend * this.kBC * avgHorizDistortion;
        this.Cright.x += bend * this.kBC * avgHorizDistortion;
        this.Aleft.x -= bend * this.kAD * avgHorizDistortion;
        this.Dleft.x -= bend * this.kAD * avgHorizDistortion;
    }

    setRise() {
        const parameters = this._parameters;
        this.adjustRisePositions(parameters.bend, this.source.height);

        this.calculateArms();
        this.setControls();

        this.adjustRiseControlPoints(parameters.bend);
    }

    adjustRisePositions(bend, height) {
        this.A.y += bend * this.kAB * height;
        this.D.y += bend * this.kCD * height;
        this.B.y -= bend * this.kAB * height;
        this.C.y -= bend * this.kCD * height;
    }

    adjustRiseControlPoints(bend) {
        const sumADyBCy = this.ADy + this.BCy;
        this.Atop.y += bend * this.kAB * sumADyBCy;
        this.Btop.y -= bend * this.kAB * sumADyBCy;
        this.Dbottom.y += bend * this.kCD * sumADyBCy;
        this.Cbottom.y -= bend * this.kCD * sumADyBCy;
    }

    setWaveUpper() {
        this.calculateArms();
        this.setControls();

        const parameters = this._parameters;
        this.adjustWaveUpperControlPoints(parameters.bend);
    }

    adjustWaveUpperControlPoints(bend) {
        const sumADyBCy = 3 * bend * this.kAB * (this.ADy + this.BCy);
        this.Atop.y += sumADyBCy;
        this.Btop.y -= sumADyBCy;
    }

    setWaveLower() {
        this.calculateArms();
        this.setControls();

        const parameters = this._parameters;
        this.adjustWaveLowerControlPoints(parameters.bend);
    }

    adjustWaveLowerControlPoints(bend) {
        const sumADyBCy = 3 * bend * this.kCD * (this.ADy + this.BCy);
        this.Dbottom.y += sumADyBCy;
        this.Cbottom.y -= sumADyBCy;
    }

    calculateArms() {
        this.ABx = (this.B.x - this.A.x) / 3;
        this.ABy = (this.B.y - this.A.y) / 3;
        this.CDx = (this.C.x - this.D.x) / 3;
        this.CDy = (this.C.y - this.D.y) / 3;
        this.BCx = (this.C.x - this.B.x) / 3;
        this.BCy = (this.C.y - this.B.y) / 3;
        this.ADx = (this.D.x - this.A.x) / 3;
        this.ADy = (this.D.y - this.A.y) / 3;
    }

    getNewSegment(start, end, control1, control2) {
        const t1 = this.T1;
        const t1Cubed = t1 * t1 * t1;
        const oneMinusT1 = 1 - t1;
        const oneMinusT1Cubed = oneMinusT1 * oneMinusT1 * oneMinusT1;

        const t2 = this.T2;
        const t2Cubed = t2 * t2 * t2;
        const oneMinusT2 = 1 - t2;
        const oneMinusT2Cubed = oneMinusT2 * oneMinusT2 * oneMinusT2;

        const bezierFactor1 = 3 * t1 * oneMinusT1;
        const controlPoint1 = {};
        controlPoint1.x = (control1.x - oneMinusT1Cubed * start.x - t1Cubed * end.x) / bezierFactor1;
        controlPoint1.y = (control1.y - oneMinusT1Cubed * start.y - t1Cubed * end.y) / bezierFactor1;

        const bezierFactor2 = 3 * t2 * oneMinusT2;
        const controlPoint2 = {};
        controlPoint2.x = (control2.x - oneMinusT2Cubed * start.x - t2Cubed * end.x) / bezierFactor2;
        controlPoint2.y = (control2.y - oneMinusT2Cubed * start.y - t2Cubed * end.y) / bezierFactor2;

        const newControl = {};
        newControl.x = (t2 * controlPoint1.x - t1 * controlPoint2.x) / (oneMinusT1 * controlPoint2.x - oneMinusT2 * controlPoint1.x);
        newControl.y = (t2 * controlPoint1.y - t1 * controlPoint2.y) / (oneMinusT1 * controlPoint2.y - oneMinusT2 * controlPoint1.y);
        if (isNaN(newControl.x)) newControl.x = 0;
        if (isNaN(newControl.y)) newControl.y = 0;

        const newEnd = {};
        newEnd.x = controlPoint1.x / (oneMinusT1 * newControl.x + t1);
        newEnd.y = controlPoint1.y / (oneMinusT1 * newControl.y + t1);

        const finalControlPoint = new Point(newControl.x * newEnd.x, newControl.y * newEnd.y);
        const finalEndPoint = new Point(newEnd.x, newEnd.y);

        return [finalControlPoint.x, finalControlPoint.y, finalEndPoint.x, finalEndPoint.y, end.x, end.y];
    }

}