import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

/*
// renderImageOnCanvas.js
export const renderImageOnCanvas = (context, { x, y, width, height, data }) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Handle CORS
        img.onload = () => {
            context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            context.drawImage(img, x, y, width, height);
            resolve(); // Successful drawing on the canvas
        };
        img.onerror = reject;
        img.src = data; // Assign the image data source
    });
};
*/



export const ImageShape = ({
                               onSelect,
                               isSelected,
                               onChange,
                               element,
                               trRef,
                               template,
                               draggable,
                               onMouseOver,
                               onMouseOut,
                               isElementTouchedRef,
                               addToActiveNodes,
                               removeFromActiveNodes
                           }) => {
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const [image, status] = useImage(element.data,'Anonymous');
    const shapeRef = React.useRef(null); // Initialize the ref here

    React.useEffect(() => {
        if (isSelected && shapeRef.current) {
            if (trRef.current) {
                addToActiveNodes(shapeRef.current);
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        } else if (!isSelected && shapeRef.current) {
            removeFromActiveNodes(shapeRef.current);
        }
    }, [isSelected]);

    React.useEffect(() => {
        return () => {
            if (shapeRef.current) {
                removeFromActiveNodes(shapeRef.current);
            }
        }
    }, []);


    React.useEffect(() => {
        if (status === 'loaded' && image){
            setImageLoaded(true);
        } else if (status === 'loading'){
        } else if (status === 'error'){
            console.error("Error");
        }
    }, [status, image]);


    return (
        <>
            {imageLoaded && (
                <Image
                    id={element.id.toString()}
                    imageLoaded={imageLoaded}
                    name="element"
                    ref={shapeRef}
                    draggable={draggable}
                    image={image}
                    x={element.x}
                    y={element.y}
                    scaleX={element.scaleX}
                    scaleY={element.scaleY}
                    width={element.width}
                    height={element.height}
                    rotation={element.rotation}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onTouchEnd={(e) => {
                        if (shapeRef && shapeRef.current) {

                            const node = shapeRef.current;
                            onChange({
                                ...element,
                                x: node.x(),
                                y: node.y(),
                                width: node.width(),
                                height: node.height(),
                                rotation: node.rotation(),
                                scaleX: node.scaleX(),
                                scaleY: node.scaleY(),
                            });
                        }
                    }}
                    onDragEnd={(e) => {
                        if (shapeRef && shapeRef.current) {
                            const node = shapeRef.current;
                            onChange({
                                ...element,
                                x: node.x(),
                                y: node.y(),
                                width: node.width(),
                                height: node.height(),
                                rotation: node.rotation(),
                                scaleX: node.scaleX(),
                                scaleY: node.scaleY(),
                            });
                        }
                    }}
                    onDragMove={(e) => {
                    }}
                    onTransform={(e) => {
                    }}
                    onTransformEnd={(e) => {
                        if (shapeRef && shapeRef.current) {
                            const node = shapeRef.current;
                            const scaleX = node.scaleX();
                            const scaleY = node.scaleY();
                            node.scaleX(1);
                            node.scaleY(1);
                            onChange({
                                ...element,
                                x: node.x(),
                                y: node.y(),
                                width: Math.max(5, node.width() * scaleX),
                                height: Math.max(node.height() * scaleY),
                                rotation: node.rotation(),
                                scaleX: node.scaleX(),
                                scaleY: node.scaleY(),
                            });
                        }
                    }}
                    onClick={onSelect}
                    onTap={onSelect}

                />
            )}
        </>
    );



};

export default ImageShape;