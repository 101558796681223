import React from "react";
import {Button, Modal} from 'react-bootstrap';
import {PreviewStage} from "./preview_pane/preview_stage";

export const SimplePreviewer = (props) => {

    const [showMaximized, setShowMaximized] = React.useState(false);

    const handleCloseModal = () => {
        setShowMaximized(false);
        // show #collapsablePreviewPane when closed
        document.getElementById("PreviewPane").classList.remove("d-none");
    }

    const handleShowMaximized = () => {
        setShowMaximized(true);
        // hide #collapsablePreviewPane when maximized
        document.getElementById("PreviewPane").classList.add("d-none");
    }

    const previewPaneSize = window.innerWidth < 768 ? window.innerWidth * 0.8 : 150; // Use 80% of the screen width for mobile devices


    return (
        <div className={'row card card-hover-shadow mt-4'} id={'PreviewPane'}>

            {props.isMobileView ? null : (
            <div className="card-header text-center">
                <Button variant="outline-secondary" className={'float-start'} href={'#collapsablePreviewPane'}
                        data-bs-toggle={'collapse'} data-target="#collapsablePreviewPane" aria-expanded="true"
                        aria-controls="collapsablePreviewPane">
                    <i className="fas fa-window-minimize m-0 p-0"></i>
                </Button>


                <Button variant="outline-secondary" className="float-end" onClick={handleShowMaximized}>
                    <i className="fas fa-expand-arrows-alt m-0 p-0"></i>
                </Button>

                <Modal
                    size={'lg'}
                    show={showMaximized}
                    onHide={handleCloseModal}
                    aria-labelledby={'expanded-preview-modal'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id={'expanded-preview-modal'}>Preview</Modal.Title>
                    </Modal.Header>


                    <Modal.Body>
                        <div className="row justify-content-center mx-auto">

                            <div className="col-md-10">
                                {
                                    <PreviewStage
                                        width={600}
                                        height={600}
                                        elems={props.elems}
                                        templates={props.templates}
                                        selectedPreviewPaneTemplateId={props.selectedPreviewPaneTemplateId}
                                        setSelectedPreviewPaneTemplateId={props.setSelectedPreviewPaneTemplateId}
                                        layerRef={props.layerRef}
                                        previewPaneRef={props.previewPaneRef}
                                    />
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


            </div>
            )}
            <div className="card-body show" id="collapsablePreviewPane">
                <div className={'d-flex justify-content-center'}>
                    <PreviewStage
                        previewPaneRef={props.previewPaneRef}
                        width={previewPaneSize}
                        height={previewPaneSize}
                        elems={props.elems}
                        templates={props.templates}
                        selectedPreviewPaneTemplateId={props.selectedPreviewPaneTemplateId}
                        setSelectedPreviewPaneTemplateId={props.setSelectedPreviewPaneTemplateId}
                        layerRef={props.layerRef}
                        setProductPhotoURL={props.setProductPhotoURL}
                    />
                </div>
            </div>
        </div>
    )
}

export default SimplePreviewer;