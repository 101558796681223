// app/javascript/controllers/sign_out_controller.js
import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

export default class extends Controller {
    signOut(event) {
        event.preventDefault();

        // Make the AJAX call using axios
        axios.delete('/users/sign_out', {
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                'Content-Type': 'application/json', // Optional based on backend requirements
                'Accept': 'application/json', // Tell the server to send back JSON
            }
        })
            .then(() => {
                console.log('Sign out successful');
                // After signing out, you should redirect to the login page or root path.
                window.location = '/';
            })
            .catch((error) => {
                console.error('Sign out error:', error);
                alert('Error signing out. Please try again.');
            });
    }
}