import React, { useState } from 'react';
import { SimpleProductPicker } from "../SimpleProductPicker/SimpleProductPicker";
import { useDelayedUnmount } from '../../hooks/useDelayedUnmount';

export const ProductPickerSliderPanel = ({ isMobileView, isOpen, onClose, ...props }) => {
    const [isDragging, setIsDragging] = useState(false);
    const shouldRenderContent = useDelayedUnmount(isOpen, 800);

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleClose = () => {
        if (!isDragging) {
            onClose();
        }
    };

    return (
        <div className={`slide-panel ${isOpen ? 'open' : 'closed'}`}>
            
            {shouldRenderContent && (
                <SimpleProductPicker
                    setSelectedDesignId={props.setSelectedDesignId}
                    setExternalProductId={props.setExternalProductId}
                    setExternalVariantId={props.setExternalVariantId}
                    setSelectedColor={props.setSelectedColor}
                    productTypeId={props.productTypeId}
                    setProductTypeId={props.setProductTypeId}
                    loadProduct={props.loadProduct}
                    availableColors={props.availableColors}
                    categoryId={props.categoryId}
                    setCategoryId={props.setCategoryId}
                    isMobileView={isMobileView}
                    onClose={onClose}
                />
            )}
            <div className="slide-panel__close-area" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onClick={handleClose}>
                <div className="slide-panel__close-text">Tap here to close</div>
            </div>
        </div>
    );
};
