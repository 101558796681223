// hooks/useSnapping.js

import React from 'react';
import { useCallback } from 'react';

const GUIDELINE_OFFSET = 5;

export const useSnapping = (stageRef, layerRef) => {
    const getLineGuideStops = (skipShape) => {
        const stage = skipShape.getStage();
        if (!stage) return { vertical: [], horizontal: [] };

        // we can snap to stage borders and the center of the stage
        const vertical = [0, stage.width() / 2, stage.width()];
        const horizontal = [0, stage.height() / 2, stage.height()];

        // and we snap over edges and center of each object on the canvas
        stage.find(".element").forEach((guideItem) => {
            if (guideItem === skipShape) {
                return;
            }
            const box = guideItem.getClientRect();
            // and we can snap to all edges of shapes
            vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
            horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
        });
        return {
            vertical,
            horizontal
        };
    };


    const getObjectSnappingEdges = React.useCallback((node) => {
        const box = node.getClientRect();
        const absPos = node.absolutePosition();

        return {
            vertical: [
                {
                    guide: Math.round(box.x),
                    offset: Math.round(absPos.x - box.x),
                    snap: "start"
                },
                {
                    guide: Math.round(box.x + box.width / 2),
                    offset: Math.round(absPos.x - box.x - box.width / 2),
                    snap: "center"
                },
                {
                    guide: Math.round(box.x + box.width),
                    offset: Math.round(absPos.x - box.x - box.width),
                    snap: "end"
                }
            ],
            horizontal: [
                {
                    guide: Math.round(box.y),
                    offset: Math.round(absPos.y - box.y),
                    snap: "start"
                },
                {
                    guide: Math.round(box.y + box.height / 2),
                    offset: Math.round(absPos.y - box.y - box.height / 2),
                    snap: "center"
                },
                {
                    guide: Math.round(box.y + box.height),
                    offset: Math.round(absPos.y - box.y - box.height),
                    snap: "end"
                }
            ]
        };
    }, []);


    const getGuides = React.useCallback((lineGuideStops, itemBounds) => {
        const resultV = [];
        const resultH = [];
        const GUIDELINE_OFFSET = 5;

        lineGuideStops.vertical.forEach((lineGuide) => {
            itemBounds.vertical.forEach((itemBound) => {
                const diff = Math.abs(lineGuide - itemBound.guide);
                if (diff < GUIDELINE_OFFSET) {
                    resultV.push({
                        lineGuide: lineGuide,
                        diff: diff,
                        snap: itemBound.snap,
                        offset: itemBound.offset
                    });
                }
            });
        });

        lineGuideStops.horizontal.forEach((lineGuide) => {
            itemBounds.horizontal.forEach((itemBound) => {
                const diff = Math.abs(lineGuide - itemBound.guide);
                if (diff < GUIDELINE_OFFSET) {
                    resultH.push({
                        lineGuide: lineGuide,
                        diff: diff,
                        snap: itemBound.snap,
                        offset: itemBound.offset
                    });
                }
            });
        });

        const guides = [];

        const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
        const minH = resultH.sort((a, b) => a.diff - b.diff)[0];

        if (minV) {
            guides.push({
                lineGuide: minV.lineGuide,
                offset: minV.offset,
                orientation: "V",
                snap: minV.snap
            });
        }

        if (minH) {
            guides.push({
                lineGuide: minH.lineGuide,
                offset: minH.offset,
                orientation: "H",
                snap: minH.snap
            });
        }

        return guides;
    }, []);


    const drawGuides = React.useCallback((guides, layer) => {
        guides.forEach((lg) => {
            let lineConfig = ({
                points: [0, -6000, 0, 6000],
                stroke: "red",
                strokeWidth: 2,
                name: "guid-line",
                dash: [10, 5],
                shadowColor: 'red',
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowOpacity: 0.5
            });

            if (lg.orientation === "H") {
                lineConfig.points = [-6000, 0, 6000, 0];
                const line = new Konva.Line(lineConfig);
                layer.add(line);
                line.absolutePosition({
                    x: 0,
                    y: lg.lineGuide
                });
            } else if (lg.orientation === "V") {
                lineConfig.points = [0, -6000, 0, 6000];
                const line = new Konva.Line(lineConfig);
                layer.add(line);
                line.absolutePosition({
                    x: lg.lineGuide,
                    y: 0
                });
            }
        });
    }, []);


    const onDragMove = useCallback((e) => {
        const layer = layerRef.current;

        // clear all previous lines on the screen
        if (layer) {
            layer.find(".guid-line").forEach(l => l.destroy());
        }

        // find possible snapping lines
        const lineGuideStops = getLineGuideStops(e.target);
        // find snapping points of current object
        const itemBounds = getObjectSnappingEdges(e.target);

        // now find where can we snap the current object
        const guides = getGuides(lineGuideStops, itemBounds);

        // do nothing if no snapping
        if (!guides.length) {
            return;
        }

        drawGuides(guides, layer);

        const absPos = e.target.absolutePosition();
        // now force object position
        guides.forEach(lg => {
            switch (lg.snap) {
                case "start":
                    if (lg.orientation === "V") {
                        absPos.x = lg.lineGuide + lg.offset;
                    } else if (lg.orientation === "H") {
                        absPos.y = lg.lineGuide + lg.offset;
                    }
                    break;
                case "center":
                    if (lg.orientation === "V") {
                        absPos.x = lg.lineGuide + lg.offset;
                    } else if (lg.orientation === "H") {
                        absPos.y = lg.lineGuide + lg.offset;
                    }
                    break;
                case "end":
                    if (lg.orientation === "V") {
                        absPos.x = lg.lineGuide + lg.offset;
                    } else if (lg.orientation === "H") {
                        absPos.y = lg.lineGuide + lg.offset;
                    }
                    break;
                default:
                    break;
            }
        });
        e.target.absolutePosition(absPos);
    }, [drawGuides, getGuides, getObjectSnappingEdges]);


    const onDragEnd = useCallback((e) => {
        const layer = layerRef.current;
        layer.find(".guid-line").forEach((l) => l.destroy());
    }, []);

    return { onDragMove, onDragEnd };
}
