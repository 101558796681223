// app/javascript/controllers/redirect_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form"]

    connect() {
        console.log("Stimulus controller connected");
        setTimeout(() => {
            this.submitForm();
        }, 1000); // 1 second delay
    }

    submitForm() {
        console.log("Submitting the redirect form via Stimulus.");
        this.formTarget.submit();
    }
}
