import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "row"]
  static values = {
    url: String,
    collectionId: Number
  }

  toggleProduct(event) {
    const checkbox = event.target
    const row = checkbox.closest('tr')
    const productId = checkbox.value
    const method = checkbox.checked ? 'POST' : 'DELETE'
    
    row.classList.add('updating')
    
    fetch(`/dashboard/collections/${this.collectionIdValue}/toggle_product`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        product_id: productId,
        toggle_action: method
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        row.classList.remove('updating')
        if (checkbox.checked) {
          row.classList.add('added-to-collection')
          setTimeout(() => {
            row.classList.remove('added-to-collection')
            row.classList.add('table-success')
          }, 1000)
        } else {
          row.classList.add('removed-from-collection')
          setTimeout(() => {
            row.classList.remove('removed-from-collection')
            row.classList.remove('table-success')
          }, 1000)
        }
      } else {
        checkbox.checked = !checkbox.checked
        console.error('Failed to update collection')
      }
    })
    .finally(() => {
      row.classList.remove('updating')
    })
  }
} 