import { Path } from '../components/TextWarp/Path';

export const applyWarpToPath = (pathData, options) => {
    const {
        type = '',
        bend = 0,
        distortV = 0,
        distortH = 0
    } = options;

    let p = new Path(pathData);
    p.warp({
        type,
        bend: (bend / 100) || 0,
        distortV: (distortV / 100) || 0,
        distortH: (distortH / 100) || 0,
    });

    return p.output();
};

export const measureWarpedPath = (pathData) => {
    // Create a temporary SVG element for measurement
    const tempSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const tempPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
    tempPath.setAttribute('d', pathData);
    tempSvg.appendChild(tempPath);
    document.body.appendChild(tempSvg);

    // Measure the dimensions
    const bbox = tempPath.getBBox();
    document.body.removeChild(tempSvg);

    return {
        x: bbox.x,
        y: bbox.y,
        width: bbox.width,
        height: bbox.height,
        viewBox: `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
    };
}; 