import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "preview"]

  preview() {
    const input = this.inputTarget
    const preview = this.previewTarget
    
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      
      reader.onload = (e) => {
        preview.innerHTML = `<img src="${e.target.result}" class="img-thumbnail" style="max-height: 200px">`
      }
      
      reader.readAsDataURL(input.files[0])
    }
  }
}