import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static values = {
    token: String
  }

  connect() {
    if (this.hasTokenValue) {
      this.updateCsrfToken(this.tokenValue)
    }
    
    document.addEventListener("turbo:before-fetch-request", this.handleBeforeFetchRequest)
    document.addEventListener("turbo:render", this.handleTurboRender)
    document.addEventListener("auth:token-update", this.handleAuthTokenUpdate)
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-request", this.handleBeforeFetchRequest)
    document.removeEventListener("turbo:render", this.handleTurboRender)
    document.removeEventListener("auth:token-update", this.handleAuthTokenUpdate)
  }

  updateCsrfToken(token) {
    console.log('Updating CSRF token:', token)
    if (!token) return
    
    const cleanToken = token.trim()
    
    // Update meta tag
    const metaTag = document.querySelector('meta[name="csrf-token"]')
    if (metaTag) {
      metaTag.setAttribute('content', cleanToken)
    }
  
    // Update axios headers - ensure only one token is set
    axios.defaults.headers.common['X-CSRF-Token'] = cleanToken
    // Remove any legacy header
    delete axios.defaults.headers.common['X-CSRF-TOKEN']
  }
  
  handleBeforeFetchRequest = (event) => {
    const token = this.tokenValue || document.querySelector('meta[name="csrf-token"]')?.content
    if (token) {
      const cleanToken = token.trim()
      const headers = event.detail.fetchOptions.headers || {}
      if (!headers['X-CSRF-Token']) {
        headers['X-CSRF-Token'] = cleanToken
      }
      event.detail.fetchOptions.headers = headers
    }
  }

  handleAuthTokenUpdate = (event) => {
    if (event.detail?.token) {
      this.tokenValue = event.detail.token
      this.updateCsrfToken(event.detail.token)
    }
  }

  handleTurboRender = () => {
    const newToken = document.querySelector('meta[name="csrf-token"]')?.content
    if (newToken) {
      this.updateCsrfToken(newToken)
    }
  }
}
