import React from 'react';
import { Dropdown } from "react-bootstrap";

export const useTextTransforms = (
    selectedItem, elems, onElementsChange, selectedId, selectedElement
) => {

    //console.log('Elems at useTextTransforms:', elems);
    // State and functions for each text transformation
    const serializeSvgToString = (svgElement) => {
        const serializer = new XMLSerializer();
        return serializer.serializeToString(svgElement);
    };

    const convertSvgToDataUrl = (svgString) => {
        return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString);
    };

    const updateCanvasWithSvg = (svgDataUrl, selectedElement, onElementsChange, elems, selectedId) => {
        const imageObj = new Image();
        imageObj.onload = () => {
            console.log('Elems at updateCanvasWithSvg:', elems);

            const selectedElem = elems.find(elem => elem.id === selectedId);
            if (!selectedElem) {
                console.error('Selected element not found in elems array');
                return;
            }

            const transformedElement = {
                id: `transformed${Date.now()}`,
                type: 'image',
                placement: selectedElem.placement,
                image: imageObj,
                x: elems.find(elem => elem.id === selectedId).x, // Use original element's position
                y: elems.find(elem => elem.id === selectedId).y,
                width: imageObj.naturalWidth, // Or some calculated width
                height: imageObj.naturalHeight, // Or some calculated height
                data: svgDataUrl,
                format: 'svg',
                svgXML: svgDataUrl, // If you store the raw SVG XML
                original: '', // The original SVG file path, if applicable
//                svgFills: [], // Any SVG fill details, if applicable
                scaleX: 1,
                scaleY: 1,
                // Include other necessary properties
            };


            // Add the new element to the 'elems' array
            const updatedElems = [...elems, transformedElement];
            onElementsChange(updatedElems);
        };
        imageObj.src = svgDataUrl;
    };





    const applyWave = () => {
        const konvaTextElement = selectedElement;
        if (!konvaTextElement) {
            console.error('Konva Text Element not found');
            return;
        }

        const textWidth = konvaTextElement.getTextWidth();
        const textHeight = konvaTextElement.getTextHeight();
        const yOffset = selectedItem.fontSize; // Base y-offset for the text

        const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgElement.setAttribute("width", textWidth);
        svgElement.setAttribute("height", textHeight + yOffset); // Add yOffset to the height for wave amplitude
        svgElement.setAttribute("viewBox", `0 0 ${textWidth} ${textHeight + yOffset}`);

        const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
        textElement.setAttribute("font-family", selectedItem.fontFamily);
        textElement.setAttribute("font-size", selectedItem.fontSize);
        textElement.textContent = "";

        // Create the wave effect
        let waveAmplitude = 100; // Amplitude of the wave
        let frequency = 2 * Math.PI / selectedItem.text.length; // Frequency of the wave

        for (let i = 0; i < selectedItem.text.length; i++) {
            // Calculate y-offset for each letter based on the sine wave
            let yWaveOffset = yOffset + waveAmplitude * Math.sin(frequency * i);

            // Apply the transformation to each letter
            let tspan = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
            tspan.setAttribute("x", i * (textWidth / selectedItem.text.length));
            tspan.setAttribute("y", yWaveOffset);
            tspan.textContent = selectedItem.text[i];

            textElement.appendChild(tspan);
        }

        svgElement.appendChild(textElement);

        // Serialize the SVG to a Data URL and update the canvas
        const svgDataUrl = convertSvgToDataUrl(serializeSvgToString(svgElement));
        updateCanvasWithSvg(svgDataUrl, selectedElement, onElementsChange, elems, selectedId);
    };






    const applyCurve = () => { /* ... */ };
    const applyRise = () => { /* ... */ };
    const resetTransform = () => { /* ... */ };

    return { applyWave, applyCurve, applyRise, resetTransform };
};



export const TextTransformsControlsContent = ({
                                                  isMobileView,
                                                  selectedItem,
                                                  onElementsChange,
                                                  elems,
                                                  selectedId,
                                                  selectedElement
}) => {
    const { applyWave, applyCurve, applyRise, resetTransform } = useTextTransforms(selectedItem, elems, onElementsChange, selectedId, selectedElement);

    if (!isMobileView) {
        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle>Transforms</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={applyWave}>Wave</Dropdown.Item>
                        <Dropdown.Item onClick={applyCurve}>Curve</Dropdown.Item>
                        <Dropdown.Item onClick={applyRise}>Rise</Dropdown.Item>
                        <Dropdown.Item onClick={resetTransform}>Reset</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }
    // ... existing mobile view code ...
};





