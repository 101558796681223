import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form"]

    connect() {
        console.log("Logout redirect controller connected")
        setTimeout(() => {
            this.submitForm()
        }, 1000)
    }

    submitForm() {
        console.log("Submitting logout redirect form")
        this.formTarget.submit()
    }
}
