import React, {useState} from 'react';
import { PropertiesPanel } from "../SimpleElementOptions/PropertiesPanel";
import { SliderPanel } from "./SliderPanel";
import { ProductPickerSliderPanel } from "./ProductPickerSliderPanel";
import { PreviewerSliderPanel } from "./PreviewerSliderPanel";
import { useSelectionContext } from "../../contexts/SelectionContext";

export const ContextualPanel = ({
                                    stageRef,
                                    printAreaRef,
                                    printedAreaRef,
                                    placementRef,
                                    template,
                                    //selectedId,
                                    //selectedItem,
                                    //selectedElement,
                                    elems,
                                    setElems,
                                    onElementsChange,
                                    trRef,
                                    ...props
                                }) => {

    const { selectedId,
        selectedElement,
        selectedItem,
    } = useSelectionContext();

    const [isAddSliderOpen, setIsAddSliderOpen] = React.useState(false);
    const [isProductPickerSliderOpen, setIsProductPickerSliderOpen] = React.useState(false);
    const [isPreviewerSliderOpen, setIsPreviewerSliderOpen] = React.useState(false);
    const isAnySliderOpen = isAddSliderOpen || isProductPickerSliderOpen || isPreviewerSliderOpen;

    // The following unloads the properties panel when the user opens a slider panel
    // It helps in Safari where the Canvas overlaps the slider panel if the properties panel is open
    // We may want to revisit this but for now it works and I don't see a downside.
    const [isPropertiesPanelVisible, setIsPropertiesPanelVisible] = useState(true);

    const isUserGuest = props.isGuest === "true" ? true : false;
    const [isAuthenticated, setIsAuthenticated] = useState(!isUserGuest);

    React.useEffect(() => {
        const handleUserAuthentication = () => {
            setIsAuthenticated(true);
        };

        window.addEventListener('userAuthenticated', handleUserAuthentication);

        // Cleanup: Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('userAuthenticated', handleUserAuthentication);
        };
    }, []);

    const mobileIconStyles = {
        mobile: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80px',
            backgroundColor: 'none',
            color: '#000',
            fontSize: '14px',
            cursor: 'pointer',
        },
        desktop: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            height: '50px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            fontSize: '15px',
            cursor: 'pointer',
            padding: '5px'
        },
        icon: {
            width: '40px',
            height: '40px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        }
    };

    const handleAddClick = () => {
        setIsAddSliderOpen(true);
        setIsPropertiesPanelVisible(false);
    };

    const handleCloseSlideUp = () => {
        setIsAddSliderOpen(false);
        setIsPropertiesPanelVisible(true);
    };

    const handleProductPickerClick = () => {
        setIsProductPickerSliderOpen(true);
        setIsPropertiesPanelVisible(false);
    };

    const closeProductPickerSlider = () => {
        setIsProductPickerSliderOpen(false);
        setIsPropertiesPanelVisible(true);
    };

    const handlePreviewerClick = () => {
        setIsPreviewerSliderOpen(true);
        setIsPropertiesPanelVisible(false);
    };

    const closePreviewerSlider = () => {
        setIsPreviewerSliderOpen(false);
        setIsPropertiesPanelVisible(true);
    };

    const handleScroll = (event) => {
        const container = event.target;
        if (event.deltaY > 0) {
            container.scrollLeft += 20;
        } else {
            container.scrollLeft -= 20;
        }
    };

    return (
        <div className="contextual-panel" onWheel={handleScroll}>
            <div className={`contextual-panel__container ${isAnySliderOpen ? 'hidden' : ''}`}>
                <div className="contextual-panel__button-group">
                    <button 
                            className="contextual-panel__button" 
                            onClick={handleAddClick}
                        >
                            <i className="fas fa-plus contextual-panel__button-icon"></i>
                            <span className="contextual-panel__button-label">Add</span>
                        </button>
                        <button 
                            className="contextual-panel__button" 
                            onClick={handleProductPickerClick}
                        >
                            <i className="fas fa-tshirt contextual-panel__button-icon"></i>
                            <span className="contextual-panel__button-label">Products</span>
                        </button>
                        <button 
                            className="contextual-panel__button" 
                            onClick={handlePreviewerClick}
                        >
                            <i className="fas fa-eye contextual-panel__button-icon"></i>
                            <span className="contextual-panel__button-label">Preview</span>
                        </button>
                    </div>
                    
                    {isPropertiesPanelVisible && selectedId && (
                        <div className="contextual-panel__properties">
                            <PropertiesPanel
                                stageRef={stageRef}
                                printAreaRef={printAreaRef}
                                placementRef={placementRef}
                                template={template}
                                selectedId={selectedId}
                                selectedItem={selectedItem}
                                selectedElement={selectedElement}
                                elems={elems}
                                setElems={setElems}
                                onElementsChange={onElementsChange}
                                isMobileView={true}
                                trRef={trRef}
                            />
                        </div>
                    )}
            </div>

                <SliderPanel
                    isMobileView={true}
                    isOpen={isAddSliderOpen}
                    onClose={handleCloseSlideUp}
                    mobileIconStyles={mobileIconStyles}
                    isAuthenticated={isAuthenticated}
                    printAreaRef={printAreaRef}
                    stageRef={stageRef}
                    placementRef={placementRef}
                    elems={elems}
                    setElems={setElems}
                    selectedElement={selectedElement}
                    printedAreaRef={printedAreaRef}
                    template={template}
                    selectedId={selectedId}
                    selectedItem={selectedItem}
                    onElementsChange={onElementsChange}
                    trRef={trRef}
                    {...props}
                />
        
            <ProductPickerSliderPanel
                isMobileView={true}
                isOpen={isProductPickerSliderOpen}
                onClose={closeProductPickerSlider}
                mobileIconStyles={mobileIconStyles}
                setSelectedDesignId={props.setSelectedDesignId}
                setExternalProductId={props.setExternalProductId}
                setExternalVariantId={props.setExternalVariantId}
                setSelectedColor={props.setSelectedColor}
                productTypeId={props.productTypeId}
                setProductTypeId={props.setProductTypeId}
                loadProduct={props.loadProduct}
                availableColors={props.availableColors}
                categoryId={props.categoryId}
                setCategoryId={props.setCategoryId}
            />
            <PreviewerSliderPanel
                isMobileView={true}
                isOpen={isPreviewerSliderOpen}
                onClose={closePreviewerSlider}
                product_id={props.product_id}
                previewStageRef={props.previewStageRef}
                previewPaneRef={props.previewPaneRef}
                elems={elems}
                templates={props.templates}
                selectedPreviewPaneTemplateId={props.selectedPreviewPaneTemplateId}
                setSelectedPreviewPaneTemplateId={props.setSelectedPreviewPaneTemplateId}
                setProductPhotoURLs={props.setProductPhotoURLs}
            />

        </div>
);
}
