import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stripe-connect"
export default class extends Controller {
  static values = { publicKey: String, clientSecret: String }
  stripe = Stripe(this.publicKeyValue)

  async connect() {
    const { error } = await this.stripe.confirmAccountSetup(
      this.clientSecretValue,
      {
        elements: {
          accountOnboarding: {
            container: '#stripe-onboarding'
          }
        }
      }
    )

    if (error) {
      console.error('Error:', error)
    }
  }
}
