import Circ from '../elements/element-circle';
import Rectangle from '../elements/element-rectangle';
import ImageShape from '../elements/element-image';
import TextShape from '../elements/element-text';
import PathShape from '../elements/element-path';
import FreehandDrawingShape from '../elements/element-freehand';
import PatternShape from '../elements/element-pattern';
//import DottedBrushShape from '../elements/element-dottedBrush';

// COMPONENTS_MAP.js
const COMPONENTS_MAP = {
    'circle': Circ,
    'rectangle': Rectangle,
    'image': ImageShape,
    //'text': TextShape,
    'path': PathShape, //Handles Text
    'freehand': FreehandDrawingShape,
    'pattern': PatternShape,
    //'dotted': DottedBrushShape,
};
export default COMPONENTS_MAP;
