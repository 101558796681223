import React from 'react';
import { Path } from 'react-konva';

// This is the Text Element
export const PathShape = ({
                              onSelect,
                              isSelected,
                              onChange,
                              element,
                              trRef,
                              draggable,
                              onMouseOver,
                              onMouseOut,
                              isElementTouchedRef,
                              addToActiveNodes,
                              removeFromActiveNodes
                          }) => {

    const shapeRef = React.useRef(null);
    const [baseDimensions, setBaseDimensions] = React.useState({ width: 100, height: 100 });
    const PADDING_FACTOR = 2.5; // 250% extra space around the element

    // Color interpolation helper
    const interpolateColor = (startColor, endColor, progress) => {
        // Convert hex to RGB
        const start = {
            r: parseInt(startColor.slice(1,3), 16),
            g: parseInt(startColor.slice(3,5), 16),
            b: parseInt(startColor.slice(5,7), 16)
        };
        
        const end = {
            r: parseInt(endColor.slice(1,3), 16),
            g: parseInt(endColor.slice(3,5), 16),
            b: parseInt(endColor.slice(5,7), 16)
        };
        
        // Interpolate
        const r = Math.round(start.r + (end.r - start.r) * progress);
        const g = Math.round(start.g + (end.g - start.g) * progress);
        const b = Math.round(start.b + (end.b - start.b) * progress);
        
        // Convert back to hex
        return `#${r.toString(16).padStart(2,'0')}${g.toString(16).padStart(2,'0')}${b.toString(16).padStart(2,'0')}`;
    };

    React.useEffect(() => {
        if (isSelected && shapeRef.current) {
            if (trRef.current) {
                addToActiveNodes(shapeRef.current);
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        } else if (!isSelected && shapeRef.current) {
           if (removeFromActiveNodes) removeFromActiveNodes(shapeRef.current);
        }
    }, [isSelected]);

    React.useEffect(() => {
        return () => {
            if (shapeRef.current) {
                removeFromActiveNodes(shapeRef.current);
            }
        }
    }, []);

    React.useEffect(() => {
        if (shapeRef.current) {
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            node.scaleX(1);
            node.scaleY(1);
            
            const box = node.getClientRect();
            setBaseDimensions({
                width: Math.max(box.width, 1),
                height: Math.max(box.height, 1)
            });
            
            node.scaleX(scaleX);
            node.scaleY(scaleY);
        }
    }, [element.data]);

    // Calculate padded dimensions for gradient
    const paddedWidth = baseDimensions.width * PADDING_FACTOR;
    const paddedHeight = baseDimensions.height * PADDING_FACTOR;
    const offsetX = (paddedWidth - baseDimensions.width) / 2;
    const offsetY = (paddedHeight - baseDimensions.height) / 2;

    return (
        <>
            {/* 3D Effect Layers First (Bottom) */}
            {element.threeDPaths?.map((pathData, index) => {
                if (index === 0) return null;
                
                const totalLayers = element.threeDPaths.length;
                // Calculate layer position based on density
                const layerProgress = index / (totalLayers - 1);
                
                // Calculate interpolated color if gradient is enabled
                let layerColor = element.fill;  // Default to element fill for first visible layer
                if (index > 1) {  // For subsequent layers
                    if (element.threeDEffects?.sideGradient?.enabled) {
                        const startColor = element.threeDEffects.sideGradient.startColor || '#000000';
                        const endColor = element.threeDEffects.sideGradient.endColor || '#333333';
                        // Normalize progress for remaining layers (index > 1)
                        const colorProgress = (index - 1) / (totalLayers - 2);  // -2 accounts for layers 0 and 1
                        layerColor = interpolateColor(startColor, endColor, colorProgress);
                    } else {
                        layerColor = element.threeDEffects?.sideColor || '#000000';
                    }
                }

                return (
                    <Path
                        key={`3d-layer-${index}`}
                        data={pathData}
                        x={element.x + (layerProgress * (element.threeDEffects?.depth || 0) * (element.threeDEffects?.layerOffset?.x || 0))}
                        y={element.y + (layerProgress * (element.threeDEffects?.depth || 0) * (element.threeDEffects?.layerOffset?.y || 1))}
                        scaleX={element.scaleX}
                        scaleY={element.scaleY}
                        rotation={element.rotation}
                        fill={layerColor}
                        opacity={1 - (layerProgress * 0.1)}
                        listening={false}
                    />
                );
            })}

            {/* Strokes (Middle) */}
            {element.strokes?.map((strokeData, index) => {
                if (!strokeData.enabled) return null;
                return (
                    <Path
                        key={strokeData.id}
                        data={element.data}
                        x={element.x}
                        y={element.y}
                        scaleX={element.scaleX}
                        scaleY={element.scaleY}
                        rotation={element.rotation}
                        fill="transparent"
                        stroke={strokeData.color}
                        strokeWidth={strokeData.width}
                        offsetX={strokeData.offset}
                        offsetY={strokeData.offset}
                        dash={Array.isArray(strokeData.dashArray) ? strokeData.dashArray : strokeData.dashArray?.split(',').map(Number)}
                        dashEnabled={true}
                        listening={false}
                    />
                );
            })}

            {/* Main Path (Top) */}
            <Path
                ref={shapeRef}
                baseDimensions={baseDimensions}
                bendValue={element.bendValue}
                data={element.data}
                distortionType={element.distortionType}
                draggable={draggable}
                fill={element.fill || 'black'}
                fillLinearGradientColorStops={element.fillLinearGradientColorStops}
                fillLinearGradientEndPoint={element.fillLinearGradientEndPoint}
                fillLinearGradientStartPoint={element.fillLinearGradientStartPoint}
                fillPriority={element.fillPriority}
                fillRadialGradientColorStops={element.fillRadialGradientColorStops}
                fillRadialGradientEndPoint={element.fillRadialGradientEndPoint}
                fillRadialGradientEndRadius={element.fillRadialGradientEndRadius}
                fillRadialGradientStartPoint={element.fillRadialGradientStartPoint}
                fillRadialGradientStartRadius={element.fillRadialGradientStartRadius}
                fontFamily={element.fontFamily}
                fontSize={element.fontSize}
                gradientAngle={element.gradientAngle}
                gradientStops={element.gradientStops}
                gradientType={element.gradientType}
                height={element.height}
                hitStrokeWidth={0}
                horizontalDistort={element.horizontalDistort}
                id={element.id.toString()}
                linearGradientX={element.linearGradientX}
                linearGradientY={element.linearGradientY}
                name="element"
                originalData={element.originalData}
                originalHeight={element.originalHeight}
                originalWidth={element.originalWidth}
                placement={element.placement}
                radialGradientSize={element.radialGradientSize}
                radialGradientX={element.radialGradientX}
                radialGradientY={element.radialGradientY}
                rotation={element.rotation}
                scaleX={element.scaleX}
                scaleY={element.scaleY}
                stroke={element.stroke}
                strokeWidth={element.strokeWidth}
                textValue={element.textValue}
                threeDEffects={element.threeDEffects}
                threeDPaths={element.threeDPaths}
                thumbnail={element.thumbnail}
                type={element.type}
                verticalDistort={element.verticalDistort}
                width={element.width}
                x={element.x}
                y={element.y}
                onClick={onSelect}
                onTap={onSelect}
                // Events
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onDragEnd={(e) => {
                    const node = shapeRef.current;
                    onChange({
                        ...element,
                        x: node.x(),
                        y: node.y()
                    });
                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    onChange({
                        ...element,
                        x: node.x(),
                        y: node.y(),
                        rotation: node.rotation(),
                        scaleX: node.scaleX(),
                        scaleY: node.scaleY(),
                        width: node.width(),
                        height: node.height(),
                        fillRadialGradientStartRadius: element.fillRadialGradientStartRadius,
                        fillRadialGradientEndRadius: element.fillRadialGradientEndRadius,
                        fillRadialGradientStartPoint: element.fillRadialGradientStartPoint,
                        fillRadialGradientEndPoint: element.fillRadialGradientEndPoint,
                        fillRadialGradientColorStops: element.fillRadialGradientColorStops,
                        fillLinearGradientStartPoint: element.fillLinearGradientStartPoint,
                        fillLinearGradientEndPoint: element.fillLinearGradientEndPoint,
                        fillLinearGradientColorStops: element.fillLinearGradientColorStops,
                        gradientType: element.gradientType,
                        gradientAngle: element.gradientAngle,
                        gradientStops: element.gradientStops,
                        radialGradientSize: element.radialGradientSize,
                        radialGradientX: element.radialGradientX,
                        radialGradientY: element.radialGradientY,
                        radialGradientShape: element.radialGradientShape
                    });
                }}
            />
        </>
    );
};

export const generatePathShapeThumbnail = async (element) => {
    const thumbnailWidth = 80;
    const thumbnailHeight = 80;

    return new Promise((resolve) => {
        // Create a canvas for drawing
        const canvas = document.createElement('canvas');
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;
        const context = canvas.getContext('2d');

        // Clear the canvas and set up styles
        context.clearRect(0, 0, thumbnailWidth, thumbnailHeight);
        context.fillStyle = 'black';
        context.font = 'bold 40px Arial'; // Adjust the font and size as necessary
        context.textAlign = 'center';
        context.textBaseline = 'middle';

        // Draw the "T" symbol centered in the canvas
        context.fillText('T', thumbnailWidth / 2, thumbnailHeight / 2);

        // Draw the `textValue` beneath it
        context.font = '12px Arial';
        context.fillText(element.textValue || '', thumbnailWidth / 2, thumbnailHeight - 10);

        resolve(canvas.toDataURL()); // Return the data URL as the thumbnail
    });
};


export default PathShape;
