import React from 'react';
import { Rect, Group, Transformer } from 'react-konva';

export const renderRectangleOnCanvas = (context, { x, y, width, height, fill, stroke, strokeWidth }) => {

}

export const Rectangle = ({
                              shapeProps,
                              onSelect,
                              getKey,
                              isSelected,
                              onChange,
                              element,
                              //layerRef,
                              trRef,
                              template,
                              //setSelectedElement,
                              //setElementColor
                              draggable,
                              onMouseOver,
                              onMouseOut,
                              onTouchStart,
                              onTouchMove,
                              onTouchEnd,
                              onTap
                          }) =>
{

    //let layer = layerRef.current;
    let designView = document.querySelector("#DesignView");
    let containerWidth = designView.offsetWidth;
    const shapeRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
        }
    }, [isSelected]);

    return (
        <Rect
            id={element.id.toString()}
            ref={shapeRef}
            name="element"
            x={element.x}
            y={element.y}
            width={element.width}
            height={element.height}
            rotation={element.rotation}
            stroke={element.stroke}
            strokeWidth={element.strokeWidth}
            fill={element.fill}
            onClick={onSelect}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onTap={onTap}
            {...shapeProps}
            draggable={draggable}
            onDragEnd={(e) => {
                onChange({
                    ...shapeProps,
                    x: e.target.x(),
                    y: e.target.y()
                });
            }}
            onTransformEnd={(e) => {
                //trRef.current.getLayer().batchDraw();
                // transformer is changing scale of the node
                // and NOT its width or height
                // but in the store we have only width and height
                // to match the data better we will reset scale on transform end
                const node = shapeRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();

                // we will reset it back
                node.scaleX(1);
                node.scaleY(1);
                onChange({
                    ...element,
                    x: node.x(),
                    y: node.y(),
                    width: Math.max(5, node.width() * scaleX),
                    height: Math.max(node.height() * scaleY),
                    rotation: node.rotation()
                });
            }}
        />
    );
};

export default Rectangle;