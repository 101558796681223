import { Point } from './Point';
import { Rectangle } from './Rectangle';
import { Warp } from './Warp';

// const ARC = "A"; Arc conversion is unsupported
const LINE = "L";
const MOVE = "M";
const CURVE_CUBIC = "C";
const CURVE_QUADRATIC = "Q";
const CLOSE = "Z";

export class Path {
    constructor(pathString) {
        this.data = [];
        this.commands = [];
        this.initialPoint = null; // Add this line
        this.parseString(pathString);
    }

    parseString(pathString) {
        let commandChars = [];
        let numbers = [];
        let dataIndex = 0;  // Initialize dataIndex

        pathString.match(/[A-Za-z][0-9\.\s\,\-]+\d/g).forEach(segment => {
            let command = segment[0];
            let args = segment.match(/[\d\.\-]+/g).map(parseFloat);

            commandChars.push(command);
            numbers.push(...args);

            // Check and set initial point when "M" command is encountered
            if (command === "M" && !this.initialPoint) {
                this.initialPoint = new Point(args[0], args[1]);
            }

            dataIndex += args.length;  // Update dataIndex
        });

        this.commands = commandChars;
        this.data = numbers;
    }

    scale(factorX, factorY = 0) {
        if (factorY === 0) {
            factorY = factorX;
        }

        for (let i = 0; i < this.data.length; i += 2) {
            this.data[i] *= factorX;
            this.data[i + 1] *= factorY;
        }
    }

    warp(warpParameters) {
        const warper = new Warp(warpParameters, this.calcRectangle());
        let newCoordinates = [];
        let currentPoint = new Point();  // Assuming Point is a class with x, y properties
        let dataIndex = 0;

        for (let commandIndex = 0; commandIndex < this.commands.length; commandIndex++) {
            let command = this.commands[commandIndex];
            switch (command) {
                case "L":
                    this.commands[commandIndex] = 'C';

                    this.convertLineToCubic(commandIndex, dataIndex, currentPoint, newCoordinates, warper);
                    dataIndex += 2;
                    break;
                case "Q":
                    this.commands[commandIndex] = 'C';

                    this.convertQuadraticToCubic(commandIndex, dataIndex, currentPoint, newCoordinates, warper);
                    dataIndex += 4;
                    break;
                case "M":
                    currentPoint.update(this.data[dataIndex], this.data[dataIndex + 1]);
                    newCoordinates.push(...warper.changePoint(currentPoint));
                    dataIndex += 2;
                    break;
                case "C":
                    this.handleCubic(commandIndex, dataIndex, currentPoint, newCoordinates, warper);
                    dataIndex += 6;
                    break;
                case "Z":
                    if (this.initialPoint) {
                        currentPoint.update(this.initialPoint.x, this.initialPoint.y);
                    }
                    break;
            }
        }

        this.data = newCoordinates;
    }

    convertLineToCubic(commandIndex, dataIndex, currentPoint, newCoordinates, warper) {
        let controlPoint1 = new Point(
            (2 * currentPoint.x + this.data[dataIndex]) / 3,
            (2 * currentPoint.y + this.data[dataIndex + 1]) / 3
        );
        let controlPoint2 = new Point(
            (currentPoint.x + 2 * this.data[dataIndex]) / 3,
            (currentPoint.y + 2 * this.data[dataIndex + 1]) / 3
        );
        let endPoint = new Point(this.data[dataIndex], this.data[dataIndex + 1]);

        newCoordinates.push(...warper.changeCubic(currentPoint, controlPoint1, controlPoint2, endPoint));

        currentPoint.update(endPoint.x, endPoint.y);
    }

    convertQuadraticToCubic(commandIndex, dataIndex, currentPoint, newCoordinates, warper) {
        // Control point and end point for the quadratic curve
        let quadControlPoint = new Point(this.data[dataIndex], this.data[dataIndex + 1]);
        let quadEndPoint = new Point(this.data[dataIndex + 2], this.data[dataIndex + 3]);

        // Convert quadratic to cubic curve
        // For a quadratic curve QP0, QP1, QP2, the cubic control points CP0, CP1, CP2, CP3 are calculated as:
        // CP0 = QP0
        // CP1 = QP0 + 2/3 * (QP1 - QP0)
        // CP2 = QP2 + 2/3 * (QP1 - QP2)
        // CP3 = QP2
        let cubicControlPoint1 = new Point(
            currentPoint.x + 2 / 3 * (quadControlPoint.x - currentPoint.x),
            currentPoint.y + 2 / 3 * (quadControlPoint.y - currentPoint.y)
        );
        let cubicControlPoint2 = new Point(
            quadEndPoint.x + 2 / 3 * (quadControlPoint.x - quadEndPoint.x),
            quadEndPoint.y + 2 / 3 * (quadControlPoint.y - quadEndPoint.y)
        );

        // Add the converted cubic curve to the new coordinates
        newCoordinates.push(...warper.changeCubic(currentPoint, cubicControlPoint1, cubicControlPoint2, quadEndPoint));

        // Update current point
        currentPoint.update(quadEndPoint.x, quadEndPoint.y);
    }


    handleCubic(commandIndex, dataIndex, currentPoint, newCoordinates, warper) {
        let controlPoint1 = new Point(this.data[dataIndex], this.data[dataIndex + 1]);
        let controlPoint2 = new Point(this.data[dataIndex + 2], this.data[dataIndex + 3]);
        let endPoint = new Point(this.data[dataIndex + 4], this.data[dataIndex + 5]);

        newCoordinates.push(...warper.changeCubic(currentPoint, controlPoint1, controlPoint2, endPoint));
        currentPoint.update(endPoint.x, endPoint.y);
    }


    getCommandArgCount(command) {
        switch (command) {
            case MOVE:
            case LINE:
                return 2;
            case CURVE_QUADRATIC:
                return 4;
            case CURVE_CUBIC:
                return 6;
            default:
                return 0;
        }
    }

    output() {
        let pathOutput = [];
        let args = this.data.slice();

        this.commands.forEach(command => {
            let argCount = this.getCommandArgCount(command);
            let commandString = command + args.splice(0, argCount).join(" ");
            pathOutput.push(commandString);
        });
        return pathOutput.join(" ");
    }

     calcRectangle() {
        const MOVE = "M";
        const LINE = "L";
        const CURVE_QUADRATIC = "Q";
        const CURVE_CUBIC = "C";

        let bounds = {
            xMin: Infinity,
            yMin: Infinity,
            xMax: -Infinity,
            yMax: -Infinity
        };

        let dataIndex = 0;

        this.commands.forEach(command => {
            let x, y;

            switch (command) {
                case MOVE:
                case LINE:
                    x = this.data[dataIndex];
                    y = this.data[dataIndex + 1];
                    dataIndex += 2;
                    break;
                case CURVE_QUADRATIC:
                    x = this.data[dataIndex + 2];
                    y = this.data[dataIndex + 3];
                    dataIndex += 4;
                    break;
                case CURVE_CUBIC:
                    x = this.data[dataIndex + 4];
                    y = this.data[dataIndex + 5];
                    dataIndex += 6;
                    break;
            }

            bounds.xMin = Math.min(x, bounds.xMin);
            bounds.xMax = Math.max(x, bounds.xMax);
            bounds.yMin = Math.min(y, bounds.yMin);
            bounds.yMax = Math.max(y, bounds.yMax);
        });

        return this.createRectangleFromBounds(bounds);
    }

    createRectangleFromBounds(bounds) {
        if (!bounds || bounds.xMin === Infinity || bounds.yMin === Infinity) {
            // Handle the case where bounds are not properly calculated
            return null;
        }
        // Calculate width and height from bounds
        const width = bounds.xMax - bounds.xMin;
        const height = bounds.yMax - bounds.yMin;

        // Return a Rectangle instance
        return new Rectangle(bounds.xMin, bounds.yMin, width, height);
    }





}