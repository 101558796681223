import React from "react";
import {Button} from "react-bootstrap";
import removeIcon from '../../../../assets/images/icon/remove.svg';

export const RemoveControls = ({
                                   isMobileView,
                                   elems,
                                   selectedId,
                                   selectedItem,
                                   onElementsChange,
                                   onButtonClick,
                                   mobileIconStyles,
                                   trRef,
                                   setSelectedElement
                               }) => {
    function remove() {

        const index = elems.findIndex((e) => e.id === selectedId);
        let propCopy = elems.slice();
        propCopy.splice(index, 1);
        onElementsChange(propCopy.slice());
        trRef.current.nodes([]);

        if (trRef.current) {
            const nodes = trRef.current.nodes().filter(node => node.id() !== elementId.toString());
            trRef.current.nodes(nodes);
        }

        if (trRef.current && trRef.current.getLayer()) {
            trRef.current.getLayer().batchDraw();
        }
        setSelectedElement(null);
    }

    if (isMobileView) {
        return (
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={remove}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Remove22"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${removeIcon})`
                    }}
                />
                <div>Remove</div>
            </button>
        )
    }
    else {
        return (
            <Button onClick={remove} title="Remove">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div
                        className={isMobileView ? "mobile_context_button_icon" : ""}
                        data-icon="Remove"
                        style={{
                            ...mobileIconStyles.icon,
                            width: '15px',
                            height: '15px',
                            backgroundImage: `url(${removeIcon})`,
                            marginBottom: '5px'
                        }}
                    />
                    Remove
                </div>
            </Button>        )
    }


}