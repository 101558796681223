import { Controller } from "@hotwired/stimulus"
// This controller is used to handle the mouseover event on the product color circles
// It changes the background color of the product image to the color of the circle
// Look at shared/_product_card.html.erb for the HTML structure
export default class extends Controller {
  static targets = ["circle", "image"]
  static values = {
    productId: String
  }

  connect() {
    this.handleMouseOver = this.handleMouseOver.bind(this)
  }

  handleMouseOver(event) {
    const color = event.currentTarget.getAttribute('data-color')
    const productImage = this.imageTarget
    if (productImage) {
      productImage.style.backgroundColor = color
    }
  }
}

