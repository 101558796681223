import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["streetAddress", "address2", "city", "state", "zip", "country"]

  connect() {
    this.debounceTimer = null
    this.loadingSpinner = document.getElementById("loading-spinner")
    
    // Check if Google Maps is already loaded
    if (window.google && window.google.maps) {
      this.initializeAutocomplete()
    }
  }

  initializeAutocomplete() {
    if (!this.streetAddressTarget) return
    
    this.autocomplete = new google.maps.places.Autocomplete(this.streetAddressTarget, {
      componentRestrictions: { country: ["us"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    })
    
    this.autocomplete.addListener("place_changed", () => this.handlePlaceChanged())
  }

  async handlePlaceChanged() {
    this.showSpinner()
    this.fillInAddress()
    if (this.validateForm()) {
      await this.calculateShippingAndTax()
    }
    this.hideSpinner()
  }

  showSpinner() {
    if (this.loadingSpinner) {
      this.loadingSpinner.style.display = "block"
    }
  }

  hideSpinner() {
    if (this.loadingSpinner) {
      this.loadingSpinner.style.display = "none"
    }
  }

  fillInAddress() {
    const place = this.autocomplete.getPlace()
    let address1 = ""
    
    for (const component of place.address_components) {
      const componentType = component.types[0]
      
      switch (componentType) {
        case "street_number":
          address1 = `${component.long_name} ${address1}`
          break
        case "route":
          address1 += component.short_name
          break
        case "postal_code":
          this.zipTarget.value = component.long_name
          break
        case "locality":
          this.cityTarget.value = component.long_name
          break
        case "administrative_area_level_1":
          this.stateTarget.value = component.short_name
          break
      }
    }
    
    this.streetAddressTarget.value = address1
    // Don't automatically focus address2 field
  }

  async calculateShippingAndTax() {
    this.showSpinner()
    const formData = new FormData()
    formData.append('address[ship_address]', this.streetAddressTarget.value)
    formData.append('address[address2]', this.address2Target.value)
    formData.append('address[city]', this.cityTarget.value)
    formData.append('address[state]', this.stateTarget.value)
    formData.append('address[zip]', this.zipTarget.value)
    formData.append('address[country]', this.countryTarget.value)

    try {
      const response = await fetch('/checkout/save_address', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      })
      
      const html = await response.text()
      Turbo.renderStreamMessage(html)
    } catch (error) {
      console.error('Error calculating shipping:', error)
    } finally {
      this.hideSpinner()
    }
  }

  handleFocus(event) {
    event.target.classList.remove('is-invalid')
  }

  handleBlur(event) {
    if (this.validateForm()) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.calculateShippingAndTax()
      }, 500)
    }
  }

  validateForm(event) {
    if (event) event.preventDefault()
    
    let isValid = true
    const requiredTargets = ["streetAddress", "city", "state", "zip"]
    
    requiredTargets.forEach(field => {
      const target = this[`${field}Target`]
      if (!target.value.trim()) {
        target.classList.add('is-invalid')
        isValid = false
      } else {
        target.classList.remove('is-invalid')
      }
    })
    
    const zipRegex = /^\d{5}(-\d{4})?$/
    if (!zipRegex.test(this.zipTarget.value)) {
      this.zipTarget.classList.add('is-invalid')
      isValid = false
    }
    
    if (isValid && event) {
      event.target.submit()
    }
    
    return isValid
  }
}
