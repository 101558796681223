import { useState, useEffect } from 'react';

export const useDelayedUnmount = (isOpen, delayTime) => {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (isOpen) {
            setShouldRender(true);
        } else {
            timeoutId = setTimeout(() => {
                setShouldRender(false);
            }, delayTime);
        }
        return () => clearTimeout(timeoutId);
    }, [isOpen, delayTime]);

    return shouldRender;
};
