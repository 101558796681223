import React, {useState, useLayoutEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import ViewBox from './ViewBox';
import DesignCanvas from './DesignCanvas';
import {Toolbar} from '../SimpleToolbar/Toolbar';

const SimpleDesignStage = (props) => {
    // todo: deconstruct props instead of using props. everywhere
    const printAreaRef = props.printAreaRef;
    const printedAreaRef = props.printedAreaRef;
    const fitToScreen = props.fitToScreen;
    const DesignViewRef = useRef();
    const templateLayerRef = props.templateLayerRef;
    //const trRef = props.trRef;
    const canvasWrapperRef = useRef();
    const drawingLayerRef = props.drawingLayerRef;
    //const [scale, setScale] = useState({x: 1, y: 1});
    const scale = props.scale;
    const setScale = props.setScale;

    // Pattern Tool Related Props
    const selectedGroupRef = props.selectedGroupRef;
    const symmetricalDrawingLayerRef = props.symmetricalDrawingLayerRef;
    const symmetryType = props.symmetryType
    const setSymmetryType = props.setSymmetryType
    const symmetryParameters = props.symmetryParameters
    const setSymmetryParameters = props.setSymmetryParameters
    const setSelectedPatternTool = props.setSelectedPatternTool
    const selectedPatternTool = props.selectedPatternTool
    const patternDesign = props.patternDesign
    const setPatternDesign = props.setPatternDesign
    const selectedPatternDesignElementId = props.selectedPatternDesignElementId
    const setSelectedPatternDesignElementId = props.setSelectedPatternDesignElementId
    const isPatternDesignUpdatingFromAsset = props.isPatternDesignUpdatingFromAsset
    const setIsPatternDesignUpdatingFromAsset = props.setIsPatternDesignUpdatingFromAsset

    const isDrawingMode = props.isDrawingMode;
    const setDrawingMode = props.setDrawingMode;
    const brushType = props.brushType;
    const setBrushType = props.setBrushType;
    const brushSize = props.brushSize;
    const setBrushSize = props.setBrushSize;
    const brushColor = props.brushColor;
    const setBrushColor = props.setBrushColor;
    const isEraserActive = props.isEraserActive;
    const setIsEraserActive = props.setIsEraserActive;

    const [snappingEnabled, setSnappingEnabled] = useState(true);

    const fitEditableAreaToScreen = () => {
        const stage = props.stageRef.current;
        const printArea = printAreaRef.current;
        const drawingArea = drawingLayerRef.current;
        const templateArea = templateLayerRef.current;

        const printAreaDimensions = {
            x: props.template.print_area_left,
            y: props.template.print_area_top,
            width: props.template.print_area_width,
            height: props.template.print_area_height,
        };

        // 1. Scaling:
        const paddingOnFocus = {
            top: stage.width() * 0.2,
            left: stage.height() * 0.2
        };
        const scale = Math.min(
            (stage.width() - paddingOnFocus.top) / printAreaDimensions.width,
            (stage.height() - paddingOnFocus.left) / printAreaDimensions.height
        );

        // 2. Calculate the center difference:
        const stageCenterX = stage.width() / 2;
        const stageCenterY = stage.height() / 2;

        const printAreaCurrentCenterX = printAreaDimensions.x + (printAreaDimensions.width / 2);
        const printAreaCurrentCenterY = printAreaDimensions.y + (printAreaDimensions.height / 2);

        const targetX = stageCenterX - (printAreaCurrentCenterX * scale);
        const targetY = stageCenterY - (printAreaCurrentCenterY * scale);

        // 3. Apply the scale and translation:
        [printArea, drawingArea, templateArea].forEach(layer => {
            layer.to({
                x: targetX,
                y: targetY,
                scaleX: scale,
                scaleY: scale
            });
        });


        setScale({ x: scale, y: scale });

    }



    useLayoutEffect(() => {
        window.addEventListener('templateLoad', fitToScreen);
        window.addEventListener('awesome', fitToScreen);
        //window.addEventListener('pageshow', fitToScreen);
        //window.addEventListener('load', fitToScreen);
        //////// Specify how to clean up after this effect:
        return function cleanup() {
            window.removeEventListener('templateLoad', fitToScreen);
            window.removeEventListener('awesome', fitToScreen);
            //window.removeEventListener('pageshow', fitToScreen);
            //window.removeEventListener('load', fitToScreen);
        }
    }, [props.template]);

    return (
        <div className={'h-100'}>

            <Toolbar
                stageRef={props.stageRef}
                printAreaRef={props.printAreaRef}
                drawingLayerRef={props.drawingLayerRef}
                templateLayerRef={props.templateLayerRef}
                fitToScreen={fitToScreen}
                fitEditableAreaToScreen={fitEditableAreaToScreen}
                setScale={setScale}
                drawingHistory={props.drawingHistory}
                template={props.template}
                templates={props.templates}
                setTemplate={props.setTemplate}
                placementRef={props.placementRef}
                snappingEnabled={snappingEnabled}
                setSnappingEnabled={setSnappingEnabled}
                isDrawingMode={isDrawingMode}
                setDrawingMode={setDrawingMode}
            />

                <div className="col DesignSpace-stageCenter" style={{position: 'relative'}}>
                    <div className="DesignView" id="DesignView" ref={DesignViewRef}>

                        <ViewBox
                            designViewRef={DesignViewRef}
                        />
                        <DesignCanvas
                            designViewRef={DesignViewRef}
                            scale={scale}
                            setScale={setScale}
                            selectedGroupRef={selectedGroupRef}
                            symmetricalDrawingLayerRef={symmetricalDrawingLayerRef}
                            symmetryType={symmetryType}
                            setSymmetryType={setSymmetryType}
                            offScreenPatternCanvasRef={props.offScreenPatternCanvasRef}
                            symmetryParameters={symmetryParameters}
                            setSymmetryParameters={setSymmetryParameters}
                            setSelectedPatternTool={setSelectedPatternTool}
                            selectedPatternTool={selectedPatternTool}
                            patternDesign={patternDesign}
                            setPatternDesign={setPatternDesign}
                            selectedPatternDesignElementId={selectedPatternDesignElementId}
                            setSelectedPatternDesignElementId={setSelectedPatternDesignElementId}
                            isPatternDesignUpdatingFromAsset={isPatternDesignUpdatingFromAsset}
                            setIsPatternDesignUpdatingFromAsset={setIsPatternDesignUpdatingFromAsset}
                            elems={props.elems}
                            setElems={props.setElems}
                            onElementsChange={(elements) => {
                                props.setElems(elements);
                            }}
                            template={props.template}
                            stageRect={props.stageRect}
                            setStageRect={props.setStageRect}
                            stageRef={props.stageRef}
                            templateLayerRef={templateLayerRef}
                            drawingLayerRef={drawingLayerRef}
                            printAreaRef={props.printAreaRef}
                            printedAreaRef={props.printedAreaRef}
                            placementRef={props.placementRef}
                            isMobileView={props.isMobileView}
                            horizontalScrollerRef={props.horizontalScrollerRef}
                            verticalScrollerRef={props.verticalScrollerRef}
                            trRef={props.trRef}
                            snappingEnabled={snappingEnabled}
                            isDrawingMode={isDrawingMode}
                            setDrawingMode={setDrawingMode}
                            brushType={brushType}
                            setBrushType={setBrushType}
                            brushSize={brushSize}
                            setBrushSize={setBrushSize}
                            brushColor={brushColor}
                            setBrushColor={setBrushColor}
                            isEraserActive={isEraserActive}
                            setIsEraserActive={setIsEraserActive}
                            squareSize={props.squareSize}
                            squarePosition={props.squarePosition}
                        />
                    </div>
                </div>
        </div>
    )
}

SimpleDesignStage.propTypes = {
    zoom: PropTypes.number,
    setZoom: PropTypes.func,
    elems: PropTypes.array,
    setElems: PropTypes.func,
    setSelectedColor: PropTypes.func,
    setSelectedSize: PropTypes.func,
    availableColors: PropTypes.array,
    availableSizes: PropTypes.array,
};


export default SimpleDesignStage;