import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "counter"]
  static values = { max: Number }
  
  connect() {
    this.updateCounter()
    this.inputTarget.addEventListener('input', () => this.updateCounter())
  }
  
  updateCounter() {
    const currentLength = this.inputTarget.value.length
    const maxLength = this.maxValue || 70
    this.counterTarget.textContent = `${currentLength}/${maxLength}`
    
    // Optional: Add visual feedback when approaching/exceeding limit
    if (currentLength > maxLength) {
      this.counterTarget.classList.add('text-danger')
    } else if (currentLength > (maxLength * 0.8)) {
      this.counterTarget.classList.add('text-warning')
      this.counterTarget.classList.remove('text-danger')
    } else {
      this.counterTarget.classList.remove('text-warning', 'text-danger')
    }
  }
}
