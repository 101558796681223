import React from "react";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import SVGEditorWrapper from "../SVGEdit/SVGEditorWrapper"; // Import SVGEditorWrapper

const IconsAsset = ({
                        loadElement,
                        isListVisible,
                        setElems,
                        elems,
                        placementRef,
                        printAreaRef,
                        stageRef
                    }) => {

    const [selectedCategory, setSelectedCategory] = React.useState('Emoji'); // default category
    const [icons, setIcons] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [loadingIconId, setLoadingIconId] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [pageLoading, setPageLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);


    // Create a reference for the last icon in the list
    const lastIconRef = React.useRef(null);

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setPage(prevPage => prevPage + 1);
        }
    }


    const goToPreviousPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));  // Ensure page doesn't go below 1.
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                console.log('Observer triggered!');

                if (entries[0].isIntersecting) {
                    goToNextPage();  // Load the next page when last icon is visible
                }
            },
            { threshold: 1 }  // Trigger when the full icon is visible
        );

        if (lastIconRef.current) {
            observer.observe(lastIconRef.current);
        }

        return () => observer.disconnect();  // Cleanup
    }, [lastIconRef]);

    React.useEffect(() => {
        const fetchCategories = async () => {
            const result = await axios('/api/v1/element_categories/by_element_type/svg/');
            if (result.data.length > 0) {
                setCategories(result.data);
            }
        };
        fetchCategories();
    }, []);

    React.useEffect(() => {
        if (selectedCategory) {
            const fetchData = async () => {
                const result = await axios(`/api/v1/element_categories/by_element_type/svg/elements/${selectedCategory}?page=${page}`);

                const totalPagesFromApi = parseInt(result.headers['pagy_pages']);
                const currentPageFromApi = parseInt(result.headers['pagy_page']);

                setTotalPages(totalPagesFromApi);
                setCurrentPage(currentPageFromApi);


                if (result.data.length > 0) {
                    setIcons(result.data);
                } else {
                    setIcons([]);
                }
            };
            fetchData();
        }
    }, [selectedCategory, page]);

    const setSelectedCategoryWithReset = (category) => {
        setSelectedCategory(category);
        setPage(1);
        setIcons([]); // Clear the icons
    };



    const handleIconClick = (event) => {
        const iconId = event.target.attributes.getNamedItem('data-itemid').value;
        setLoadingIconId(iconId);  // Set the loading state

        loadElement(event).then(() => {
            setLoadingIconId("");  // Reset the loading state
        }).catch(error => {
            console.error("Error in loadElement:", error);
        })
    };

    // Function to handle the creation of new SVG
    const [isEditorOpen, setEditorOpen] = React.useState(false);
    const [newSVGContent, setNewSVGContent] = React.useState('');

    const handleCreateNewSVG = () => {
        const blankSVGTemplate = `<svg width="600" height="600" xmlns="http://www.w3.org/2000/svg">
                <!-- Add default elements if necessary -->
        </svg>`;

        // Set the SVG Editor modal open with a blank or default SVG
        setNewSVGContent(blankSVGTemplate);
        setEditorOpen(true);
    };

    return (
        <div>
            {/* "Create Icon" button */}
            <div className="create-icon-button" style={{ textAlign: 'center', margin: '10px 0' }}>
                <Button
                    onClick={handleCreateNewSVG}
                    style={{
                        backgroundColor: '#5C6BC0', // Example color
                        borderColor: 'transparent',
                        boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
                        padding: '10px 20px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: 'white',
                        borderRadius: '5px',
                        transition: 'all 0.3s ease',
                    }}
                    onMouseOver={({ target }) => target.style.backgroundColor = '#3949AB'} // Darken button color on hover
                    onMouseOut={({ target }) => target.style.backgroundColor = '#5C6BC0'} // Revert button color on mouse out
                    onFocus={({ target }) => target.style.boxShadow = '0 0 0 2px rgba(92,107,192,.5)'} // Box shadow for focus
                    onBlur={({ target }) => target.style.boxShadow = '0 2px 4px 0 rgba(0,0,0,.2)'} // Revert box shadow on blur
                    aria-label="Create new icon"
                >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i> {/* FontAwesome icon */}
                    {' '}Create New Icon
                </Button>
            </div>


            {/* SVG Editor Modal */}
            <Modal
                show={isEditorOpen}
                onHide={() => setEditorOpen(false)}
                size="xl"
                dialogClassName="modal-90w modal-90h"
                className="fullHeightModal"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New SVG Icon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SVGEditorWrapper
                        svgContent={newSVGContent}
                        elems={elems}
                        setElems={setElems}
                        //selectedId={props.selectedId}
                        //selectedItem={props.selectedItem}
                        //selectedElement={props.selectedElement}
                        onClose={() => setEditorOpen(false)}
                    />
                </Modal.Body>
            </Modal>
            {/* Render category options */}
            <div className="icon-categories">
                {categories.map(categoryObj => (
                    <button
                        key={categoryObj.name}
                        onClick={() => setSelectedCategoryWithReset(categoryObj.name)}
                        className={selectedCategory === categoryObj.name ? 'active' : ''}
                    >
                        <img
                            src={categoryObj.element_category_icon}
                            alt={categoryObj.name}
                            title={categoryObj.name}
                            width="20"
                        />
                    </button>
                ))}
            </div>

            {/* Render icons */}
            <div className="icons-grid">
                {page > 1 && <button onClick={goToPreviousPage} className="load-prev-btn">Load Previous</button>}

                {icons.map((icon, index) => {
                    const isLoading = String(loadingIconId) === String(icon.id);
                    const isLastIcon = index === icons.length - 1; // Check if this is the last icon in the list

                    return (
                        <div key={icon.id} className="icon-wrapper" ref={isLastIcon ? lastIconRef : null}>
                            <img
                                src={icon.image_address}
                                alt={icon.element_category}
                                onClick={handleIconClick}
                                width="50"
                                data-itemtype='Icons'
                                data-itemdata={icon.image_address}
                                data-itemdatas3url={icon.s3_full_image_address}
                                data-itemformat={icon.element_type}
                                data-itemid={icon.id}
                            />
                            {isLoading && <div className="asset-loading-spinner"></div>}
                        </div>
                    );
                })}
                {pageLoading && <div className="asset-loading-spinner"></div>}
                {currentPage < totalPages && <button onClick={goToNextPage} className="load-next-btn">Load More</button>}


            </div>

        </div>
    );
};

export default IconsAsset;
