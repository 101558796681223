import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Group, Line, Path, Rect, Shape, Image } from "react-konva";
import useImage from 'use-image';

export const PatternShape = ({
                                 onSelect,
                                 isSelected,
                                 onChange,
                                 element,
                                 trRef,
                                 template,
                                 draggable,
                                 onMouseOver,
                                 onMouseOut,
                                 iselemententTouchedRef,
                                 addToActiveNodes,
                                 removeFromActiveNodes
                             }) => {
    // Cache the image URL
    const imageUrl = element.elems[0]?.image;
    const shapeRef = React.useRef(null); // Initialize the ref here

    useEffect(() => {
        if (isSelected && shapeRef.current) {
            if (trRef.current) {
                addToActiveNodes(shapeRef.current);
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        } else if (!isSelected && shapeRef.current) {
            //removeFromActiveNodes(shapeRef.current);
        }
    }, [isSelected]);

    useEffect(() => {
        return () => {
            if (shapeRef.current) {
                //removeFromActiveNodes(shapeRef.current);
            }
        }
    }, []);

    return (
        <Group
            id={element.id}
            key={element.id}
            name={'element'}
            ref={shapeRef}
            draggable={true}
            //set={element.set}
            symmetryType={element.symmetryType}
            x={element.x}
            y={element.y}
            width={element.width}
            height={element.height}
            scaleX={element.scaleX}
            scaleY={element.scaleY}
            rotation={element.rotation}
            listening={true}
            onTransformEnd={(e) => {
                onChange({
                    ...element,
                    x: e.target.x(),
                    y: e.target.y(),
                    scaleX: e.target.scaleX(),
                    scaleY: e.target.scaleY(),
                    width: e.target.width(),
                    height: e.target.height(),
                    rotation: e.target.rotation(),
                });
            }}
            onClick={onSelect}
            onTap={(e) => {
                onSelect(e.target);
                //e.cancelBubble = true;
            }}
            onDragEnd={(e) => {
                onChange({
                    ...element,
                    x: e.target.x(),
                    y: e.target.y(),
                });
            }}
        >
            {imageUrl && (
                <ImageComponent
                    imageUrl={imageUrl}
                    elements={element.elems}
                />
            )}
        </Group>
    );
};

const ImageComponent = React.memo(({ imageUrl, elements }) => {
    const [image, status] = useImage(imageUrl, 'Anonymous');
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        if (status === 'loaded' && image) {
            setImageLoaded(true);
        } else if (status === 'loading') {
            // Handle loading state if needed
        } else if (status === 'error') {
            console.error("Error loading image");
        }
    }, [status, image]);

    if (imageLoaded) {
        return elements.map((childElement) => (
            <Image
                key={childElement.id}
                id={childElement.id}
                image={image}
                scaleX={childElement.scaleX}
                scaleY={childElement.scaleY}
                x={childElement.x}
                y={childElement.y}
                width={childElement.width}
                height={childElement.height}
            />
        ));
    } else {
        return null;
    }
});

export default PatternShape;