import { Controller } from "@hotwired/stimulus"
import { updateSVGColors } from "../packs/components/SimpleElementOptions/propertiesUtils"

export default class extends Controller {
  static targets = ["form", "elementForms", "elementProperties"]
  static values = { 
    productId: Number
  }

  colorDebounceTimer = null
  scaleDebounceTimer = null

  connect() {
    this.observer = new MutationObserver(() => {
      const designStateController = this.application.controllers.find(
        controller => 
          controller.identifier === 'design-state' && 
          controller.element.contains(this.element)
      )

      const productController = this.application.controllers.find(
        controller => controller.identifier === 'product' && controller.stageService
      )

      if (designStateController && productController) {
        this.designStateController = designStateController
        this.stageService = productController.stageService
        this.designStateController.setStageService(this.stageService)
        this.observer.disconnect()
        this.setupCustomizationForm()
      }
    })

    this.observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
    }
    if (this.colorDebounceTimer) {
      clearTimeout(this.colorDebounceTimer)
    }
    if (this.scaleDebounceTimer) {
      clearTimeout(this.scaleDebounceTimer)
    }
  }

  setupCustomizationForm() {
    const elements = this.designStateController.currentState.filter(element => 
      (element.type === 'path' && !element.textValue) || 
      (element.type === 'image' && element.format === 'svg') ||
      element.type === 'shape'
    )
    
    if (elements.length === 0) return
    if (!this.hasFormTarget) return

    const form = this.formTarget
    form.innerHTML = this.buildCustomizationFormHTML(elements)
  }

  buildCustomizationFormHTML(elements) {
    return `
      <form data-action="submit->element-customization#saveCustomization">
        <div class="mb-3">
          <label class="form-label">Select Element</label>
          <select class="form-select" data-action="change->element-customization#selectElement">
            ${elements.map((element, index) => `
              <option value="${element.id}">
                Element ${index + 1}: "${element.name || 'Unnamed Element'}"
              </option>
            `).join('')}
          </select>
        </div>

        <div data-element-customization-target="elementForms">
          ${this.buildElementFormHTML(elements[0])}
        </div>
      </form>
    `
  }

  buildElementFormHTML(element) {
    return `
      <div class="element-properties-grid">
        <div class="controls-grid">
          <div class="position-size-controls">
            <div class="position-controls">
              <button type="button" 
                      class="pos-button pos-up"
                      data-action="click->element-customization#moveElement"
                      data-element-id="${element.id}"
                      data-direction="up">
                <i class="fas fa-chevron-up"></i>
              </button>
              
              <button type="button" 
                      class="pos-button pos-left"
                      data-action="click->element-customization#moveElement"
                      data-element-id="${element.id}"
                      data-direction="left">
                <i class="fas fa-chevron-left"></i>
              </button>
              
              <button type="button" 
                      class="pos-button pos-center"
                      data-action="click->element-customization#centerElement"
                      data-element-id="${element.id}">
                <i class="fas fa-dot-circle"></i>
              </button>
              
              <button type="button" 
                      class="pos-button pos-right"
                      data-action="click->element-customization#moveElement"
                      data-element-id="${element.id}"
                      data-direction="right">
                <i class="fas fa-chevron-right"></i>
              </button>
              
              <button type="button" 
                      class="pos-button pos-down"
                      data-action="click->element-customization#moveElement"
                      data-element-id="${element.id}"
                      data-direction="down">
                <i class="fas fa-chevron-down"></i>
              </button>
            </div>

            <div class="size-control">
                <label class="form-label">Size</label>
                <input type="range" 
                      class="form-range" 
                      min="10" 
                      max="200" 
                      value="${(element.scale || 1) * 100}"
                      data-action="input->element-customization#debouncedUpdateScale"
                      data-element-id="${element.id}">
            </div>
          </div>
          
          <div class="adjustment-controls">

            ${element.svgXML ? `
              <div class="property-row">
                <label class="form-label">Colors</label>
                <div class="color-swatches d-flex gap-2 flex-wrap">
                  ${this.extractColorsFromSVG(element.svgXML).map(color => `
                    <div class="color-swatch-container">
                      <button type="button" 
                              class="btn btn-sm color-swatch" 
                              style="background-color: ${color}; width: 30px; height: 30px; border-radius: 4px;"
                              data-element-id="${element.id}"
                              data-original-color="${color}"
                              data-color="${color}">
                        <input type="color" 
                               class="swatch-color-picker" 
                               value="${color}"
                               style="opacity: 0; position: absolute; width: 100%; height: 100%; left: 0; top: 0; cursor: pointer;"
                               data-element-id="${element.id}"
                               data-original-color="${color}"
                               data-action="input->element-customization#updateSVGColor">
                      </button>
                    </div>
                  `).join('')}
                </div>
              </div>
            ` : ''}
          </div>
        </div>
      </div>
    `
  }

  extractColorsFromSVG(svgXML) {
    if (!svgXML) return []
    
    const colorRegex = /#[0-9A-Fa-f]{6}|#[0-9A-Fa-f]{3}|rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)|rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*[\d.]+\s*\)|fill="[^"]+"|stroke="[^"]+"/g
    const matches = svgXML.match(colorRegex) || []
    
    return [...new Set(matches.map(match => {
      if (match.startsWith('fill="') || match.startsWith('stroke="')) {
        return match.split('"')[1]
      }
      return match
    }))]
  }

  async updateSVGColor(event) {
    if (this.colorDebounceTimer) {
      clearTimeout(this.colorDebounceTimer)
    }

    this.colorDebounceTimer = setTimeout(async () => {
      const colorInput = event.target
      const elementId = colorInput.dataset.elementId
      const originalColor = colorInput.dataset.originalColor
      const newColor = colorInput.value
      
      // Update the swatch button's background color
      const swatchButton = colorInput.closest('.color-swatch-container')?.querySelector('.color-swatch')
      if (swatchButton) {
        swatchButton.style.backgroundColor = newColor
        swatchButton.dataset.color = newColor
        swatchButton.dataset.originalColor = newColor
      }
      
      const element = this.designStateController.currentState.find(elem => elem.id === elementId)
      if (!element || !element.svgXML) return

      const colorMap = {
        [originalColor.toLowerCase()]: newColor.toLowerCase()
      }

      const updatedSVG = updateSVGColors(colorMap, element.svgXML)
      const newData = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(updatedSVG)))}`
      
      colorInput.dataset.originalColor = newColor

      const updatedState = this.designStateController.currentState.map(elem => {
        if (elem.id === elementId) {
          return {
            ...elem,
            svgXML: updatedSVG,
            data: newData,
            fill: newColor
          }
        }
        return elem
      })

      await this.updateDesignAndPreview(elementId, updatedState)
    }, 150)
  }

  debouncedUpdateScale(event) {
    const elementId = event.target.dataset.elementId
    const scalePercent = parseInt(event.target.value)
    const scale = scalePercent / 100
    
    if (this.scaleDebounceTimer) {
      clearTimeout(this.scaleDebounceTimer)
    }
    
    this.scaleDebounceTimer = setTimeout(async () => {
      const updatedState = this.designStateController.currentState.map(element => {
        if (element.id === elementId) {
          return { 
            ...element, 
            scale: scale,
            scaleX: scale,
            scaleY: scale,
            originalWidth: element.originalWidth || element.width,
            originalHeight: element.originalHeight || element.height
          }
        }
        return element
      })
      await this.updateDesignAndPreview(elementId, updatedState)
    }, 150)
  }

  async selectElement(event) {
    const elementId = event.target.value
    const element = this.designStateController.currentState.find(e => e.id === elementId)
    
    if (!element) return
    
    const formsContainer = this.element.querySelector('[data-element-customization-target="elementForms"]')
    formsContainer.innerHTML = this.buildElementFormHTML(element)
  }

  async centerElement(event) {
    const elementId = event.currentTarget.dataset.elementId
    const currentPlacement = this.stageService.currentPlacement
    const template = this.stageService.templates.find(t => t.placement === currentPlacement)
    
    const element = this.designStateController.currentState.find(e => e.id === elementId)
    if (!element) return

    const printAreaCenterX = template.print_area_left + (template.print_area_width / 2)
    const printAreaCenterY = template.print_area_top + (template.print_area_height / 2)
    
    const updatedState = this.designStateController.currentState.map(elem => {
      if (elem.id === elementId) {
        return {
          ...elem,
          x: printAreaCenterX - ((elem.width * (elem.scale || 1)) / 2),
          y: printAreaCenterY - ((elem.height * (elem.scale || 1)) / 2)
        }
      }
      return elem
    })

    await this.updateDesignAndPreview(elementId, updatedState)
  }

  async moveElement(event) {
    const elementId = event.currentTarget.dataset.elementId
    const direction = event.currentTarget.dataset.direction
    const moveAmount = 10

    const updatedState = this.designStateController.currentState.map(element => {
      if (element.id === elementId) {
        const updates = {}
        switch (direction) {
          case 'left':
            updates.x = element.x - moveAmount
            break
          case 'right':
            updates.x = element.x + moveAmount
            break
          case 'up':
            updates.y = element.y - moveAmount
            break
          case 'down':
            updates.y = element.y + moveAmount
            break
        }
        return { ...element, ...updates }
      }
      return element
    })

    await this.updateDesignAndPreview(elementId, updatedState)
  }

  async updateDesignAndPreview(elementId, updatedState) {
    await this.designStateController.updateState(updatedState)
  }

  async updateSVGColorFromSwatch(event) {
    const elementId = event.currentTarget.dataset.elementId
    const originalColor = event.currentTarget.dataset.originalColor
    const newColor = event.currentTarget.dataset.color
    
    const element = this.designStateController.currentState.find(elem => elem.id === elementId)
    if (!element || !element.svgXML) return

    const colorMap = {
      [originalColor.toLowerCase()]: newColor.toLowerCase()
    }

    const updatedSVG = updateSVGColors(colorMap, element.svgXML)
    const newData = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(updatedSVG)))}`
    
    // Update the color picker value to match the swatch
    const colorPicker = event.currentTarget.closest('.mb-3').querySelector('input[type="color"]')
    colorPicker.value = newColor
    colorPicker.dataset.originalColor = newColor

    const updatedState = this.designStateController.currentState.map(elem => {
      if (elem.id === elementId) {
        return {
          ...elem,
          svgXML: updatedSVG,
          data: newData,
          fill: newColor
        }
      }
      return elem
    })

    await this.updateDesignAndPreview(elementId, updatedState)
  }

  async saveCustomization(event) {
    event.preventDefault()
    await this.designStateController.saveDesignState()
  }
} 