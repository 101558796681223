import React, {useState} from 'react';
import {extractStyleTagRules} from '../lib/utils';
import {base64encode} from "../lib/utils";
import AssetTypeButton from "./AssetTypeButton";
import AssetList from "./AssetList";
import PhotosAsset from "./PhotosAsset";
import IconsAsset from "./IconsAsset";
import TextAsset from "./TextAsset";
import MyFilesAsset from "./MyFilesAsset";
import FreeHandDrawingAsset from "./FreeHandDrawingAsset";
import SymmetryAsset from "./SymmetryAsset";
import LayersAsset from "./LayersAsset"; // Import the new Layers component
import {loadElement} from "../lib/assetUtils";
import {useSelectionContext} from '../../contexts/SelectionContext';

const SimpleAssets = (props) => {


    const {
        selectedElement,
        selectedId,
        selectElement
    } = useSelectionContext();
    const [selectedButton, setSelectedButton] = React.useState('Icons');
    const [isListVisible, setListVisible] = React.useState(true);
    const isUserGuest = props.isGuest === "true" ? true : false;
    const [isAuthenticated, setIsAuthenticated] = useState(!isUserGuest);

    React.useEffect(() => {
        const handleUserAuthentication = () => {
            setIsAuthenticated(true);
        };

        window.addEventListener('userAuthenticated', handleUserAuthentication);

        // Cleanup: Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('userAuthenticated', handleUserAuthentication);
        };
    }, []);

    const [buttonList, setButtonList] = React.useState([
        //['Templates', 'fa-clipboard', 'Templates'],
        ['Text', 'fa-font', 'Text'],
        ['Photos', 'fa-image', 'Photos'],
        ['Icons', 'fa-icons', 'Icons'],
        ['My Files', 'fa-file-image', '/myfiles/userId'],
        ['FreeHand Drawing', 'fa-paint-brush', 'FreeHand Drawing'],
        ['Symmetry', 'fa-symmetry', 'Symmetry'],
        ['Layers', 'fa-layers', 'Layers'],
        //['Products', 'fa-socks', 'Products'],
        //['Digital', 'fa-digital-tachograph', 'Digital'],
    ]);

    const getRandomInt = (min, max) => {
        return Math.floor(
            Math.random() * (max - min + 1) + min
        )
    };

    const toggleSidebar = (e) => {
        e.preventDefault();

        document.querySelector('.sidebar').classList.toggle('shrink');
        document.querySelector('.sidebar').classList.toggle('show');
    };

    return (
        <div className='sidebar py-3' id='sidebar'>

            <ul className='asset-list'>
                {buttonList.map((item, index) => {
                    return (
                        <li key={index}>
                            <AssetTypeButton
                                type={item[0]}
                                icon={item[1]}
                                onSelect={(type) => {
                                    if (type === selectedButton) {
                                        // Toggle visibility of the current list
                                        setListVisible(prevVisible => !prevVisible);
                                    } else {
                                        // Hide the previous list and show the new one
                                        setSelectedButton(type);
                                        setListVisible(true);
                                    }
                                }}
                                selectedButton={selectedButton}
                            />
                            {isListVisible && item[0] === selectedButton && (
                                <AssetContent
                                    type={item[0]}
                                    loadElement={(e) => loadElement(e, props, selectedButton, selectElement)}
                                    isListVisible={isListVisible}
                                    isAuthenticated={isAuthenticated}
                                    selectedButton={selectedButton}
                                    setElems={props.setElems}
                                    elems={props.elems}
                                    placementRef={props.placementRef}
                                    printAreaRef={props.printAreaRef}
                                    printedAreaRef={props.printedAreaRef}
                                    stageRef={props.stageRef}
                                    setSelectedElement={props.setSelectedElement}
                                    setSelectedId={props.setSelectedId}
                                    selectedElement={props.selectedElement}
                                    selectedId={props.selectedId}
                                    selectedGroupRef={props.selectedGroupRef}
                                    selectElement={selectElement}
                                    //selectedItem={props.selectedItem}
                                    isDrawingMode={props.isDrawingMode}
                                    setIsDrawingMode={props.setIsDrawingMode}
                                    brushType={props.brushType}
                                    setBrushType={props.setBrushType}
                                    brushColor={props.brushColor}
                                    setBrushColor={props.setBrushColor}
                                    brushSize={props.brushSize}
                                    setBrushSize={props.setBrushSize}
                                    isEraserActive={props.isEraserActive}
                                    setIsEraserActive={props.setIsEraserActive}
                                    drawingLayerRef={props.drawingLayerRef}
                                    template={props.template}
                                    symmetryType={props.symmetryType}
                                    setSymmetryType={props.setSymmetryType}
                                    symmetryParameters={props.symmetryParameters}
                                    setSymmetryParameters={props.setSymmetryParameters}
                                    selectedPatternTool={props.selectedPatternTool}
                                    setSelectedPatternTool={props.setSelectedPatternTool}
                                    offScreenPatternCanvasRef={props.offScreenPatternCanvasRef}
                                    patternDesign={props.patternDesign}
                                    setPatternDesign={props.setPatternDesign}
                                    selectedPatternDesignElementId={props.selectedPatternDesignElementId}
                                    setSelectedPatternDesignElementId={props.setSelectedPatternDesignElementId}
                                    isPatternDesignUpdatingFromAsset={props.isPatternDesignUpdatingFromAsset}
                                    setIsPatternDesignUpdatingFromAsset={props.setIsPatternDesignUpdatingFromAsset}
                                />

                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const AssetContent = ({type, ...props}) => {

    switch (type) {
        case 'Text':
            return <TextAsset {...props} />;
        case 'Photos':
            return <PhotosAsset {...props} />;
        case 'Icons':
            return <IconsAsset {...props} />;
        case 'My Files':
            return <MyFilesAsset {...props} />;
        case 'FreeHand Drawing':
            return <FreeHandDrawingAsset {...props} />;
        case 'Symmetry':
            return <SymmetryAsset {...props} />;
        case 'Layers':
            return <LayersAsset {...props} />;
        default:
            return <AssetList type={type} {...props} />;
    }
}

export default SimpleAssets;
