import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "submit", "spinner"]

  connect() {
    this.spinnerTarget.style.display = "none"
  }

  submitForm(event) {
    this.submitTarget.style.display = "none"
    this.spinnerTarget.style.display = "flex"
  }
}

