import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["progressBar", "bar", "status", "button", "statusContainer", "profitMargin"]

  connect() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const statusDiv = mutation.addedNodes[0]
          if (statusDiv.dataset.progressBarStatus === 'success') {
            this.success()
          } else if (statusDiv.dataset.progressBarStatus === 'error') {
            this.error(statusDiv.dataset.errorMessage)
          }
        }
      })
    })

    this.observer.observe(this.statusContainerTarget, { childList: true })
  }

  disconnect() {
    this.observer?.disconnect()
  }

  startProgress(event) {
    this.progressBarTarget.classList.remove("d-none")
    this.buttonTarget.disabled = true
    this.updateProgress(0, "Starting price reset...")
    
    const formData = new FormData()
    formData.append('default_profit_margin', this.profitMarginTarget.value)
    
    setTimeout(() => this.updateProgress(30, "Resetting variant prices..."), 500)
    setTimeout(() => this.updateProgress(60, "Resetting placement prices..."), 1500)
    setTimeout(() => this.updateProgress(90, "Finalizing changes..."), 2500)

    fetch('/dashboard/shop_settings/handle_price_reset', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(formData),
      credentials: 'same-origin'
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      setTimeout(() => {
        if (this.statusTarget.textContent === "Finalizing changes...") {
          this.success()
        }
      }, 3000)
    })
    .catch(error => {
      this.error("Network error occurred")
      console.error('Error:', error)
    })
  }

  success() {
    this.updateProgress(100, "Complete!")
    setTimeout(() => {
      this.progressBarTarget.classList.add("d-none")
      this.buttonTarget.disabled = false
    }, 1000)
  }

  error(message) {
    this.barTarget.classList.add("bg-danger")
    this.updateProgress(100, `Error: ${message}`)
    setTimeout(() => {
      this.progressBarTarget.classList.add("d-none")
      this.buttonTarget.disabled = false
      this.barTarget.classList.remove("bg-danger")
    }, 3000)
  }

  updateProgress(percentage, status) {
    this.barTarget.style.width = `${percentage}%`
    this.statusTarget.textContent = status
  }
}