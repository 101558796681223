import React, { useRef, useState, forwardRef } from "react";
import ReactDom from "react-dom";
import {
    QueryClientProvider,
    QueryClient,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {PriceCalculator} from "./components/PriceCalculator/PriceCalculator";
import SimpleDesignStage from "./components/SimpleDesigner/SimpleDesignStage";
import SimpleAssets from "./components/SimpleAssets/SimpleAssets";
import SimplePreviewer from "./components/SimplePreviewer/SimplePreviewer";
import { PropertiesPanel } from "./components/SimpleElementOptions/PropertiesPanel";
import useHistory from "./components/lib/useHistory";
import PropTypes from "prop-types";
import axios from "axios";
import { SimpleProductPicker } from "./components/SimpleProductPicker/SimpleProductPicker";
import { CreateNewProduct } from "./components/CreateNewProduct/CreateNewProduct";
import { DashboardCreateNewProduct } from "./components/DashboardCreateNewProduct/DashboardCreateNewProduct";
import { SimplePlacementPicker} from "./components/SimplePlacementPicker/SimplePlacementPicker";
import { MobileHeader } from "./components/mobile/MobileHeader";
import { ContextualPanel } from "./components/mobile/ContextualPanel";
import {SelectionProvider, useSelectionContext, KonvaSelectionProvider} from "./contexts/SelectionContext";
import { Stage, Layer } from 'react-konva';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught in Error Boundary:", error);
        this.setState({ errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    {/* Optionally, you can display the error info here */}
                    {/* <details>{this.state.errorInfo && this.state.errorInfo.componentStack}</details> */}
                </div>
            );
        }
        return this.props.children;
    }
}

export const WorkTable = (props) => {

    return (
        <div className={"page-holder bg-gray-100"}>
            <div className="">
                <section className="mb-4 mb-lg-5">
                    <div className="row">
                        <SimpleDesignStage
                            trRef={props.trRef}
                            scale={props.scale}
                            setScale={props.setScale}
                            product_id={props.product_id}
                            drawingHistory={props.drawingHistory}
                            setTemplate={props.setTemplate}
                            elems={props.elems}
                            setElems={props.setElems}
                            availableColors={props.availableColors}
                            setAvailableColors={props.setAvailableColors}
                            availableSizes={props.availableSizes}
                            setSelectedColor={props.setSelectedColor}
                            selectableColors={props.selectableColors}
                            selectableSizes={props.selectableSizes}
                            setSelectedSize={props.setSelectedSize}
                            template={props.template}
                            templates={props.templates}
                            stageRect={props.stageRect}
                            setStageRect={props.setStageRect}
                            stageRef={props.stageRef}
                            drawingLayerRef={props.drawingLayerRef}
                            symmetricalDrawingLayerRef={props.symmetricalDrawingLayerRef}
                            offScreenPatternCanvasRef={props.offScreenPatternCanvasRef}
                            symmetryType={props.symmetryType}
                            setSymmetryType={props.setSymmetryType}
                            selectedPatternTool={props.selectedPatternTool}
                            setSelectedPatternTool={props.setSelectedPatternTool}
                            patternDesign={props.patternDesign}
                            setPatternDesign={props.setPatternDesign}
                            selectedPatternDesignElementId={props.selectedPatternDesignElementId}
                            isPatternDesignUpdatingFromAsset={props.isPatternDesignUpdatingFromAsset}
                            setIsPatternDesignUpdatingFromAsset={props.setIsPatternDesignUpdatingFromAsset}
                            setSelectedPatternDesignElementId={props.setSelectedPatternDesignElementId}
                            symmetryParameters={props.symmetryParameters}
                            setSymmetryParameters={props.setSymmetryParameters}
                            categoryName={props.categoryName}
                            categoryId={props.categoryId}
                            productTypeIds={props.productTypeIds}
                            productTypeId={props.productTypeId}
                            setProductTypeId={props.setProductTypeId}
                            setExternalProductId={props.setExternalProductId}
                            selectedProductVariantId={props.selectedProductVariantId}
                            selectedColor={props.selectedColor}
                            selectedSize={props.selectedSize}
                            productTypes={props.productTypes}
                            setProductTypes={props.setProductTypes}
                            placementRef={props.placementRef}
                            selectedPreviewPaneTemplateId={
                                props.selectedPreviewPaneTemplateId
                            }
                            setSelectedPreviewPaneTemplateId={
                                props.setSelectedPreviewPaneTemplateId
                            }
                            previewPaneRef={props.previewPaneRef}
                            setProductPhotoURLs={props.setProductPhotoURLs}
                            printAreaRef={props.printAreaRef}
                            selectedGroupRef={props.selectedGroupRef}
                            fitToScreen={props.fitToScreen}
                            templateLayerRef={props.templateLayerRef}
                            printedAreaRef={props.printedAreaRef}
                            isMobileView={props.isMobileView}
                            isDrawingMode={props.isDrawingMode}
                            setIsDrawingMode={props.setIsDrawingMode}
                            brushType={props.brushType}
                            setBrushType={props.setBrushType}
                            brushColor={props.brushColor}
                            setBrushColor={props.setBrushColor}
                            brushSize={props.brushSize}
                            setBrushSize={props.setBrushSize}
                            isEraserActive={props.isEraserActive}
                            setIsEraserActive={props.setIsEraserActive}
                        />
                    </div>
                </section>
            </div>
        </div>
    );
};

WorkTable.propTypes = {
    elems: PropTypes.array,
    setElems: PropTypes.func,
    drawingHistory: PropTypes.object,
    availableColors: PropTypes.array,
    availableSizes: PropTypes.array,
    setSelectedColor: PropTypes.func,
    setSelectedSize: PropTypes.func,
};

const queryClient = new QueryClient();


const OffScreenPatternCanvas = forwardRef((props, ref) => {
    return (
        <div style={{ width: '150', height: '150', overflow: 'hidden', display: 'none' }}>
            {/* Directly pass ref to the Stage component */}
            <Stage width={150} height={150}>
                <Layer ref={ref}>

                </Layer>
            </Stage>
        </div>
    );
});


export const Root = (props) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [originalPrintableArea, setOriginalPrintableArea] = useState(null);

    const [title, setTitle] = useState(
        props.product_title ? props.product_title : "Untitled"
    );
    const [currentUser, setCurrentUser] = useState(() => {
        return {
            id: props.current_user_id,
            roles: props.user_roles ? props.user_roles.split(' ') : [],
            is_admin: props.is_admin,
            is_shop_owner: props.is_shop_owner,
            product_user_id: props.product_user_id
        }
    });

    const [isGuest, setIsGuest] = useState(
        props.is_guest ? props.is_guest : false
    );

    // The user ID of the user who created the product
    const [productUserId, setProductUserId] = useState(
        props.product_user_id ? props.product_user_id : null
    );

    const [product_id, setProduct_Id] = useState(
        props.product_id ? props.product_id : ""
    );

    const [externalProductId, setExternalProductId] = useState(
        props.external_id ? props.external_id : ""
    );

    const [categoryName, setCategoryName] = React.useState(
        props.data.category_name ? props.data.category_name : ""
    );
    const [categoryId, setCategoryId] = React.useState(
        props.category_id ? props.category_id : "1"
    );

    const [selectedColor, setSelectedColor] = useState(
        props.data.product_color ? props.data.product_color : "#FFFFFF"
    );
    const [selectableColors, setSelectableColors] = useState([]);
    const [availableColors, setAvailableColors] = useState([]);

    const [selectedSize, setSelectedSize] = useState(
        props.data.size ? props.data.size : ""
    );
    const [availableSizes, setAvailableSizes] = useState([]);
    const [selectableSizes, setSelectableSizes] = useState([]);
    const [printFiles, setPrintFiles] = useState([]);

    const [productTypeId, setProductTypeId] = React.useState(
        props.data.product_type_id ? props.data.product_type_id : "1"
    );
    const [productTypeIds, setProductTypeIds] = React.useState(
        props.data.product_type_ids ? props.data.product_type_ids : []
    );
    const [productTypes, setProductTypes] = React.useState([]);

    const [selectedProductVariantId, setSelectedProductVariantId] = useState(
        props.data.product_variant_id ? props.data.product_variant_id : ""
    );

    const printAreaRef = React.useRef();
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);

    React.useEffect(() => {
        const mediaQuery = window.matchMedia("(orientation: landscape)");
    
        const handleOrientationChange = () => {
            setIsMobileView(window.innerWidth <= 768);
            setIsLandscape(mediaQuery.matches);
            // Todo: Is this needed? fitToScreen();
        };
    
        // Add event listener for orientation changes
        mediaQuery.addEventListener('change', handleOrientationChange);
        window.addEventListener('resize', handleOrientationChange);
    
        return () => {
            mediaQuery.removeEventListener('change', handleOrientationChange);
            window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    const [externalVariantId, setExternalVariantId] = useState(
        props.external_variant_id ? props.external_variant_id : ""
    );
    const [productVariants, setProductVariants] = useState([]);

    const [selectedDesignId, setSelectedDesignId] = useState(
        props.data.design_id ? props.data.design_id : ""
    );

    const [price, setPrice] = React.useState(props.base_price ? props.base_price : 0);
    const [basePrice, setBasePrice] = React.useState(props.base_price ? props.base_price : 0);

    const [elems, setElems, drawingHistory] = useHistory([]);
    const [asset, setAsset] = useState(null);

    const [templates, setTemplates] = useState([]); // All templates of the product
    const [template, setTemplate] = useState({}); // Selected template
    const [selectedPreviewPaneTemplateId, setSelectedPreviewPaneTemplateId] =
        React.useState(0);

    const [productPhotoURLs, setProductPhotoURLs] = React.useState([]);

    const [mockupTaskKey, setMockupTaskKey] = useState(null);

    const placementRef = useRef('front'); // This sets the default placement to front for template display.
    const stageRef = useRef();
    const drawingLayerRef = useRef();
    const previewPaneRef = useRef();
    const previewStageRef = useRef([]);

    // These are for the symmetry tool
    const selectedGroupRef = React.useRef(null);

    const symmetricalDrawingLayerRef = useRef();
    const [symmetryType, setSymmetryType] = useState('p6m'); // Default to basic symmetry
    const [selectedPatternTool, setSelectedPatternTool] = useState('line'); // Default to basic symmetry
    const offScreenPatternCanvasRef = useRef();
    const [patternDesign, setPatternDesign] = useState([]);
    const [isPatternDesignUpdatingFromAsset, setIsPatternDesignUpdatingFromAsset] = useState(false);
    const [selectedPatternDesignElementId, setSelectedPatternDesignElementId] = useState(null);


    const [symmetryParameters, setSymmetryParameters] = useState({
        nx: 2,   //        nx: The number of repetitions of the pattern along the x-axis. In your case, the pattern is repeated 5 times horizontally.
        ny: 2,   //        ny: The number of repetitions of the pattern along the y-axis. Similarly, the pattern is repeated 5 times vertically.
        d: 100,   //        d: The distance between each repetition of the pattern. Here, each repetition is 100 pixels apart from its neighbors.
        phi: 1,   //        phi: The rotation angle (in radians) applied to the entire pattern. A value of 0 means no rotation.
        x: 60,   //        x: The x-coordinate of the starting point of the pattern. The pattern generation will start 100 pixels to the right of the canvas's origin (top-left corner).
        y: 60,    //        y: The y-coordinate of the starting point of the pattern. The pattern generation will start 100 pixels down from the canvas's origin.
    });

    const [scale, setScale] = useState({ x: 1, y: 1 });

    const [stageRect, setStageRect] = useState({
        x: 0,
        y: 0,
        width: 1000,
        height: 1000,
    });

    // Freehand drawing controls
    const [isDrawingMode, setIsDrawingMode] = useState(false);
    const [brushType, setBrushType] = useState("normal"); // Default brush type
    const [brushColor, setBrushColor] = useState('#000000'); // Default color
    const [brushSize, setBrushSize] = useState(5); // Default size
    const [isEraserActive, setIsEraserActive] = useState(false); // Default eraser state


    const templateLayerRef = React.useRef();
    const printedAreaRef = React.useRef();
    const worktableRef = React.useRef();
    // Transformer Ref
    const trRef = React.useRef();

    const fitToScreen = () => {
        const stage = stageRef.current;
        const templateLayer = templateLayerRef.current;
        const printArea = printAreaRef.current;
        const drawLayer = drawingLayerRef.current;

        let designView = document.querySelector("#DesignView");

        if (stage && designView) {
            if (isMobileView) {
                const totalHeight = window.innerHeight;
                const otherElementsHeight = 300;  // value based on the total height of other fixed elements

                const availableHeight = totalHeight - otherElementsHeight;
                const calculatedHeight = availableHeight * (designView.offsetWidth / window.innerWidth);

                setStageRect({
                    width: designView.offsetWidth,
                    height: calculatedHeight
                });
            } else {
                setStageRect({
                    width: designView.offsetWidth,
                    height: Math.max(window.innerHeight - designView.offsetTop - 550, designView.offsetWidth) //350  //550 looks better
                });
            }

            stage.position({ x: 0, y: 0 });
        }

        const templateSize = {
            width: template.template_width,
            height: template.template_height,
        };

        const scaleX = stage.width() / templateSize.width - 0.01;
        const scaleY = stage.height() / templateSize.height - 0.01;
        const scaleValue = Math.min(Math.abs(scaleX), Math.abs(scaleY));

        const computedScale = {
            x: scaleValue,
            y: scaleValue,
        };

        // Calculate the centered position
        const centeredPositionX = (stage.width() - templateSize.width * computedScale.x) / 2;
        const centeredPositionY = (stage.height() - templateSize.height * computedScale.y) / 2;

        // Apply scale and position to each layer
        [templateLayer, printArea, drawLayer].forEach(layer => {
            layer.scale(computedScale);
            layer.position({ x: centeredPositionX, y: centeredPositionY });
        });

        setScale(computedScale);

    }


    //Product Type ID has changed
    React.useEffect(() => {
        // console.log("1 - Product Type ID has changed");
        loadAllVariants(productTypeId);
        setSelectedPreviewPaneTemplateId(0);
        setSelectedSize("");
        setSelectedColor("");
        //setSelectedProductVariantId('');
    }, [productTypeId]);

    //selectedColor, selectedSize, productTypeId have changed
    React.useEffect(() => {
        // console.log("2 - selectedColor, selectedSize, productTypeId have changed");
        // only run if productTypeId is set
        if (productTypeId) {

            const availableColorsForSelectedSizeURL =
                "/api/v1/product_types/" +
                productTypeId +
                "/product_variants/?size=" +
                selectedSize;
            const availableSizesForSelectedColorURL =
                "/api/v1/product_types/" +
                productTypeId +
                "/product_variants/?color=" +
                selectedColor;

            // Get the available Colors for the selected Size
            axios
                .get(availableColorsForSelectedSizeURL)
                .then((response) => {
                    let colors = [];
                    response.data.forEach((item) => {
                        if (item.color && !colors.includes(item.color)) {
                            colors.push(item.color);
                        }
                    });
                    setSelectableColors(colors);
                })
                .catch((error) => {
                    console.error(error);
                    // default behavior for a failed or empty response
                    setSelectableColors([]);
                });

            // Get the available Sizes for the selected Color
            axios
                .get(availableSizesForSelectedColorURL)
                .then((response) => {
                    let sizes = [];
                    response.data.forEach((item) => {
                        if (item.size && !sizes.includes(item.size)) {
                            sizes.push(item.size);
                        }
                    });
                    setSelectableSizes(sizes);
                })
                .catch((error) => {
                    console.error(error);
                    // default behavior for a failed or empty response
                    setSelectableSizes([]);
                });
        }
    }, [selectedColor, selectedSize, productTypeId]);

    // Color and Size have changed
    React.useEffect(() => {
        //console.log("3 - Color and Size have changed");

        // if productTypeId, selectedColor and selectedSize are set, get the product variant
        if (productTypeId && selectedColor && selectedSize) {
            const variantURL =
                "/api/v1/product_types/" +
                productTypeId +
                "/product_variants/?size=" +
                selectedSize +
                selectedSize +
                "&color=" +
                selectedColor;
            axios
                .get(variantURL)
                .then((response) => {
                    setSelectedProductVariantId(response.data[0].id);
                    setExternalVariantId(response.data[0].printful_variant_id);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [selectedColor, selectedSize]);

    // Color has changed
    React.useEffect(() => {
        // console.log("4 - Color has changed");
        // console.log("selectedColor: " + selectedColor);
        // only run if productTypeId is set
        if (productTypeId) {

            const variantURL =
                "/api/v1/product_types/" +
                productTypeId +
                "/product_variants/?size=" +
                selectedSize +
                "&color=" +
                selectedColor;
            axios
                .get(variantURL)
                .then((response) => {
                    setExternalVariantId(response.data[0].printful_variant_id);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [selectedColor]);

    // External (?) Product Variant ID has changed; update the loaded template
    React.useEffect(() => {
        // console.log("5 - External (?) Product Variant ID has changed");
        loadTemplateInformation(externalProductId, externalVariantId);
        //loadPrintFiles(externalProductId, externalVariantId);
        loadColorSwatches(productTypeId, selectedProductVariantId);
        loadAvailableSizes(productTypeId, selectedProductVariantId);
    }, [externalVariantId]);

    // Selected Design Id has changed
    React.useEffect(() => {
        // console.log("6 - Selected Design Id has changed");
        loadTemplate();
        if (selectedDesignId !== "") loadDesign();
        //loadTemplate();
    }, [selectedDesignId]);

    const loadDesign = () => {
        const designURL = "/api/v1/designs/" + selectedDesignId;
        axios
            .get(designURL)
            .then((response) => {
                const design = response.data;
                let state = design.state !== null ? design.state : [];
                // Initialize original dimensions for each element
                const initializedDesign = state.map(elem => {
                    return {
                        ...elem,
                        originalWidth: elem.width,
                        originalHeight: elem.height,
                    };
                });

                setElems(initializedDesign);
                setInitialLoad(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const loadPrintFiles = (externalProductId, externalVariantId) => {

        const printFilesURL =
            "/api/v1/product_types/" +
            externalProductId +
            "/product_print_files/" +
            externalVariantId;
        axios
            .get(printFilesURL)
            .then((response) => {
                const retrievedPrintFiles = response.data;
                // Todo: Only load the printfiles from enabled placements
                setPrintFiles(retrievedPrintFiles["message"]["full_placements"]);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const computeTransformValues = (currentPrintableArea, newPrintableArea, originalPrintableArea) => {
        // Add checks to ensure the input objects are valid
        if (!currentPrintableArea || !newPrintableArea) {
            return { shiftX: 0, shiftY: 0, scaleFactor: 1 };
        }

        // 1. Compute the shift in x and y coordinates directly based on top-left corners
        const shiftX = newPrintableArea.x - currentPrintableArea.x;
        const shiftY = newPrintableArea.y - currentPrintableArea.y;

        let scaleFactor;

        if (arePrintAreasEqual(newPrintableArea, originalPrintableArea)) {
            //Original print area detected. Skipping scaling.
            scaleFactor = 1;
        } else {
            // 2. Compute the scaling factor using the average scale
            const scaleX = newPrintableArea.width / currentPrintableArea.width;
            const scaleY = newPrintableArea.height / currentPrintableArea.height;
            scaleFactor = Math.min(scaleX, scaleY);
        }

        const finalShiftX = isNaN(shiftX) ? 0 : shiftX;
        const finalShiftY = isNaN(shiftY) ? 0 : shiftY;
        const finalScaleFactor = isNaN(scaleFactor) ? 1 : scaleFactor;

        return { shiftX: finalShiftX, shiftY: finalShiftY, scaleFactor: finalScaleFactor };
    };


    const arePrintAreasEqual = (area1, area2) => {
        return (
            area1.x === area2.x &&
            area1.y === area2.y &&
            area1.width === area2.width &&
            area1.height === area2.height
        );
    };

    const adjustDesignToNewTemplate = (currentDesignState, newTemplate) => {
        if (initialLoad) {
            return;
        }
        const newPrintArea = {
            x: newTemplate.print_area_left,
            y: newTemplate.print_area_top,
            width: newTemplate.print_area_width,
            height: newTemplate.print_area_height,
        };

        const currentPrintableArea = {
            x: template.print_area_left,
            y: template.print_area_top,
            width: template.print_area_width,
            height: template.print_area_height,
        };

        // Check if the print areas are the same
        if (arePrintAreasEqual(newPrintArea, currentPrintableArea)) {
            return;
        }

        const { shiftX, shiftY, scaleFactor } = computeTransformValues(currentPrintableArea, newPrintArea, originalPrintableArea);

        // Validate if the scaling factor is valid before adjusting design elements
        if (!isNaN(scaleFactor)) {
            const adjustedDesign = currentDesignState.map(elem => {
                // Always use original dimensions for scaling
                const originalWidth = elem.originalWidth || elem.width;
                const originalHeight = elem.originalHeight || elem.height;

                // Adjust each element's position
                const adjustedX = elem.x + shiftX;
                const adjustedY = elem.y + shiftY;

                // Adjust each element's size and scale
                const adjustedWidth = originalWidth * scaleFactor;
                const adjustedHeight = originalHeight * scaleFactor;
                const adjustedScaleX = elem.scaleX ? elem.scaleX * scaleFactor : scaleFactor;
                const adjustedScaleY = elem.scaleY ? elem.scaleY * scaleFactor : scaleFactor;

                // To do: Still unable to translate the width and height appropriately for different sized print areas
                // Return the adjusted element
                return {
                    ...elem,
                    x: adjustedX,
                    y: adjustedY,
                    //width: adjustedWidth,
                    //height: adjustedHeight,
                    //scaleX: adjustedScaleX,
                    //scaleY: adjustedScaleY,
                    originalWidth: originalWidth,   // Save original dimensions for future adjustments
                    originalHeight: originalHeight,
                };
            });

            setElems(adjustedDesign);
            stageRef.current.batchDraw();

        } else {
            console.error("Invalid scale factor detected. Design adjustment skipped.");
        }
    };


    const loadTemplateInformation = (external_id, external_variant_id) => {

        if (external_id && external_variant_id) {
            const templatesURL =
                "/api/v1/product_types/" +
                external_id +
                "/product_templates/" +
                external_variant_id;
            axios
                .get(templatesURL)
                .then((response) => {
                    placementRef.current = response.data[0].placement;
                    const template_info = response.data[0]["template_data"];

                    // Adjust the design to fit the new template
                    adjustDesignToNewTemplate(elems, template_info);

                    setTemplates(response.data); //add list of all templates for this product
                    setTemplate(template_info);

                    if (initialLoad) {
                        const children = printAreaRef.current.children;
                        const groupChild = children.find(child => child.getClassName() === "Group");

                        if (groupChild) {
                            const groupAttributes = {
                                x: groupChild.x(),
                                y: groupChild.y(),
                                width: groupChild.width(),
                                height: groupChild.height()
                            };

                            setOriginalPrintableArea(groupAttributes);
                        }

                    }

                    const event = new Event("templateLoad");
                    window.dispatchEvent(event);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const loadColorSwatches = (productTypeId) => {
        if (productTypeId) {
            const colorSwatchesURL =
                "/api/v1/product_types/" + productTypeId + "/product_variants/colors";

            axios
                .get(colorSwatchesURL)
                .then((response) => {
                    const colorSwatches = response.data;
                    setAvailableColors(colorSwatches);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const loadAvailableSizes = (productTypeId) => {
        if (productTypeId) {
            const availableSizesURL =
                "/api/v1/product_types/" + productTypeId + "/product_variants/sizes";

            axios
                .get(availableSizesURL)
                .then((response) => {
                    const availableSizes = response.data;
                    setAvailableSizes(availableSizes);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const loadAllVariants = (productTypeId) => {
        // Only run if productTypeId is set
        if (productTypeId !== "") {
            const variantsURL = `/api/v1/product_types/${productTypeId}/product_variants`;
            axios
                .get(variantsURL)
                .then((response) => {
                    const variants = response.data;
                    if (variants.length > 0) {
                        setProductVariants(variants);
                        setExternalVariantId(variants[0].printful_variant_id); // set the first variant as the default
                        setSelectedProductVariantId(variants[0].id);

                        // Fetch the store price for the first variant
                        const storePriceURL = `/api/v1/product_types/${productTypeId}/product_variants/store_price`;
                        axios
                            .get(storePriceURL)
                            .then((response) => {
                                const storePrice = response.data.store_price;
                                if (storePrice !== undefined) {
                                    setBasePrice(storePrice);
                                    setPrice(storePrice);
                                } else {
                                    console.error("Store price not found");
                                }
                            })
                            .catch((error) => {
                                console.error("Error fetching store price:", error);
                            });
                    } else {
                        console.error('No variants found for product type:', productTypeId);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching product variants:', error);
                });
        }
    };


    const loadTemplate = () => {
        loadTemplateInformation(externalProductId, externalVariantId);
    };

    const loadProduct = (e) => {
        setProductTypeId(e.target.dataset.product_type_id);
        setSelectedColor(null);
        setSelectedSize(null);
        setExternalVariantId(null);
        //setSelectedProductVariantId(null);
        loadTemplateInformation(e.target.dataset.product_type_id);
        loadAllVariants(e.target.dataset.product_type_id);
    };


    return (
        <>
            <SelectionProvider
                elems={elems}
                setElems={setElems}
                drawingLayerRef={drawingLayerRef}
                trRef={trRef}
                stageRef={stageRef}
            >
            
            {(isMobileView) ? <MobileHeader
                template={template}
                templates={templates}
                setTemplate={setTemplate}
                setTemplates={setTemplates}
                placementRef={placementRef}
                fitToScreen={fitToScreen}
                drawingHistory={drawingHistory}
                title={title}
                productId={product_id}
                designId={selectedDesignId}
                currentUser={currentUser}
                productTypeId={productTypeId}
                selectedProductVariantId={selectedProductVariantId}
                elems={elems}
                previewStageRef={previewStageRef}
                printFiles={printFiles}
                externalProductId={externalProductId}
                externalVariantId={externalVariantId}
            /> : null}
            {(isMobileView) ? null :

                <div className="col-lg-12">
                    <div id="product-picker">
                        <ErrorBoundary>
                            <SimpleProductPicker
                                setSelectedDesignId={setSelectedDesignId}
                                setExternalProductId={setExternalProductId}
                                setExternalVariantId={setExternalVariantId}
                                setSelectedColor={setSelectedColor}
                                productTypeId={productTypeId}
                                setProductTypeId={setProductTypeId}
                                loadProduct={loadProduct}
                                availableColors={availableColors}
                                categoryId={categoryId}
                                setCategoryId={setCategoryId}
                                onClose={() => {}}
                            /></ErrorBoundary>
                    </div>
                </div>
            }
            {(isMobileView) ? null :
                <div className="col-lg-1 content__sides">
                    <div className="sides">

                        <ErrorBoundary>
                            <SimplePlacementPicker
                                template={template}
                                templates={templates}
                                setTemplate={setTemplate}
                                setTemplates={setTemplates}
                                placementRef={placementRef}
                                fitToScreen={fitToScreen}
                            /></ErrorBoundary>

                    </div>
                </div>
            }
            <div
                className="col-lg-7 pe-lg-0 pt-lg-4"
                style={{ position: "relative" }}
            >
                {(isMobileView) ? null :
                    <div id="element-properties">
                        <ErrorBoundary><PropertiesPanel
                            stageRef={stageRef}
                            printAreaRef={printAreaRef}
                            placementRef={placementRef}
                            template={template}
                            selectedGroupRef={selectedGroupRef}
                            symmetricalDrawingLayerRef={symmetricalDrawingLayerRef}
                            elems={elems}
                            setElems={setElems}
                            onElementsChange={(elements) => {
                                setElems(elements);
                            }}
                        /></ErrorBoundary>
                    </div>}
                <OffScreenPatternCanvas ref={offScreenPatternCanvasRef} />

                <div id="worktable" ref={worktableRef}>
                    <ErrorBoundary>
                        <WorkTable
                            trRef={trRef}
                            scale={scale}
                            setScale={setScale}
                            product_id={product_id}
                            elems={elems}
                            setElems={setElems}
                            drawingHistory={drawingHistory}
                            availableColors={availableColors}
                            setAvailableColors={setAvailableColors}
                            availableSizes={availableSizes}
                            setSelectedColor={setSelectedColor}
                            selectableColors={selectableColors}
                            selectableSizes={selectableSizes}
                            setSelectedSize={setSelectedSize}
                            template={template}
                            templates={templates}
                            setTemplate={setTemplate}
                            placementRef={placementRef}
                            stageRect={stageRect}
                            setStageRect={setStageRect}
                            stageRef={stageRef}
                            drawingLayerRef={drawingLayerRef}
                            symmetricalDrawingLayerRef={symmetricalDrawingLayerRef}
                            symmetryType={symmetryType}
                            setSymmetryType={setSymmetryType}
                            selectedPatternTool={selectedPatternTool}
                            setSelectedPatternTool={setSelectedPatternTool}
                            patternDesign={patternDesign}
                            setPatternDesign={setPatternDesign}
                            isPatternDesignUpdatingFromAsset={isPatternDesignUpdatingFromAsset}
                            setIsPatternDesignUpdatingFromAsset={setIsPatternDesignUpdatingFromAsset}
                            selectedPatternDesignElementId={selectedPatternDesignElementId}
                            setSelectedPatternDesignElementId={setSelectedPatternDesignElementId}
                            symmetryParameters={symmetryParameters}
                            setSymmetryParameters={setSymmetryParameters}
                            offScreenPatternCanvasRef={offScreenPatternCanvasRef}
                            categoryName={categoryName}
                            categoryId={categoryId}
                            productTypeIds={productTypeIds}
                            setProductTypeId={setProductTypeId}
                            productTypes={productTypes}
                            setProductTypes={setProductTypes}
                            setExternalProductId={setExternalProductId}
                            productTypeId={productTypeId}
                            selectedProductVariantId={selectedProductVariantId}
                            selectedColor={selectedColor}
                            selectedSize={selectedSize}
                            selectedPreviewPaneTemplateId={selectedPreviewPaneTemplateId}
                            setSelectedPreviewPaneTemplateId={setSelectedPreviewPaneTemplateId}
                            previewPaneRef={previewPaneRef}
                            setProductPhotoURLs={setProductPhotoURLs}
                            printAreaRef={printAreaRef}
                            selectedGroupRef={selectedGroupRef}
                            templateLayerRef={templateLayerRef}
                            printedAreaRef={printedAreaRef}
                            fitToScreen={fitToScreen}
                            isMobileView={isMobileView}
                            isDrawingMode={isDrawingMode}
                            setIsDrawingMode={setIsDrawingMode}
                            brushType={brushType}
                            setBrushType={setBrushType}
                            brushColor={brushColor}
                            setBrushColor={setBrushColor}
                            brushSize={brushSize}
                            setBrushSize={setBrushSize}
                            isEraserActive={isEraserActive}
                            setIsEraserActive={setIsEraserActive}
                        /></ErrorBoundary>
                </div>
            </div>

            <div className="col-lg-4 pt-4 pt-lg-0">
                {/* <div id="elementAssets">
          <ElementAssets
              selectedId={selectedId}
          />
        </div> */}
                {(isMobileView) ? null :
                    <div id="price-calculator">
                        <ErrorBoundary>
                            <PriceCalculator
                                elems={elems}
                                price={price}
                                baseprice={basePrice}
                                setPrice={setPrice}
                                templates={templates}
                            /></ErrorBoundary>
                    </div>
                }
                {(isMobileView) ? null :
                    <div id="btn-create-new-product" style={{margin: '20px auto', textAlign: 'center'}}>
                        <ErrorBoundary>
                            {props.is_admin || props.is_shop_owner ? 
                            <DashboardCreateNewProduct 
                                productId={product_id}
                                title={title}
                                currentUser={currentUser}
                                productTypeId={productTypeId}
                                selectedProductVariantId={selectedProductVariantId}
                                elems={elems}
                                templates={templates}
                                previewStageRef={previewStageRef}
                                printFiles={printFiles}
                                externalProductId={externalProductId}
                                externalVariantId={externalVariantId}
                            /> : 
                            <CreateNewProduct
                                productId={product_id}
                                title={title}
                                currentUser={currentUser}
                                productTypeId={productTypeId}
                                selectedProductVariantId={selectedProductVariantId}
                                elems={elems}
                                templates={templates}
                                previewStageRef={previewStageRef}
                                printFiles={printFiles}
                                externalProductId={externalProductId}
                                externalVariantId={externalVariantId}
                            />
                            }
                        </ErrorBoundary>
                    </div>
                }
                {(isMobileView) ?
                    <>
                        <ContextualPanel
                            stageRef={stageRef}
                            printAreaRef={printAreaRef}
                            placementRef={placementRef}
                            printedAreaRef={printedAreaRef}
                            template={template}
                            selectedGroupRef={selectedGroupRef}
                            symmetricalDrawingLayerRef={symmetricalDrawingLayerRef}
                            elems={elems}
                            setElems={setElems}
                            onElementsChange={(elements) => {
                                setElems(elements);
                            }}
                            isGuest={isGuest}
                            setSelectedDesignId={setSelectedDesignId}
                            setExternalProductId={setExternalProductId}
                            setExternalVariantId={setExternalVariantId}
                            setSelectedColor={setSelectedColor}
                            productTypeId={productTypeId}
                            setProductTypeId={setProductTypeId}
                            loadProduct={loadProduct}
                            availableColors={availableColors}
                            categoryId={categoryId}
                            setCategoryId={setCategoryId}
                            product_id={product_id}
                            previewStageRef={previewStageRef}
                            previewPaneRef={previewPaneRef}
                            templates={templates}
                            selectedPreviewPaneTemplateId={selectedPreviewPaneTemplateId}
                            setSelectedPreviewPaneTemplateId={setSelectedPreviewPaneTemplateId}
                            setProductPhotoURLs={setProductPhotoURLs}
                            isDrawingMode={isDrawingMode}
                            setIsDrawingMode={setIsDrawingMode}
                        />
                    </>
                    :
                    <div id="assets">
                        <ErrorBoundary>
                            <SimpleAssets
                                elems={elems}
                                setElems={setElems}
                                setSelectedDesignId={setSelectedDesignId}
                                setExternalProductId={setExternalProductId}
                                setExternalVariantId={setExternalVariantId}
                                setProductTypeId={setProductTypeId}
                                loadProduct={loadProduct}
                                placementRef={placementRef}
                                printedAreaRef={printedAreaRef}
                                printAreaRef={printAreaRef}
                                drawingLayerRef={drawingLayerRef}
                                offScreenPatternCanvasRef={offScreenPatternCanvasRef}
                                symmetricalDrawingLayerRef={symmetricalDrawingLayerRef}
                                symmetryType={symmetryType}
                                setSymmetryType={setSymmetryType}
                                selectedPatternTool={selectedPatternTool}
                                setSelectedPatternTool={setSelectedPatternTool}
                                patternDesign={patternDesign}
                                setPatternDesign={setPatternDesign}
                                isPatternDesignUpdatingFromAsset={isPatternDesignUpdatingFromAsset}
                                setIsPatternDesignUpdatingFromAsset={setIsPatternDesignUpdatingFromAsset}
                                selectedPatternDesignElementId={selectedPatternDesignElementId}
                                setSelectedPatternDesignElementId={setSelectedPatternDesignElementId}
                                symmetryParameters={symmetryParameters}
                                setSymmetryParameters={setSymmetryParameters}
                                stageRef={stageRef}
                                stageRect={stageRect}
                                scale={scale}
                                isGuest={isGuest}
                                selectedGroupRef={selectedGroupRef}
                                isDrawingMode={isDrawingMode}
                                setIsDrawingMode={setIsDrawingMode}
                                brushType={brushType}
                                setBrushType={setBrushType}
                                brushColor={brushColor}
                                setBrushColor={setBrushColor}
                                brushSize={brushSize}
                                setBrushSize={setBrushSize}
                                isEraserActive={isEraserActive}
                                setIsEraserActive={setIsEraserActive}
                                template={template}
                            /></ErrorBoundary>
                    </div>
                }
                {(isMobileView) ? null :
                    <div id="previewer" style={{ position: 'absolute', bottom: '10px', left: '25px', width: '150px' }}>
                        <ErrorBoundary>
                            <SimplePreviewer
                                product_id={product_id}
                                previewStageRef={previewStageRef}
                                previewPaneRef={previewPaneRef}
                                elems={elems}
                                templates={templates}
                                selectedPreviewPaneTemplateId={selectedPreviewPaneTemplateId}
                                setSelectedPreviewPaneTemplateId={setSelectedPreviewPaneTemplateId}
                                setProductPhotoURLs={setProductPhotoURLs}
                            /></ErrorBoundary>
                    </div>
                }
            </div>

            </SelectionProvider>
        </>
    );
};
