import React, { useState } from 'react';
import formattingIcon from '../../../../assets/images/icon/formatting.svg';
import { ButtonGroup } from "react-bootstrap";
import {Path} from '../TextWarp/Path';
import Session from 'svg-text-to-path';  // For Text to SVG Path

const PropertyRow = ({ label, min, max, step, value, onChange }) => {
    return (
        <div className="property-row">
            <div className="property-row__header">
                <label>{label}</label>
                <span className="property-row__value">{value}</span>
            </div>
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="form-range"
            />
        </div>
    );
};

export const useFontFormatting = (selectedItem, elems, onElementsChange, selectedId) => {
    const [distortionType, setDistortionType] = useState(selectedItem?.distortionType || '');
    const [bendValue, setBendValue] = useState(selectedItem?.bendValue || 30);
    const [horizontalDistort, setHorizontalDistort] = useState(selectedItem?.horizontalDistort || 0);
    const [verticalDistort, setVerticalDistort] = useState(selectedItem?.verticalDistort || 0);

    const updateTextPath = (updatedProps) => {
        if (!selectedId || !selectedItem) return;

        const path = new Path(selectedItem.originalData);
        
        path.warp({
            type: updatedProps.distortionType || 'WARP_ARC',
            bend: (updatedProps.bendValue / 100) || 0,
            distortV: (updatedProps.horizontalDistort / 100) || 0,
            distortH: (updatedProps.verticalDistort / 100) || 0
        });

        const updatedElems = elems.map(elem => {
            if (elem.id === selectedId) {
                return {
                    ...elem,
                    ...updatedProps,
                    data: path.output()
                };
            }
            return elem;
        });

        onElementsChange(updatedElems);
    };

    const handleDistortionChange = (type) => {
        setDistortionType(type);
        updateTextPath({
            distortionType: type,
            bendValue,
            horizontalDistort,
            verticalDistort
        });
    };

    const handleBendValueChange = (value) => {
        const newValue = parseFloat(value);
        setBendValue(newValue);
        updateTextPath({
            distortionType,
            bendValue: newValue,
            horizontalDistort,
            verticalDistort
        });
    };

    const handleHorizontalDistortChange = (value) => {
        const newValue = parseFloat(value);
        setHorizontalDistort(newValue);
        updateTextPath({
            distortionType,
            bendValue,
            horizontalDistort: newValue,
            verticalDistort
        });
    };

    const handleVerticalDistortChange = (value) => {
        const newValue = parseFloat(value);
        setVerticalDistort(newValue);
        updateTextPath({
            distortionType,
            bendValue,
            horizontalDistort,
            verticalDistort: newValue
        });
    };

    const handleResetEffect = () => {
        setDistortionType('');
        setBendValue(30);
        setHorizontalDistort(10);
        setVerticalDistort(10);

        const updatedElems = elems.map(elem => {
            if (elem.id === selectedId) {
                return {
                    ...elem,
                    distortionType: '',
                    bendValue: 30,
                    horizontalDistort: 10,
                    verticalDistort: 10,
                    data: elem.originalData // Reset to original path data
                };
            }
            return elem;
        });

        onElementsChange(updatedElems);
    };

    return {
        distortionType,
        bendValue,
        horizontalDistort,
        verticalDistort,
        handleDistortionChange,
        handleBendValueChange,
        handleHorizontalDistortChange,
        handleVerticalDistortChange,
        handleResetEffect
    };
};

export const FontFormattingControls = ({
    isMobileView,
    onButtonClick,
    mobileIconStyles
}) => {
    if (isMobileView) {
        return (
            <button
                style={mobileIconStyles.mobile}
                className="mobile_context_button"
                onClick={onButtonClick}
            >
                <div
                    className="mobile_context_button_icon"
                    data-icon="Format"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${formattingIcon})`
                    }}
                />
                <div>Format</div>
            </button>
        );
    }
    
    return (
        <button
            className="properties-panel__button desktop"
            onClick={onButtonClick}
        >
            <div
                className="properties-panel__button-icon"
                style={{
                    backgroundImage: `url(${formattingIcon})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            />
            <span className="properties-panel__button-label">Format</span>
        </button>
    );
};

export const FontFormattingControlsContent = ({
    selectedItem,
    onElementsChange,
    elems,
    selectedId,
    isMobileView
}) => {
    const {
        distortionType,
        bendValue,
        horizontalDistort,
        verticalDistort,
        handleDistortionChange,
        handleBendValueChange,
        handleHorizontalDistortChange,
        handleVerticalDistortChange,
        handleResetEffect
    } = useFontFormatting(selectedItem, elems, onElementsChange, selectedId);

    const handleTextAttributeChange = async (updates) => {
        if (!selectedId || !selectedItem?.textValue) return;
        
        let svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
        
        const existingElem = elems.find(elem => elem.id === selectedId);
        if (!existingElem) return;

        const currentAttributes = {
            fontSize: existingElem.fontSize || 72,
            letterSpacing: existingElem.letterSpacing || 0,
            textAnchor: existingElem.textAnchor || 'start'
        };

        const mergedAttributes = Object.entries(updates).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {...currentAttributes});

        let tempContainer = document.createElement('div');
        tempContainer.style.display = 'none';
        document.body.appendChild(tempContainer);

        try {
            const attributes = {
                fill: existingElem.fill || "black",
                x: "0",
                y: mergedAttributes.fontSize.toString(),
                "font-family": existingElem.fontFamily,
                "font-size": mergedAttributes.fontSize.toString(),
                "letter-spacing": mergedAttributes.letterSpacing,
                "text-anchor": mergedAttributes.textAnchor
            };

            Object.entries(attributes).forEach(([attr, value]) => 
                textElement.setAttribute(attr, value)
            );

            textElement.textContent = existingElem.textValue;
            svgElement.appendChild(textElement);
            tempContainer.appendChild(svgElement);

            const session = new Session(svgElement, {
                googleApiKey: 'AIzaSyCOE5K7L-6bbNK6aY3SBU-ZT8kLVw_D2XU',
            });

            await session.replaceAll();
            const path = svgElement.querySelector('path');
            const pathData = path?.getAttribute('d');

            if (pathData) {
                const updatedElems = elems.map(elem => 
                    elem.id === selectedId 
                        ? {
                            ...elem,
                            ...mergedAttributes,
                            data: pathData,
                            originalData: pathData
                        }
                        : elem
                );
                onElementsChange(updatedElems);
            }
        } finally {
            document.body.removeChild(tempContainer);
        }
    };

    const WARP_TYPES = [
        { value: 'WARP_ARC', label: 'Arc' },
        { value: 'WARP_ARC_LOWER', label: 'Arc Lower' },
        { value: 'WARP_ARC_UPPER', label: 'Arc Upper' },
        { value: 'WARP_ARCH', label: 'Arch' },
        { value: 'WARP_BULGE', label: 'Bulge' },
        { value: 'WARP_FLAG', label: 'Flag' },
        { value: 'WARP_FISH', label: 'Fish' },
        { value: 'WARP_RISE', label: 'Rise' },
        { value: 'WARP_INFLATE', label: 'Inflate' },
        { value: 'WARP_SQUEEZE', label: 'Squeeze' },
        { value: 'WARP_WAVE_LOWER', label: 'Wave Lower' },
        { value: 'WARP_WAVE_UPPER', label: 'Wave Upper' }
    ];

    return (
        <div className={`font-formatting-controls ${isMobileView ? 'mobile' : ''}`}>
            <div className="font-formatting-controls__section">
                <div className="section-header">
                    <label>Text Effect</label>
                    <button 
                        className="reset-button"
                        onClick={handleResetEffect}
                        disabled={!distortionType && bendValue === 0 && horizontalDistort === 0 && verticalDistort === 0}
                    >
                        Reset Effect
                    </button>
                </div>
                <div className="warp-types-grid">
                    {WARP_TYPES.map(({ value, label }) => (
                        <button
                            key={value}
                            className={`warp-type-button ${distortionType === value ? 'active' : ''}`}
                            onClick={() => handleDistortionChange(value)}
                        >
                            <span className="warp-type-button__preview">Aa</span>
                            <span className="warp-type-button__label">{label}</span>
                        </button>
                    ))}
                </div>
                <PropertyRow
                    label="Bend"
                    min={-100}
                    max={100}
                    step={1}
                    value={bendValue}
                    onChange={handleBendValueChange}
                />
                <PropertyRow
                    label="Horizontal"
                    min={-100}
                    max={100}
                    step={1}
                    value={horizontalDistort}
                    onChange={handleHorizontalDistortChange}
                />
                <PropertyRow
                    label="Vertical"
                    min={-100}
                    max={100}
                    step={1}
                    value={verticalDistort}
                    onChange={handleVerticalDistortChange}
                />
            </div>

            <div className="font-formatting-controls__section">
                <label>Text Properties</label>
                <div className="text-properties-grid">
                    <PropertyRow
                        label="Size"
                        min={8}
                        max={288}
                        step={1}
                        value={selectedItem?.fontSize || 72}
                        onChange={(value) => handleTextAttributeChange({ fontSize: parseInt(value) })}
                    />
                    <PropertyRow
                        label="Letter Spacing"
                        min={-20}
                        max={100}
                        step={1}
                        value={selectedItem?.letterSpacing || 0}
                        onChange={(value) => handleTextAttributeChange({ letterSpacing: parseInt(value) })}
                    />
                </div>
                <div className="text-alignment-buttons">
                    <ButtonGroup>
                        <button
                            className={`style-button ${selectedItem?.textAnchor === 'start' ? 'active' : ''}`}
                            onClick={() => handleTextAttributeChange({ textAnchor: 'start' })}
                        >
                            ←
                        </button>
                        <button
                            className={`style-button ${selectedItem?.textAnchor === 'middle' ? 'active' : ''}`}
                            onClick={() => handleTextAttributeChange({ textAnchor: 'middle' })}
                        >
                            ↔
                        </button>
                        <button
                            className={`style-button ${selectedItem?.textAnchor === 'end' ? 'active' : ''}`}
                            onClick={() => handleTextAttributeChange({ textAnchor: 'end' })}
                        >
                            →
                        </button>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};
