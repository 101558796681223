import React from 'react';
import { Circle, Transformer } from 'react-konva';

export const renderCircleOnCanvas = (context, { x, y, width, height, fill, stroke, strokeWidth }) => {

}

export const Circ = ({ shapeProps,
                         isSelected,
                         onSelect,
                         onMouseOver,
                         onMouseOut,
                         onChange,
                         element,
                         //layerRef,
                         trRef,
                         setSelectedElement,
                         template,
                         draggable,
                         globalCompositeOperation,
                         opacity,
                         onTouchStart,
                         onTouchMove,
                         onTouchEnd,
                         onTap

                     }) => {
    //let layer = layerRef.current;
    const shapeRef = React.useRef();
    let designView = document.querySelector("#DesignView");
    let containerWidth = designView.offsetWidth;
    //const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            //trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <Circle
            id={element.id.toString()}
            globalCompositeOperation={globalCompositeOperation || 'source-over'}
            opacity={opacity || 1}
            ref={shapeRef}
            name="element"
            //x={element.x}
            //y={element.y}
            width={element.width}
            height={element.height}
            rotation={element.rotation}
            stroke={element.stroke}
            strokeWidth={element.strokeWidth}
            fill={element.fill}
            onClick={onSelect}
            {...shapeProps}
            draggable={draggable}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            // onMouseOver={() => {
            //     const node = shapeRef.current;
            //     node.shadowColor('black');
            //     node.shadowBlur(35);
            //     node.shadowOpacity(1);
            // }}
            // onMouseOut={() => {
            //     const node = shapeRef.current;
            //     node.scaleX(1);
            //     node.scaleY(1);
            //     node.shadowOpacity(0);
            // }}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onTap={onTap}
            onDragEnd={(e) => {
                onChange({
                    ...shapeProps,
                    x: e.target.x(),
                    y: e.target.y()
                });
            }}
            onTransformEnd={e => {
                //trRef.current.getLayer().batchDraw();
                const node = shapeRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
                node.scaleX(1);
                node.scaleY(1);
                onChange({
                    ...element,
                    x: node.x(),
                    y: node.y(),
                    width: Math.max(5, node.width() * scaleX),
                    height: Math.max(node.height() * scaleY),
                    rotation: node.rotation()
                });
            }}
        />
    );
};

export default Circ;