import React from 'react';
import {Layer, Stage, Text} from 'react-konva';
import {ContextMenu} from './ui/context-menu';
import PropTypes from "prop-types";
import {
    handleCanvasTouchEnd,
    handleCanvasTouchMove,
    handleCanvasTouchStart,
    onClickTap,
    onMouseDown,
    onMouseMove,
    onMouseUp
} from "./eventHandlers";
import TemplateLayer from "./TemplateLayer";
import DrawingLayer from "./DrawingLayer";
import ScrollbarLayer from "./ScrollbarLayer";
import PrintArea from "./PrintArea";
import SymmetryShapeEditor from "./SymmetryShapeEditor";
import {useSnapping} from "./hooks/useSnapping";
import {SelectionProvider, useSelectionContext, KonvaSelectionProvider} from '../../contexts/SelectionContext';


const DesignCanvas = (props) => {

    const trRef = props.trRef;

    const {
        selectedId,
        setSelectedId,
        setSelectedElement,
    } = useSelectionContext();

    const isElementTouchedRef = React.useRef(false);
    const isElementTransformingRef = React.useRef(false);

    const drawingModeNotification = React.useRef();

    const productTemplateRef = React.useRef();
    const [contextMenuPosition, setContextMenuPosition] = React.useState(null);

    // Free Drawing States
    const [currentLine, setCurrentLine] = React.useState(null);


    // Multi-Selection Transforms

    const scaleRef = React.useRef(props.scale);

    React.useEffect(() => {
        scaleRef.current = props.scale;
    }, [props.scale]);

    React.useEffect(() => {
        console.log('selectedPatternDesignElementId', props.selectedPatternDesignElementId);
    }, [props.selectedPatternDesignElementId]);

    return (
        <>
            <DesignStage
                {...props}
                trRef={trRef}
                scaleRef={scaleRef}
                isElementTouchedRef={isElementTouchedRef}
                isElementTransformingRef={isElementTransformingRef}
                contextMenuPosition={contextMenuPosition}
                setContextMenuPosition={setContextMenuPosition}
                currentLine={currentLine}
                setCurrentLine={setCurrentLine}
            />


            {contextMenuPosition && (
                <ContextMenu
                    position={contextMenuPosition}
                    onRemove={() => {
                        setSelectedId(null);
                        setSelectedElement(null);

                        const index = props.elems.findIndex((e) => e.id === selectedId);
                        let propCopy = props.elems.slice();
                        propCopy.splice(index, 1);
                        props.onElementsChange(propCopy.slice());
                        setContextMenuPosition(null);
                        trRef.current.nodes([]);
                    }}
                    moveToTop={() => {
                        const index = props.elems.findIndex((e) => e.id === selectedId);
                        const element = props.elems[index];
                        let propCopy = props.elems.slice();
                        propCopy.splice(index, 1);
                        propCopy.push(element);
                        props.onElementsChange(propCopy.slice());
                    }}
                    moveToBottom={() => {
                        const index = props.elems.findIndex((e) => e.id === selectedId);
                        const element = props.elems[index];
                        let propCopy = props.elems.slice();
                        propCopy.splice(index, 1);
                        propCopy.unshift(element);
                        props.onElementsChange(propCopy.slice());
                    }}
                    moveDown={() => {
                        //swap index with index - 1 unless index 0
                        const index = props.elems.findIndex((e) => e.id === selectedId);

                        if ( index <= 0) {
                            return
                        } else {
                            const elementToMoveUp = props.elems[index];
                            const elementToMoveDown = props.elems[index - 1];
                            let propCopy = props.elems.slice();
                            propCopy[index - 1] = elementToMoveUp;
                            propCopy[index] = elementToMoveDown;
                            props.onElementsChange(propCopy.slice());
                        }
                    }}
                    moveUp={() => {
                        //swap index with index + 1 unless index = props.elems.length - 1
                        const index = props.elems.findIndex((e) => e.id === selectedId);

                        if ( index >= props.elems.length - 1) {
                            return
                        } else {
                            const elementToMoveDown = props.elems[index];
                            const elementToMoveUp = props.elems[index + 1];
                            let propCopy = props.elems.slice();
                            propCopy[index + 1] = elementToMoveDown;
                            propCopy[index] = elementToMoveUp;
                            props.onElementsChange(propCopy.slice());
                        }
                    }}
                    onHide={() => {
                        setContextMenuPosition(null)
                    }}
                />
            )}
        </>
    );
}

const DesignStage = (props) => {
    const debugTextRef = React.useRef();
    const trRef = props.trRef;
    const {
        //trRef,
        handleSelectionStart,
        handleSelectionMouseMove,
        handleSelectionMouseUp,
        selectionIsVisible,
        selection,
        setNodes,
        oldPos,
        selectionRectRef,
        onDragEnd,
        onDragMove,
        selectedId,
        setSelectedId,
        selectedElement,
        setSelectedElement,
        deselectElement,
        selectElement,
    } = useSelectionContext();

    const symmetryShapeEditorRef = React.useRef();
    // Define the square area's size
    const squareSize = 150; // Size of the square

    // Calculate position based on the stage size and square size
    const squarePosition = {
        x: props.stageRect.width - squareSize - 10, // 10 pixels padding from the right edge
        y: props.stageRect.height - squareSize - 10, // 10 pixels padding from the bottom edge
    };

    return (
        <Stage
            onTouchStart={(e) => handleCanvasTouchStart(e,
                props.scaleRef,
                props.setScale,
                props.stageRef.current,
                props.printAreaRef.current,
                props.drawingLayerRef.current,
                props.templateLayerRef.current,
                props.isElementTouchedRef,
                props.isElementTransformingRef,
                props.isDrawingMode
            )}
            onTouchMove={(e) => handleCanvasTouchMove(e,
                props.scaleRef,
                props.setScale,
                props.stageRef.current,
                props.printAreaRef.current,
                props.drawingLayerRef.current,
                props.templateLayerRef.current,
                props.isElementTouchedRef,
                props.isElementTransformingRef,
                props.isDrawingMode
            )}
            onTouchEnd={(e) => handleCanvasTouchEnd(e, props.scale, props.setScale)}
            ref={props.stageRef}
            container={'#DesignView'}
            width={props.stageRect.width}
            height={props.stageRect.height} //{600} //{props.stageRect.height}
            //onWheel={onWheel}
            onDragEnd={(e) => {
                if (props.snappingEnabled) onDragEnd(e)
            }}
            onDragMove={(e) => {
                if (props.snappingEnabled) onDragMove(e)
            }}
            onMouseDown={(e) =>
                onMouseDown(e, handleSelectionStart, props,
                    props.isDrawingMode,
                    props.setCurrentLine,
                    props.brushSize, props.brushColor, props.isEraserActive,
                    props.setIsEraserActive, props.setBrushColor, props.setBrushSize,
                    props.brushType)}
            onMouseUp={(e) => {
                onMouseUp(e, handleSelectionMouseUp, selectionIsVisible, selection, props, trRef, selectionRectRef,
                    props.isDrawingMode, props.currentLine, props.setCurrentLine,
                    props.brushSize, props.brushColor, props.isEraserActive,
                    props.setIsEraserActive, props.setBrushColor, props.setBrushSize,
                    props.elems, props.setElems, props.brushType, props.setBrushType,
                    // add to active nodes
                    setSelectedId, setSelectedElement, setNodes,
                    props.selectedGroupRef, selectedElement);
            }}
            onMouseMove={(e) => {
                onMouseMove(e, handleSelectionMouseMove, selectionIsVisible,
                    props.isDrawingMode,
                    props.currentLine, props.setCurrentLine,
                    props.brushSize, props.brushColor, props.isEraserActive,
                    props.setIsEraserActive, props.brushType)
            }}
            //onTouchStart={checkDeselect}
            onTap={(e) => onClickTap(e, handleSelectionMouseUp, selection, setSelectedId, setSelectedElement, trRef,
                setNodes, deselectElement, selectElement, props.elems, props.setSelectedPatternDesignElementId)}
            onClick={(e) => onClickTap(e, handleSelectionMouseUp, selection, setSelectedId, setSelectedElement, trRef,
                setNodes, deselectElement, selectElement, props.elems, props.setSelectedPatternDesignElementId)}
            //scale={props.scale} // Note we don't set the scale for the Stage. Instead we are setting the scale for Layers
            onContextMenu={(e) => {
                e.evt.preventDefault();
                const id = e.target.id();
                if (!id) {
                    return;
                }
                setSelectedId(id);
                setContextMenuPosition(e.currentTarget.getPointerPosition());
                //selection.current.visible = false;
            }}
        >
            <KonvaSelectionProvider
                elems={props.elems}
                trRef={trRef}
                stageRef={props.stageRef}
            >
                <TemplateLayer {...props} />
                <DrawingLayer {...props}
                              debugTextRef={debugTextRef}
                              isElementTouchedRef={props.isElementTouchedRef}
                              isElementTransformingRef={props.isElementTransformingRef}
                              isMobileView={props.isMobileView}
                              printAreaRef={props.printAreaRef}
                              stageRef={props.stageRef}
                              currentLine={props.currentLine}
                              isDrawingMode={props.isDrawingMode}
                              placementRef={props.placementRef}
                              setSelectedPatternTool={props.setSelectedPatternTool}
                              selectedPatternTool={props.selectedPatternTool}
                              selectionRectRef={selectionRectRef}
                />
                {!props.isMobileView && (
                    <ScrollbarLayer {...props}
                                    selectionRectRef={selectionRectRef}
                    />
                )}
                <PrintArea {...props} />

                <Layer>
                    <Text
                        ref={debugTextRef}
                        x={0}
                        y={0}
                        text={'X: 0'}
                        fontSize={10}
                        fontFamily={'Calibri'}
                        fill={'black'}
                        name={'positionX'}
                    />
                    {props.isDrawingMode && (
                        <Text
                            ref={props.drawingModeNotification}
                            x={0}
                            y={50}
                            text={'Drawing Mode Enabled'}
                            fontSize={25}
                            fontFamily={'Calibri'}
                            fill={'black'}
                            name={'positionX'}
                        />
                    )}
                </Layer>
                {/* Layer for Symmetry Pattern Editing */}
                {selectedElement && selectedElement.type === 'pattern' && (
                    <SymmetryShapeEditor
                        selectedGroupRef={props.selectedGroupRef}
                        stageRef={props.stageRef}
                        trRef={trRef}
                        setNodes={setNodes}
                        setSelectedId={setSelectedId}
                        setSelectedElement={setSelectedElement}
                        selectedElement={selectedElement}
                        elems={props.elems}
                        setElems={props.setElems}
                        onElementsChange={props.onElementsChange}
                        squareSize={squareSize}
                        squarePosition={squarePosition}
                        symmetricalDrawingLayerRef={props.symmetricalDrawingLayerRef}
                        placementRef={props.placementRef}
                        drawingLayerRef={props.drawingLayerRef}
                        symmetryType={props.symmetryType}
                        setSymmetryType={props.setSymmetryType}
                        symmetryParameters={props.symmetryParameters}
                        setSymmetryParameters={props.setSymmetryParameters}
                        setSelectedPatternTool={props.setSelectedPatternTool}
                        selectedPatternTool={props.selectedPatternTool}
                        offScreenPatternCanvasRef={props.offScreenPatternCanvasRef}
                        patternDesign={props.patternDesign}
                        setPatternDesign={props.setPatternDesign}
                        selectedPatternDesignElementId={props.selectedPatternDesignElementId}
                        setSelectedPatternDesignElementId={props.setSelectedPatternDesignElementId}
                        isPatternDesignUpdatingFromAsset={props.isPatternDesignUpdatingFromAsset}
                        setIsPatternDesignUpdatingFromAsset={props.setIsPatternDesignUpdatingFromAsset}
                    />
                )}
                {/*<SymmetricalBackgroundLayer {...props} />*/}
            </KonvaSelectionProvider>
        </Stage>
    )
}

DesignCanvas.propTypes = {
    elems: PropTypes.array,
}

export default DesignCanvas;