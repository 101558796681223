import React, { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { SimplePlacementPicker } from "../SimplePlacementPicker/SimplePlacementPicker";
import { CreateNewProduct } from "../CreateNewProduct/CreateNewProduct";
import { DashboardCreateNewProduct } from "../DashboardCreateNewProduct/DashboardCreateNewProduct";

export const MobileHeader = ({
    template,
    templates,
    setTemplate,
    setTemplates,
    placementRef,
    fitToScreen,
    currentUser,
    title,
    productTypeId,
    selectedProductVariantId,
    elems,
    previewStageRef,
    printFiles,
    externalProductId,
    externalVariantId,
    productId,
    designId,
    ...props
}) => {

    const createNewProductRef = useRef();
    const dashboardCreateNewProductRef = useRef();

    // Check if we're in dashboard context
    const isDashboardPath = window.location.pathname.includes('/dashboard/');
    
    // Check if user is product owner
    const isProductOwner = currentUser?.id === currentUser?.product_user_id;
    
    // Check if user has dashboard access
    const hasDashboardAccess = currentUser?.is_admin === true || 
                              (currentUser?.roles || []).includes('shop_admin');

    const shouldUseDashboard = hasDashboardAccess && isDashboardPath;

    const handleSave = (e) => {
        e.preventDefault();

        if (shouldUseDashboard) {
            dashboardCreateNewProductRef.current?.saveProduct(e, 'published');
        } else {
            createNewProductRef.current?.saveProduct(e, 'published');
        }
    };

    // Get appropriate button text
    const getButtonText = () => {
        if (isProductOwner) return "Save Changes";
        if (productId) return "Save as Copy";
        return "Create Product";
    };

    return (
        <header className="mobile-header d-lg-none">
            <div className="mobile-header__container">
                {/* Left side placement picker remains the same */}
                <div className="mobile-header__left">
                    <Dropdown align="start">
                        <Dropdown.Toggle variant="light" className="header-btn placement-btn">
                            <i className="fas fa-tshirt me-2"></i>
                            Placement
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="placement-menu">
                            <SimplePlacementPicker
                                template={template}
                                templates={templates}
                                setTemplate={setTemplate}
                                setTemplates={setTemplates}
                                placementRef={placementRef}
                                fitToScreen={fitToScreen}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className="mobile-header__right">
                    {shouldUseDashboard ? (
                        <Dropdown align="end">
                            <Dropdown.Toggle variant="primary" className="header-btn action-btn">
                                <i className="fas fa-save me-2"></i>
                                Save
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => dashboardCreateNewProductRef.current?.saveProduct(e, 'draft')}>
                                    <i className="fas fa-file-alt me-2"></i>
                                    Save to Draft
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => dashboardCreateNewProductRef.current?.saveProduct(e, 'published')}>
                                    <i className="fas fa-upload me-2"></i>
                                    Save & Publish
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <button 
                            className="btn btn-primary header-btn action-btn"
                            onClick={handleSave}
                        >
                            <i className="fas fa-save me-2"></i>
                            {getButtonText()}
                        </button>
                    )}

                    <Dropdown align="end">
                        <Dropdown.Toggle variant="light" className="header-btn auth-btn">
                            <i className="fas fa-user"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {currentUser ? (
                                <>
                                    <Dropdown.Item href="/my_account/index">
                                        <i className="fas fa-user-circle me-2"></i>
                                        My Account
                                    </Dropdown.Item>
                                    <form action="/users/sign_out" method="POST" style={{ display: 'contents' }}>
                                        <input type="hidden" name="_method" value="DELETE" />
                                        <input type="hidden" name="authenticity_token" 
                                               value={document.querySelector('meta[name="csrf-token"]').content} />
                                        <Dropdown.Item as="button" type="submit">
                                            <i className="fas fa-sign-out-alt me-2"></i>
                                            Sign Out
                                        </Dropdown.Item>
                                    </form>
                                </>
                            ) : (
                                <>
                                    <Dropdown.Item href="/users/sign_in">
                                        <i className="fas fa-sign-in-alt me-2"></i>
                                        Sign In
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/users/sign_up">
                                        <i className="fas fa-user-plus me-2"></i>
                                        Register
                                    </Dropdown.Item>
                                </>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Hidden components for functionality */}
                    <div style={{ display: 'none' }}>
                        {shouldUseDashboard ? (
                            <DashboardCreateNewProduct
                                ref={dashboardCreateNewProductRef}
                                productId={productId}
                                designId={designId}
                                title={title}
                                currentUser={currentUser}
                                productTypeId={productTypeId}
                                selectedProductVariantId={selectedProductVariantId}
                                elems={elems}
                                templates={templates}
                                previewStageRef={previewStageRef}
                                printFiles={printFiles}
                                externalProductId={externalProductId}
                                externalVariantId={externalVariantId}
                            />
                        ) : (
                            <CreateNewProduct
                                ref={createNewProductRef}
                                title={title}
                                currentUser={currentUser}
                                productTypeId={productTypeId}
                                selectedProductVariantId={selectedProductVariantId}
                                elems={elems}
                                templates={templates}
                                previewStageRef={previewStageRef}
                                printFiles={printFiles}
                                externalProductId={externalProductId}
                                externalVariantId={externalVariantId}
                            />
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}
