// app/javascript/controllers/customize_preview_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Initialize carousel
    this.carousel = new bootstrap.Carousel(this.element, {
      interval: 4000,
      wrap: true
    })
  }
  
  disconnect() {
    if (this.carousel) {
      this.carousel.dispose()
    }
  }
}