import { Controller } from "@hotwired/stimulus"
import KonvaStageService from "../services/konva_stage_service"

export default class extends Controller {
  static targets = ["canvas", "swatches", "placements"]
  static values = {
    design: Object,
    templates: Array,
    productId: Number,
    variants: Array,
    placements: Array
  }

  async connect() {
    if (!this.hasCanvasTarget) return
    
    this.stageService = await KonvaStageService.createProductPreview(this.canvasTarget, {
      variants: this.variantsValue,
      templates: this.templatesValue,
      designData: this.designValue,
      swatchesContainer: this.hasSwatchesTarget ? this.swatchesTarget : null,
      placementsContainer: this.hasPlacementsTarget ? this.placementsTarget : null,
      productId: this.productIdValue,
      mockupGallerySelector: '[data-controller="mockup-gallery"]'
    })
  }

  changeColor(event) {
    event.preventDefault()
    this.stageService.changeColor(event.currentTarget.dataset.color)
  }

  async changePlacement(event) {
    event.preventDefault()
    await this.stageService.changePlacement(event.currentTarget.dataset.placementType)
  }

  disconnect() {
    if (this.stageService) {
      this.stageService.destroy()
    }
  }
}

