import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Root } from './make'
import axios from 'axios'

// Import utility functions
import { setupAjaxHandlers } from '../src/ajax'
import { initializeBootstrapComponents } from '../src/ui'

class AppInitializer {
  static initialized = false;
  static mountedComponents = new Set();
  static queryClient = new QueryClient();

  static initialize() {
    if (this.initialized) return;
    
    // Initialize axios interceptors
    this.initializeAxiosInterceptors();

    // Initialize React components
    this.initializeReactComponents();
    
    // Initialize UI components
    this.initializeUIComponents();

    this.initialized = true;
    console.log("🚀 Application initialized");
  }

  static initializeReactComponents() {
    // Initialize Make designer
    this.initializeMakeDesigner();
    
    // Initialize Product Variant Selector
    //this.initializeProductVariantSelector();
  }

  static initializeMakeDesigner() {
    // Make Designer
    const root = document.getElementById("rootx");
    if (!root || this.mountedComponents.has('make-designer')) return;

    console.log("🚀 Initializing Make Designer");

    const props = {
      product_id: root.getAttribute("product_id"),
      data: JSON.parse(root.getAttribute("data") || '{}'),
      external_id: root.getAttribute("external_id"),
      external_variant_id: root.getAttribute("external_variant_id"),
      category_id: root.getAttribute("category_id"),
      base_price: parseInt(root.getAttribute("base_price")),
      is_guest: root.getAttribute("is_guest"),
      user_roles: root.getAttribute("user_roles"),
      is_admin: root.getAttribute("is_admin"),
      is_shop_owner: root.getAttribute("is_shop_owner"),
      current_user_id: root.getAttribute("current_user_id"),
      product_title: root.getAttribute("product_title"),
      api_key: root.getAttribute("api_key"),
    };

    // Clean up previous mount when modal closes
    const modal = document.getElementById('designToolModal');
    if (modal) {
      modal.addEventListener('hidden.bs.modal', () => {
        ReactDOM.unmountComponentAtNode(root);
        this.mountedComponents.delete('make-designer');
      });
    }

    ReactDOM.render(
      <QueryClientProvider client={this.queryClient}>
        <Root {...props} />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>,
      root
    );

    this.mountedComponents.add('make-designer');
  }

  static initializeUIComponents() {
    // Initialize spinners
    const spinner = document.getElementById("loading-spinner");
    if (spinner) spinner.style.display = "none";

    initializeBootstrapComponents()
  }

  static reset() {
    this.initialized = false;
    this.mountedComponents.clear();
    console.log("🔄 Application reset");
  }

  static initializeAxiosInterceptors() {
    // Initialize legacy handlers
    setupAjaxHandlers()

    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    const apiKey = document.querySelector('meta[name="api-key"]')?.getAttribute('content');
    
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    if (apiKey) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${apiKey}`;
    }

    axios.interceptors.request.use(
      function (config) {
        document.body.classList.add("loading-indicator");
        return config;
      },
      function (error) {
        document.body.classList.remove("loading-indicator");
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        document.body.classList.remove("loading-indicator");
        return response;
      },
      function (error) {
        document.body.classList.remove("loading-indicator");
        return Promise.reject(error);
      }
    );
  }
}

// Single event listener for Turbo
document.addEventListener("turbo:load", () => {
  AppInitializer.initialize();
});

document.addEventListener("turbo:before-render", () => {
  AppInitializer.reset();
});

export { AppInitializer };
