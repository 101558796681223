import lodash from 'lodash';

export const extractColorsFromSVG = (svgContent) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgContent, "text/xml");
    const allElements = xmlDoc.querySelectorAll('*');
    const colorSet = new Set();

    allElements.forEach(element => {
        // Check inline fill attribute
        const fillAttribute = element.getAttribute("fill");
        if (fillAttribute && fillAttribute !== 'none' && fillAttribute !== 'currentColor') {
            colorSet.add(fillAttribute);
        }

        // Check style attribute for fill
        const styleValue = element.getAttribute("style");
        if (styleValue) {
            const fillMatch = styleValue.match(/fill:\s*(#[0-9a-fA-F]{3,6}|rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)|currentColor)/ig);
            if (fillMatch) {
                fillMatch.forEach(match => {
                    const color = match.split(':')[1].trim();
                    if (color !== 'none') {
                        colorSet.add(color);
                    }
                });
            }
        }
    });

    // New code to extract colors from <style> tags
    const styleTags = xmlDoc.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
        const cssText = styleTags[i].textContent;
        const colorMatches = cssText.match(/fill:\s*(#[0-9a-fA-F]{3,6}|rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\))/ig);
        if (colorMatches) {
            colorMatches.forEach(match => {
                const color = match.split(':')[1].trim();
                colorSet.add(color);
            });
        }
    }

    return Array.from(colorSet);
};


export const updateSVGColors = (colorMap, svgContent) => {
    //console.log("Received SVG Content for Update:", svgContent);

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgContent, "text/xml");

    if (xmlDoc.documentElement.nodeName === "parsererror") {
        console.error("Parser Error: Invalid SVG XML content");
        //console.error(svgContent);
        return svgContent; // Return original content if parsing fails
    }

    const normalizeColor = (color) => {
        if (color === null || color === undefined) {
            return null;
        }
        // Implement logic to convert RGB or named colors to lowercase hex
        // For now, just converts to lowercase
        return color.toLowerCase();
    };


    // Log the colorMap
    //console.log("Color Map for Replacement:", colorMap);

// Update the CSS styles in <style> tags
    const styleTags = xmlDoc.getElementsByTagName("style");
    for (let i = 0; i < styleTags.length; i++) {
        let cssText = styleTags[i].textContent;
        let updatedCssText = '';

        // Split CSS text into lines (each line is a rule)
        let cssRules = cssText.split('\n');
        cssRules.forEach(rule => {
            // For each rule, check if it contains a color that needs to be replaced
            for (const [originalColor, newColor] of Object.entries(colorMap)) {
                // Construct regex for case-insensitive search
                const regex = new RegExp(normalizeColor(originalColor), 'i');
                if (regex.test(rule)) {
                    // Replace the color and add to updated CSS text
                    rule = rule.replace(regex, normalizeColor(newColor));
                }
            }
            updatedCssText += rule + (rule ? '\n' : ''); // Add newline if rule is not empty
        });

        styleTags[i].textContent = updatedCssText.trim();
        console.log("Updated CSS:", updatedCssText); // Log updated CSS
    }


// Update direct fill colors and inline styles on SVG elements
    const elementsToUpdate = xmlDoc.querySelectorAll("[fill], [style]");
    elementsToUpdate.forEach(element => {
        // Update fill attribute
        const fillColor = element.getAttribute("fill");
        if (fillColor && colorMap[normalizeColor(fillColor)]) {
            console.log(`Updating fill color ${fillColor} to ${colorMap[normalizeColor(fillColor)]}`);
            element.setAttribute("fill", colorMap[normalizeColor(fillColor)]);
        }

        // Update inline style fill color
        const styleValue = element.getAttribute("style");
        if (styleValue && styleValue.includes("fill:")) {
            let updatedStyle = styleValue;
            for (const [originalColor, newColor] of Object.entries(colorMap)) {
                // Normalize the original color key
                const normalizedOriginalColor = normalizeColor(originalColor);
                const styleRegex = new RegExp(`fill:\\s*${normalizedOriginalColor}(;|\\b)`, 'gi');
                updatedStyle = updatedStyle.replace(styleRegex, `fill: ${normalizeColor(newColor)}$1`);
            }
            element.setAttribute("style", updatedStyle);
            console.log(`Updated inline style for element`, element);
        }
    });

    const serializer = new XMLSerializer();
    const newSvgContent = serializer.serializeToString(xmlDoc);
    console.log("New SVG Content after Update:", newSvgContent);

    return newSvgContent;
};




export const buildColorMap = (previousColorMap, newColor) => {
    /*
    todo: rename function (?update color object)
    In:
    PreviousColorMap:
    [
      {".st0":"rgb(128, 128, 128)"},
      {".st1":"rgb(39, 37, 37)"}
    ]
    Out:
    newColor:
    {.st1: "#f0f0f0"}
    +
    PreviousColorMap:
    [
      {".st0":"rgb(128, 128, 128)"},
      {".st1":"rgb(39, 37, 37)"}
    ]
    =
    StyleMap:
    {
    ".st0":"rgb(128, 128, 128)",
    ".st1":"#f0f0f0"
    }
     */
    const flattenedColorArray =
        // converts array of objects into single object
        Object.assign({}, ...previousColorMap.map((obj) => {
            for (const [key, value] of Object.entries(obj)) {
                let obj = {}
                obj[key] = value;
                return obj
            }
        } ));

    // Update the existing color entry in the color map instead of appending a new one
    let styleMap = {...flattenedColorArray, ...newColor};
    return styleMap
}


export const scaleValue = (value, from, to) => {
    return (to[1] - to[0]) * (value - from[0]) / (from[1] - from[0]) + to[0];
}

export const drawArcPath = (centerX, centerY, radius, startAngle, endAngle) => {
    const start = polarToCartesian(centerX, centerY, radius, endAngle);
    const end = polarToCartesian(centerX, centerY, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;
};

// Helper function to get point in polar coordinates
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    let angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

export const describeArc = (x, y, radius, startAngle, endAngle, curvatureDirection) => {
    let endAngleOriginal = endAngle;
    if (endAngleOriginal - startAngle === 360) {
        endAngle = 359;
    }

    let start = polarToCartesian(x, y, radius, endAngle);
    let end = polarToCartesian(x, y, radius, startAngle);

    //let arcSweep = endAngle - startAngle <= 180 ? "1" : "0";
    let arcSweep;
    if (curvatureDirection >= 0) {
        arcSweep = endAngle - startAngle > 180 ? "0" : "1";
    } else {
        arcSweep = endAngle - startAngle <= 180 ? "-1" : "0";
    }


    if (endAngleOriginal - startAngle === 360) {
        return [
            "M", x, y,  // Start from the center
            "A", radius, radius, 0, arcSweep, 0, end.x, end.y, "z"
        ].join(" ");
    } else {
        return [
            "M", x, y,  // Start from the center
            "A", radius, radius, 0, arcSweep, 0, end.x, end.y
        ].join(" ");
    }
}

