import React from 'react';
import { Shape } from 'react-konva';

export const EditableArea = (props) => {

  const areaCenter = () => {
    props.stageRef.current.position(
      {
        x: (props.template.print_area_left + (props.template.print_area_width / 2)),
        y: (props.template.print_area_top + (props.template.print_area_height / 2)),
      }
    );

    //console.log(props.stageRef.current.position());

  };


  return (
    <Shape
      //x={containerWidth/2}
      //offsetX={props.template.template_width / 2}
      width={props.stageRect.width}
      height={props.stageRect.height}
      name={'element'}
      stroke={'#000'}
      strokeWidth={0}
      fill='rgba(0,0,0,0)'
      sceneFunc={(ctx, shape) => {
          const {
              print_area_left: left,
              print_area_top: top,
              print_area_width: width,
              print_area_height: height,
              template_width: tWidth,
              template_height: tHeight
          } = props.template;

          // First, fill the entire canvas with the desired color
          ctx.fillStyle = 'rgba(0,0,0,0.3)';
          ctx.fillRect(0, 0, tWidth, tHeight);

          // Now, set the composite operation to "destination-out"
          ctx.globalCompositeOperation = 'destination-out';

          // Then, draw the rectangle for the printable area
          ctx.fillStyle = 'rgba(0,0,0,1)';
          ctx.fillRect(left, top, width, height);

          // Reset the composite operation back to "source-over" (default mode)
          ctx.globalCompositeOperation = 'source-over';

          //ctx.stroke();
          ctx.fillStrokeShape(shape);
      }}



      listening={false}
      />
  )
}