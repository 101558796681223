// For Shop Dashboard Product Pricing
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["colorFilter", "variantTable", "variantImage"]

  connect() {
    // Show first color's variants by default
    if (this.colorFilterTargets.length > 0) {
      this.colorFilterTargets[0].classList.add('active')
      this.showVariantsForColor(this.colorFilterTargets[0].dataset.color)
    }
  }

  switchColor(event) {
    const selectedColor = event.currentTarget.dataset.color
    const variantImage = event.currentTarget.dataset.variantImage
    
    // Update active state of color filters
    this.colorFilterTargets.forEach(filter => {
      filter.classList.toggle('active', filter.dataset.color === selectedColor)
    })
    
    // Update variant image
    this.variantImageTarget.src = variantImage

    // Remove active class from all color filters
    this.colorFilterTargets.forEach(filter => filter.classList.remove('active'))
  
    // Add active class to clicked filter
    event.currentTarget.classList.add('active')
    
    this.showVariantsForColor(selectedColor)
  }

  showVariantsForColor(color) {
    this.variantTableTargets.forEach(table => {
      table.style.display = table.dataset.color === color ? 'block' : 'none'
    })
  }
}
