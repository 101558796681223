import React, {useEffect, useState} from 'react';
import {planarSymmetries, generateTiling, generateLattice, RosetteGroup, IdentitySet} from '../lib/SymmetryGenerator';
import PropTypes from "prop-types";
import {Group, Path, Layer} from 'react-konva';
import {v4 as uuidv4} from 'uuid';
import {useSelectionContext} from "../../contexts/SelectionContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faTrash, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const SymmetryAsset = ({
                           setElems,
                           elems,
                           stageRef,
                           placementRef,
                           drawingLayerRef,
                           selectedGroupRef,
                           printAreaRef,
                           printedAreaRef,
                           template,
                           symmetryType,
                           setSymmetryType,
                           symmetryParameters,
                           setSymmetryParameters,
                           //selectedElement,
                           //setSelectedElement,
                           //setSelectedId,
                           setSelectedPatternTool,
                           selectedPatternTool,
                           squareSize,
                           squarePosition,
                           offScreenPatternCanvasRef,
                           patternDesign,
                           setPatternDesign,
                           selectedPatternDesignElementId,
                           setSelectedPatternDesignElementId,
                           setIsPatternDesignUpdatingFromAsset,
                           isPatternDesignUpdatingFromAsset,
                       }) => {

    const {
        activeNodesRef,
        setSelectedId,
        setSelectedElement,
        selectedElement,
        selectedId
    } = useSelectionContext();

    const [newlyAddedGroupId, setNewlyAddedGroupId] = useState(null);
    const [selectedPatternDesignElement, setSelectedPatternDesignElement] = useState(null);

    const [showShapeCreation, setShowShapeCreation] = useState(true);
    const [showTools, setShowTools] = useState(true);
    const [showSymmetryType, setShowSymmetryType] = useState(false);
    const [showSymmetryParams, setShowSymmetryParams] = useState(false);
    const [showElementDetails, setShowElementDetails] = useState(true);

    const toggleShapeCreation = () => setShowShapeCreation(!showShapeCreation);
    const toggleTools = () => setShowTools(!showTools);
    const toggleSymmetryType = () => setShowSymmetryType(!showSymmetryType);
    const toggleSymmetryParams = () => setShowSymmetryParams(!showSymmetryParams);
    const toggleElementDetails = () => setShowElementDetails(!showElementDetails);


    useEffect(() => {
        // This function will run after symmetryType has been updated
        updateSymmetry(symmetryParameters);
    }, [symmetryType]); // This tells React to re-run the effect when symmetryType changes

    const generateSymmetryElements = (tiling, currentGroup) => {
        // Assuming there's only one element and it's an image
        const existingElem = currentGroup.elems[0]; // This should be the image now

        return tiling.map((transform, index) => {
            const uniqueIdPart = uuidv4();
            const elementId = `new_shape__${uniqueIdPart}`;

            // For an image, we are mainly interested in applying the transformations
            // to position it correctly according to the symmetry
            if (existingElem.type === 'image') {
                return {
                    ...existingElem,
                    id: elementId,
                    key: elementId,
                    x: transform.x,
                    y: transform.y,
                    scaleX: transform.a, // Apply the transformation scale
                    scaleY: transform.d, // Apply the transformation scale
                    //rotation: transform.phi * (180 / Math.PI), // Convert radians to degrees for rotation
                };
            }

            // If needed, handle other shape types here
            return {
                ...existingElem,
                id: elementId,
                key: elementId,
                x: transform.x,
                y: transform.y,
            };
        });
    };

    const updateSymmetry = (newParameters) => {
        const spec = planarSymmetries[symmetryType];
        const tiling = generateTiling(spec, newParameters.nx, newParameters.ny, newParameters.d, newParameters.phi, newParameters.x, newParameters.y);

        const currentGroup = selectedElement ? elems.find(elem => elem.id === selectedElement.id) : null;

        if (currentGroup) {
            const setIds = [...new Set(currentGroup.elems.map(elem => elem.set))];

            const updatedGroupElems = setIds.flatMap(setId => {
                const setElems = currentGroup.elems.filter(elem => elem.set === setId);
                return generateSymmetryElements(tiling, {...currentGroup, elems: setElems}, setId, squareSize);
            });

            const updatedElems = elems.map(elem =>
                elem.id === currentGroup.id
                    ? {
                        ...elem,
                        symmetryType: symmetryType,
                        elems: updatedGroupElems,
                    }
                    : elem
            );

            setSelectedElement({...currentGroup, symmetryType: symmetryType});
            setElems(updatedElems);
        }
    };

    // Function to generate and add symmetrical design to canvas
    const addSymmetryToCanvas = (callback = () => {}) => {

        // Assuming printedAreaRef is available in the scope
        const printedArea = {
            x: template.print_area_left,
            y: template.print_area_top,
            width: template.print_area_width,
            height: template.print_area_height,
        };

        const spec = planarSymmetries[symmetryType];
        const tiling = generateTiling(spec, symmetryParameters.nx, symmetryParameters.ny, symmetryParameters.d, symmetryParameters.phi, symmetryParameters.x, symmetryParameters.y);

        const currentGroup = selectedElement ? elems.find(elem => elem.id === selectedElement.id) : null;

        if (currentGroup) {
            //const newSetId = (currentGroup.maxSetId || 0) + 1;
            const updatedGroupElems = generateSymmetryElements(tiling, currentGroup);

            const updatedElems = elems.map(elem =>
                elem.id === currentGroup.id
                    ? {
                        ...elem,
                        //maxSetId: newSetId,
                        symmetryType: symmetryType,
                        elems: updatedGroupElems,
                    }
                    : elem
            );
            setElems(updatedElems);
        } else {
            // Creating a new group

            const symmetryGroupId = `symmetry_group_${Date.now()}`;
            const newSetId = 0; // Start with 0 for the first set of elements

            //const symmetryGroupElems = tiling.map((transform, index) => {
            //    return {};
            //});

            const symmetryGroupElems = []; // Initialize as an empty array

            setElems([...elems, {
                id: symmetryGroupId,
                type: 'pattern',
                maxSetId: newSetId,
                x: printedArea.x,
                y: printedArea.y,
                width: printedArea.width,
                height: printedArea.height,
                symmetryType: symmetryType,
                //scaleX: drawingAreaScaleX,
                //scaleY: drawingAreaScaleY,
                name: 'element',
                placement: placementRef.current,
                elems: symmetryGroupElems
            }])

            setNewlyAddedGroupId(symmetryGroupId);
        }

        callback();
    };

    useEffect(() => {
        if (newlyAddedGroupId) {
            const groupRef = stageRef.current.findOne(`#${newlyAddedGroupId}`);

            if (groupRef) {
                // Now that you have the groupRef, perform your selection logic here
                let updatedPattern = {
                    ...groupRef.attrs,
                    elems: groupRef.children.map(child => child.attrs),
                    type: 'pattern',
                    placement: placementRef.current,
                    maxSetId: 0,
                }
                //setSelectedGroup(selectedGroup);
                //setSelectedElement(groupRef);
                setSelectedElement(updatedPattern);

                // Clear the identifier to avoid re-selecting unless a new group is added
                setNewlyAddedGroupId(null);
            }
        }
    }, [newlyAddedGroupId, elems]); // Depend on elems to ensure re-check after updates

    useEffect(() => {
        if (selectedPatternDesignElementId) {
            console.log('Selected Pattern Design Element ID: ', selectedPatternDesignElementId)
            const element = patternDesign.find(
                (element) => element.id === selectedPatternDesignElementId
            );
            console.log('Pattern Design: ', patternDesign);
            console.log('Selected Pattern Design Element: ', element);
            setSelectedPatternDesignElement(element);
        } else {
            setSelectedPatternDesignElement(null);
        }
    }, [selectedPatternDesignElementId, patternDesign]);

    const handlePatternDesignElementChange = (prop, value) => {
        console.log('handlePatternDesignElementChange: ', prop, value);
        setSelectedPatternDesignElement((prevElement) => ({
            ...prevElement,
            [prop]: value,
        }));

        setPatternDesign((prevDesign) =>
            prevDesign.map((element) =>
                element.id === selectedPatternDesignElementId ? {...element, [prop]: value} : element
            )
        );

        setIsPatternDesignUpdatingFromAsset(true);

    };

    // Handle changes to symmetry parameters
    const handleParameterChange = (parameter, value) => {
        const updatedParameters = {...symmetryParameters, [parameter]: value};
        setSymmetryParameters(updatedParameters);
        updateSymmetry(updatedParameters);
    };

    // Handle changes to symmetry type
    const handleSymmetryTypeChange = (newType) => {
        setSymmetryType(newType);
        //updateSymmetry(symmetryParameters); // Update symmetry with current parameters but new type
    };

    const handleToolSelection = (tool) => {
        console.log('handleToolSelection: ', tool);
        // Check if there is no currently selected pattern element
        if (!selectedElement && ['line', 'rect', 'circle', 'star', 'polygon'].includes(tool)) {
            console.log('Adding symmetry to canvas' + tool)
            // Automatically add symmetry if it's one of the shape tools and no element is selected
            addSymmetryToCanvas(() => {
                // After adding symmetry, set the selected tool
                setSelectedPatternTool(tool);
            });
        } else {
            // Set the tool normally if another condition isn't met
            setSelectedPatternTool(tool);
        }
    };

    const handleDeleteElement = () => {
        // Remove the selected element from the pattern design
        const updatedPatternDesign = patternDesign.filter(element => element.id !== selectedPatternDesignElementId);
        setPatternDesign(updatedPatternDesign);

        // Find the group in elems that contains the design element
        const updatedElems = elems.map(group => {
            if (group.design) {
                const updatedDesign = group.design.filter(designElement => designElement.id !== selectedPatternDesignElementId);
                return {...group, design: updatedDesign};
            }
            return group;
        });
        setElems(updatedElems);
        setIsPatternDesignUpdatingFromAsset(true);
        // Clear the selected element ID
        setSelectedPatternDesignElementId(null);
        setSelectedPatternDesignElement(null);  // Clear selected pattern design element
    };

    const handleMoveElement = (direction) => {
        const index = patternDesign.findIndex((element) => element.id === selectedPatternDesignElementId);
        if (index === -1) return; // Element not found

        // Adjust direction logic: 'up' should increase index (move down visually), 'down' should decrease index (move up visually)
        let newIndex = index + (direction === 'up' ? 1 : -1);
        if (newIndex < 0 || newIndex >= patternDesign.length) return; // Check bounds

        // Swap elements in patternDesign
        const newPatternDesign = [...patternDesign];
        [newPatternDesign[index], newPatternDesign[newIndex]] = [newPatternDesign[newIndex], newPatternDesign[index]];
        setPatternDesign(newPatternDesign);

        // Update the design within the correct group in elems
        const updatedElems = elems.map(group => {
            if (group.design && group.design.some(designElement => designElement.id === selectedPatternDesignElementId)) {
                // Deep copy the design array to ensure immutability
                let newDesign = [...group.design];
                const designIndex = newDesign.findIndex(design => design.id === selectedPatternDesignElementId);
                const designNewIndex = designIndex + (direction === 'up' ? 1 : -1);

                // Swap design elements similar to patternDesign
                if (designIndex !== -1 && designNewIndex >= 0 && designNewIndex < newDesign.length) {
                    [newDesign[designIndex], newDesign[designNewIndex]] = [newDesign[designNewIndex], newDesign[designIndex]];
                }

                return {...group, design: newDesign};
            }
            return group;
        });

        setElems(updatedElems);
        setIsPatternDesignUpdatingFromAsset(true);  // Trigger re-rendering and visual updates
    };




    /*    const handleMoveElement = (direction) => {
            const index = patternDesign.findIndex((element) => element.id === selectedPatternDesignElementId);
            if (index === -1) return;

            const newIndex = direction === 'up' ? index - 1 : index + 1;
            if (newIndex < 0 || newIndex >= patternDesign.length) return; // Check bounds

            const newDesign = [...patternDesign];
            const [reorderedElement] = newDesign.splice(index, 1);
            newDesign.splice(newIndex, 0, reorderedElement);

            setPatternDesign(newDesign);
        };*/

    useEffect(() => {
        if (selectedPatternDesignElement) {
            console.log('Current Element:', selectedPatternDesignElement);
            console.log('Config for type:', elementConfig[selectedPatternDesignElement.type]);
        }
    }, [selectedPatternDesignElement]);

    const parameterConfig = {
        nx: {min: 1, max: 12, step: 1},
        ny: {min: 1, max: 12, step: 1},
        d: {min: -500, max: 500, step: 10},
        x: {min: -100, max: 100, step: 5},
        y: {min: -100, max: 100, step: 5}
    };

    // Configuration for different element types
    const elementConfig = {
        line: {
            strokeWidth: {type: 'range', min: 1, max: 10, step: 1},
            stroke: {type: 'color'},
            lineCap: {type: 'dropdown', options: ['butt', 'round', 'square']},
            lineJoin: {type: 'dropdown', options: ['miter', 'round', 'bevel']},
            tension: {type: 'range', min: 0, max: 1, step: 0.1},
            opacity: {type: 'range', min: 0, max: 1, step: 0.1}
        },
        rect: {
            strokeWidth: {type: 'range', min: 1, max: 10, step: 1},
            stroke: {type: 'color'},
            fill: {type: 'color'},
            width: {type: 'range', min: 10, max: 100, step: 10},
            height: {type: 'range', min: 10, max: 100, step: 10},
            opacity: {type: 'range', min: 0, max: 1, step: 0.1}
            // Add other attributes as necessary
        },
        circle: {
            strokeWidth: {type: 'range', min: 1, max: 10, step: 1},
            stroke: {type: 'color'},
            fill: {type: 'color'},
            radius: {type: 'range', min: 10, max: 100, step: 10},
            opacity: {type: 'range', min: 0, max: 1, step: 0.1}
            // Add other attributes as necessary
        },
        star: {
            strokeWidth: {type: 'range', min: 0, max: 10, step: 1},
            stroke: {type: 'color'},
            fill: {type: 'color'},
            numPoints: {type: 'range', min: 3, max: 20, step: 1},
            innerRadius: {type: 'range', min: 10, max: 100, step: 10},
            outerRadius: {type: 'range', min: 10, max: 100, step: 10},
            opacity: {type: 'range', min: 0, max: 1, step: 0.1}
            // Add other attributes as necessary
        },
        polygon: {
            strokeWidth: {type: 'range', min: 1, max: 10, step: 1},
            stroke: {type: 'color'},
            fill: {type: 'color'},
            sides: {type: 'range', min: 3, max: 12, step: 1},
            radius: {type: 'range', min: 10, max: 100, step: 10},
            opacity: {type: 'range', min: 0, max: 1, step: 0.1}
            // Add other attributes as necessary
        }
        // Define configurations for other element types similarly
    };

// Render inputs based on element type and configuration
    const renderInput = (attr, config, value) => {
        switch (config.type) {
            case 'range':
                return (
                    <>
                        <input
                            type="range"
                            className="form-range"
                            min={config.min}
                            max={config.max}
                            step={config.step}
                            value={value}
                            onChange={(e) => handlePatternDesignElementChange(attr, Number(e.target.value))}
                        />
                        <small>{value}</small>
                    </>
                );
            case 'color':
                return (
                    <input
                        type="color"
                        className="form-control"
                        value={value}
                        onChange={(e) => handlePatternDesignElementChange(attr, e.target.value)}
                    />
                );
            case 'dropdown':
                return (
                    <select
                        className="form-select"
                        value={value}
                        onChange={(e) => handlePatternDesignElementChange(attr, e.target.value)}
                    >
                        {config.options.map(option => <option key={option} value={option}>{option}</option>)}
                    </select>
                );
            default:
                return (
                    <input
                        type="text"
                        className="form-control"
                        value={value}
                        onChange={(e) => handlePatternDesignElementChange(attr, e.target.value)}
                    />
                );
        }
    };

    return (
        <div className="symmetry-asset card">
            <div className="card-header text-center">
                <h2>{selectedElement ? "Edit Pattern" : "Make Pattern"}</h2>
            </div>

            <div className="card-body">
                {/* Shape creation buttons */}
                <div className="mb-3">
                    <h6
                        className="mb-1 clickable cursor-pointer"
                        data-bs-toggle="collapse"
                        data-bs-target="#shapeCreationCollapse"
                        aria-expanded="true"
                        aria-controls="shapeCreationCollapse"
                        onClick={toggleShapeCreation}
                        role="button" // Accessibility improvement
                    >
                        Create Shapes
                        <FontAwesomeIcon icon={showShapeCreation ? faChevronUp : faChevronDown} className="float-end" />
                    </h6>
                    <div className="collapse show" id="shapeCreationCollapse">
                        <div className="btn-group d-flex flex-wrap" role="group" aria-label="Shape Creation">
                            {['line', 'rect', 'circle', 'star', 'polygon'].map(tool => (
                                <button
                                    key={tool}
                                    type="button"
                                    className={`btn ${selectedPatternTool === tool ? 'btn-primary' : 'btn-outline-secondary'}`}
                                    onClick={() => handleToolSelection(tool)}
                                >
                                    {tool.charAt(0).toUpperCase() + tool.slice(1)}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {selectedElement && (
                    <div className="mb-3">
                        {/* Tool selection buttons */}

                        <label htmlFor="toolSelection" className="form-label">Tools:</label>
                        <div className="btn-group d-flex flex-wrap" role="group" aria-label="Tool Selection">
                            {/* Selection Tool */}
                            <button
                                type="button"
                                className={`btn ${selectedPatternTool === 'transform' ? 'btn-primary' : 'btn-outline-secondary'}`}
                                onClick={() => handleToolSelection('transform')}
                            >
                                <i className="fa fa-mouse-pointer"></i>
                            </button>
                            {/* Undo Tool */}
                            <button
                                type="button"
                                className={`btn ${selectedPatternTool === 'undo' ? 'btn-primary' : 'btn-outline-secondary'}`}
                                onClick={() => handleToolSelection('undo')}
                            >
                                <i className="fa fa-undo"></i>
                            </button>
                            {/* Redo Tool */}
                            <button
                                type="button"
                                className={`btn ${selectedPatternTool === 'redo' ? 'btn-primary' : 'btn-outline-secondary'}`}
                                onClick={() => handleToolSelection('redo')}
                            >
                                <i className="fa fa-redo"></i>
                            </button>
                        </div>
                    </div>
                )}

                {/* Display selected element attributes */}
                {selectedElement && selectedPatternDesignElementId && selectedPatternDesignElement && (
                    <div className="mb-3">
                        <h6
                            className="mb-1 clickable cursor-pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#elementDetailsCollapse"
                            aria-expanded="true"
                            aria-controls="elementDetailsCollapse"
                            onClick={toggleElementDetails}
                            role="button" // Accessibility improvement
                        >
                            Selected: {selectedPatternDesignElement.type.toUpperCase()}
                            <FontAwesomeIcon icon={showElementDetails ? faChevronUp : faChevronDown}
                                             className="float-end"/>
                        </h6>
                        <div className="collapse show" id="elementDetailsCollapse">

                            <div className="collapse show" id="elementDetailsCollapse">
                                <div className="btn-group d-flex justify-content-start" role="group">
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        onClick={handleDeleteElement}
                                        title="Delete" // Tooltip for accessibility and clarity
                                        aria-label="Delete"
                                    >
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => handleMoveElement('up')}
                                        disabled={patternDesign.findIndex((element) => element.id === selectedPatternDesignElementId) === patternDesign.length - 1}
                                        title="Move Up" // Tooltip for accessibility and clarity
                                        aria-label="Move Up"
                                    >
                                        <FontAwesomeIcon icon={faArrowUp}/>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => handleMoveElement('down')}
                                        disabled={patternDesign.findIndex((element) => element.id === selectedPatternDesignElementId) === 0}
                                        title="Move Down" // Tooltip for accessibility and clarity
                                        aria-label="Move Down"
                                    >
                                        <FontAwesomeIcon icon={faArrowDown}/>
                                    </button>

                                </div>
                            </div>

                            <div className="row">
                                {selectedPatternDesignElement && Object.entries(elementConfig[selectedPatternDesignElement.type] || {}).map(([attr, config]) => {
                                    const value = selectedPatternDesignElement[attr];
                                    if (!['id', 'points'].includes(attr) && value !== undefined) {
                                        return (
                                            <div key={attr} className="col-sm-6 col-md-4 mb-3">
                                                <label
                                                    className="form-label">{attr.charAt(0).toUpperCase() + attr.slice(1)}:</label>
                                                {renderInput(attr, config, value)}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>

                        </div>
                    </div>
                )}

                {/* Symmetry Type Selection */}
                <div className="mb-3">
                    <h6
                        className="mb-1 clickable cursor-pointer"
                        data-bs-toggle="collapse"
                        data-bs-target="#symmetryTypeCollapse"
                        aria-expanded="false"
                        aria-controls="symmetryTypeCollapse"
                        onClick={toggleSymmetryType}
                        role="button" // Accessibility improvement
                    >
                        Symmetry Type
                        <FontAwesomeIcon icon={showSymmetryType ? faChevronUp : faChevronDown} className="float-end" />
                    </h6>
                    <div className="collapse" id="symmetryTypeCollapse">
                        <div
                            className="btn-group btn-group-sm d-flex flex-wrap" role="group" aria-label="Symmetry Type">
                            {Object.keys(planarSymmetries).map(key => (
                                <button
                                    key={key}
                                    type="button"
                                    className={`btn ${symmetryType === key ? 'btn-primary' : 'btn-outline-secondary'}`}
                                    onClick={() => handleSymmetryTypeChange(key)}
                                >
                                    {key}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Inputs for adjusting symmetryParameters */}
                <div className="mb-3">
                    <h6
                        className="mb-1 clickable cursor-pointer"
                        data-bs-toggle="collapse"
                        data-bs-target="#symmetryParamsCollapse"
                        aria-expanded="false"
                        aria-controls="symmetryParamsCollapse"
                        onClick={toggleSymmetryParams}
                        role="button" // Accessibility improvement
                    >
                        Symmetry Parameters
                        <FontAwesomeIcon icon={showSymmetryParams ? faChevronUp : faChevronDown} className="float-end" />
                    </h6>
                    <div className="collapse" id="symmetryParamsCollapse">

                        <div className="row">
                            {Object.entries(parameterConfig).map(([param, config]) => (
                                <div key={param} className="col-sm-6 col-md-4 mb-3">
                                    <label htmlFor={param} className="form-label">{param.toUpperCase()}:</label>
                                    <input
                                        type="number"
                                        className="form-control mb-2"
                                        id={param}
                                        value={symmetryParameters[param]}
                                        onChange={(e) => handleParameterChange(param, Number(e.target.value))}
                                    />
                                    <input
                                        type="range"
                                        className="form-range"
                                        id={`${param}Range`}
                                        min={config.min}
                                        max={config.max}
                                        step={config.step}
                                        value={symmetryParameters[param]}
                                        onChange={(e) => handleParameterChange(param, Number(e.target.value))}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

SymmetryAsset.propTypes = {
    setElems: PropTypes.func.isRequired,
    elems: PropTypes.array.isRequired,
    stageRef: PropTypes.object.isRequired,
    printAreaRef: PropTypes.object.isRequired,
    placementRef: PropTypes.object.isRequired,
};

export default SymmetryAsset;
