import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.bindDirectUploadEvents()
  }

  bindDirectUploadEvents() {
    document.addEventListener('direct-upload:initialize', event => {
      const { target, detail } = event
      const { id, file } = detail
      
      // Show progress container
      const progressContainer = document.getElementById('direct-upload-progress')
      progressContainer.style.display = 'block'
    })

    document.addEventListener('direct-upload:progress', event => {
      const { detail } = event
      const { id, progress } = detail
      
      // Update progress bar
      const progressBar = document.querySelector('#direct-upload-progress .progress-bar')
      progressBar.style.width = `${progress}%`
    })

    document.addEventListener('direct-upload:error', event => {
      const { detail } = event
      const { id, error } = detail
      
      // Hide progress container and show error
      const progressContainer = document.getElementById('direct-upload-progress')
      progressContainer.style.display = 'none'
      
      // You might want to show an error message here
      alert(`Upload failed: ${error}`)
    })

    document.addEventListener('direct-upload:end', event => {
      const { detail } = event
      const { id } = detail
      
      // Hide progress container after a short delay
      setTimeout(() => {
        const progressContainer = document.getElementById('direct-upload-progress')
        progressContainer.style.display = 'none'
        
        // Reset progress bar
        const progressBar = document.querySelector('#direct-upload-progress .progress-bar')
        progressBar.style.width = '0%'
      }, 500)
    })
  }
}
