export class Point {
    constructor(x = 0, y = 0) {
        this.validateCoordinates(x, y);
        this.x = this.roundCoordinate(x);
        this.y = this.roundCoordinate(y);
    }

    validateCoordinates(x, y) {
        if (typeof x !== 'number' || typeof y !== 'number') {
            throw new Error("Invalid points");
        }
    }

    roundCoordinate(value) {
        return Math.round(value * 1000) / 1000;
    }

    set x(value) {
        this._x = value;
    }

    get x() {
        return this._x;
    }

    set y(value) {
        this._y = value;
    }

    get y() {
        return this._y;
    }

    update(newX, newY) {
        this.x = newX;
        this.y = newY;
    }
}