import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["basicInfo", "appearance", "business", "contact", "step1", "step2", "step3", "step4", "progressBar", "shopName", "subdomain", "profitMargin", "profitMarginError"]
  static values = {
    currentStep: { type: Number, default: 1 }
  }

  connect() {
    this.updateProgress()
  }

  nextStep(event) {
    if (this.currentStepValue === 1 && !this.validateBasicInfo()) {
      return
    }

    if (this.currentStepValue === 3 && !this.validateBusinessInfo()) {
      return
    }

    if (this.currentStepValue < 4) {
      this.currentStepValue++
      this.updateSteps()
      this.updateProgress()
    }
  }

  previousStep() {
    if (this.currentStepValue > 1) {
      this.currentStepValue--
      this.updateSteps()
      this.updateProgress()
    }
  }

  validateBasicInfo() {
    const shopName = this.shopNameTarget.value
    const subdomain = this.subdomainTarget.value
    const subdomainValidator = document.querySelector('[data-controller="subdomain-validator"]')
    
    if (!shopName || !subdomain) {
      this.showError('Please fill in all required fields before proceeding')
      return false
    }
    
    const subdomainRegex = /^[a-z0-9][a-z0-9-]*[a-z0-9]$/
    if (!subdomainRegex.test(subdomain)) {
      this.showError('Subdomain must start and end with a letter or number, and can only contain lowercase letters, numbers, and hyphens')
      return false
    }

    // Check if subdomain is valid and available
    if (!subdomainValidator.hasAttribute('data-subdomain-validator-is-valid-value') || 
        subdomainValidator.getAttribute('data-subdomain-validator-is-valid-value') === 'false') {
      this.showError('Please choose a valid and available subdomain')
      return false
    }

    return true
  }

  validateBusinessInfo() {
    const profitMargin = parseFloat(this.profitMarginTarget.value)
    
    if (isNaN(profitMargin)) {
      this.showError('Please enter a valid profit margin')
      return false
    }
    
    if (profitMargin < 0) {
      this.showError('Profit margin cannot be negative')
      return false
    }
    
    if (profitMargin > 100) {
      this.showError('Profit margin cannot exceed 100%')
      return false
    }
    
    return true
  }

  showError(message) {
    const notification = document.createElement('div')
    notification.id = "settings-notification"
    notification.className = "alert alert-danger"
    notification.innerHTML = message
    
    let container = document.getElementById('notification-container')
    if (!container) {
      container = document.createElement('div')
      container.id = 'notification-container'
      container.setAttribute('data-controller', 'notification')
      document.body.prepend(container)
    }
    
    container.innerHTML = ''
    container.appendChild(notification)
  }

  updateSteps() {
    this.basicInfoTarget.classList.remove('active')
    this.appearanceTarget.classList.remove('active')
    this.businessTarget.classList.remove('active')
    this.contactTarget.classList.remove('active')
    
    switch(this.currentStepValue) {
      case 1:
        this.basicInfoTarget.classList.add('active')
        break
      case 2:
        this.appearanceTarget.classList.add('active')
        break
      case 3:
        this.businessTarget.classList.add('active')
        break
      case 4:
        this.contactTarget.classList.add('active')
        break
    }
    
    this.step1Target.classList.remove('active')
    this.step2Target.classList.remove('active')
    this.step3Target.classList.remove('active')
    this.step4Target.classList.remove('active')

    switch(this.currentStepValue) {
      case 1:
        this.step1Target.classList.add('active')
        break
      case 2:
        this.step2Target.classList.add('active')
        break
      case 3:
        this.step3Target.classList.add('active')
        break
      case 4:
        this.step4Target.classList.add('active')
        break
    }
  }
  
  updateProgress() {
    const progress = ((this.currentStepValue - 1) / 3) * 100
    this.progressBarTarget.style.width = `${progress}%`
  }

  validateProfitMarginInput(event) {
    const input = event.target
    const value = parseFloat(input.value)
    const errorDiv = this.profitMarginErrorTarget
    
    input.classList.remove('is-invalid', 'is-valid')
    
    if (isNaN(value)) {
      input.classList.add('is-invalid')
      errorDiv.textContent = 'Please enter a valid number'
      return
    }
    
    if (value < 0) {
      input.classList.add('is-invalid')
      errorDiv.textContent = 'Profit margin cannot be negative'
      return
    }
    
    if (value > 100) {
      input.classList.add('is-invalid')
      errorDiv.textContent = 'Profit margin cannot exceed 100%'
      return
    }
    
    if (value < 10) {
      input.classList.add('is-valid')
      errorDiv.textContent = 'Warning: Low profit margin'
      return
    }
    
    if (value > 50) {
      input.classList.add('is-valid')
      errorDiv.textContent = 'Warning: High profit margin'
      return
    }
    
    input.classList.add('is-valid')
    errorDiv.textContent = ''
  }
}
