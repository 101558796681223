import React from 'react';
import { TextPath } from 'react-konva';
import { drawArcPath } from "../SimpleElementOptions/propertiesUtils";

export const TextShape = ({
                              onSelect,
                              isSelected,
                              onChange,
                              element,
                              trRef,
                              template,
                              draggable,
                              onMouseOver,
                              onMouseOut,
                              isElementTouchedRef,
                              addToActiveNodes,
                              removeFromActiveNodes
                          }) => {

    const shapeRef = React.useRef(null);

    React.useEffect(() => {
        if (isSelected && shapeRef.current) {
            if (trRef.current) {
                addToActiveNodes(shapeRef.current);
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        } else if (!isSelected && shapeRef.current) {
            if (removeFromActiveNodes) removeFromActiveNodes(shapeRef.current);
        }
    }, [isSelected]);

    const hiddenTextNode = new Konva.Text({
        text: element.text,
        fontSize: element.fontSize || 152,
        fontFamily: element.fontFamily || 'Arial',
    });
    const actualTextWidth = hiddenTextNode.width() + 15;

    // if selected element does not have a pathData, create one
    let pathData;
    if (!element.data) {
        pathData = `M 0 0 L ${actualTextWidth} 0`;  // Default to a straight line
    }

    return (
        <TextPath
            type={element.type}
            data={element.data || pathData}
            id={element.id.toString()}
            x={element.x}
            y={element.y}
            onClick={onSelect}
            onTap={onSelect}
            ref={shapeRef}
            text={element.text}
            fill={element.fill || 'black'}
            fontSize={element.fontSize || 151}
            fontFamily={element.fontFamily || 'Arial'}
            draggable={true}
            scaleX={element.scaleX}
            scaleY={element.scaleY}
            width={element.width}
            height={element.height}
            rotation={element.rotation}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onTouchEnd={(e) => {
                if (shapeRef && shapeRef.current) {

                    const node = shapeRef.current;
                    onChange({
                        ...element,
                        x: node.x(),
                        y: node.y(),
                        width: node.width(),
                        height: node.height(),
                        rotation: node.rotation(),
                        scaleX: node.scaleX(),
                        scaleY: node.scaleY(),
                    });
                }
            }}
            onDragEnd={(e) => {
                if (shapeRef && shapeRef.current) {
                    const node = shapeRef.current;
                    onChange({
                        ...element,
                        x: node.x(),
                        y: node.y(),
                        width: node.width(),
                        height: node.height(),
                        rotation: node.rotation(),
                        scaleX: node.scaleX(),
                        scaleY: node.scaleY(),
                    });
                }
            }}
            onDragMove={(e) => {
            }}
            onTransform={(e) => {
            }}
            onTransformEnd={(e) => {
                if (shapeRef && shapeRef.current) {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    onChange({
                        ...element,
                        x: node.x(),
                        y: node.y(),
                        rotation: node.rotation(),
                        scaleX: node.scaleX(),
                        scaleY: node.scaleY(),

                    });
                }
            }}
        />
    );
};

export default TextShape;
