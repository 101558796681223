import React, { useEffect } from "react";

export const PriceCalculator = ({ elems, price, baseprice, setPrice, templates }) => {
    useEffect(() => {
        // Extract unique placements from elems
        const placementsUsed = [...new Set(elems.map(e => e.placement))];

        // Calculate the total price based on the placements used
        let totalPriceInCents = baseprice;

        placementsUsed.forEach(placement => {
            const template = templates.find(t => t.placement === placement);
            if (template && template.additional_price) {
                // Add the additional price to the total price
                totalPriceInCents = Number(totalPriceInCents) + Number(template.additional_price);
            }
        });

        setPrice(totalPriceInCents);
    }, [elems, baseprice, setPrice, templates]);

    // Convert price from cents to dollars and format it
    const formattedPrice = (price / 100).toFixed(2);

    return (
        <div className="price-calculator">
            <div className="price-calculator__content">
                <div className="price-calculator__content__item">
                    <div className="price-calculator__content__item__value">
                        ${formattedPrice}
                    </div>
                </div>
            </div>
        </div>
    );
};
