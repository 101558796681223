import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "title", "templateGrid", "search", "select"]

  connect() {
    console.log("ProductTypePreviewController connected")
    if (this.hasSelectTarget) {
      this.previewProduct({ target: { value: this.selectTarget.value } })
    }
  }

  previewProduct(event) {
    const productId = event.target.value
    const selectedOption = this.selectTarget.querySelector(`option[value="${productId}"]`)
    
    if (selectedOption) {  
      const imageUrl = selectedOption.dataset.image
      const model = selectedOption.dataset.model

      this.imageTarget.src = imageUrl
      this.titleTarget.textContent = model
      
      this.fetchTemplates(productId)
    } else {
      this.imageTarget.src = ''
      this.titleTarget.textContent = ''
      this.templateGridTarget.innerHTML = ''
    }
  }

  async fetchTemplates(productId) {
    this.templateGridTarget.innerHTML = '<div class="text-center">Loading templates...</div>'
    
    try {
      const response = await fetch(`/admin/printful/templates/${productId}`)
      const data = await response.json()
      
      if (data.success) {
        this.templateGridTarget.innerHTML = data.payload.templates.map(template => `
          <div class="col-6">
            <div class="card h-100">
              <div class="card-body p-2">
                <img src="${template.image_url}" 
                     class="img-fluid mb-2" 
                     style="background-color: ${template.background_color || '#f8f9fa'}">
                <div class="small">
                  <strong>${template.title || template.placement || 'Unknown Placement'}</strong><br>
                  ${template.print_area_width}x${template.print_area_height}
                </div>
              </div>
            </div>
          </div>
        `).join('')
      } else {
        this.templateGridTarget.innerHTML = '<div class="text-danger">Error loading templates</div>'
      }
    } catch (error) {
      console.error('Template fetch error:', error)
      this.templateGridTarget.innerHTML = '<div class="text-danger">Failed to load templates</div>'
    }
  }

  search(event) {
    const searchTerm = event.target.value.toLowerCase()
    const options = this.selectTarget.querySelectorAll('option')
    
    options.forEach(option => {
      const model = option.dataset.model
      if (model) {
        const matches = model.includes(searchTerm)
        option.style.display = matches ? '' : 'none'
      }
    })
  }
}