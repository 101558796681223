import React from "react";
import effectsIcon from "../../../../assets/images/icon/effects.svg";

export const EffectControls = ({
                                   isMobileView,
                                   elems,
                                   selectedId,
                                   selectedItem,
                                   onElementsChange,
                                   onButtonClick,
                                   mobileIconStyles
                               }) => {
    return (
        <button
            style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
            className={isMobileView ? "mobile_context_button" : ""}
            onClick={onButtonClick}
        >
            <div
                className={isMobileView ? "mobile_context_button_icon" : ""}
                data-icon="Effects22"
                style={{
                    ...mobileIconStyles.icon,
                    backgroundImage: `url(${effectsIcon})`
                }}
            />
            <div>Effects</div>
        </button>
    );
}


export const EffectsControlsContent = () => (
    <div>
        <button>Ooohh!</button>
        <button>Aaahh!</button>
    </div>
);