import React, { useState } from 'react';
import SVGEditor from './SVGEditor.jsx';


const SVGEditorWrapper = (props) => {
    const [svgContent, setSvgContent] = useState(props.svgContent);

    const handleSvgUpdate = (updatedSvgContent) => {
        let updatedSvgXML = updatedSvgContent;
        let data = window.btoa(updatedSvgXML);
        let dataUrl = "data:image/svg+xml;base64," + data;

        if (props.elemToEdit) {
            // Updating an existing SVG element
            const originalElemId = props.elemToEdit.id;
            const updatedElem = {
                ...props.elemToEdit,
                data: dataUrl,
                svgXML: updatedSvgXML
            };
            const updatedElems = props.elems.map(elem =>
                elem.id === originalElemId ? updatedElem : elem
            );
            props.setElems(updatedElems);
        } else {
            // Creating a new SVG element
            const newElem = {
                // Define properties for the new element, including a unique ID
                id: generateUniqueID(), // You need to define a function to generate a unique ID
                data: dataUrl,
                svgXML: updatedSvgXML,
                format: 'svg',
                placement: 'front',
                type: 'image',
                width: 600,
                height: 600,
                //svgFills: [],
                x: 0,
                y: 0,
                // Add other necessary properties for a new element
            };
            const updatedElems = [...props.elems, newElem];
            props.setElems(updatedElems);
        }

        console.log("SVG updated");
    };

// Utility function to generate a unique ID (simple example)
    const generateUniqueID = () => {
        return 'image' + Date.now(); // Example: create a unique ID using the current timestamp
    };



    const handleClose = () => {
        // Handle the close event.
        props.onClose();
        console.log("SVG Editor closed");
    };

    return (
        <div>
            <SVGEditor
                svgContent={svgContent}
                locale="en"
                svgUpdate={handleSvgUpdate}
                onClose={handleClose}
                log={() => {}} // or your own logging function
            />
        </div>
    );
}

export default SVGEditorWrapper;
