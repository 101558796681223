import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initializeThumbnails(event) {
    // Wait for tab content to be visible
    setTimeout(() => {
      const tabPane = document.querySelector(event.target.getAttribute('href'))
      const thumbnailControllers = tabPane.querySelectorAll('[data-controller="product-thumbnail"]')
      
      // Force Stimulus to reconnect controllers in the newly visible tab
      thumbnailControllers.forEach(element => {
        const controller = this.application.getControllerForElementAndIdentifier(element, 'product-thumbnail')
        if (controller) {
          controller.disconnect()
          controller.connect()
        }
      })
    }, 150) // Small delay to ensure tab content is visible
  }
}