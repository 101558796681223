import {Layer, Text, Transformer, Rect, Circle, Line, Group, Path, Shape} from "react-konva";
import COMPONENTS_MAP from "./COMPONENTS_MAP";
import { generateThumbnail, updateElementThumbnail } from "../elements/element-utils";
import React from "react";
import {useEffect} from "react";
import useImage from 'use-image';
import resizeIcon from '../../../../assets/images/icon/resize.png';
import resizeRightIcon from '../../../../assets/images/icon/resizeright.png';
import rotationIcon from '../../../../assets/images/icon/rotation.png';
import {useSelectionContext} from "../../contexts/SelectionContext";


const DrawingLayer = (props) => {

    const trRef = props.trRef;
    const {
        activeNodesRef,
        setSelectedId,
        setSelectedElement,
        selectedId,
        selectElement,
        deselectElement,
        selectionRectRef
    } = useSelectionContext();

    const [resizeIconImage, resizeIconImageStatus] = useImage(resizeIcon, 'Anonymous');
    const [resizeRightIconImage, resizeRightIconeStatus] = useImage(resizeRightIcon, 'Anonymous');
    const [rotationIconImage, rotationIconImageStatus] = useImage(rotationIcon, 'Anonymous');
    //const [selectedGroup, setSelectedGroup] = React.useState(null);

    //const activeNodesRef = React.useRef([]);
    //const [activeNodes, setActiveNodes] = React.useState([]);


    useEffect(() => {
        // Automatically update Transformer's nodes when activeNodesRef changes
        if (trRef.current) {
            const nodes = activeNodesRef.current;
            trRef.current.nodes(nodes);
            trRef.current.getLayer().batchDraw();
        }
    }, [activeNodesRef.current]);

    const addToActiveNodes = (node) => {
        activeNodesRef.current.push(node);
    }

    const removeFromActiveNodes = (node) => {
        activeNodesRef.current = activeNodesRef.current.filter(n => n !== node);
    }

    const addTextToCanvas = (textData) => {
        setElems([...elems, textData]);
    };

    const updateElementThumbnail = (element, elems, setElems) => {
        const updatedElems = elems.map((elem) => {
            if (elem.id === element.id) {
                // Generate a new thumbnail for the element
                return {
                    ...elem,
                    thumbnail: generateThumbnail(element, COMPONENTS_MAP),
                };
            }
            return elem;
        });

        // Update the elements state
        setElems(updatedElems);
    };

    return (
        <Layer
            ref={props.drawingLayerRef}
            name={'drawingLayer'}
            opTap={(e) => {
                if (!e.target.attrs.id) { // Check if the tapped target doesn't have an ID (indicating it's the stage)
                    deselectElement();
                }
            }}
        >
            <Text
                ref={props.debugTextRef}
                x={0}
                y={0}
                text={'DrawingLayer'}
                fontSize={10}
                fontFamily={'Calibri'}
                fill={'black'}
                name={'positionX'}
            />
            {props.elems.map((elem, i) => {

                const Component = COMPONENTS_MAP[elem.type];
                if (elem.placement === props.placementRef.current) {
                    return (
                        <Component
                            key={i}
                            data={elem.pathData || null}
                            name={'element'}
                            getKey={i}
                            x={elem.x}
                            y={elem.y}
                            width={elem.width}
                            height={elem.height}
                            rotation={elem.rotation}
                            scaleX={elem.scaleX}
                            scaleY={elem.scaleY}
                            element={elem}
                            layerRef={props.drawingLayerRef}
                            trRef={trRef}
                            shapeProps={elem}
                            template={props.template}
                            //setSelectedElement={setSelectedElement}
                            isSelected={elem.id === selectedId}
                            /*onSelect={(e) => {
                                console.log(elem.id);
                                console.log(e.target);
                                setSelectedId(elem.id);
                                setSelectedElement(e.target);
                            }}*/
                            onSelect={(e) => {
                                // Use the selectElement function from the SelectionContext
                                selectElement(elem.id);
                            }}
                            getLength={props.elems.length}
                            onChange={async (newAttrs) => {
                                // Update state first
                                const updatedElems = props.elems.slice();
                                updatedElems[i] = newAttrs;
                                props.setElems(updatedElems);

                                // Asynchronously update the thumbnail after the state is set
                                await updateElementThumbnail(newAttrs, updatedElems, props.setElems);
                            }}
                            onRemove={() => {
                                deselectElement();

                                const index = props.elems.findIndex((e) => e.id === elem.id);
                                props.elems.splice(index, 1);
                                props.onElementsChange(props.elems.slice());
                            }}
                            draggable={true}
                            onMouseOver={(e) => {
                                const node = e.target;
                                node.shadowColor('black');
                                node.shadowBlur(35);
                                node.shadowOpacity(1);
                            }}
                            onMouseOut={(e) => {
                                const node = e.target;
                                //node.scaleX(1);
                                //node.scaleY(1);
                                node.shadowOpacity(0);
                            }}
                            isElementTouchedRef={props.isElementTouchedRef}
                            printAreaRef={props.printAreaRef}
                            stageRef={props.stageRef}
                            addToActiveNodes={addToActiveNodes}
                            removeFromActiveNodes={removeFromActiveNodes}
                        />
                    )
                }


            })}
            {props.isDrawingMode && props.currentLine && (
                // Render the current line being drawn
                <Line
                    points={props.currentLine.points}
                    stroke={props.currentLine.stroke || '#000000'}
                    strokeWidth={props.currentLine.strokeWidth || 2}
                    lineCap="round"
                    lineJoin="round"
                    draggable={false}
                    scaleX={1 / props.scale.x}
                    scaleY={1 / props.scale.y}
                    name={'drawingLine'}
                    dash={props.currentLine.dash || []}
                />
            )}

            <Transformer
                ref={trRef}
                nodes={activeNodesRef.current}
                anchorSize={props.isMobileView ? 20 : 10}
                anchorStroke={'black'}
                anchorFill={'white'}
                anchorStrokeWidth={2}
                anchorCornerRadius={20}
                boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                    }
                    return newBox;
                }}
                onTransformStart={(e) => {
                    props.isElementTransformingRef.current = true;
                }}
                onTransform={() => {
                }}
                onTransformEnd={(e) => {
                    props.isElementTransformingRef.current = false;
                }}
                anchorStyleFunc={(anchor) => {
                    if (resizeIconImage) {
                        if (anchor.hasName('top-center') || anchor.hasName('bottom-center')) {
                            anchor.height(6);
                            anchor.offsetY(3);
                            anchor.width(20);
                            anchor.offsetX(15);
                        }
                        if (anchor.hasName('middle-left') || anchor.hasName('middle-right')) {
                            anchor.height(30);
                            anchor.offsetY(15);
                            anchor.width(6);
                            anchor.offsetX(3);
                        }
                        if (anchor.hasName('top-left') || anchor.hasName('bottom-right')) {
                            anchor.height(20);  // adjust these values as needed
                            anchor.width(20);   // adjust these values as needed
                        }
                        if (anchor.hasName('top-right') || anchor.hasName('bottom-left')) {
                            anchor.height(20);  // adjust these values as needed
                            anchor.width(20);   // adjust these values as needed
                        }
                        if (anchor.hasName('rotater')) {
                            anchor.fill(null);
                            anchor.height(30);  // adjust these values as needed
                            anchor.width(30);   // adjust these values as needed
                            anchor.fillPatternImage(rotationIconImage);
                            anchor.fillPatternRepeat('no-repeat');
                            anchor.fillPatternScale({x: 1, y: 1});
                            anchor.fillPatternOffset({x: -2.5, y: -2.5});
                        }
                    }
                }}

            />
        </Layer>
    )
}


export default DrawingLayer;