import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["color", "size", "addToCart", "variantInput", "buttonContent", "buttonLoading"]
  static values = {
    productId: String,
    productTypeId: String,
    externalId: String,
    externalVariantId: String,
    data: Object,
    selectedColor: String,
    selectedSize: String,
    selectableColors: Array,
    selectableSizes: Array,
    availableColors: Array,
    availableSizes: Array
  }
  static outlets = ["designState"]

  connect() {
    const apiKey = document.querySelector('meta[name="api-key"]').content

    this.initializeState()
    this.loadInitialData()

    // Ensure variant input exists
    if (!this.hasVariantInputTarget) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'product_variant_id'
      input.id = 'product_variant_id'
      this.element.appendChild(input)
    }

    // Set up synchronization
    this.handleVariantChanged = this.handleVariantChanged.bind(this)
    document.addEventListener('variant-changed', this.handleVariantChanged)

    // Add listener for button state changes
    this.handleButtonStateChanged = this.handleButtonStateChanged.bind(this)
    document.addEventListener('variant-button-state-changed', this.handleButtonStateChanged)

    // Listen for Turbo Stream updates
    this.handleTurboRender = this.handleTurboRender.bind(this)
    document.addEventListener('turbo:render', this.handleTurboRender)

    // **Add listener for successful cart additions**
    this.handleFrameLoad = this.handleFrameLoad.bind(this)
    document.addEventListener('turbo:frame-load', this.handleFrameLoad)

    // Listen for design state changes
    document.addEventListener('design-state:changed', this.handleDesignStateChange.bind(this))
  }

  disconnect() {
    document.removeEventListener('variant-changed', this.handleVariantChanged)
    document.removeEventListener('variant-button-state-changed', this.handleButtonStateChanged)
    document.removeEventListener('turbo:render', this.handleTurboRender)
    document.removeEventListener('turbo:frame-load', this.handleFrameLoad)
    document.removeEventListener('design-state:changed', this.handleDesignStateChange.bind(this));
  }

  handleDesignStateChange(event) {
    console.log('Design state change event received:', event.detail)
    const hasUnsavedChanges = event.detail.hasUnsavedChanges
    this.addToCartTargets.forEach(target => {
      const addButtons = target.querySelectorAll('button[data-cart-button-disabled]')
      addButtons.forEach(button => {
        console.log('Setting hasUnsavedDesign on button:', button)
        if (hasUnsavedChanges) {
          button.setAttribute('data-has-unsaved-design', 'true')
          button.disabled = true
          button.classList.remove('btn-primary');
          button.classList.add('btn-secondary');
        } else {
          button.removeAttribute('data-has-unsaved-design')
          button.disabled = false
          button.classList.add('btn-primary')
          button.classList.remove('btn-secondary')
        }
      })
    })
  }

  handleVariantChanged(event) {
    const { color, size, sourceElement } = event.detail
    
    // Only sync if this isn't our own event
    if (sourceElement !== this.element) {
      this.syncVariantSelection(color, size)
    }
  }

  handleButtonStateChanged(event) {
    const { isValid, sourceElement } = event.detail
    // Only update if this isn't our own event
    if (sourceElement !== this.element) {
      this.addToCartTargets.forEach(target => {
        const addButtons = target.querySelectorAll('button[data-cart-button-disabled]')
        addButtons.forEach(addButton => {
          if (addButton) {
            addButton.disabled = !isValid
            addButton.dataset.cartButtonDisabled = (!isValid).toString()
            addButton.classList.toggle('btn-primary', isValid)
            addButton.classList.toggle('btn-secondary', !isValid)
          }
        })
      })
    }
  }

  handleFrameLoad(event) {
    const frame = event.target
    if (frame.id === 'cart-form-frame' || frame.id === 'mobile-cart-form-frame') {
      this.resetAfterCartAdd()
    }
  }

  resetAfterCartAdd() {

    // Reset selections
    this.selectedColorValue = ""
    this.selectedSizeValue = ""

    // Reset UI
    this.renderColorSelector()
    this.renderSizeSelector()

    // Reset button state
    this.updateAddToCartButton(false)

    // Clear variant input
    if (this.hasVariantInputTarget) {
      this.variantInputTarget.value = ""
    }

    // Dispatch events to sync other instances
    this.dispatch('variantStateChanged', {
      detail: {
        isValid: false,
        color: this.selectedColorValue,
        size: this.selectedSizeValue
      }
    })

    document.dispatchEvent(new CustomEvent('variant-button-state-changed', {
      detail: {
        isValid: false,
        sourceElement: this.element
      }
    }))
  }

  // Add new method
  resetAddToCartButton() {
    this.addToCartTargets.forEach(target => {
      const addButtons = target.querySelectorAll('button[data-cart-button-disabled]')
      addButtons.forEach(addButton => {
        if (addButton) {
          addButton.disabled = false
          addButton.dataset.cartButtonDisabled = "false"
          addButton.classList.add('btn-primary')
          addButton.classList.remove('btn-secondary')
          
          // Clear any existing tooltips
          if (addButton._tooltip) {
            addButton._tooltip.dispose()
            addButton._tooltip = null
          }
        }
      })
    })
  }

  initializeState() {
    const data = this.dataValue
    this.productTypeIdValue = data.product_type_id
    this.selectedColorValue = data.product_color || ""
    this.selectedSizeValue = data.size || ""
    this.selectableColorsValue = []
    this.selectableSizesValue = []
    this.availableColorsValue = []
    this.availableSizesValue = []
  }

  setupSync() {
    if (this.hasProductVariantOutlet) {
      
      // Listen on both this element and the outlet element
      const setupListener = (element, source) => {
        element.addEventListener('variant-changed', (event) => {
          const { color, size } = event.detail
          
          // Prevent infinite loops by checking if this is our own event
          if (event.target !== this.element) {
            this.syncVariantSelection(color, size)
          }
        })
      }
      
      // Set up listeners on both elements
      setupListener(this.element, this.element.id)
      setupListener(this.productVariantOutlet.element, 'outlet')
    }
  }

  syncVariantSelection(color, size) {
    
    if (color && color !== this.selectedColorValue) {
      this.selectedColorValue = color
      
      // Update color swatches UI
      const colorSwatches = this.element.querySelectorAll('[data-action="click->product-variant#handleColorChange"]')
      colorSwatches.forEach(swatch => {
        const isSelected = swatch.dataset.colorName === color
        swatch.classList.toggle('color-selector_swatch', !isSelected)
        swatch.classList.toggle('selected-color', isSelected)
      })
      
      this.updateAvailableSizesForColor(color)
      
      // Update product images if color hex is available
      const selectedSwatch = Array.from(colorSwatches).find(swatch => swatch.dataset.colorName === color)
      if (selectedSwatch) {
        this.updateProductImages(selectedSwatch.dataset.colorHex)
      }
    }

    if (size && size !== this.selectedSizeValue) {
      this.selectedSizeValue = size
      
      // Update size selector UI
      const sizeSelect = this.sizeTarget.querySelector('select')
      if (sizeSelect) {
        sizeSelect.value = size
      }
      
      this.updateAvailableColorsForSize(size)
    }

    // Update product variant and add to cart button state
    this.updateProductVariant()
  }

  async loadInitialData() {
    await this.loadColorSwatches()
    await this.loadAvailableSizes()
  }

  async loadColorSwatches() {
    try {
      const apiKey = document.querySelector('meta[name="api-key"]').content
      const response = await fetch(`/api/v1/product_types/${this.productTypeIdValue}/product_variants/colors`, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        }
      })
      const colors = await response.json()
      this.availableColorsValue = colors
      this.selectableColorsValue = colors.map(c => c.name)
      this.renderColorSelector()
    } catch (error) {
      console.error("Error loading color swatches:", error)
    }
  }

  async loadAvailableSizes() {
    try {
      const apiKey = document.querySelector('meta[name="api-key"]').content
      const response = await fetch(`/api/v1/product_types/${this.productTypeIdValue}/product_variants/sizes`, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        }
      })
      const sizes = await response.json()
      this.availableSizesValue = sizes
      this.selectableSizesValue = sizes.map(s => s.size)
      this.renderSizeSelector()
    } catch (error) {
      console.error("Error loading sizes:", error)
    }
  }

  renderColorSelector() {
    const colorHtml = this.availableColorsValue.map(color => {
      const isSelectable = this.selectableColorsValue.includes(color.name);
      const isSelected = this.selectedColorValue === color.name;
      
      return `
        <li class="list-group-item border-0" 
            data-color-parent="${color.name}">
          <div 
            class="${this.getColorSwatchClasses(isSelectable, isSelected)}"
            style="background-color: ${color.hex}; position: relative;"
            data-action="click->product-variant#handleColorChange"
            data-color-name="${color.name}"
            data-color-hex="${color.hex}"
            data-external-product-id="${color.ext_id}"
          >
            ${!isSelectable ? this.renderUnavailableOverlay() : ''}
          </div>
        </li>
      `
    }).join('')

    this.colorTarget.innerHTML = `
      <div class="color-selector position-relative me-n4 mb-3">
        <label class="form-label">Color:</label>
        <ul class="list-group list-group-horizontal productColorSwatch" style="flex-wrap: wrap">
          ${colorHtml}
        </ul>
      </div>
    `
  }

  getColorSwatchClasses(isSelectable, isSelected) {
    const baseClasses = "color-selector_swatch"
    if (!isSelectable) {
      return `${baseClasses} color-selector_swatch_disabled`
    }
    return isSelected ? 'selected-color' : baseClasses
  }

  renderUnavailableOverlay() {
    return `
      <div class="unavailable-overlay" style="
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 50%;
      ">
        <span style="
          color: #dc3545;
          font-weight: bold;
          font-size: 1.5em;
        ">×</span>
      </div>
    `
  }

  renderSizeSelector() {
    const sizeOptions = this.availableSizesValue.map(size => `
      <option value="${size.size}" 
              data-variant-id="${size.variant_id}"
              ${!this.selectableSizesValue.includes(size.size) ? 'disabled' : ''}>
        ${size.size}
      </option>
    `).join('')

    this.sizeTarget.innerHTML = `
      <div class="size-selector mb-3">
        <label class="form-label">Size:</label>
        <select class="form-select" 
                data-action="change->product-variant#handleSizeChange">
          <option value="">Select Size</option>
          ${sizeOptions}
        </select>
      </div>
    `

    // Set the selected value if one exists
    const select = this.sizeTarget.querySelector('select')
    if (select && this.selectedSizeValue) {
      select.value = this.selectedSizeValue
    }
  }

  async handleColorChange(event) {
    const colorName = event.currentTarget.dataset.colorName
    const colorHex = event.currentTarget.dataset.colorHex
    
    if (!this.selectableColorsValue.includes(colorName)) {
        this.showModal(`${colorName} is currently unavailable in the selected size.`)
        this.selectedSizeValue = ""
        this.renderSizeSelector()
        return
    }

    // Update this instance's UI
    this.element.querySelectorAll('.color-selector_swatch, .selected-color').forEach(swatch => {
        swatch.classList.remove('selected-color')
        swatch.classList.add('color-selector_swatch')
    })

    event.currentTarget.classList.remove('color-selector_swatch')
    event.currentTarget.classList.add('selected-color')

    this.selectedColorValue = colorName
    await this.updateAvailableSizesForColor(colorName)
    await this.updateProductVariant()
    this.updateProductImages(colorHex)
    this.dispatchVariantChanged()
  }
    
  async handleSizeChange(event) {
    const size = event.target.value
    
    if (!this.selectableSizesValue.includes(size)) {
      this.showModal(`Size ${size} is currently unavailable in the selected color.`)
      return
    }

    this.selectedSizeValue = size
    await this.updateAvailableColorsForSize(size)
    await this.updateProductVariant()

    // Notify other controllers about the change
    this.dispatchVariantChanged()
  }

  dispatchVariantChanged() {
    const event = new CustomEvent('variant-changed', {
      detail: {
        color: this.selectedColorValue,
        size: this.selectedSizeValue,
        sourceElement: this.element
      },
      bubbles: true
    })
  
    document.dispatchEvent(event)
  }

  async updateAvailableSizes() {
    if (!this.selectedColorValue) return
    
    try {
      const apiKey = document.querySelector('meta[name="api-key"]').content
      const response = await fetch(
        `/api/v1/product_types/${this.productTypeIdValue}/product_variants/available_sizes?color=${this.selectedColorValue}`,
        {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const sizes = await response.json()
      this.availableSizesValue = sizes
      this.selectableSizesValue = sizes.filter(s => s.available).map(s => s.size)
      this.renderSizeSelector()
    } catch (error) {
      console.error("Error updating available sizes:", error)
    }
  }

  async updateAvailableSizesForColor(color) {
    if (!color) return
    
    try {
      const apiKey = document.querySelector('meta[name="api-key"]').content
      const response = await fetch(
        `/api/v1/product_types/${this.productTypeIdValue}/product_variants/sizes?color=${color}`,
        {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const sizes = await response.json()
      const availableSizes = sizes
        .filter(sizeObj => sizeObj.available_colors.includes(color))
        .map(sizeObj => sizeObj.size)
      
      this.selectableSizesValue = availableSizes
      this.renderSizeSelector()

      // If current size is not available for this color, reset it
      if (this.selectedSizeValue && !availableSizes.includes(this.selectedSizeValue)) {
        this.selectedSizeValue = ""
      }
    } catch (error) {
      console.error("Error updating available sizes:", error)
    }
  }

  async updateAvailableColorsForSize(size) {
    if (!size) return
    
    try {
      const apiKey = document.querySelector('meta[name="api-key"]').content
      
      const headers = {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      }
      
      const response = await fetch(
        `/api/v1/product_types/${this.productTypeIdValue}/product_variants/colors?size=${size}`,
        { headers }
      )
      const colors = await response.json()
      const availableColors = colors
        .filter(colorObj => colorObj.available_sizes.includes(size))
        .map(colorObj => colorObj.name)
      
      this.selectableColorsValue = availableColors
      this.renderColorSelector()

      // If current color is not available for this size, reset it
      if (this.selectedColorValue && !availableColors.includes(this.selectedColorValue)) {
        this.selectedColorValue = ""
      }
    } catch (error) {
      console.error("Error updating available colors:", error)
    }
  }

  async updateProductVariant() {
    if (!this.productTypeIdValue || !this.selectedColorValue || !this.selectedSizeValue) {
      this.updateAddToCartButton(false)
      this.variantInputTargets.forEach(input => input.value = '')
      return
    }

    try {
      const apiKey = document.querySelector('meta[name="api-key"]').content
      const response = await fetch(
        `/api/v1/product_types/${this.productTypeIdValue}/product_variants/?size=${this.selectedSizeValue}&color=${this.selectedColorValue}`,
        {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const variants = await response.json()
      
      if (variants.length > 0) {
        const variant = variants[0]
        this.externalVariantIdValue = variant.printful_variant_id
        this.variantInputTargets.forEach(input => input.value = variant.id)
        this.updateAddToCartButton(true)
      } else {
        this.variantInputTargets.forEach(input => input.value = '')
        this.updateAddToCartButton(false)
      }
    } catch (error) {
      console.error("Error updating product variant:", error)
      this.variantInputTargets.forEach(input => input.value = '')
      this.updateAddToCartButton(false)
    }
  }

  updateProductImages(colorHex) {
    // Update thumbnails
    document.querySelectorAll('.product-gallery-thumblist-item')
      .forEach(element => {
        if (element) element.style.backgroundColor = colorHex
      })

    // Update large photo container and its konvajs-content
    const largePhoto = document.getElementById('product-gallery-large-photo')
    if (largePhoto) {
      largePhoto.style.backgroundColor = colorHex
      
      // Update all konvajs-content elements within large photo
      largePhoto.querySelectorAll('.konvajs-content').forEach(konvajsContent => {
        konvajsContent.style.backgroundColor = colorHex
        
        // Update canvas within konvajs-content
        const canvas = konvajsContent.querySelector('canvas')
        if (canvas) canvas.style.backgroundColor = colorHex
      })
    }

    // Update product controller color code
    const productElement = document.querySelector("[data-controller='product']")
    if (productElement) {
      productElement.dataset.productColorCodeValue = colorHex
    }
  }

  updateAddToCartButton(enabled) {
    const buttons = this.addToCartTargets
    const isValid = enabled && 
                   this.selectableSizesValue.includes(this.selectedSizeValue) && 
                   this.selectableColorsValue.includes(this.selectedColorValue)
    
    buttons.forEach(target => {
      // Find both regular and mobile buttons within the target
      const addButtons = target.querySelectorAll('button[data-cart-button-disabled]')
      addButtons.forEach(addButton => {
        if (addButton) {
          addButton.disabled = !isValid
          addButton.dataset.cartButtonDisabled = (!isValid).toString()
          
          // Remove all button state classes first
          addButton.classList.remove('btn-primary', 'btn-secondary')
          
          // Add appropriate class based on state
          if (isValid) {
            addButton.classList.add('btn-primary')
          } else {
            addButton.classList.add('btn-secondary')
          }
          
          // Clear any existing tooltips
          if (addButton._tooltip) {
            addButton._tooltip.dispose()
            addButton._tooltip = null
          }
        }
      })
    })
  
    // Dispatch events to sync other instances
    this.dispatch('variantStateChanged', { 
      detail: { 
        isValid,
        color: this.selectedColorValue,
        size: this.selectedSizeValue
      }
    })
  
    // Sync with mobile variant instance
    document.dispatchEvent(new CustomEvent('variant-button-state-changed', {
      detail: {
        isValid,
        sourceElement: this.element
      }
    }))
  }

  showModal(message) {
    // You can implement this using Bootstrap's modal or a custom implementation
    alert(message) // Temporary simple implementation
  }

  handleAddToCartClick(event) {
    const button = event.currentTarget
    console.log('Add to cart clicked, button:', button)
    console.log('Has unsaved design:', button.getAttribute('data-has-unsaved-design'))
    
    if (button.getAttribute('data-has-unsaved-design') === 'true') {
      event.preventDefault();
      alert('Please save your design changes before adding to cart.');
      return;
    }

    if (button.dataset.cartButtonDisabled === "true") {
      event.preventDefault()
      
      // Determine which selections are missing
      let message = []
      if (!this.selectedColorValue) message.push("color")
      if (!this.selectedSizeValue) message.push("size")
      
      if (message.length > 0) {
        this.showSelectionTooltip(button, message)
      }
    } else {
      // Show loading state
      this.setLoadingState(true);

      // Add event listener for turbo:submit-end
      const form = button.closest('form');
      const handleSubmitEnd = () => {
        this.setLoadingState(false);
        form.removeEventListener('turbo:submit-end', handleSubmitEnd);
      };
      form.addEventListener('turbo:submit-end', handleSubmitEnd);

      // Submit the form
      form.requestSubmit();    
    }
  }

  setLoadingState(loading) {
    this.buttonContentTargets.forEach(content => {
      content.classList.toggle('d-none', loading)
    })
    this.buttonLoadingTargets.forEach(loadingEl => {
      loadingEl.classList.toggle('d-none', !loading)
    })
  }

  showSelectionTooltip(button, missingItems) {
    if (!button._tooltip) {
      button._tooltip = new bootstrap.Tooltip(button, {
        title: `Please select ${missingItems.join(" and ")}`,
        trigger: "manual",
        placement: "top"
      })
    }
    
    button._tooltip.show()
    button.classList.add('shake-animation')
    
    setTimeout(() => {
      button.classList.remove('shake-animation')
    }, 820)
    
    setTimeout(() => {
      button._tooltip.hide()
    }, 2000)
  }

  handleTurboRender() {
    this.resetAfterCartAdd()
  }

  static stylesheets = `
    .color-selector_swatch_disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    
    .selected-color {
      border: 2px solid #000 !important;
    }
    
    .color-selector_swatch {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    
    .color-selector_swatch:hover {
      transform: scale(1.1);
    }
  `
}
