import { Controller } from "@hotwired/stimulus"
import KonvaStageService from '../services/konva_stage_service'

export default class extends Controller {
  static values = {
    id: Number,
    design: Object,
    templateData: Object
  }

  async connect() {
    if (this.stageService) {
      // If already initialized, destroy the previous instance
      this.stageService.destroy()
    }

    // Set explicit dimensions for the container
    const containerWidth = this.element.offsetWidth
    const containerHeight = this.element.offsetHeight

    // Ensure container has dimensions
    if (containerWidth === 0 || containerHeight === 0) {
      console.error('Container has zero dimensions')
      return
    }

    // Initialize the Konva stage
    this.stageService = await KonvaStageService.createThumbnail(
      this.element,
      this.templateDataValue,
      this.designValue,
      {
        width: containerWidth,
        height: containerHeight
      }
    )
  }

  disconnect() {
    if (this.stageService) {
      this.stageService.destroy()
    }
  }
}
