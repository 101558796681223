import React from "react";
import arrangeIcon from '../../../../assets/images/icon/arrange.svg';
import arrangeMoveUpIcon from '../../../../assets/images/icon/arrange-moveUp.svg';
import arrangeMoveDownIcon from '../../../../assets/images/icon/arrange-moveDown.svg';
import arrangeMoveToTopIcon from '../../../../assets/images/icon/arrange-moveTop.svg';
import arrangeMoveToBottomIcon from '../../../../assets/images/icon/arrange-moveBottom.svg';



export const ArrangeControls = ({
                                    isMobileView,
                                    onButtonClick,
                                    mobileIconStyles,
                                    props
                                }) => {

    if (isMobileView) {
        return (
            <button
                style={mobileIconStyles.mobile}
                className="mobile_context_button"
                onClick={onButtonClick}
            >
                <div
                    className="mobile_context_button_icon"
                    data-icon="Arrange"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${arrangeIcon})`
                    }}
                />
                <div>Arrange</div>
            </button>
        )
    }

    else {
        return (
            <button
                className="properties-panel__button desktop"
                onClick={onButtonClick}
            >
                <div
                    className="properties-panel__button-icon"
                    style={{
                        backgroundImage: `url(${arrangeIcon})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <span className="properties-panel__button-label">Arrange</span>
            </button>
        )
    }
}


export const ArrangeControlsContent = ({
                                           elems,
                                           setElems,
                                           selectedId,
                                           selectedItem,
                                           onElementsChange,
                                           selectedElement,
                                           onMoveUp,
                                           onMoveDown,
                                           onMoveToTop,
                                           onMoveToBottom,
                                           mobileIconStyles,
                                           isMobileView,
                                           placementRef,
                                           trRef
                                       }) => (
    <div className={isMobileView ? "slide-panel__grid" : "properties-panel__control-group"}>
        <button 
            className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
            onClick={() => onMoveToTop(
                elems,
                setElems,
                selectedId,
                selectedItem,
                selectedElement,
                onElementsChange,
                placementRef,
                trRef
            )}
        >
            <div 
                className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                style={{
                    backgroundImage: `url(${arrangeMoveToTopIcon})`
                }}
            />
            <span className="slide-panel__button-label">
                {isMobileView ? "Move to Top" : "Top"}
            </span>
        </button>

        <button 
            className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
            onClick={() => onMoveUp(
                elems,
                setElems,
                selectedId,
                selectedItem,
                selectedElement,
                onElementsChange,
                placementRef,
                trRef
            )}
        >
            <div 
                className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                style={{
                    backgroundImage: `url(${arrangeMoveUpIcon})`
                }}
            />
            <span className="slide-panel__button-label">
                {isMobileView ? "Move Up" : "Up"}
            </span>
        </button>

        <button 
            className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
            onClick={() => onMoveDown(
                elems,
                setElems,
                selectedId,
                selectedItem,
                selectedElement,
                onElementsChange,
                placementRef,
                trRef
            )}
        >
            <div 
                className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                style={{
                    backgroundImage: `url(${arrangeMoveDownIcon})`
                }}
            />
            <span className="slide-panel__button-label">
                {isMobileView ? "Move Down" : "Down"}
            </span>
        </button>

        <button 
            className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
            onClick={() => onMoveToBottom(
                elems,
                setElems,
                selectedId,
                selectedItem,
                selectedElement,
                onElementsChange,
                placementRef,
                trRef
            )}
        >
            <div 
                className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                style={{
                    backgroundImage: `url(${arrangeMoveToBottomIcon})`
                }}
            />
            <span className="slide-panel__button-label">
                {isMobileView ? "Move to Bottom" : "Bottom"}
            </span>
        </button>
    </div>
);

export const handleMoveToBottom = (elems,
                                   setElems,
                                   selectedId,
                                   selectedItem,
                                   selectedElement,
                                   onElementsChange,
                                   placementRef,
                                   trRef
) => {
    console.log("handleMoveToTop")
    // Filter out elements with the same placement as the selected element
    const samePlacementElements = elems.filter(e => e.placement === placementRef.current);

    // Find the index of the selected element within the filtered list
    const indexInSamePlacement = samePlacementElements.findIndex(e => e.id === selectedId);

    // Move the selected element to the top of the filtered list
    const movedElement = samePlacementElements.splice(indexInSamePlacement, 1)[0];
    samePlacementElements.unshift(movedElement);

    // Merge the updated filtered list with the rest of the elements
    const updatedElems = elems
        .filter(e => e.placement !== placementRef.current)
        .concat(samePlacementElements);

    if (selectedElement.current) {
        trRef.current.nodes([selectedElement.current]);
        trRef.current.getLayer().batchDraw();
    }


    // Update state and trigger any additional necessary changes
    setElems(updatedElems);
    onElementsChange(updatedElems);
}


export const handleMoveToTop = (elems,
                                setElems,
                                selectedId,
                                selectedItem,
                                selectedElement,
                                onElementsChange,
                                placementRef,
                                trRef
) => {
    // Filter out elements with the same placement as the selected element
    const samePlacementElements = elems.filter(e => e.placement === placementRef.current);

    // Find the index of the selected element within the filtered list
    const indexInSamePlacement = samePlacementElements.findIndex(e => e.id === selectedId);

    // Move the selected element to the end of the filtered list (i.e. the top layer)
    const movedElement = samePlacementElements.splice(indexInSamePlacement, 1)[0];
    samePlacementElements.push(movedElement);

    // Merge the updated filtered list with the rest of the elements
    const updatedElems = elems
        .filter(e => e.placement !== placementRef.current)
        .concat(samePlacementElements);

    if (selectedElement.current) {
        trRef.current.nodes([selectedElement.current]);
        trRef.current.getLayer().batchDraw();
    }


    // Update state and trigger any additional necessary changes
    setElems(updatedElems);
    onElementsChange(updatedElems);
}


export const handleMoveDown = (elems,
                               setElems,
                               selectedId,
                               selectedItem,
                               selectedElement,
                               onElementsChange,
                               placementRef,
                               trRef
) => {
    // Filter out elements with the same placement as the selected element
    const samePlacementElements = elems.filter(e => e.placement === placementRef.current);

    // Find the index of the selected element within the filtered list
    const indexInSamePlacement = samePlacementElements.findIndex(e => e.id === selectedId);

    // Check if the selected element is already at the bottom-most position
    if (indexInSamePlacement <= 0) {
        return;
    }

    // Swap the selected element with the one below it
    [samePlacementElements[indexInSamePlacement], samePlacementElements[indexInSamePlacement - 1]] =
        [samePlacementElements[indexInSamePlacement - 1], samePlacementElements[indexInSamePlacement]];

    // Merge the updated filtered list with the rest of the elements
    const updatedElems = elems
        .filter(e => e.placement !== placementRef.current)
        .concat(samePlacementElements);

    if (selectedElement.current) {
        trRef.current.nodes([selectedElement.current]);
        trRef.current.getLayer().batchDraw();
    }

    // Update state and trigger any additional necessary changes
    setElems(updatedElems);
    onElementsChange(updatedElems);
}


export const handleMoveUp = (elems,
                             setElems,
                             selectedId,
                             selectedItem,
                             selectedElement,
                             onElementsChange,
                             placementRef,
                             trRef) => {
    // Filter out elements with the same placement as the selected element
    const samePlacementElements = elems.filter(e => e.placement === placementRef.current);

    // Find the index of the selected element within the filtered list
    const indexInSamePlacement = samePlacementElements.findIndex(e => e.id === selectedId);

    // Check if the selected element is already at the top-most position
    if (indexInSamePlacement >= samePlacementElements.length - 1) {
        return;
    }

    // Swap the selected element with the one above it
    [samePlacementElements[indexInSamePlacement], samePlacementElements[indexInSamePlacement + 1]] =
        [samePlacementElements[indexInSamePlacement + 1], samePlacementElements[indexInSamePlacement]];

    // Merge the updated filtered list with the rest of the elements
    const updatedElems = elems
        .filter(e => e.placement !== placementRef.current)
        .concat(samePlacementElements);

    if (selectedElement.current) {
        trRef.current.nodes([selectedElement.current]);
        trRef.current.getLayer().batchDraw();
    }

    // Update state and trigger any additional necessary changes
    setElems(updatedElems);
    onElementsChange(updatedElems);
}
