// app/javascript/controllers/product_controller.js
import { Controller } from "@hotwired/stimulus"
import KonvaStageService from "../services/konva_stage_service"

export default class extends Controller {

    static targets = ["gallery"]
    static values = { 
        id: Number, 
        colorCode: String,
        templates: Array,
        design: Object,
        photos: Array
    }

    async connect() {
        if (this.hasGalleryTarget) {
            await this.initializeGallery()
        }
    }

    async initializeGallery() {
        const hasCustomizableText = this.designValue?.state?.some(element => 
            element.type === 'text' || (element.type === 'path' && element.textValue)
        )
        const konvaContainer = document.getElementById('konva-container')
        
        if ((hasCustomizableText || !this.photosValue?.length) && this.templatesValue?.length > 0 && konvaContainer) {
            this.stageService = await KonvaStageService.createProductGalleryWithPlacements(
                konvaContainer,
                {
                    templates: this.templatesValue,
                    designData: this.designValue,
                    productId: this.idValue,
                    defaultPlacement: 'front',
                    sizeReferenceSelector: '.col-lg-7'
                }
            )
        } else {
            const galleryContainer = document.getElementById('product-gallery-large-photo')
            if (galleryContainer) {
                this.stageService = new KonvaStageService(galleryContainer)
                await this.stageService.loadProductPhotos(this.photosValue)
            }
        }
    }

    handleResize() {
        if (this.stageService) {
            this.stageService.resize()
        }
    }

    disconnect() {
        if (this.stageService) {
            this.stageService.destroy()
            window.removeEventListener('resize', this.handleResize.bind(this))
        }
    }

    changePhoto(event) {
        event.preventDefault()
        const photoUrl = event.currentTarget.dataset.photoUrl
        if (this.stageService) {
            this.stageService.changePhoto(photoUrl)
        }
    }

}
