import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner", "text"]

  connect() {
    this.form = this.element.tagName === 'FORM' ? this.element : this.element.closest('form')
    if (this.form) {
      this.form.addEventListener('submit', this.handleSubmit.bind(this))
    }
  }

  handleSubmit() {
    sessionStorage.setItem('returnFromStripe', window.location.href)
    this.showSpinner()
  }

  showSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove('d-none')
    }
    if (this.hasTextTarget) {
      this.textTarget.textContent = this.element.dataset.loadingButtonTextValue || 'Loading...'
    }
    this.element.disabled = true
  }
}
