import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "destination"]

  connect() {
    //console.log("Admin remote select controller connected")
  }

  change() {
    // Add debugging to see what's happening
    //console.log("Selected option:", this.sourceTarget.selectedOptions[0])
    //console.log("Dataset:", this.sourceTarget.selectedOptions[0].dataset)
    
    // Construct URL based on the API pattern we see in the network tab
    const productTypeId = this.sourceTarget.value
    const url = `/api/v1/product_types/${productTypeId}/product_variants`

    // URL from options_for_select(ProductVariant.select_values(product&.product_type, product&.product_variant&.id))

    const target = this.destinationTarget

    if (!productTypeId) return

    fetch(url)
      .then(response => response.json())
      .then(data => {
        target.innerHTML = ''
        data.forEach(item => {
          const option = document.createElement('option')
          option.value = item.id
          option.textContent = item.variant_name
          target.appendChild(option)
        })
      })
      .catch(error => {
        console.error('Remote select error:', error)
      })
  }
}
