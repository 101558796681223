import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "suggestions", "recentSearches", "trendingSearches", "liveResults"]

  connect() {
    this.loadRecentSearches()
    this.loadTrendingSearches()
    this.debounceTimer = null
    this.setupClickOutsideListener()
    this.clickInProgress = false
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  setupClickOutsideListener() {
    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener('click', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.clickInProgress) return
    
    if (!this.element.contains(event.target)) {
      this.hideSuggestions()
    }
  }

  showSuggestions() {
    this.suggestionsTarget.classList.add('active')
  }

  hideSuggestions() {
    if (this.clickInProgress) return
    this.suggestionsTarget.classList.remove('active')
  }

  handleResultMouseDown(event) {
    if (event.target.closest('a')) {
      this.clickInProgress = true
    }
  }

  handleResultMouseUp() {
    setTimeout(() => {
      this.clickInProgress = false
    }, 100)
  }

  suggest() {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      const query = this.inputTarget.value.trim()
      if (query.length >= 2) {
        this.showLoadingState()
        this.performLiveSearch(query)
      } else {
        this.clearLiveResults()
      }
    }, 300)
  }

  async performLiveSearch(query) {
    try {
      const response = await fetch(`/store/search/suggestions?q=${encodeURIComponent(query)}`)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data = await response.json()
      this.updateLiveResults(data)
    } catch (error) {
      console.error('Search error:', error)
      this.handleSearchError(error)
    }
  }

  updateLiveResults(data) {
    const resultsHtml = this.formatSearchResults(data)
    this.liveResultsTarget.innerHTML = resultsHtml
    this.showSuggestions()
  }

  formatSearchResults(data) {
    if (!data.products?.length) {
      return '<div class="p-3 text-muted">No results found</div>'
    }

    return `
      <div class="search-suggestions-wrapper"
           data-action="mousedown->global-search#handleResultMouseDown mouseup->global-search#handleResultMouseUp">
        <div class="search-suggestions-scroll">
          ${data.products.join('')}
        </div>
      </div>
    `
  }

  clearLiveResults() {
    this.liveResultsTarget.innerHTML = ''
    this.hideSuggestions()
  }

  handleSearchError(error) {
    let errorMessage = 'Sorry, something went wrong. Please try again.'
    
    if (error.message.includes('Failed to fetch') || error.message.includes('NetworkError')) {
      errorMessage = 'Search is temporarily unavailable. Please try again later.'
    }
    
    this.liveResultsTarget.innerHTML = `
      <div class="p-3 text-danger">
        <i class="fas fa-exclamation-circle me-2"></i>
        ${errorMessage}
      </div>
    `
    
    // Hide error message after 3 seconds
    setTimeout(() => {
      this.clearLiveResults()
    }, 3000)
  }

  loadRecentSearches() {
    const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]')
    if (recentSearches.length === 0) {
      this.recentSearchesTarget.style.display = 'none'
      return
    }

    const recentSearchesHtml = recentSearches.map(term => `
      <a href="/store/search?search=${encodeURIComponent(term)}" 
         class="suggestion-item d-flex align-items-center p-2">
        <i class="fas fa-history me-2 text-muted"></i>
        ${term}
      </a>
    `).join('')

    this.recentSearchesTarget.querySelector('.suggestions-list').innerHTML = recentSearchesHtml
  }

  loadTrendingSearches() {
    // This would typically fetch from the server, but for now we'll hide it
    this.trendingSearchesTarget.style.display = 'none'
  }

  submit(event) {
    const query = this.inputTarget.value.trim()
    if (query) {
      this.saveRecentSearch(query)
    }
  }

  saveRecentSearch(query) {
    const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]')
    const updatedSearches = [query, ...recentSearches.filter(s => s !== query)].slice(0, 5)
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches))
  }

  showLoadingState() {
    this.liveResultsTarget.innerHTML = `
      <div class="p-3 text-center text-muted">
        <div class="spinner-border spinner-border-sm me-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        Searching...
      </div>
    `
    this.showSuggestions()
  }
}