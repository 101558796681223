import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "mobileCtaContainer", "mobileCta"]
  static values = {
    design: Object,
    productId: Number
  }

  connect() {
    this.stageService = null
    if (this.hasDesignValue && this.designValue.state) {
      this.currentDesignState = this.designValue.state
      this.originalState = JSON.parse(JSON.stringify(this.designValue.state))
    }
    document.addEventListener("design-state:updated", this.handleStateUpdate.bind(this))
  }

  disconnect() {
    document.removeEventListener("design-state:updated", this.handleStateUpdate.bind(this))
  }

  setStageService(service) {
    this.stageService = service
  }

  async updateState(newState) {
    this.currentDesignState = newState
    this.designValue = {
      ...this.designValue,
      state: newState
    }

    // Dispatch event for other controllers
    const event = new CustomEvent("design-state:updated", {
      detail: { state: newState }
    })
    document.dispatchEvent(event)

    // Update preview if stage service exists
    if (this.stageService) {
      await this.stageService.updateDesign(this.designValue)
    }
  }

  handleStateUpdate(event) {
    console.log('State update event received:', event.detail.state)
    this.currentDesignState = event.detail.state
    this.designValue = {
      ...this.designValue,
      state: event.detail.state
    }
    
    // Emit state changed event with hasChanges status
    const stateChangedEvent = new CustomEvent('design-state:changed', {
      bubbles: true,
      detail: { 
        hasUnsavedChanges: this.hasUnsavedChanges() 
      }
    })
    document.dispatchEvent(stateChangedEvent)
  }

  hasUnsavedChanges() {
    if (!this.currentDesignState || !this.originalState) {
      return false
    }
    return JSON.stringify(this.currentDesignState) !== JSON.stringify(this.originalState)
  }

  get currentState() {
    return this.currentDesignState || []
  }

  async saveDesignState() {
    try {
      const response = await fetch(`/products/${this.productIdValue}/design_customizations/update_design`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          state: this.currentState
        })
      })

      if (response.ok) {
        const result = await response.json()
        
        if (result.status === 'ok') {
          // Update URL without page reload
          window.history.pushState(
            {},
            '',
            result.data.product_path
          )

          // Refresh the cart form Turbo frame with the new product
          const cartFrame = document.getElementById('cart-form-frame')
          if (cartFrame) {
            const newProductId = result.data.product_path.split('/').pop()
            Turbo.visit(`/products/${newProductId}?frame=cart-form`, { frame: 'cart-form-frame' })
          }

          // Show success message
          const successAlert = document.createElement('div')
          successAlert.className = 'alert alert-success alert-dismissible fade show'
          successAlert.innerHTML = `
            Changes saved successfully!
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
          `
          this.element.insertAdjacentElement('beforebegin', successAlert)

          // Update originalState to reflect the saved state
          this.originalState = JSON.parse(JSON.stringify(this.currentDesignState));

          // Dispatch event indicating no unsaved changes
          const stateChangedEvent = new CustomEvent('design-state:changed', {
            bubbles: true,
            detail: { hasUnsavedChanges: false }
          });
          document.dispatchEvent(stateChangedEvent);

          return true
        }
      }
      return false
    } catch (error) {
      console.error('Error saving design state:', error)
      return false
    }
  }
} 