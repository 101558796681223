export const updateSelectionRect = (selection, selectionRectRef) => {
    if (selectionRectRef && selectionRectRef.current) {

        const node = selectionRectRef.current;
        node.setAttrs({
            visible: selection.current.visible,
            x: Math.min(selection.current.x1, selection.current.x2),
            y: Math.min(selection.current.y1, selection.current.y2),
            width: Math.abs(selection.current.x1 - selection.current.x2),
            height: Math.abs(selection.current.y1 - selection.current.y2),
            fill: "rgba(0, 161, 255, 0.3)"
        });
        //node.getLayer().batchDraw();
    }
};

export const checkDeselect = (e, setSelectedId, setSelectedElement, trRef, setNodes) => {
    // deselect when clicked on empty area
    console.log('Clicked on Empty Area:')
    const clickedOnEmpty = e.target === e.target.getStage();

    if (clickedOnEmpty) {
        setSelectedId(null);
        setSelectedElement(null);
        trRef.current.nodes([]);
        setNodes([]);
        //props.layerRef.current.remove(selectionRectangle);
    }
};

export const handleZoomInClick = (e, stage, printArea, drawingArea, templateArea, prevZoomValue, setScale, pinchDist = null, lastPinchDist = null) => {
    let prevZoom = prevZoomValue || printArea.scaleX();

    // Default zoom factor for button click if pinch distances are not provided
    let zoomFactor = pinchDist && lastPinchDist ? 1 + (pinchDist - lastPinchDist) * 0.001 : 1.1;

    let scaleValue = prevZoom * zoomFactor;
    setScale({ x: scaleValue, y: scaleValue });

    printArea.scale({ x: scaleValue, y: scaleValue });
    drawingArea.scale({ x: scaleValue, y: scaleValue });
    templateArea.scale({ x: scaleValue, y: scaleValue });

    if (pinchDist && lastPinchDist) {
        if (e.touches && e.touches.length > 0) {
            const zoomCenterX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
            const zoomCenterY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
            const deltaX = stage.x() - zoomCenterX;
            const deltaY = stage.y() - zoomCenterY;

            [printArea, drawingArea, templateArea].forEach(layer => {
                layer.x(layer.x() + deltaX * (zoomFactor - 1));
                layer.y(layer.y() + deltaY * (zoomFactor - 1));
            });
        }
    } else {  // handle button click zooming
        const center = {
            x: stage.width() / 2,
            y: stage.height() / 2,
        };
        const relatedTo = {
            x: (center.x - printArea.x()) / prevZoom,
            y: (center.y - printArea.y()) / prevZoom,
        };
        const newPos = {
            x: center.x - relatedTo.x * scaleValue,
            y: center.y - relatedTo.y * scaleValue,
        };

        [printArea, drawingArea, templateArea].forEach(layer => {
            layer.position(newPos);
        });
    }

    stage.batchDraw();

    const event = new Event('handleZoomInClick');
    window.dispatchEvent(event);
}



export const handleZoomOutClick = (e, stage, printArea, drawingArea, templateArea, currentScale, setScale, pinchDist = null, lastPinchDist = null) => {
    let prevZoom = currentScale || printArea.scaleX();

    // Default zoom factor for button click if pinch distances are not provided
    let zoomFactor = pinchDist && lastPinchDist ? 1 - (lastPinchDist - pinchDist) * 0.001 : 0.9;

    let scaleValue = prevZoom * zoomFactor;
    setScale({ x: scaleValue, y: scaleValue });

    printArea.scale({ x: scaleValue, y: scaleValue });
    drawingArea.scale({ x: scaleValue, y: scaleValue });
    templateArea.scale({ x: scaleValue, y: scaleValue });

    if (pinchDist && lastPinchDist) {
        if (e.touches && e.touches.length > 0) {

            const zoomCenterX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
            const zoomCenterY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
            const deltaX = stage.x() - zoomCenterX;
            const deltaY = stage.y() - zoomCenterY;
            [printArea, drawingArea, templateArea].forEach(layer => {
                layer.x(layer.x() + deltaX * (zoomFactor - 1));
                layer.y(layer.y() + deltaY * (zoomFactor - 1));
            });
        }
    } else {  // handle button click zooming
        const center = {
            x: stage.width() / 2,
            y: stage.height() / 2,
        };
        const relatedTo = {
            x: (center.x - printArea.x()) / prevZoom,
            y: (center.y - printArea.y()) / prevZoom,
        };
        const newPos = {
            x: center.x - relatedTo.x * scaleValue,
            y: center.y - relatedTo.y * scaleValue,
        };

        [printArea, drawingArea, templateArea].forEach(layer => {
            layer.position(newPos);
        });
    }
    const event = new Event('handleZoomOutClick');
    window.dispatchEvent(event);
}




export const handleZoomFitClick = (fitToScreen, horizontalScrollerRef, verticalScrollerRef) => {
    // props.zoom or scale is default scale set when template is loaded
    fitToScreen(horizontalScrollerRef, verticalScrollerRef);
    const event = new Event('handleZoomFitClick');
    window.dispatchEvent(event);

}

export const handleZoomEditableClick = (fitToScreen, fitEditableAreaToScreen, horizontalScrollerRef, verticalScrollerRef) => {
    fitToScreen();
    setTimeout(() => {
        fitEditableAreaToScreen(horizontalScrollerRef, verticalScrollerRef);
    }, 250);
    setTimeout(() => {
        fitEditableAreaToScreen(horizontalScrollerRef, verticalScrollerRef);
    }, 250);
    const event = new Event('handleZoomEditableClick');
    window.dispatchEvent(event);
}