import React, { useState } from 'react';
import axios from 'axios';


const MyFilesAsset = ({ loadElement, isAuthenticated }) => {
    const [userFiles, setUserFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingPhotoId, setLoadingPhotoId] = React.useState(null);
    
    React.useEffect(() => {
        // Fetch the user's uploaded files

        if (isAuthenticated){
            setLoading(true);
            setTimeout(() => {
                axios.get('/api/v1/user_uploads')
                    .then(response => {
                        setUserFiles(response.data);
                    })
                    .catch(error => {
                        console.error("There was an error fetching the user's files", error);
                    });
                setLoading(false);
            }, 100);
        }

    }, [isAuthenticated]); // Empty dependency array means this useEffect will run once when component mounts


    const handleFileUpload = async (event) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('user_upload[file]', event.target.files[0]); //

        const file = event.target.files[0];

        if (!['image/jpeg', 'image/png'].includes(file.type)) {
            setError('Only PNG/JPG files are allowed.');
            setIsLoading(false);
            return;
        }

        try {
            const response = await axios.post('/api/v1/user_uploads', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            });
            setUserFiles([...userFiles, response.data.upload]);
        } catch (error) {
            console.error("There was an error uploading the file", error);
            setError('There was an error uploading the file.');
        } finally {
            setIsLoading(false);
            setUploadProgress(0); // Reset progress after upload is complete
        }

    };

    const handleFileClick = (event) => {
        const s3Url = event.target.attributes.getNamedItem('data-itemdatas3url').value;
        const fileId = event.target.attributes.getNamedItem('data-itemid').value;

        setLoadingPhotoId(fileId);  // Set the loading state

        loadElement(event).then(() => {
            setLoadingPhotoId(null);  // Reset the loading state
        }).catch(error => {
            console.error("Error in loadElement:", error);
        });    };

    return (
        isAuthenticated ? (

            <div>
                {error && <div className="error-message">{error}</div>}
                <label className="upload-btn-wrapper">
                    <input type="file" onChange={handleFileUpload} disabled={isLoading} />
                    Upload a File
                </label>
                {isLoading && (
                    <div className="progress-bar-container">
                        <div className="progress-bar" style={{width: `${uploadProgress}%`}}></div>
                    </div>
                )}
                <div className="user-files-grid icons-grid">
                    {userFiles.map(file => (
                        <div key={file.id} className="file-wrapper">

                            <img
                                key={file.id}
                                src={file.image_address}
                                alt={file.name}
                                onClick={handleFileClick}
                                width="50"
                                data-itemtype='MyFiles'
                                data-itemdatas3url={file.s3_full_image_address}
                                data-itemformat='raster'
                                data-itemid={file.id}
                                data-itemdata={file.image_address}
                            />
                            {String(loadingPhotoId) === String(file.id) && <div className="asset-loading-spinner"></div>}
                        </div>
                    ))}
                </div>
            </div>
        ) : (
            <div className="unauthenticated-message">
                <p>You need to be registered and signed in to upload files.</p>
            </div>
        )
    );
};

export default MyFilesAsset;
