import { Controller } from "@hotwired/stimulus"
import Session from 'svg-text-to-path'
import { Path } from '../packs/components/TextWarp/Path'

export default class extends Controller {
  static targets = ["form"]
  static values = {
    productId: Number
  }

  bendDebounceTimer = null
  scaleDebounceTimer = null

  connect() {
    // Use MutationObserver to wait for both designState and stageService
    this.observer = new MutationObserver(() => {
      const designStateController = this.application.controllers.find(
        controller => 
          controller.identifier === 'design-state' && 
          controller.element.contains(this.element)
      )

      const productController = this.application.controllers.find(
        controller => controller.identifier === 'product' && controller.stageService
      )

      if (designStateController && productController) {
        this.designStateController = designStateController
        this.stageService = productController.stageService
        this.designStateController.setStageService(this.stageService)
        this.observer.disconnect()
        this.setupCustomizationForm()
      }
    })

    this.observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  setupCustomizationForm() {
    const textElements = this.designStateController.currentState.filter(element => 
      element.type === 'path' && element.textValue
    )
    
    if (textElements.length === 0) return
    if (!this.hasFormTarget) return

    const form = this.formTarget
    form.innerHTML = this.buildCustomizationFormHTML(textElements)
  }

  buildCustomizationFormHTML(elements) {
    return `
      <div class="text-customization-element">
        <div class="element-selector">
          <select class="form-select" 
                  data-action="change->text-customization#selectElement">
            ${elements.map((element, index) => {
              const elementName = element.textValue || element.name || `Text ${index + 1}`
              return `
                <option value="${element.id}">
                  Text ${index + 1}: "${elementName}"
                </option>
              `
            }).join('')}
          </select>
        </div>
        ${this.buildElementFormHTML(elements[0])}
      </div>
    `
  }

  buildElementFormHTML(element) {
    const isText = element.textValue !== undefined
    const isSVG = element.format === 'svg'
    
    if (isText) {
      // Keep existing text customization code unchanged
      const WARP_TYPES = [
        { value: 'WARP_ARC', label: 'Arc' },
        { value: 'WARP_ARC_LOWER', label: 'Arc Lower' },
        { value: 'WARP_ARC_UPPER', label: 'Arc Upper' },
        { value: 'WARP_ARCH', label: 'Arch' },
        { value: 'WARP_BULGE', label: 'Bulge' },
        { value: 'WARP_FLAG', label: 'Flag' },
        { value: 'WARP_FISH', label: 'Fish' },
        { value: 'WARP_RISE', label: 'Rise' },
        { value: 'WARP_INFLATE', label: 'Inflate' },
        { value: 'WARP_SQUEEZE', label: 'Squeeze' },
        { value: 'WARP_WAVE_LOWER', label: 'Wave Lower' },
        { value: 'WARP_WAVE_UPPER', label: 'Wave Upper' }
      ]

      const FONTS = [
        { value: 'Roboto', label: 'Roboto' },
        { value: 'Open Sans', label: 'Open Sans' },
        { value: 'Lato', label: 'Lato' },
        { value: 'Montserrat', label: 'Montserrat' },
        { value: 'Oswald', label: 'Oswald' },
        { value: 'Raleway', label: 'Raleway' },
        { value: 'Playfair Display', label: 'Playfair Display' }
      ]

      return `
        <div class="text-customization-element" data-element-id="${element.id}">
          <div class="text-properties-grid">  
              <!-- Main Text Input -->
              <div class="text-input">
                <input type="text" 
                    class="form-control" 
                    value="${element.textValue || ''}" 
                    data-element-id="${element.id}"
                    data-action="input->text-customization#updatePreview"
                    placeholder="Enter text...">
              </div>

              <!-- Font Selection -->
              <div class="quick-controls">
                <select class="form-select"
                        data-element-id="${element.id}"
                        data-action="change->text-customization#updateFont"
                        value="${element.fontFamily || 'Roboto'}">
                    ${FONTS.map(font => `
                      <option value="${font.value}" ${element.fontFamily === font.value ? 'selected' : ''}>
                        ${font.label}
                      </option>
                    `).join('')}
                  </select>

                <select class="form-select"
                  data-element-id="${element.id}"
                  data-action="change->text-customization#updateWarpType"
                  value="${element.distortionType || 'none'}">
                  <option value="none">None</option>
                  ${WARP_TYPES.map(type => `
                    <option value="${type.value}" ${element.distortionType === type.value ? 'selected' : ''}>
                      ${type.label}
                    </option>
                  `).join('')}
                </select>

                <!-- Color Picker -->
                <input type="color" 
                    class="form-control form-control-color" 
                    value="${element.fill || '#000000'}" 
                    data-element-id="${element.id}"
                    data-action="input->text-customization#updateTextColor">
              </div>
          
            <!-- Controls Grid --> 
            <div class="controls-grid">
              <div class="position-controls">
                <button type="button" 
                        class="pos-button pos-up"
                        data-action="click->text-customization#moveText"
                        data-element-id="${element.id}"
                        data-direction="up">
                  <i class="fas fa-chevron-up"></i>
                </button>
                
                <button type="button" 
                        class="pos-button pos-left"
                        data-action="click->text-customization#moveText"
                        data-element-id="${element.id}"
                        data-direction="left">
                  <i class="fas fa-chevron-left"></i>
                </button>
                
                <button type="button" 
                        class="pos-button pos-center"
                        data-action="click->text-customization#centerText"
                        data-element-id="${element.id}">
                  <i class="fas fa-dot-circle"></i>
                </button>
                
                <button type="button" 
                        class="pos-button pos-right"
                        data-action="click->text-customization#moveText"
                        data-element-id="${element.id}"
                        data-direction="right">
                  <i class="fas fa-chevron-right"></i>
                </button>
                
                <button type="button" 
                        class="pos-button pos-down"
                        data-action="click->text-customization#moveText"
                        data-element-id="${element.id}"
                        data-direction="down">
                  <i class="fas fa-chevron-down"></i>
                </button>
              </div>
              
              <div class="adjustment-controls">
                <div class="property-row">
                  <label class="form-label">Bend Amount</label>
                  <input type="range" 
                        class="form-range" 
                        min="0" 
                        max="100" 
                        value="${element.bendValue || 0}"
                        data-element-id="${element.id}"
                        data-action="input->text-customization#debouncedUpdateBend">
                </div>

                <div class="property-row">
                  <label class="form-label">Size</label>
                  <input type="range" 
                        class="form-range" 
                        min="10" 
                        max="200" 
                        value="${(element.scale || 1) * 100}"
                        data-element-id="${element.id}"
                        data-action="input->text-customization#debouncedUpdateScale">
                </div>
              </div>
            </div>
          </div>
        </div>
      `
    } else if (isSVG) {
      // Add SVG color customization
      const colors = this.extractColorsFromSVG(element.svgXML)
      return `
        <div class="text-customization-element" data-element-id="${element.id}">
          <div class="mb-3">
            <label class="form-label">SVG Colors</label>
            <div class="d-flex flex-wrap gap-2">
              ${colors.map(color => `
                <div class="svg-color-item">
                  <input type="color" 
                         class="form-control form-control-color" 
                         value="${color}"
                         data-original-color="${color}"
                         data-element-id="${element.id}"
                         data-action="input->text-customization#updateSVGColor">
                  <small class="text-muted d-block text-center">${color}</small>
                </div>
              `).join('')}
            </div>
          </div>
        </div>
      `
    } else {
      // Simple color control for other elements
      return `
        <div class="text-customization-element" data-element-id="${element.id}">
          <div class="mb-2">
            <label class="form-label">Color</label>
            <input type="color" 
                   class="form-control form-control-color w-100" 
                   value="${element.fill || '#000000'}"
                   data-element-id="${element.id}"
                   data-action="input->text-customization#updateColor">
          </div>
        </div>
      `
    }
  }

  extractColorsFromSVG(svgXML) {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(svgXML, "text/xml")
    const uniqueColors = new Set()

    // Extract colors from fill attributes
    xmlDoc.querySelectorAll('[fill]').forEach(element => {
      const color = element.getAttribute('fill')
      if (color && color !== 'none') {
        uniqueColors.add(color.toLowerCase())
      }
    })

    // Extract colors from CSS
    const styleElements = xmlDoc.getElementsByTagName('style')
    Array.from(styleElements).forEach(style => {
      const colorRegex = /fill:\s*(#[0-9a-fA-F]{3,6}|rgb\([^)]+\))/g
      const matches = style.textContent.match(colorRegex) || []
      matches.forEach(match => {
        const color = match.replace('fill:', '').trim()
        uniqueColors.add(color.toLowerCase())
      })
    })

    return Array.from(uniqueColors)
  }

  async updatePreview(event) {
    const elementId = event.target.dataset.elementId
    const newText = event.target.value
    await this.updateTextWithEffects(elementId, { textValue: newText })
  }

  async updateWarpType(event) {
    const elementId = event.target.dataset.elementId
    const newType = event.target.value
    await this.updateTextWithEffects(elementId, { distortionType: newType })
  }

  async updateBend(event) {
    const elementId = event.target.dataset.elementId
    const bendValue = parseInt(event.target.value)
    await this.updateTextWithEffects(elementId, { bendValue })
  }

  async updateTextColor(event) {
    const elementId = event.target.dataset.elementId
    const newColor = event.target.value
    
    const updatedState = this.designStateController.currentState.map(element => {
      if (element.id === elementId) {
        return {
          ...element,
          fill: newColor
        }
      }
      return element
    })

    await this.updateDesignAndPreview(elementId, updatedState)
  }

  async convertTextToPath(text, container) {
    const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    svgElement.setAttribute("width", "500px")
    svgElement.setAttribute("height", "200px")

    const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text")
    textElement.setAttribute("fill", "black")
    textElement.setAttribute("x", "10")
    textElement.setAttribute("y", "144") // Set y to match fontSize
    textElement.setAttribute("font-family", "Roboto")
    textElement.setAttribute("font-size", "144")
    textElement.setAttribute("font-weight", "normal")
    textElement.setAttribute("font-style", "normal")
    textElement.textContent = text

    svgElement.appendChild(textElement)
    container.appendChild(svgElement)

    const session = new Session(svgElement, {
      googleApiKey: 'AIzaSyCOE5K7L-6bbNK6aY3SBU-ZT8kLVw_D2XU'
    })

    try {
      await session.replaceAll()
      const path = svgElement.querySelector('path')
      return path ? path.getAttribute('d') : null
    } catch (error) {
      console.error('Error converting text to path:', error)
      return null
    }
  }

  async saveCustomization(event) {
    event.preventDefault()
    await this.designStateController.saveDesignState()
  }

  // Helper method to handle all text updates
  async updateTextWithEffects(elementId, updates = {}) {
    const originalElement = this.designStateController.currentState.find(element => element.id === elementId)
    if (!originalElement) return
    
    const newElement = { ...originalElement, ...updates }
    const svgContainer = document.createElement('div')
    svgContainer.style.display = 'none'
    document.body.appendChild(svgContainer)

    try {
      const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg")
      
      // Use relative positioning within the SVG
      const baseWidth = originalElement.originalWidth || originalElement.width || 500
      const baseHeight = originalElement.originalHeight || originalElement.height || 200
      const scale = originalElement.scale || 1

      svgElement.setAttribute("width", `${baseWidth}px`)
      svgElement.setAttribute("height", `${baseHeight}px`)
      svgElement.setAttribute("viewBox", `0 0 ${baseWidth} ${baseHeight}`)

      const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text")
      Object.entries({
        fill: newElement.fill || originalElement.fill || "black",
        x: "10", // Use relative positioning
        y: "144", // Use relative positioning based on font size
        "font-family": newElement.fontFamily || originalElement.fontFamily || "Roboto",
        "font-size": newElement.fontSize || originalElement.fontSize || "144"
      }).forEach(([attr, value]) => textElement.setAttribute(attr, value))
      
      textElement.textContent = newElement.textValue || originalElement.textValue

      svgElement.appendChild(textElement)
      svgContainer.appendChild(svgElement)

      const session = new Session(svgElement, {
        googleApiKey: 'AIzaSyCOE5K7L-6bbNK6aY3SBU-ZT8kLVw_D2XU'
      })
      
      await session.replaceAll()
      const path = svgElement.querySelector('path')
      const originalPathData = path ? path.getAttribute('d') : null

      if (!originalPathData) {
        throw new Error('Failed to convert text to path')
      }

      // Apply distortion if needed
      let p = new Path(originalPathData)
      if (newElement.distortionType) {
        p.warp({
          type: newElement.distortionType,
          bend: (newElement.bendValue / 100) || 0,
          distortV: (newElement.horizontalDistort / 100) || 0,
          distortH: (newElement.verticalDistort / 100) || 0
        })
      }

      // Preserve the original position while updating other properties
      const updatedState = this.designStateController.currentState.map(element => {
        if (element.id === elementId) {
          return {
            ...element,
            ...newElement,
            type: 'path',
            data: p.output(),
            originalData: originalPathData,
            // Preserve original position
            x: originalElement.x,
            y: originalElement.y,
            width: baseWidth,
            height: baseHeight,
            scale: scale,
            originalWidth: baseWidth,
            originalHeight: baseHeight
          }
        }
        return element
      })

      await this.designStateController.updateState(updatedState)

    } catch (error) {
      console.error('Error updating text effects:', error)
    } finally {
      document.body.removeChild(svgContainer)
    }
  }

  async updateFont(event) {
    const elementId = event.target.dataset.elementId
    const newFont = event.target.value
    await this.updateTextWithEffects(elementId, { fontFamily: newFont })
  }

  async updateDesignAndPreview(elementId, updatedState) {
    await this.designStateController.updateState(updatedState)
  }

  debouncedUpdateBend(event) {
    const elementId = event.target.dataset.elementId
    const bendValue = parseInt(event.target.value)
    
    // Clear any existing timer
    if (this.bendDebounceTimer) {
      clearTimeout(this.bendDebounceTimer)
    }
    
    // Set new timer
    this.bendDebounceTimer = setTimeout(async () => {
      await this.updateTextWithEffects(elementId, { bendValue })
    }, 150) // 150ms delay
  }

  async moveText(event) {
    event.preventDefault() // Prevent any form submission
    const elementId = event.currentTarget.dataset.elementId
    const direction = event.currentTarget.dataset.direction
    const step = 10 // pixels to move per click
    
    const updatedState = this.designStateController.currentState.map(element => {
      if (element.id === elementId) {
        const x = element.x || 0
        const y = element.y || 0
        
        switch(direction) {
          case 'left': return { ...element, x: x - step }
          case 'right': return { ...element, x: x + step }
          case 'up': return { ...element, y: y - step }
          case 'down': return { ...element, y: y + step }
          default: return element
        }
      }
      return element
    })

    await this.updateDesignAndPreview(elementId, updatedState)
  }

  measureTextDimensions(element) {
    const svgNS = "http://www.w3.org/2000/svg"
    const tempSvg = document.createElementNS(svgNS, "svg")
    const textElement = document.createElementNS(svgNS, "text")

    textElement.setAttribute("fill", element.fill || "black")
    textElement.setAttribute("font-family", element.fontFamily || "Roboto")
    textElement.setAttribute("font-size", element.fontSize || "144")
    textElement.textContent = element.textValue

    tempSvg.appendChild(textElement)
    document.body.appendChild(tempSvg)

    const bbox = textElement.getBBox()
    const scale = element.scaleX || 1  // Use scaleX since we're using uniform scaling

    const dimensions = {
      width: bbox.width * scale,
      height: bbox.height * scale
    }

    document.body.removeChild(tempSvg)
    return dimensions
  }

  async centerText(event) {
    event.preventDefault()
    const elementId = event.currentTarget.dataset.elementId
    const currentPlacement = this.stageService.currentPlacement
    const template = this.stageService.templates.find(t => t.placement === currentPlacement)
    
    const element = this.designStateController.currentState.find(e => e.id === elementId)
    const { width, height } = this.measureTextDimensions(element)
    
    // Calculate center of printable area
    const printAreaCenterX = template.print_area_left + (template.print_area_width / 2)
    const printAreaCenterY = template.print_area_top + (template.print_area_height / 2)
    
    const updatedState = this.designStateController.currentState.map(element => {
      if (element.id === elementId) {
        return {
          ...element,
          x: printAreaCenterX - (width / 2),
          y: printAreaCenterY - (height / 2)
        }
      }
      return element
    })

    await this.updateDesignAndPreview(elementId, updatedState)
  }

  async resetEffects(event) {
    event.preventDefault()
    const elementId = event.currentTarget.dataset.elementId
    
    await this.updateTextWithEffects(elementId, {
      distortionType: '',
      bendValue: 0,
      horizontalDistort: 0,
      verticalDistort: 0
    })
  }

  debouncedUpdateScale(event) {
    const elementId = event.target.dataset.elementId
    const scalePercent = parseInt(event.target.value)
    const scale = scalePercent / 100
    
    if (this.scaleDebounceTimer) {
      clearTimeout(this.scaleDebounceTimer)
    }
    
    this.scaleDebounceTimer = setTimeout(async () => {
      const updatedState = this.designStateController.currentState.map(element => {
        if (element.id === elementId) {
          return { 
            ...element, 
            scaleX: scale,
            scaleY: scale,
            // Keep original dimensions for reference
            originalWidth: element.originalWidth || element.width,
            originalHeight: element.originalHeight || element.height
          }
        }
        return element
      })
      await this.updateDesignAndPreview(elementId, updatedState)
    }, 150)
  }

  async selectElement(event) {
    const elementId = event.target.value
    const element = this.designStateController.currentState.find(e => e.id === elementId)
    
    if (!element) return
    
    const formsContainer = this.element.querySelector('[data-text-customization-target="elementForms"]')
    formsContainer.innerHTML = this.buildElementFormHTML(element)
  }

  updateSVGColor(event) {
    const elementId = event.target.dataset.elementId
    const originalColor = event.target.dataset.originalColor
    const newColor = event.target.value
    
    const element = this.designStateController.currentState.find(elem => elem.id === elementId)
    if (!element || !element.svgXML) return

    const colorMap = {
      [originalColor.toLowerCase()]: newColor.toLowerCase()
    }

    const updatedSVG = this.updateSVGColors(colorMap, element.svgXML)
    const newData = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(updatedSVG)))}`
    
    this.stageService.updateElement(elementId, {
      svgXML: updatedSVG,
      data: newData
    })
  }

  async updateTextEffect(event) {
    const elementId = event.target.dataset.elementId
    const newType = event.target.value
    await this.updateTextWithEffects(elementId, { distortionType: newType })
  }
}
