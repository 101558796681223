import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sortOverlay", "filterOverlay", "noProductModal"]

  toggleSortOverlay(event) {
    this.sortOverlayTarget.classList.toggle('shown')
  }

  toggleFilterOverlay(event) {
    this.filterOverlayTarget.classList.toggle('shown')
  }

  closeNoProductModal() {
    this.noProductModalTarget.classList.remove('shown')
    document.getElementById('overlay').classList.remove('shown')
  }
}

