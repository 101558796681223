import React from 'react';

import normalPreview from '../../../../assets/images/brushes/normal.png';
import wavyPreview from '../../../../assets/images/brushes/wavy.png';
import zigzagPreview from '../../../../assets/images/brushes/zigzag.png';
import dottedPreview from '../../../../assets/images/brushes/dotted.png';

const FreeHandDrawingAsset = ({
                                  isDrawingMode,
                                  setIsDrawingMode,
                                  brushColor,
                                  setBrushColor,
                                  brushSize,
                                  setBrushSize,
                                  isEraserActive,
                                  setIsEraserActive,
                                  brushType,
                                  setBrushType,
                              }) => {
    const handleBrushSelect = (type) => {
        setIsEraserActive(false);
        setBrushType(type);
        console.log(`${type} brush selected`);
    };

    const handleEraserSelect = () => {
        setIsEraserActive(true);
    };

    const handleColorChange = (e) => {
        setBrushColor(e.target.value);
    };

    const handleSizeChange = (e) => {
        const newSize = parseInt(e.target.value, 10);
        setBrushSize(newSize);
    };

    // Define a mapping from brush types to their respective preview images
    const brushPreviews = {
        normal: normalPreview,
        dotted: dottedPreview,
        wavy: wavyPreview,
        zigzag: zigzagPreview,
    };

    return (
        <div className="p-3">
            <div className="mb-3">
                <button className={`btn ${isDrawingMode ? 'btn-danger' : 'btn-info'}`} onClick={() => setIsDrawingMode(!isDrawingMode)}>
                    {isDrawingMode ? 'Disable Drawing Mode' : 'Enable Drawing Mode'}
                </button>
            </div>
            <div className="btn-group-vertical" role="group" aria-label="Brush types">
                {Object.entries(brushPreviews).map(([type, preview]) => (
                    <button key={type} className={`btn ${brushType === type ? 'btn-success' : 'btn-secondary'}`}
                            onClick={() => handleBrushSelect(type)}>
                        <img src={preview} alt={`${type} brush`} className="img-thumbnail" style={{width: '100%', height: 25}}/>
                    </button>
                ))}
            </div>
            <div className="mt-3">
                <p className="form-label">Brush Type: {brushType}</p>
                <div className="mb-3">
                    <label htmlFor="brushColor" className="form-label">Color:</label>
                    <input id="brushColor" type="color" className="form-control form-control-color" value={brushColor} onChange={handleColorChange}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="brushSize" className="form-label">Size:</label>
                    <input id="brushSize" type="range" className="form-range" min="1" max="20" value={brushSize} onChange={handleSizeChange}/>
                </div>
            </div>
        </div>
    );
};

export default FreeHandDrawingAsset;
