// app/javascript/controllers/auto_hide_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delay: Number
  }
  
  connect() {
    // Auto-hide after delay (30 minutes by default)
    setTimeout(() => {
      this.element.classList.add('fade-out')
      setTimeout(() => {
        this.element.remove()
      }, 500)
    }, this.delayValue)
  }
}