import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect() {
    this.updateBulkActionsVisibility()
  }

  toggleAll(event) {
    const checked = event.target.checked
    document.querySelectorAll('.element-checkbox').forEach(checkbox => {
      checkbox.checked = checked
    })
    this.updateBulkActionsVisibility()
  }

  toggleActions() {
    this.updateBulkActionsVisibility()
  }

  updateBulkActionsVisibility() {
    const checkedBoxes = document.querySelectorAll('.element-checkbox:checked')
    const bulkActions = document.querySelector('.bulk-actions')
    
    if (checkedBoxes.length > 0) {
      bulkActions.style.display = 'block'
    } else {
      bulkActions.style.display = 'none'
    }
  }
}