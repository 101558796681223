export const setupAjaxHandlers = () => {
  // Generic AJAX success handler
  document.addEventListener('ajax:success', function(event) {
    const [response] = event.detail;
    if (response.success) {
      window.location.href = response.location;
    } else {
      const errorsDiv = document.getElementById('ajax_errors');
      if (errorsDiv) {
        errorsDiv.innerHTML = response.errors;
        errorsDiv.style.display = 'block';
      }
    }
  });

  // Generic AJAX error handler
  document.addEventListener('ajax:error', function(event) {
    const errorsDiv = document.getElementById('ajax_errors');
    if (errorsDiv) {
      errorsDiv.innerHTML = "There was an error processing the request.";
      errorsDiv.style.display = 'block';
    }
  });
};



