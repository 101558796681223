import React from "react";

const AssetTypeButton = ({ type, icon, onSelect, selectedButton }) => {
    const isSelected = type === selectedButton;
    const buttonStyle = isSelected ? { backgroundColor: 'rgb(78 84 200 / 31%)' } : {};

    return (
        <span
            className='categories-list-item'
            data-button-title={type}
            style={buttonStyle}
            onClick={() => {
                console.log("Button clicked:", type);
                onSelect(type);
            }}
        >
      <span className={`bp3-icon bp3-icon-${type} categories-icon`}>
        <i className={`fas ${icon}`}></i>
      </span>
            &nbsp;&nbsp;&nbsp;
            <span className='sidebar-link-title'>{type}</span>
        </span>
    );
};

export default AssetTypeButton;