import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const returnUrl = sessionStorage.getItem('returnFromStripe')
    if (returnUrl && window.location.href.includes(returnUrl)) {
      sessionStorage.removeItem('returnFromStripe')
      window.location.reload()
    }
  }
}
