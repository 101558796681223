import { loadImage } from './image-loader.js';

export class KonvaRenderer {
  constructor(container) {
    this.container = container;
  }

  /**
   * Creates a stage with appropriate dimensions
   * @param {Object} template - Template information
   * @param {boolean} isPrintable - Whether this is a print asset
   */
  createStage(template, isPrintable) {
    return new Konva.Stage({
      container: this.container,
      width: isPrintable ? template.print_area_width : template.print_width,
      height: isPrintable ? template.print_area_height : template.print_height,
    });
  }

  /**
   * Main entry point for rendering elements
   * @param {Object} options
   * @param {Array} options.elements - Array of design elements
   * @param {Object} options.template - Template information
   * @param {boolean} options.isPrintable - Whether this is a print asset
   * @param {number} options.scale - Scale factor
   */
  async renderDesign({ elements, template, isPrintable = false, scale = 1 }) {
    const stage = this.createStage(template, isPrintable);

    const layer = new Konva.Layer();
    stage.add(layer);

    // Process each element
    for (const element of elements) {
      const node = await this.createElementNode(element, {
        template,
        isPrintable,
        scale
      });
      if (node) layer.add(node);
    }

    layer.draw();
    return stage;
  }

  async createElementNode(element, options = {}) {
    const { template, isPrintable, scale = 1 } = options;
    
    // Calculate position with print area offset if needed
    const x = isPrintable ? element.x - template.print_area_left : element.x;
    const y = isPrintable ? element.y - template.print_area_top : element.y;

    switch (element.type) {
      case 'text':
        return this.createTextNode(element, { x, y, scale });
      case 'path':
        return this.createPathNode(element, { x, y, scale });
      case 'pattern':
        return this.createPatternNode(element, { x, y, scale });
      case 'circle':
        return this.createCircleNode(element, { x, y, scale });
      case 'dotted-brush':
        return this.createDottedBrushNode(element, { x, y, scale });
      case 'freehand':
        return this.createFreehandNode(element, { x, y, scale });
      case 'rectangle':
        return this.createRectangleNode(element, { x, y, scale });
      default:
        return this.createImageNode(element, { x, y, scale });
    }
  }

  async createTextNode(element, { x, y, scale }) {
    return new Konva.TextPath({
      x, y,
      data: element.data,
      text: element.text,
      fill: element.fill,
      stroke: element.stroke || 'transparent',
      strokeWidth: element.strokeWidth || 0,
      fontSize: element.fontSize * scale,
      fontFamily: element.fontFamily,
      scaleX: (element.scaleX || 1) * scale,
      scaleY: (element.scaleY || 1) * scale,
      rotation: element.rotation || 0
    });
  }

  async createPathNode(element, { x, y, scale }) {
    // Create a group to hold all layers
    const group = new Konva.Group({
      x, y,
      rotation: element.rotation || 0
    });

    // 1. Add 3D layers first (Bottom)
    if (element.threeDPaths?.length > 0) {
      const totalLayers = element.threeDPaths.length;
      element.threeDPaths.forEach((pathData, index) => {
        if (index === 0) return; // Skip first layer as it's the main path

        const layerProgress = index / (totalLayers - 1);
        let layerColor = element.fill;

        if (index > 1) {
          const sideGradient = element.threeDEffects?.sideGradient || {
            enabled: false,
            startColor: '#000000',
            endColor: '#333333'
          };

          if (sideGradient.enabled) {
            const colorProgress = (index - 1) / (totalLayers - 2);
            layerColor = this.interpolateColor(sideGradient.startColor, sideGradient.endColor, colorProgress);
          } else {
            layerColor = element.threeDEffects?.sideColor || '#000000';
          }
        }

        const layer = new Konva.Path({
          data: pathData,
          x: (layerProgress * (element.threeDEffects?.depth || 0) * (element.threeDEffects?.layerOffset?.x || 0)),
          y: (layerProgress * (element.threeDEffects?.depth || 0) * (element.threeDEffects?.layerOffset?.y || 1)),
          scaleX: (element.scaleX || 1) * scale,
          scaleY: (element.scaleY || 1) * scale,
          fill: layerColor,
          opacity: 1 - (layerProgress * 0.1),
          listening: false
        });

        group.add(layer);
      });
    }

    // 2. Add strokes (Middle)
    if (element.strokes) {
      element.strokes.forEach(strokeData => {
        if (!strokeData.enabled) return;
        
        const strokeLayer = new Konva.Path({
          data: element.data,
          scaleX: (element.scaleX || 1) * scale,
          scaleY: (element.scaleY || 1) * scale,
          fill: 'transparent',
          stroke: strokeData.color,
          strokeWidth: strokeData.width * scale,
          offsetX: strokeData.offset,
          offsetY: strokeData.offset,
          dash: Array.isArray(strokeData.dashArray) ? 
            strokeData.dashArray : 
            strokeData.dashArray?.split(',').map(Number),
          dashEnabled: true,
          listening: false
        });

        group.add(strokeLayer);
      });
    }

    // 3. Add main path last (Top)
    const mainPath = new Konva.Path({
      data: element.data,
      fill: element.gradientType ? undefined : (element.fill || 'transparent'),
      stroke: element.stroke || 'black',
      strokeWidth: (element.strokeWidth || 1) * scale,
      scaleX: (element.scaleX || 1) * scale,
      scaleY: (element.scaleY || 1) * scale,
      fillPriority: element.gradientType ? 
        (element.gradientType === 'linear' ? 'linear-gradient' : 'radial-gradient') : 
        'color'
    });

    group.add(mainPath);

    // Handle gradients if present
    if (element.gradientType) {
      await this.applyGradient(mainPath, element);
    }

    return group;
  }

  async createPatternNode(element, { x, y, scale }) {
    const group = new Konva.Group({
      x, y,
      width: element.width * scale,
      height: element.height * scale,
      scaleX: element.scaleX || 1,
      scaleY: element.scaleY || 1,
      rotation: element.rotation || 0
    });

    // Add each image element in the pattern
    if (element.elems?.length) {
      for (const subElem of element.elems) {
        const image = await loadImage(subElem.image);
        if (!image) {
          console.warn(`Failed to load sub-element image for pattern: ${subElem.id}. Skipping...`);
          continue;
        }

        const subImgNode = new Konva.Image({
          x: subElem.x * scale,
          y: subElem.y * scale,
          image: image,
          width: subElem.width * scale,
          height: subElem.height * scale
        });
        
        group.add(subImgNode);
      }
    }

    return group;
  }

  async createImageNode(element, { x, y, scale }) {
    let src = null;
    
    // Determine image source
    if (element.data?.startsWith('data:image/svg+xml;base64')) {
      src = element.data;
    } else if (element.s3Url) {
      src = element.s3Url;
    }

    if (!src) {
      console.warn(`No valid image source found for element ${element.id}`);
      return null;
    }

    const image = await loadImage(src);
    if (!image) {
      console.warn(`Failed to load image for element ${element.id}`);
      return null;
    }

    // Calculate dimensions while preserving aspect ratio
    const originalWidth = element.originalWidth || element.width || image.naturalWidth;
    const originalHeight = element.originalHeight || element.height || image.naturalHeight;
    
    // Apply scaling while maintaining aspect ratio
    const aspectRatio = originalWidth / originalHeight;
    let finalWidth = originalWidth * scale;
    let finalHeight = originalHeight * scale;

    if (element.width) {
      finalWidth = element.width * scale;
      finalHeight = finalWidth / aspectRatio;
    } else if (element.height) {
      finalHeight = element.height * scale;
      finalWidth = finalHeight * aspectRatio;
    }

    return new Konva.Image({
      x, y,
      image: image,
      id: element.id,
      width: finalWidth,
      height: finalHeight,
      rotation: element.rotation || 0,
      scaleX: element.scaleX || 1,
      scaleY: element.scaleY || 1,
      opacity: element.opacity || 1,
      draggable: false,
      listening: false
    });
  }

  async applyGradient(node, element) {
    // Calculate padded dimensions for gradient (matching element-path.jsx)
    const PADDING_FACTOR = 2.5;
    const box = {
      width: Math.max(element.width || 1, 1),
      height: Math.max(element.height || 1, 1)
    };
    const paddedWidth = box.width * PADDING_FACTOR;
    const paddedHeight = box.height * PADDING_FACTOR;
    const offsetX = (paddedWidth - box.width) / 2;
    const offsetY = (paddedHeight - box.height) / 2;

    if (element.gradientType === 'linear') {
      // Convert to local coordinates
      const localStartX = -offsetX + (paddedWidth * (element.linearGradientX || 50) / 100);
      const localStartY = -offsetY + (paddedHeight * (element.linearGradientY || 50) / 100);
      const angleRad = (element.gradientAngle || 0) * Math.PI / 180;
      
      node.fillLinearGradientStartPoint({ 
        x: localStartX / node.scaleX(),
        y: localStartY / node.scaleY()
      });
      node.fillLinearGradientEndPoint({
        x: (localStartX + Math.cos(angleRad) * 100) / node.scaleX(),
        y: (localStartY + Math.sin(angleRad) * 100) / node.scaleY()
      });

      if (element.gradientStops) {
        const colorStops = element.gradientStops.reduce((arr, stop) => {
          arr.push(parseFloat(stop.offset), stop.color);
          return arr;
        }, []);
        node.fillLinearGradientColorStops(colorStops);
      }
    } 
    else if (element.gradientType === 'radial') {
      const localCenterX = -offsetX + (paddedWidth * (element.radialGradientX || 50) / 100);
      const localCenterY = -offsetY + (paddedHeight * (element.radialGradientY || 50) / 100);
      
      node.fillRadialGradientStartPoint({ 
        x: localCenterX / node.scaleX(),
        y: localCenterY / node.scaleY()
      });
      node.fillRadialGradientEndPoint({ 
        x: localCenterX / node.scaleX(),
        y: localCenterY / node.scaleY()
      });
      node.fillRadialGradientStartRadius(0);
      node.fillRadialGradientEndRadius(
        Math.max(paddedWidth, paddedHeight) * (element.radialGradientSize || 50) / 100
      );
      
      if (element.gradientStops) {
        const colorStops = element.gradientStops.reduce((arr, stop) => {
          arr.push(parseFloat(stop.offset), stop.color);
          return arr;
        }, []);
        node.fillRadialGradientColorStops(colorStops);
      }
    }
  }

  interpolateColor(startColor, endColor, progress) {
    const start = {
      r: parseInt(startColor.slice(1,3), 16),
      g: parseInt(startColor.slice(3,5), 16),
      b: parseInt(startColor.slice(5,7), 16)
    };
    
    const end = {
      r: parseInt(endColor.slice(1,3), 16),
      g: parseInt(endColor.slice(3,5), 16),
      b: parseInt(endColor.slice(5,7), 16)
    };
    
    const r = Math.round(start.r + (end.r - start.r) * progress);
    const g = Math.round(start.g + (end.g - start.g) * progress);
    const b = Math.round(start.b + (end.b - start.b) * progress);
    
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }

  async createCircleNode(element, { x, y, scale }) {
    return new Konva.Circle({
      x, y,
      width: element.width * scale,
      height: element.height * scale,
      rotation: element.rotation || 0,
      stroke: element.stroke,
      strokeWidth: (element.strokeWidth || 0) * scale,
      fill: element.fill,
      scaleX: (element.scaleX || 1) * scale,
      scaleY: (element.scaleY || 1) * scale,
      opacity: element.opacity || 1,
      draggable: false,
      listening: false
    });
  }

  async createDottedBrushNode(element, { x, y, scale }) {
    return new Konva.Line({
      x, y,
      points: element.points,
      stroke: element.stroke || '#000000',
      strokeWidth: (element.strokeWidth || 2) * scale,
      lineCap: 'round',
      lineJoin: 'round',
      dash: [element.strokeWidth * 2, element.strokeWidth * 2],
      scaleX: (element.scaleX || 1) * scale,
      scaleY: (element.scaleY || 1) * scale,
      draggable: false,
      listening: false
    });
  }

  async createFreehandNode(element, { x, y, scale }) {
    return new Konva.Line({
      x, y,
      points: element.points,
      stroke: element.stroke || '#000000',
      strokeWidth: (element.strokeWidth || 2) * scale,
      lineCap: 'round',
      lineJoin: 'round',
      dash: element.dash || [],
      scaleX: (element.scaleX || 1) * scale,
      scaleY: (element.scaleY || 1) * scale,
      draggable: false,
      listening: false
    });
  }

  async createRectangleNode(element, { x, y, scale }) {
    return new Konva.Rect({
      x, y,
      width: element.width * scale,
      height: element.height * scale,
      rotation: element.rotation || 0,
      stroke: element.stroke,
      strokeWidth: (element.strokeWidth || 0) * scale,
      fill: element.fill,
      scaleX: (element.scaleX || 1) * scale,
      scaleY: (element.scaleY || 1) * scale,
      opacity: element.opacity || 1,
      draggable: false,
      listening: false
    });
  }

  async createCompositeDesign(template, options) {
    const { state, scale, currentPlacement } = options;
    
    // Create an offscreen canvas for compositing
    const offscreenStage = new Konva.Stage({
      container: document.createElement('div'),
      width: template.print_area_width,
      height: template.print_area_height
    });
    
    const offscreenLayer = new Konva.Layer();
    offscreenStage.add(offscreenLayer);
    
    // Filter and load all designs for current placement
    const placementDesigns = state.filter(
      design => design.placement === currentPlacement
    );
    
    // Create a group for clipping
    const group = new Konva.Group({
      width: template.print_area_width,
      height: template.print_area_height,
      clipFunc: (context) => {
        context.rect(
          0,
          0,
          template.print_area_width,
          template.print_area_height
        );
      },
    });
    offscreenLayer.add(group);
    
    // Load all elements
    const elements = await Promise.all(
      placementDesigns.map(async (design) => {
        const adjustedDesign = {
          ...design,
          x: design.x - template.print_area_left,
          y: design.y - template.print_area_top
        };
        return this.createElementNode(adjustedDesign, {
          scale: 1,
          template,
          isPrintable: false
        });
      })
    );
    
    // Add all successfully loaded elements to group
    elements.filter(Boolean).forEach(element => {
      group.add(element);
    });
    
    offscreenLayer.draw();
    
    // Create composite image
    const compositeDataUrl = offscreenStage.toDataURL({ pixelRatio: 1 });
    const compositeImage = await this.loadImage(compositeDataUrl);
    
    if (!compositeImage) {
      console.error('Failed to create composite image');
      return null;
    }
    
    // Create final group with proper positioning and compositing
    const finalGroup = new Konva.Group({
      x: template.print_area_left * scale,
      y: template.print_area_top * scale,
      opacity: 0.7,
      clipFunc: (context) => {
        context.rect(
          0,
          0,
          template.print_area_width * scale,
          template.print_area_height * scale
        );
      }
    });
    
    const compositeNode = new Konva.Image({
      image: compositeImage,
      width: template.print_area_width * scale,
      height: template.print_area_height * scale,
      globalCompositeOperation: 'multiply'
    });
    
    finalGroup.add(compositeNode);
    offscreenStage.destroy();
    
    return finalGroup;
  }

  // Add helper method for image loading
  async loadImage(url) {
    return new Promise((resolve) => {
      if (!url) {
        console.warn('No URL provided for image loading');
        return resolve(null);
      }

      const image = new Image();
      image.crossOrigin = 'anonymous';
      
      image.onload = () => resolve(image);
      image.onerror = (error) => {
        console.error(`Failed to load image: ${url}`, error);
        const proxyUrl = `/api/v1/proxy_image?url=${encodeURIComponent(url)}`;
        const proxyImage = new Image();
        proxyImage.crossOrigin = 'anonymous';
        
        proxyImage.onload = () => resolve(proxyImage);
        proxyImage.onerror = () => {
          console.error(`Failed to load image through proxy: ${url}`);
          resolve(null);
        };
        
        proxyImage.src = proxyUrl;
      };

      image.src = url;
    });
  }

  async addTemplateImage(template, scale = 1) {
    const image = await this.loadImage(template.image_url);
    if (!image) {
      console.error('Failed to load template image:', template.image_url);
      return null;
    }

    return new Konva.Image({
      image: image,
      width: template.template_width * scale,
      height: template.template_height * scale,
      listening: false
    });
  }
} 