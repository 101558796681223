import React from "react";
import rotateIcon from "../../../../assets/images/icon/rotate.svg";
import rotateLeftIcon from "../../../../assets/images/icon/rotate-left.svg";
import rotateRightIcon from "../../../../assets/images/icon/rotate-right.svg";

export const handleRotateLeft = (
    elems,
    setElems,
    selectedId,
    selectedItem,
    selectedElement,
    onElementsChange
) => {
    let newRotation = selectedItem.rotation - 15;
    const element = {
        ...selectedItem,
        rotation: newRotation
    };
    selectedElement.rotation(newRotation);
    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    onElementsChange(elems_copy.slice());
};

export const handleRotateRight = (
    elems,
    setElems,
    selectedId,
    selectedItem,
    selectedElement,
    onElementsChange
) => {
    let newRotation = selectedItem.rotation + 15;
    const element = {
        ...selectedItem,
        rotation: newRotation
    };
    selectedElement.rotation(newRotation);
    const index = elems.findIndex((arrItem) => arrItem.id === selectedId);
    let elems_copy = elems.slice();
    elems_copy.splice(index, 1, element);
    onElementsChange(elems_copy.slice());
};

export const RotateControls = ({
    isMobileView,
    onButtonClick,
    mobileIconStyles,
    elems,
    setElems,
    selectedId,
    selectedItem,
    onElementsChange,
    selectedElement,
}) => {
    if (isMobileView) {
        return (
            <button
                style={mobileIconStyles.mobile}
                className="mobile_context_button"
                onClick={onButtonClick}
            >
                <div
                    className="mobile_context_button_icon"
                    data-icon="Rotate22"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${rotateIcon})`
                    }}
                />
                <div>Rotate</div>
            </button>
        );
    } else {
        return (
            <button
                className="properties-panel__button desktop"
                onClick={onButtonClick}
            >
                <div
                    className="properties-panel__button-icon"
                    style={{
                        backgroundImage: `url(${rotateIcon})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <span className="properties-panel__button-label">Rotate</span>
            </button>
        );
    }
}

export const RotateControlsContent = ({
    elems,
    setElems,
    selectedId,
    selectedItem,
    onElementsChange,
    selectedElement,
    mobileIconStyles,
    isMobileView
}) => {
    return (
        <div className={isMobileView ? "slide-panel__grid" : "properties-panel__control-group"}>
            <button 
                className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
                onClick={() => handleRotateLeft(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange
                )}
            >
                <div 
                    className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                    style={{
                        backgroundImage: `url(${rotateLeftIcon})`
                    }}
                />
                <span className="slide-panel__button-label">
                    {isMobileView ? "Rotate Left" : "Left"}
                </span>
            </button>

            <button 
                className={isMobileView ? "slide-panel__button" : "properties-panel__button desktop"}
                onClick={() => handleRotateRight(
                    elems,
                    setElems,
                    selectedId,
                    selectedItem,
                    selectedElement,
                    onElementsChange
                )}
            >
                <div 
                    className={isMobileView ? "slide-panel__button-icon" : "properties-panel__button-icon"}
                    style={{
                        backgroundImage: `url(${rotateRightIcon})`
                    }}
                />
                <span className="slide-panel__button-label">
                    {isMobileView ? "Rotate Right" : "Right"}
                </span>
            </button>
        </div>
    );
}