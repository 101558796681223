import React from 'react';

export const OptionSlider = ({ isVisible, content, onClose, children }) => {
    const sliderStyles = {
        position: 'absolute',
        bottom: isVisible ? '0' : '-100%',
        left: '0',
        width: '100%',
        height: isVisible ? '100%' : '0',
        backgroundColor: '#f5f5f5',
       // transition: 'bottom 0.3s',
    };

    return (
        <div style={sliderStyles} className={'option-slider'}>
            <div className="top-row-close" onClick={onClose}>
                Tap here to close
            </div>
            <div>
                {children || content}
            </div>
        </div>
    );
}
