//from: https://codesandbox.io/s/j28nz494r3?file=/src/utils.js:0-1354

export const calculateViewportCenter = (printAreaRef, stageRef, containerWidth, containerHeight) => {
  // Getting the top-left position of the printAreaRef relative to the stage
  const relativeX = -printAreaRef.current.getClientRect({relativeTo: stageRef.current}).x;
  const relativeY = -printAreaRef.current.getClientRect({relativeTo: stageRef.current}).y;

  // Calculate the current position of the stage
  const stageX = stageRef.current.x();
  const stageY = stageRef.current.y();

  // Get the scale values
  const scaleX = printAreaRef.current.scaleX();
  const scaleY = printAreaRef.current.scaleY();

  // Calculate the center of the viewport
  const centerX = (relativeX - stageX + containerWidth / 2) / scaleX;
  const centerY = (relativeY - stageY + containerHeight / 2) / scaleY;

  return { centerX, centerY };
}

export const getImageDimensions = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      resolve({ width: this.width, height: this.height });
    };
    img.onerror = function() {
      reject(new Error("Failed to load image at " + url));
    };
    img.src = url;
  });
};

export function base64encode(str) {
  if (typeof btoa === 'function') {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (m, p1) => {
      return String.fromCharCode(parseInt(p1, 16));
    }));
  }

  if (typeof Buffer !== 'undefined') {
    return Buffer.from(str, 'utf8').toString('base64');
  }

  throw new Error(`Can not find window.btoa or Buffer`);
}

export function base64decode(str) {
  if (typeof atob === 'function') {
    return decodeURIComponent(Array.prototype.map.call(atob(str), c => {
      // eslint-disable-next-line prefer-template
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  if (typeof Buffer !== 'undefined') {
    return Buffer.from(str, 'base64').toString('utf8');
  }

  throw new Error(`Can not find window.atob or Buffer`);
}

export function degToRad(angle) {
  return (angle / 180) * Math.PI;
}

export function getShapeRect(shape) {
  const angleRad = degToRad(shape.rotation);
  const x1 = shape.x;
  const y1 = shape.y;
  const x2 = x1 + shape.width * Math.cos(angleRad);
  const y2 = y1 + shape.width * Math.sin(angleRad);
  const x3 =
      shape.x +
      shape.width * Math.cos(angleRad) +
      shape.height * Math.sin(-angleRad);
  const y3 =
      shape.y +
      shape.height * Math.cos(angleRad) +
      shape.width * Math.sin(angleRad);
  const x4 = shape.x + shape.height * Math.sin(-angleRad);
  const y4 = shape.y + shape.height * Math.cos(angleRad);

  const leftX = Math.min(x1, x2, x3, x4);
  const rightX = Math.max(x1, x2, x3, x4);
  const topY = Math.min(y1, y2, y3, y4);
  const bottomY = Math.max(y1, y2, y3, y4);
  return {
    x: leftX,
    y: topY,
    width: rightX - leftX,
    height: bottomY - topY
  };
}

export function getBoundingBox(shapes) {
  console.log(shapes);
  let x1 = 9999999999;
  let y1 = 9999999999;
  let x2 = -999999999;
  let y2 = -999999999;
  shapes.forEach(shape => {
    const rect = getShapeRect(shape);
    x1 = Math.min(x1, rect.x);
    y1 = Math.min(y1, rect.y);
    x2 = Math.max(x2, rect.x + rect.width);
    y2 = Math.max(y2, rect.y + rect.height);
  });
  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1,
    rotation: 0
  };
}

export function extractStyleTagRules (styleContent) {
  let doc = document.implementation.createHTMLDocument(""),
      styleElement = document.createElement("style");

  styleElement.textContent = styleContent;
  // the style will only be parsed once it is added to a document
  doc.body.appendChild(styleElement);

  return styleElement.sheet.cssRules;
}