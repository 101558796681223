import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["warning"]

  connect() {
    this.checkOrientation = this.checkOrientation.bind(this)
    this.mediaQuery = window.matchMedia("(orientation: landscape)")
    this.mediaQuery.addEventListener('change', this.checkOrientation)
    this.checkOrientation()
    console.log('OrientationWarningController connected')
  }

  disconnect() {
    this.mediaQuery.removeEventListener('change', this.checkOrientation)
  }

  checkOrientation() {
    const isLandscape = this.mediaQuery.matches
    console.log('Orientation change detected:', { isLandscape })

    // Detect if the device is mobile
    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    if (isMobile && isLandscape) {
      this.showWarning()
    } else {
      this.hideWarning()
    }
  }

  showWarning() {
    this.warningTarget.style.display = 'flex'
    document.body.style.overflow = 'hidden' // Prevent scrolling behind the warning
  }

  hideWarning() {
    this.warningTarget.style.display = 'none'
    document.body.style.overflow = '' // Restore scrolling
  }
}