import React from 'react';
import {useState, useCallback, useEffect, useRef} from 'react';
import {Layer, Rect, Path, Line, Shape, Group, Image, Circle, Star, RegularPolygon} from 'react-konva';
import Konva from 'konva';
import {planarSymmetries, generateTiling} from '../lib/SymmetryGenerator';
import {throttle} from 'lodash';
import simplify from 'simplify-js';
import {v4 as uuidv4} from 'uuid';
import {Transformer} from 'react-konva';
import useImage from 'use-image';

const SymmetryShapeEditor = ({
                                 selectedElement,
                                 setSelectedElement,
                                 selectedGroupRef,
                                 symmetricalDrawingLayerRef,
                                 squarePosition,
                                 squareSize,
                                 setElems,
                                 elems,
                                 placementRef,
                                 symmetryType,
                                 setSymmetryType,
                                 symmetryParameters,
                                 setSymmetryParameters,
                                 setSelectedPatternTool,
                                 selectedPatternTool,
                                 patternDesign,
                                 setPatternDesign,
                                 selectedPatternDesignElementId,
                                 setSelectedPatternDesignElementId,
                                 isPatternDesignUpdatingFromAsset,
                                 setIsPatternDesignUpdatingFromAsset,
                                 offScreenPatternCanvasRef,
                                 stageRef,
                             }) => {

    const [isDrawing, setIsDrawing] = useState(false);
    // Store the original design
    //const [design, setDesign] = useState([]); Replaced by patternDesign and setPatternDesign
    //const [selectedDesignShapeId, setSelectedDesignShapeId] = useState(null); Replaced by selectedPatternDesignElementId and setSelectedPatternDesignElementId

    const [liveDrawingShapes, setLiveDrawingShapes] = useState([]); // Tracks all shapes drawn, including lines and rects
    const [startPoint, setStartPoint] = useState(null); // Starting point for rects and polygons
    const [currentElement, setCurrentElement] = useState(null);
    let currentOffScreenLine = null; // Reference to the current line being drawn off-screen
    const latestDesignRef = useRef(null);


    //Transformer states
    const transformerRef = useRef(null);

    const handleSelectDesignShape = (id) => {
        setSelectedPatternDesignElementId(id);
        const selectedShape = symmetricalDrawingLayerRef.current.findOne(`#${id}`);
        if (selectedShape) {
            transformerRef.current.nodes([selectedShape]);
            transformerRef.current.getLayer().batchDraw();
        }
    };

    // Add a useEffect to make TransformerRef to null when selectedPatternDesignElementId is null
    useEffect(() => {
        if (!selectedPatternDesignElementId) {
            transformerRef.current.nodes([]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [selectedPatternDesignElementId]);


    useEffect(() => {
        const handleSymmetryShapeClick = (e) => {
            const clickedElement = e.detail;
            // Handle the selection logic for the clicked element within the SymmetryShapeEditor
            // ...
        };

        const stage = stageRef.current;
        stage.container().addEventListener('symmetryShapeClick', handleSymmetryShapeClick);

        return () => {
            stage.container().removeEventListener('symmetryShapeClick', handleSymmetryShapeClick);
        };
    }, []);

    useEffect(() => {
        if (selectedElement && selectedElement.type === 'pattern' && selectedElement.design) {
            // If a pattern element is selected and it has a design, store it in the ref
            latestDesignRef.current = selectedElement.design;
        }
    }, [selectedElement]);

    useEffect(() => {
        // Whenever patternDesign changes, update the elems and design states
        //if (patternDesign.length > 0 && isPatternDesignUpdatingFromAsset) {
        if (isPatternDesignUpdatingFromAsset) {
            const updatedDesign = patternDesign;
            latestDesignRef.current = updatedDesign;
            loadDesignOntoOffScreenCanvas(updatedDesign);
            updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
            setIsPatternDesignUpdatingFromAsset(false);
        }
    }, [patternDesign, isPatternDesignUpdatingFromAsset]);



    useEffect(() => {
        if (selectedPatternDesignElementId) {
            const selectedShape = symmetricalDrawingLayerRef.current.findOne(`#${selectedPatternDesignElementId}`);
            if (selectedShape && selectedShape.isNode) {
                transformerRef.current.nodes([selectedShape]);
                transformerRef.current.getLayer().batchDraw();
            }
        } else {
            transformerRef.current.nodes([]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [setSelectedPatternDesignElementId]);

    const clearOffScreenCanvas = () => {
        const offScreenLayer = offScreenPatternCanvasRef.current.getLayer(); // Assuming getLayer() gets the correct Konva Layer
        offScreenLayer?.destroyChildren(); // Destroy all children of the off-screen canvas layer
        offScreenLayer?.clear(); // Clear the off-screen canvas layer
        offScreenLayer?.batchDraw(); // Ensure the canvas is re-drawn
        stageRef.current.batchDraw();
    };

    const loadDesignOntoOffScreenCanvas = (design) => {
        const offScreenLayer = offScreenPatternCanvasRef.current.getLayer();
        if (!offScreenLayer || !design) return;

        // Clear existing drawings before loading new ones
        offScreenLayer.destroyChildren();

        design.forEach(item => {
            let shape;
            switch (item.type) {
                case 'line':
                    shape = new Konva.Line({
                        x: item.x,
                        y: item.y,
                        width: item.width,
                        height: item.height,
                        rotation: item.rotation || 0,
                        scaleX: item.scaleX || 1,
                        scaleY: item.scaleY || 1,
                        opacity: item.opacity || 1,
                        points: item.points,
                        stroke: item.stroke || '#000000',
                        strokeWidth: item.strokeWidth || 2,
                        lineCap: 'round',
                        lineJoin: 'round',
                        tension: item.tension || 0,
                    });
                    break;
                case 'rect':
                    shape = new Konva.Rect({
                        x: item.x,
                        y: item.y,
                        width: item.width,
                        height: item.height,
                        rotation: item.rotation || 0,
                        scaleX: item.scaleX || 1,
                        scaleY: item.scaleY || 1,
                        opacity: item.opacity || 1,
                        fill: item.fill || 'transparent',
                        stroke: item.stroke || '#000000',
                        strokeWidth: item.strokeWidth || 2,
                    });
                    break;
                case 'circle':
                    shape = new Konva.Circle({
                        x: item.x,
                        y: item.y,
                        radius: item.radius,
                        rotation: item.rotation || 0,
                        scaleX: item.scaleX || 1,
                        scaleY: item.scaleY || 1,
                        opacity: item.opacity || 1,
                        fill: item.fill || 'transparent',
                        stroke: item.stroke || '#000000',
                        strokeWidth: item.strokeWidth || 2,
                    });
                    break;
                case 'star':
                    shape = new Konva.Star({
                        x: item.x,
                        y: item.y,
                        numPoints: item.numPoints,
                        innerRadius: item.innerRadius,
                        outerRadius: item.outerRadius,
                        rotation: item.rotation || 0,
                        scaleX: item.scaleX || 1,
                        scaleY: item.scaleY || 1,
                        opacity: item.opacity || 1,
                        fill: item.fill || 'transparent',
                        stroke: item.stroke || '#000000',
                        strokeWidth: item.strokeWidth || 2,
                    });
                    break;
                case 'polygon':
                    shape = new Konva.RegularPolygon({
                        x: item.x,
                        y: item.y,
                        sides: item.sides,
                        radius: item.radius,
                        rotation: item.rotation || 0,
                        scaleX: item.scaleX || 1,
                        scaleY: item.scaleY || 1,
                        opacity: item.opacity || 1,
                        fill: item.fill || 'transparent',
                        stroke: item.stroke || '#000000',
                        strokeWidth: item.strokeWidth || 2,
                    });
                    break;
                // Add cases for other shapes (circle, ellipse, polygon, etc.) as needed
            }

            if (shape) {
                offScreenLayer.add(shape);
            }
        });

        // Update the layer to render the newly added shapes
        offScreenLayer.batchDraw();
    };

    const drawElementOnOffScreenCanvas = (shape, update = false) => {
        const offScreenLayer = offScreenPatternCanvasRef.current;
        if (!offScreenLayer) {
            console.error("Off-screen layer not found.");
            return;
        }

        if (!update) {
            // Creating a new shape
            switch (shape.type) {
                case 'line':
                    currentOffScreenLine = new Konva.Line({
                        points: shape.points,
                        stroke: shape.stroke,
                        strokeWidth: shape.strokeWidth,
                    });
                    offScreenLayer.add(currentOffScreenLine);
                    break;
                // Add cases for other shapes
            }
        } else {
            // Updating the existing shape (for lines, update the points)
            if (shape.type === 'line' && currentOffScreenLine) {
                currentOffScreenLine.points(shape.points);
            }
        }
        offScreenLayer.batchDraw();
    };

    useEffect(() => {
        if (selectedElement && selectedElement.elems && selectedElement.elems.length > 0) {
            const currentElement = elems.find(elem => elem.id === selectedElement.id);
            setCurrentElement(currentElement);
        }
    }, [selectedElement, elems]);

    useEffect(() => {
        // Whenever selectedElement changes, update design to match the selected element's design
        const element = elems.find(elem => elem.id === selectedElement?.id);
        if (element && element.design) {
            setPatternDesign(element.design);
        } else {
            setPatternDesign([]); // Reset design if there's no selected element or if it doesn't have a design
        }
    }, [selectedElement, elems]);

    const handleMouseDown = (e) => {
        if (selectedPatternTool === 'transform') {
            // If in transform mode, don't start drawing
            return;
        }


        const pos = e.target.getStage().getPointerPosition();
        setIsDrawing(true);
        setStartPoint(pos);

        const startPosRelative = {
            x: pos.x - squarePosition.x,
            y: pos.y - squarePosition.y,
        };

        let newShape;
        let newDesignElement;

        if (selectedPatternTool === 'line') {
            // Store shape for the main canvas
            newShape = {type: 'line', points: [pos.x, pos.y]};
            setLiveDrawingShapes([newShape]); // Start a new line

            // Store design element for the off-screen canvas
            newDesignElement = {
                id: uuidv4(),
                type: 'line',
                points: [startPosRelative.x, startPosRelative.y],
                stroke: '#000000',
                strokeWidth: 5,
                tension: 0.5,
                lineCap: 'round',
                lineJoin: 'round',
                opacity: 1,
            };
        } else if (selectedPatternTool === 'rect') {
            // Store shape for the main canvas
            newShape = {type: 'rect', x: pos.x, y: pos.y, width: 0, height: 0};
            setLiveDrawingShapes([newShape]); // Start a new rect

            // Store design element for the off-screen canvas
            newDesignElement = {
                id: uuidv4(),
                type: 'rect',
                x: startPosRelative.x,
                y: startPosRelative.y,
                width: 0,
                height: 0,
                fill: '#FF0000',
                stroke: '#000000',
                strokeWidth: 1,
                opacity: 1,
            };
        } else if (selectedPatternTool === 'circle') {
            // Store shape for the main canvas
            newShape = {type: 'circle', x: pos.x, y: pos.y, radius: 0};
            setLiveDrawingShapes([newShape]); // Start a new circle

            // Store design element for the off-screen canvas
            newDesignElement = {
                id: uuidv4(),
                type: 'circle',
                x: startPosRelative.x,
                y: startPosRelative.y,
                radius: 0,
                fill: '#FF0000',
                stroke: '#000000',
                strokeWidth: 1,
                opacity: 1,
            };
        } else if (selectedPatternTool === 'star') {
            // Store shape for the main canvas
            newShape = {type: 'star', x: pos.x, y: pos.y, numPoints: 5, innerRadius: 30, outerRadius: 70};
            setLiveDrawingShapes([newShape]); // Start a new star

            // Store design element for the off-screen canvas
            newDesignElement = {
                id: uuidv4(),
                type: 'star',
                x: startPosRelative.x,
                y: startPosRelative.y,
                numPoints: 5,
                innerRadius: 30,
                outerRadius: 70,
                fill: '#FF0000',
                stroke: '#000000',
                strokeWidth: 1,
                opacity: 1,
            };
        } else if (selectedPatternTool === 'polygon') {
            // Store shape for the main canvas
            newShape = {type: 'polygon', x: pos.x, y: pos.y, sides: 5, radius: 50};
            setLiveDrawingShapes([newShape]); // Start a new polygon

            // Store design element for the off-screen canvas
            newDesignElement = {
                id: uuidv4(),
                type: 'polygon',
                x: startPosRelative.x,
                y: startPosRelative.y,
                sides: 5,
                radius: 50,
                fill: '#FF0000',
                stroke: '#000000',
                strokeWidth: 1,
                opacity: 1,
            };
        }

        if (newDesignElement) {
            setPatternDesign([...patternDesign, newDesignElement]); // Add the new design element to the existing design
            drawElementOnOffScreenCanvas(newDesignElement); // Mirror the initial shape drawing on the off-screen canvas
        }
    };

    const handleMouseMove = useCallback(throttle((e) => {
        if (!isDrawing || selectedPatternTool === 'transform') return;
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();

        const pointRelative = {
            x: point.x - squarePosition.x,
            y: point.y - squarePosition.y,
        };

        setLiveDrawingShapes(shapes => shapes.map((shape, index) => {
            if (index === shapes.length - 1) {
                let updatedShape = {...shape};
                if (shape.type === 'line') {
                    updatedShape.points = [...shape.points, point.x, point.y]; // Main canvas
                } else if (shape.type === 'rect' && startPoint) {
                    updatedShape.width = point.x - startPoint.x; // Main canvas
                    updatedShape.height = point.y - startPoint.y; // Main canvas
                } else if (shape.type === 'circle' && startPoint) {
                    const dx = point.x - startPoint.x;
                    const dy = point.y - startPoint.y;
                    const radius = Math.sqrt(dx * dx + dy * dy);
                    updatedShape.radius = radius; // Main canvas
                } else if (shape.type === 'star' && startPoint) {
                    const dx = point.x - startPoint.x;
                    const dy = point.y - startPoint.y;
                    const outerRadius = Math.sqrt(dx * dx + dy * dy);
                    updatedShape.outerRadius = outerRadius; // Main canvas
                } else if (shape.type === 'polygon' && startPoint) {
                    const dx = point.x - startPoint.x;
                    const dy = point.y - startPoint.y;
                    const radius = Math.sqrt(dx * dx + dy * dy);
                    updatedShape.radius = radius; // Main canvas
                }
                return updatedShape;
            }
            return shape;
        }));

        // Update only the last design element
        let lastDesignElement = patternDesign[patternDesign.length - 1];
        if (lastDesignElement.type === 'line') {
            lastDesignElement.points.push(pointRelative.x, pointRelative.y); // Off-screen canvas
        } else if (lastDesignElement.type === 'rect' && startPoint) {
            lastDesignElement.width = pointRelative.x - lastDesignElement.x; // Off-screen canvas
            lastDesignElement.height = pointRelative.y - lastDesignElement.y; // Off-screen canvas
        } else if (lastDesignElement.type === 'circle' && startPoint) {
            const dx = pointRelative.x - lastDesignElement.x;
            const dy = pointRelative.y - lastDesignElement.y;
            const radius = Math.sqrt(dx * dx + dy * dy);
            lastDesignElement.radius = radius; // Off-screen canvas
        } else if (lastDesignElement.type === 'star' && startPoint) {
            const dx = pointRelative.x - lastDesignElement.x;
            const dy = pointRelative.y - lastDesignElement.y;
            const outerRadius = Math.sqrt(dx * dx + dy * dy);
            lastDesignElement.outerRadius = outerRadius; // Off-screen canvas
        } else if (lastDesignElement.type === 'polygon' && startPoint) {
            const dx = pointRelative.x - lastDesignElement.x;
            const dy = pointRelative.y - lastDesignElement.y;
            const radius = Math.sqrt(dx * dx + dy * dy);
            lastDesignElement.radius = radius; // Off-screen canvas
        }

        setPatternDesign([...patternDesign.slice(0, -1), lastDesignElement]); // Update the design state with the modified last element
        drawElementOnOffScreenCanvas(lastDesignElement, true); // Update the off-screen canvas with the modified shape
    }, 10), [isDrawing, liveDrawingShapes, startPoint, squarePosition, patternDesign]);

    const handleMouseUp = () => {
        if (!isDrawing || selectedPatternTool === 'transform') return;
        setIsDrawing(false);
        setStartPoint(null);

        // Replace the latest design in the ref with the new design
        latestDesignRef.current = [...patternDesign];

        // Clear the off-screen canvas
        clearOffScreenCanvas();

        // Load the latest design from the ref onto the off-screen canvas
        loadDesignOntoOffScreenCanvas(latestDesignRef.current);

        // Wait for a short delay to ensure the off-screen canvas has finished updating
        setTimeout(() => {
            // Generate the symmetrical pattern based on the updated design
            if (offScreenPatternCanvasRef.current) {
                const offScreenImage = offScreenPatternCanvasRef.current.toDataURL();
                updateSelectedGroupWithNewDesign(offScreenImage);
            }
        }, 10);

        setLiveDrawingShapes([]);
    };

    const generateSymmetryElements = (tiling, currentGroup) => {
        // Assuming there's only one element and it's an image
        const existingElem = currentGroup.elems[0]; // This should be the image now

        return tiling.map((transform, index) => {
            const uniqueIdPart = uuidv4();
            const elementId = `new_shape_${uniqueIdPart}`;

            // For an image, we are mainly interested in applying the transformations
            // to position it correctly according to the symmetry
            if (existingElem.type === 'image') {
                return {
                    ...existingElem,
                    id: elementId,
                    key: elementId,
                    x: transform.x,
                    y: transform.y,
                    scaleX: transform.a, // Apply the transformation scale
                    scaleY: transform.d, // Apply the transformation scale
                    //rotation: transform.phi * (180 / Math.PI), // Convert radians to degrees for rotation
                    width: existingElem.width,
                    height: existingElem.height, // * transform.d, // Apply the transformation scale
                };
            }

            // If needed, handle other shape types here
            return {
                ...existingElem,
                id: elementId,
                key: elementId,
                x: transform.x,
                y: transform.y,
                width: existingElem.width, // * transform.a, // Apply the transformation scale
                height: existingElem.height, // * transform.d, // Apply the transformation scale
            };
        });
    };

    const updateSelectedGroupWithNewDesign = (offScreenImageURL) => {
        console.log("Updating selected group with new design");
        if (!selectedElement || selectedElement.type !== 'pattern') {
            console.error("No selected pattern element to update.");
            return;
        }

        // Find the selected pattern element from the elems array
        const selectedPatternElement = elems.find(elem => elem.id === selectedElement?.id && elem.type === 'pattern');

        // Generate new symmetrical elements based on the updated design
        const spec = planarSymmetries[symmetryType];
        const tiling = generateTiling(spec, symmetryParameters.nx, symmetryParameters.ny, symmetryParameters.d, symmetryParameters.phi, symmetryParameters.x, symmetryParameters.y);
        const newElems = generateSymmetryElements(tiling, {
            elems: [{
                image: offScreenImageURL,
                width: squareSize,
                height: squareSize,
                x: 0,
                y: 0
            }]
        });

        // Update the selected element's elems and design attributes
        const updatedSelectedGroup = {
            ...selectedPatternElement,
            elems: newElems,
            design: latestDesignRef.current,
        };

        // Update the global elements array
        const updatedElems = elems.map(elem => elem.id === selectedElement.id ? updatedSelectedGroup : elem);

        // Update state
        setSelectedElement(updatedSelectedGroup);
        setElems(updatedElems);
    };

    return (
        <Layer
            id="symmetryShapeEditorLayer"
            ref={symmetricalDrawingLayerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            <Rect
                id="symmetrySquareDrawingPad"
                x={squarePosition.x}
                y={squarePosition.y}
                width={squareSize}
                height={squareSize}
                stroke="grey"
                strokeWidth={2}
                dash={[10, 5]}
            />
            {/* Draw the shapes on the main canvas in the SymmetryShapeEditor component */}
            {liveDrawingShapes.map((shape, i) => {
                if (shape.type === 'line') {
                    return (
                        <Line
                            key={i}
                            points={shape.points}
                            stroke="#df4b26"
                            strokeWidth={5}
                            tension={0.5}
                            lineCap="round"
                            lineJoin="round"
                        />
                    );
                } else if (shape.type === 'rect') {
                    return (
                        <Rect
                            key={i}
                            {...shape}
                            stroke="#df4b26"
                            strokeWidth={2}
                        />
                    );
                } else if (shape.type === 'image') {
                    return (
                        <Image
                            key={i}
                            image={shape.image}
                            x={shape.x}
                            y={shape.y}
                            scaleX={shape.scaleX}
                            scaleY={shape.scaleY}
                        />
                    );
                } else if (shape.type === 'circle') {
                    return (
                        <Circle
                            key={i}
                            {...shape}
                            stroke="#df4b26"
                            strokeWidth={2}
                        />
                    );
                } else if (shape.type === 'star') {
                    return (
                        <Star
                            key={i}
                            {...shape}
                            stroke="#df4b26"
                            strokeWidth={2}
                        />
                    );
                } else if (shape.type === 'polygon') {
                    return (
                        <RegularPolygon
                            key={i}
                            {...shape}
                            stroke="#df4b26"
                            strokeWidth={2}
                        />
                    );
                }
                // Add more conditionals for other shape types (e.g., polygon)
                return null;
            })}
            {/* Render the design if it exists */}
            {currentElement && currentElement.type === 'pattern' && currentElement.design && (
                <Group
                    scaleX={1} // Adjust scale if needed
                    scaleY={1} // Adjust scale if needed
                    x={squarePosition.x}
                    y={squarePosition.y}
                >
                    {currentElement.design.map((shape, index) => {
                        if (shape.type === 'line') {
                            return (
                                <Line
                                    id={shape.id}
                                    type="line"
                                    key={index}
                                    points={shape.points}
                                    stroke={shape.stroke}
                                    strokeWidth={shape.strokeWidth}
                                    tension={shape.tension}
                                    lineCap={shape.lineCap}
                                    lineJoin={shape.lineJoin}
                                    draggable={selectedPatternTool === 'transform'}
                                    onClick={(e) => {
                                        if (selectedPatternTool === 'transform') {
                                            handleSelectDesignShape(shape.id);
                                        }
                                    }}
                                    onDragEnd={(e) => {
                                        if (selectedPatternTool === 'transform') {
                                            console.log("Line dragged: ", e)
                                            console.log("Line dragged: ", e.target.x(), e.target.y());
                                            const updatedLine = {
                                                ...shape,
                                                x: e.target.x(),
                                                y: e.target.y(),
                                            };
                                            console.log("Updated line: ", updatedLine);
                                            const updatedDesign = currentElement.design.map((s) => (s.id === shape.id ? updatedLine : s));
                                            console.log("Updated design: ", updatedDesign);
                                            setPatternDesign(updatedDesign);
                                            latestDesignRef.current = updatedDesign;
                                            loadDesignOntoOffScreenCanvas(updatedDesign);
                                            updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                        }
                                    }}
                                    onTransformEnd={() => {
                                        const node = transformerRef.current.nodes()[0];
                                        const updatedLine = {
                                            ...shape,
                                            x: node.x(),
                                            y: node.y(),
                                            rotation: node.rotation(),
                                            scaleX: node.scaleX(),
                                            scaleY: node.scaleY(),
                                            opacity: node.opacity(),
                                        };
                                        const updatedDesign = currentElement.design.map((s) => (s.id === shape.id ? updatedLine : s));
                                        setPatternDesign(updatedDesign);
                                        latestDesignRef.current = updatedDesign;
                                        loadDesignOntoOffScreenCanvas(updatedDesign);
                                        updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                    }}
                                />
                            );
                        } else if (shape.type === 'rect') {
                            return (
                                <Rect
                                    id={shape.id}
                                    type="rect"
                                    key={index}
                                    x={shape.x}
                                    y={shape.y}
                                    width={shape.width}
                                    height={shape.height}
                                    rotation={shape.rotation}
                                    scaleX={shape.scaleX}
                                    scaleY={shape.scaleY}
                                    opacity={shape.opacity}
                                    fill={shape.fill}
                                    stroke={shape.stroke}
                                    strokeWidth={shape.strokeWidth}
                                    draggable={selectedPatternTool === 'transform'}
                                    onClick={() => {
                                        if (selectedPatternTool === 'transform') {
                                            console.log("Rect clicked");
                                            handleSelectDesignShape(shape.id);
                                        }
                                    }}
                                    onDragEnd={(e) => {
                                        if (selectedPatternTool === 'transform') {
                                            const updatedRect = {
                                                ...shape,
                                                x: e.target.x(),
                                                y: e.target.y(),
                                                width: e.target.width(),
                                                height: e.target.height(),
                                                rotation: e.target.rotation(),
                                                scaleX: e.target.scaleX(),
                                                scaleY: e.target.scaleY(),
                                                opacity: e.target.opacity(),
                                            };
                                            const updatedDesign = currentElement.design.map((s) => (s.id === shape.id ? updatedRect : s));
                                            setPatternDesign(updatedDesign);
                                            latestDesignRef.current = updatedDesign; // Update latestDesignRef
                                            loadDesignOntoOffScreenCanvas(updatedDesign);
                                            updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                        }
                                    }}
                                    onTransformEnd={() => {
                                        console.log("Transform end - RECT");
                                        const node = transformerRef.current.nodes()[0];
                                        const updatedRect = {
                                            ...shape,
                                            x: node.x(),
                                            y: node.y(),
                                            width: node.width(),
                                            height: node.height(),
                                            rotation: node.rotation(),
                                            scaleX: node.scaleX(),
                                            scaleY: node.scaleY(),
                                            opacity: node.opacity(),
                                        };
                                        const updatedDesign = currentElement.design.map((s) => {
                                            if (s.id === shape.id) {
                                                return updatedRect;
                                            } else {
                                                return s;
                                            }
                                        });
                                        setPatternDesign(updatedDesign);
                                        latestDesignRef.current = updatedDesign; // Update latestDesignRef
                                        loadDesignOntoOffScreenCanvas(updatedDesign);
                                        updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                    }}
                                />
                            );
                        } else if (shape.type === 'circle') {
                            return (
                                <Circle
                                    id={shape.id}
                                    type="circle"
                                    key={index}
                                    x={shape.x}
                                    y={shape.y}
                                    radius={shape.radius}
                                    rotation={shape.rotation}
                                    scaleX={shape.scaleX}
                                    scaleY={shape.scaleY}
                                    opacity={shape.opacity}
                                    fill={shape.fill}
                                    stroke={shape.stroke}
                                    strokeWidth={shape.strokeWidth}
                                    draggable={selectedPatternTool === 'transform'}
                                    onClick={() => {
                                        if (selectedPatternTool === 'transform') {
                                            console.log("Circle clicked");
                                            handleSelectDesignShape(shape.id);
                                        }
                                    }}
                                    onDragEnd={(e) => {
                                        if (selectedPatternTool === 'transform') {
                                            const updatedCircle = {
                                                ...shape,
                                                x: e.target.x(),
                                                y: e.target.y(),
                                                radius: e.target.radius(),
                                                rotation: e.target.rotation(),
                                                scaleX: e.target.scaleX(),
                                                scaleY: e.target.scaleY(),
                                                opacity: e.target.opacity(),
                                            };
                                            const updatedDesign = currentElement.design.map((s) => (s.id === shape.id ? updatedCircle : s));
                                            setPatternDesign(updatedDesign);
                                            latestDesignRef.current = updatedDesign; // Update latestDesignRef
                                            loadDesignOntoOffScreenCanvas(updatedDesign);
                                            updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                        }
                                    }}
                                    onTransformEnd={() => {
                                        console.log("Transform end - CIRCLE");
                                        const node = transformerRef.current.nodes()[0];
                                        const updatedCircle = {
                                            ...shape,
                                            x: node.x(),
                                            y: node.y(),
                                            radius: node.radius(),
                                            rotation: node.rotation(),
                                            scaleX: node.scaleX(),
                                            scaleY: node.scaleY(),
                                            opacity: node.opacity(),
                                        };
                                        const updatedDesign = currentElement.design.map((s) => {
                                            if (s.id === shape.id) {
                                                return updatedCircle;
                                            } else {
                                                return s;
                                            }
                                        });
                                        setPatternDesign(updatedDesign);
                                        latestDesignRef.current = updatedDesign; // Update latestDesignRef
                                        loadDesignOntoOffScreenCanvas(updatedDesign);
                                        updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                    }}
                                />
                            );
                        } else if (shape.type === 'star') {
                            return (
                                <Star
                                    id={shape.id}
                                    type="star"
                                    key={index}
                                    x={shape.x}
                                    y={shape.y}
                                    numPoints={shape.numPoints}
                                    innerRadius={shape.innerRadius}
                                    outerRadius={shape.outerRadius}
                                    rotation={shape.rotation}
                                    scaleX={shape.scaleX}
                                    scaleY={shape.scaleY}
                                    opacity={shape.opacity}
                                    fill={shape.fill}
                                    stroke={shape.stroke}
                                    strokeWidth={shape.strokeWidth}
                                    draggable={selectedPatternTool === 'transform'}
                                    onClick={() => {
                                        if (selectedPatternTool === 'transform') {
                                            console.log("Star clicked");
                                            handleSelectDesignShape(shape.id);
                                        }
                                    }}
                                    onDragEnd={(e) => {
                                        if (selectedPatternTool === 'transform') {
                                            const updatedStar = {
                                                ...shape,
                                                x: e.target.x(),
                                                y: e.target.y(),
                                                numPoints: e.target.numPoints(),
                                                innerRadius: e.target.innerRadius(),
                                                outerRadius: e.target.outerRadius(),
                                                rotation: e.target.rotation(),
                                                scaleX: e.target.scaleX(),
                                                scaleY: e.target.scaleY(),
                                                opacity: e.target.opacity(),
                                            };
                                            const updatedDesign = currentElement.design.map((s) => (s.id === shape.id ? updatedStar : s));
                                            setPatternDesign(updatedDesign);
                                            latestDesignRef.current = updatedDesign; // Update latestDesignRef
                                            loadDesignOntoOffScreenCanvas(updatedDesign);
                                            updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                        }
                                    }}
                                    onTransformEnd={() => {
                                        console.log("Transform end - STAR");
                                        const node = transformerRef.current.nodes()[0];
                                        const updatedStar = {
                                            ...shape,
                                            x: node.x(),
                                            y: node.y(),
                                            numPoints: node.numPoints(),
                                            innerRadius: node.innerRadius(),
                                            outerRadius: node.outerRadius(),
                                            rotation: node.rotation(),
                                            scaleX: node.scaleX(),
                                            scaleY: node.scaleY(),
                                            opacity: node.opacity(),
                                        };
                                        const updatedDesign = currentElement.design.map((s) => {
                                            if (s.id === shape.id) {
                                                return updatedStar;
                                            } else {
                                                return s;
                                            }
                                        });
                                        setPatternDesign(updatedDesign);
                                        latestDesignRef.current = updatedDesign; // Update latestDesignRef
                                        loadDesignOntoOffScreenCanvas(updatedDesign);
                                        updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                    }
                                    }
                                />
                            );
                        } else if (shape.type === 'polygon') {
                            return (
                                <RegularPolygon
                                    id={shape.id}
                                    type="polygon"
                                    key={index}
                                    x={shape.x}
                                    y={shape.y}
                                    sides={shape.sides}
                                    radius={shape.radius}
                                    rotation={shape.rotation}
                                    scaleX={shape.scaleX}
                                    scaleY={shape.scaleY}
                                    opacity={shape.opacity}
                                    fill={shape.fill}
                                    stroke={shape.stroke}
                                    strokeWidth={shape.strokeWidth}
                                    draggable={selectedPatternTool === 'transform'}
                                    onClick={() => {
                                        if (selectedPatternTool === 'transform') {
                                            console.log("Polygon clicked");
                                            handleSelectDesignShape(shape.id);
                                        }
                                    }}
                                    onDragEnd={(e) => {
                                        if (selectedPatternTool === 'transform') {
                                            const updatedPolygon = {
                                                ...shape,
                                                x: e.target.x(),
                                                y: e.target.y(),
                                                sides: e.target.sides(),
                                                radius: e.target.radius(),
                                                rotation: e.target.rotation(),
                                                scaleX: e.target.scaleX(),
                                                scaleY: e.target.scaleY(),
                                                opacity: e.target.opacity(),
                                            };
                                            const updatedDesign = currentElement.design.map((s) => (s.id === shape.id ? updatedPolygon : s));
                                            setPatternDesign(updatedDesign);
                                            latestDesignRef.current = updatedDesign; // Update latestDesignRef
                                            loadDesignOntoOffScreenCanvas(updatedDesign);
                                            updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                        }
                                    }}
                                    onTransformEnd={() => {
                                        console.log("Transform end - POLYGON");
                                        const node = transformerRef.current.nodes()[0];
                                        const updatedPolygon = {
                                            ...shape,
                                            x: node.x(),
                                            y: node.y(),
                                            sides: node.sides(),
                                            radius: node.radius(),
                                            rotation: node.rotation(),
                                            scaleX: node.scaleX(),
                                            scaleY: node.scaleY(),
                                            opacity: node.opacity(),
                                        };
                                        const updatedDesign = currentElement.design.map((s) => {
                                            if (s.id === shape.id) {
                                                return updatedPolygon;
                                            } else {
                                                return s;
                                            }
                                        });
                                        setPatternDesign(updatedDesign);
                                        latestDesignRef.current = updatedDesign; // Update latestDesignRef
                                        loadDesignOntoOffScreenCanvas(updatedDesign);
                                        updateSelectedGroupWithNewDesign(offScreenPatternCanvasRef.current.toDataURL());
                                    }
                                    }
                                />
                            );
                        }
                        // Add more shape types as needed
                        return null;
                    })}
                </Group>
            )}
            <Transformer
                ref={transformerRef} // You'll manage this ref to attach it to selected shapes
                // Additional props to customize the transformer appearance and behavior
            />
        </Layer>
    );
};

export default SymmetryShapeEditor;



