import React from "react";
import {Button} from "react-bootstrap";
import cloneIcon from "../../../../assets/images/icon/clone.svg";
export const CloneControls = ({
                                  isMobileView,
                                  elems,
                                  selectedId,
                                  onElementsChange,
                                  onButtonClick,
                                  mobileIconStyles,
                                  trRef,
                                  setSelectedElement
                              }) => {

    function clone() {
        console.log("clone")
        const index = elems.findIndex((e) => e.id === selectedId);
        let propCopy = elems.slice();
        const elemByIndex = propCopy[index];
        const newElem = Object.assign({}, elemByIndex, {
            id: (new Date()).getTime(),
            x: elemByIndex.x + 100,
            y: elemByIndex.y + 100,
        });
        trRef.current.nodes([]);
        setSelectedElement(null);

        propCopy.push(newElem)
        onElementsChange(propCopy.slice());


    }

    if (isMobileView) {
        return (
            <button
                style={isMobileView ? mobileIconStyles.mobile : mobileIconStyles.desktop}
                className={isMobileView ? "mobile_context_button" : ""}
                onClick={clone}
            >
                <div
                    className={isMobileView ? "mobile_context_button_icon" : ""}
                    data-icon="Clone22"
                    style={{
                        ...mobileIconStyles.icon,
                        backgroundImage: `url(${cloneIcon})`
                    }}
                />
                <div>Clone</div>
            </button>
        )
    }
    else {
        return (
            <Button onClick={clone} title="Clone">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div
                        className={isMobileView ? "mobile_context_button_icon" : ""}
                        data-icon="Clone"
                        style={{
                            ...mobileIconStyles.desktopIcon,
                            backgroundImage: `url(${cloneIcon})`,
                        }}
                    />
                    Clone
                </div>
            </Button>
        )
    }

}