import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "content"]
  
  connect() {
    this.checkScreenSize()
    window.addEventListener('resize', () => this.checkScreenSize())
  }

  toggleSidebar() {
    this.menuTarget.classList.toggle('collapsed')
    this.contentTarget.classList.toggle('expanded')
  }

  checkScreenSize() {
    if (window.innerWidth < 768) {
      this.menuTarget.classList.add('collapsed')
      this.contentTarget.classList.add('expanded')
    } else {
      this.menuTarget.classList.remove('collapsed')
      this.contentTarget.classList.remove('expanded')
    }
  }
}