import React, { useState, useEffect } from 'react';

const ViewBox = (props) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth - 200,
    height: window.innerHeight - 270
  });

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = props.designViewRef.current.offsetWidth;
      const containerHeight = props.designViewRef.current.offsetHeight;

      setDimensions({
        width: containerWidth,
        height: containerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Set initial dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.designViewRef]);

  const svgStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,  // this ensures the SVG is behind the canvas
    opacity: 0.4,
    width: dimensions.width + 'px',
    height: dimensions.height + 'px'
  };

  return (
      <svg id="gridViewBox" xmlns="http://www.w3.org/2000/svg" style={svgStyle}>
        <defs>
          <pattern
              id="smallGrid"
              width="8"
              height="8"
              patternUnits="userSpaceOnUse"
          >
            <path fill="none" stroke="gray" strokeWidth="0.5" d="M8 0H0v8"></path>
          </pattern>
          <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
            <path fill="url(#smallGrid)" d="M0 0H80V80H0z"></path>
            <path fill="none" stroke="gray" d="M80 0H0v80"></path>
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)"></rect>
      </svg>
  );
}

export default ViewBox;
