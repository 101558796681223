import React from 'react';

export const ContextMenu = ({
    position,
    onRemove,
    moveToTop,
    moveToBottom,
    moveUp,
    moveDown,
    onHide,
}) => {
    const actions = {
        "Move to Top": moveToTop,
        "Move to Bottom": moveToBottom,
        "Move Up": moveUp,
        "Move Down": moveDown,
        Remove: onRemove
    };

    React.useEffect(() => {
        window.addEventListener('click', onHide);
        return () => window.removeEventListener('click', onHide);
    }, [onHide]);

    return (
        <div
            style={{
                position: 'absolute',
                width: '100px',
                backgroundColor: 'white',
                boxShadow: '0 0 5px grey',
                borderRadius: '3px',
                top: position.y + 'px',
                left: position.x + 'px',
            }}
        >
            <div>
                {Object.keys(actions).map((label) => {
                    return (
                        <button
                            key={label}
                            onClick={actions[label]}
                            style={{
                                width: '100%',
                                backgroundColor: 'white',
                                border: 'none',
                                margin: '0',
                                padding: '10px',
                            }}
                        >
                            {label}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};