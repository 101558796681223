export const initializeBootstrapComponents = () => {
  // Initialize Bootstrap 5 tooltips
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

  // Initialize popovers
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
};

export const initializeMobileMenu = () => {
  const toggler = document.getElementById('mobile-navbar-toggler');
  const menu = document.getElementById('mobile-navbar-menu');

  if (toggler && menu) {
    toggler.addEventListener('click', () => {
      menu.style.display = menu.style.display === "none" || menu.style.display === "" 
        ? "contents" 
        : "none";
    });
  }
};

export const initializeColorCircles = () => {
  const colorCircles = document.querySelectorAll('.color-circle');
  colorCircles.forEach(circle => {
    circle.addEventListener('mouseover', function() {
      const color = this.getAttribute('data-color');
      const productImage = document.querySelector(`#product-image-${this.getAttribute('data-product-id')}`);
      if (productImage) {
        productImage.style.backgroundColor = color;
      }
    });
  });
};

